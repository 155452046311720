import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  addAllowanceInputAction,
  addDeductibleInputAction,
  resetSalaryConfigFormAction,
  setInputInValidateAction,
  setInputValidateAction,
  updateValueAction,
  setSalaryCalculationToFixed,
  onFormResetAction,
  showErrorToast,
  onInputResetWithValueAction,
} from "../../Store/Actions";
import PaymentFactors from "../../Constants/PaymentFactors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoneyBill,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import moment from "moment";

import {
  CheckboxBooleanForm,
  DateTimePickerForm,
  InputForm,
  BSelect,
  RadioboxForm,
} from "form-builder";
import "react-dropzone-uploader/dist/styles.css";
import Loader from "../../Components/Loader";
import DeuductableSection from "./DeuductableSection";
import AllowanceSection from "./AllowanceSection";
import SocialSection from "./SocialSection";
import MedicalSection from "./MedicalSection";
import TaxSection from "./TaxSection";
import PenaltyCalculation from "./PenaltyCalculation";
import BonusCalculation from "./BonusCalculation";
import UnpaidCalculation from "./UnpaidCalculation";
import OvertimeCalculation from "./OvertimeCalculation";
import HourlySectionDetails from "./HourlySectionDetails";
import { gql, useLazyQuery } from "@apollo/client";
import {
  GET_RELEVANT_TAX_INFO,
} from "../../Graphql/query";
import { GET_WEEK_OPTIONS } from "../../Graphql/query";
import { EditIconButton } from "../IconButtonWithTooltip";
import { Spinner } from "reactstrap";
import {
  normalizeFetchedSalaryConfigInput,
  normalizeFetchedTimeRangeProfileInput,
} from "../../Helpers/HelperFns";
import _ from "lodash";
import { RegulationTypes } from "../../Constants/RegulationTypes";
import { AccessTime } from "@mui/icons-material";
import OvertTimeGraceMinutesForm from "../../Containers/OvertTimeGraceMinutesForm";

const OvertTimeRangeSetting = (props) => {
  const { t } = useTranslation();

  const { formServerValidation } = props;
  return (
    <>
      <div className="name_container row align-items-center mt-4 mb-2 mx-0 gap-10">
        <AccessTime style={{ fontSize: 16, color: "#0890d1" }} />
        <h4 className="sub-title-style mb-0">
          {t("overtime configuration")}
        </h4>
      </div>

      <OvertTimeGraceMinutesForm
        serverValidationPrefix="input.salaryConfiguration.employee_time_range_profile_input."
        hideType
        FormProps={{
          formName: "timeRangeProfileSetting",
          formServerValidation,
        }}
      />
    </>
  );
};

const SalaryConfigForm = ({
  countryId,
  userId,
  office,
  isSalaryConfigModal = false,
  ...props
}) => {
  const formName = props.formName;
  const formNameValidation = `salaryConfigFormValidation`;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [fetchWeeksOptions, { loading, data }] = useLazyQuery(
    GET_WEEK_OPTIONS,
    {
      onCompleted: (data) => {
        if (props?.isFirst || props?.activate) {
          let startDate = props?.isFirst
            ? starts_at
            : start_at;

          const preselectedWeek = data?.weeks?.find(
            (week) =>
              (moment(startDate).isSame(moment(week?.from), "days") ||
                moment(startDate).isAfter(moment(week?.from), "days")) &&
              (moment(startDate).isSame(moment(week?.paidAt), "days") ||
                moment(startDate).isBefore(moment(week?.paidAt), "days"))
          );

          dispatch(
            onInputResetWithValueAction(
              formName,
              "week_start",
              preselectedWeek?.from
            )
          );
        }
      },
    }
  );

  const FormProps = {
    formName,
    formNameValidation,
    formSubmitting: props.formSubmitting,
    formServerValidation: props.formServerValidation,
  };

  // Reducer State
  const starts_at = useSelector((state) => state.super.employeeForm?.starts_at);
  const state_id = useSelector((state) => state.super.employeeForm?.state_id);
  const office_id = useSelector((state) => state.super.employeeForm?.office_id);

  const id = useSelector((state) => state.super.salaryConfigForm?.id);
  const base_salary = useSelector((state) => state.super.salaryConfigForm?.base_salary);
  const salary_deductibles = useSelector((state) => state.super.salaryConfigForm?.salary_deductibles);
  const salary_allowances = useSelector((state) => state.super.salaryConfigForm?.salary_allowances);
  const socialDeduction = useSelector((state) => state.super.salaryConfigForm?.socialDeduction);
  const medicalDeduction = useSelector((state) => state.super.salaryConfigForm?.medicalDeduction);
  const regulation_type_id = useSelector((state) => state.super.salaryConfigForm?.regulation_type_id);
  const taxDeduction = useSelector((state) => state.super.salaryConfigForm?.taxDeduction);
  const calculate_salary_by = useSelector((state) => state.super.salaryConfigForm?.calculate_salary_by);
  const salary_management_type = useSelector((state) => state.super.salaryConfigForm?.salary_management_type);
  const start_at = useSelector((state) => state.super.salaryConfigForm?.start_at);
  const tax_info_id = useSelector((state) => state.super.salaryConfigForm?.tax_info_id);
  const currency_id = useSelector((state) => state.super.salaryConfigForm?.currency_id);
  const payment_interval = useSelector((state) => state.super.salaryConfigForm?.payment_interval);
  const canEditStartAt = useSelector((state) => state.super.salaryConfigForm?.canEditStartAt);
  const company_salary_config_preset_id = useSelector((state) => state.super.salaryConfigForm?.company_salary_config_preset_id);

  const salaryConfigFormServerValidation = useSelector(
    (state) => state.super[props.formServerValidation]
  );

  const salaryConfigFormValidation = useSelector(
    (state) => state.super[formNameValidation]
  );

  const userCurrenciesOptions = useSelector(
    (state) => state.super?.userCurrenciesOptions
  );

  const companySalaryConfigPresets = useSelector(
    (state) => state.super.companySalaryConfigPresets
  );

  const offices = useSelector((state) => state.super.editEmployeeFormData?.offices);

  const loadingPresetSalary = useSelector(
    (state) => state.super.loadingPresetSalary
  );

  const userRegulationTypesOptions = useSelector(
    (state) => state.super?.userRegulationTypesOptions
  );

  const relevantStates = useSelector((state) => state.super?.relevantStates);

  const showState = !!userRegulationTypesOptions?.find(
    (regulation) => regulation?.id == regulation_type_id
  )?.hasCustomizationOnStates;

  // Form Clean Up

  const handleSalaryManagementTypeChange = (e) => {
    if (e.target.checked) {
      dispatch(resetSalaryConfigFormAction(1));
      // breaks
      return true;
    } else {
      dispatch(resetSalaryConfigFormAction(0));
      // break;
      return true;
    }
  };

  const handleAddAllowanceInput = () => {
    dispatch(addAllowanceInputAction());
  };

  const handleAddDeductibleInput = () => {
    dispatch(addDeductibleInputAction());
  };

  const totalGross = () => {
    let base = +base_salary;
    return `${salary_allowances
      ?.map((allowance) =>
        allowance.payment_factor_id == PaymentFactors.FIXED_AMOUNT
          ? +allowance.value
          : allowance.payment_factor_id == PaymentFactors.BASE_PERCENTAGE
            ? +(+allowance.value * base) / 100
            : 0
      )
      .reduce((acc, curr) => acc + curr, base)
      .toFixed(2)}`;
  };

  const totalNet = () => {
    let base = +base_salary;
    let gross = +totalGross();

    return `${salary_deductibles
      ?.map((deductible) =>
        deductible.payment_factor_id == PaymentFactors.FIXED_AMOUNT ||
          deductible.payment_factor_id ==
          PaymentFactors.PAYMENT_FACTOR_ACCORDING_TO_CUSTOM_EQUATION ||
          deductible.payment_factor_id == PaymentFactors.PERCENT_OF_INSURED_SALARY
          ? +deductible.value
          : deductible.payment_factor_id == PaymentFactors.BASE_PERCENTAGE
            ? +(+deductible.value * base) / 100
            : deductible.payment_factor_id == PaymentFactors.GROSS_PERCENTAGE
              ? +(+deductible.value * gross) / 100
              : 0
      )
      .reduce(
        (acc, curr) => acc - curr,
        gross -
        +socialDeduction -
        +medicalDeduction -
        +taxDeduction
      )
      .toFixed(2)}`;
  };

  useEffect(() => {
    if (
      totalNet() < 0 &&
      calculate_salary_by == "payment_interval"
    ) {
      dispatch(setInputInValidateAction(formNameValidation, "totalNet"));
    } else {
      dispatch(setInputValidateAction(formNameValidation, "totalNet"));
    }

    return () => {
      dispatch(setInputValidateAction(formNameValidation, "totalNet"));
    };
  }, [totalNet()]);

  useEffect(() => {
    return () => {
      // reset regulations info and relevant states
      dispatch(onFormResetAction("userRegulationTypesOptions"));
      dispatch(onFormResetAction("relevantStates"));
    };
  }, []);

  useEffect(() => {
    if (
      !id &&
      !salary_management_type &&
      !!office
    ) {
      const paymentIntervals = JSON.parse(office?.payment_intervals);

      let preselectPaymentInterval =
        paymentIntervals?.length === 2
          ? payment_interval ?? "monthly"
          : paymentIntervals?.includes("Month")
            ? "monthly"
            : paymentIntervals?.includes("Week")
              ? "weekly"
              : null;

      dispatch(
        onInputResetWithValueAction(
          formName,
          "payment_interval",
          preselectPaymentInterval
        )
      );
    }
  }, [
    office?.payment_intervals,
    salary_management_type,
    office,
  ]);

  useEffect(() => {
    if (props.isFirst) {
      if (
        starts_at !== "" &&
        typeof starts_at == "string" &&
        !!regulation_type_id &&
        countryId &&
        ((!!showState && !!state_id) || !!!showState)
      ) {
        attemptGetRelevantTaxInfo({
          variables: {
            input: {
              regulation_type_id: regulation_type_id,
              state_id: state_id,
              date: moment(starts_at).format("YYYY-MM-DD"),
              country_id: countryId,
            },
          },
        });
      }
    } else {
      if (
        start_at !== "" &&
        typeof start_at == "string" &&
        !!regulation_type_id &&
        countryId &&
        ((!!showState && !!state_id) || !!!showState)
      ) {
        attemptGetRelevantTaxInfo({
          variables: {
            input: {
              regulation_type_id: regulation_type_id,
              state_id: state_id,
              date: moment(start_at, [
                "MM-YYYY",
                "YYYY-MM-DD",
              ]).format("YYYY-MM"),
              country_id: countryId,
            },
          },
        });
      }
    }
  }, [
    starts_at,
    start_at,
    regulation_type_id,
    countryId,
    state_id,
  ]);

  useEffect(() => {
    if (
      (!!starts_at || !!start_at) &&
      payment_interval == "weekly" &&
      ((props?.isFirst && !!office_id) ||
        (!props?.isFirst && !!userId))
    ) {
      const selectedDate = props.isFirst
        ? starts_at
        : start_at;

      fetchWeeksOptions({
        variables: {
          from: moment(selectedDate, ["MM-YYYY", "DD-MM-YYYY", "YYYY-MM-DD"])
            ?.startOf("month")
            .format("YYYY-MM-DD"),
          to: moment(selectedDate, ["MM-YYYY", "DD-MM-YYYY", "YYYY-MM-DD"])
            ?.endOf("month")
            .format("YYYY-MM-DD"),
          userId: +userId,
          type: props?.isFirst ? "Office" : "Users",
          filterKey: "from",
        },
      });
    }
  }, [
    starts_at,
    office_id,
    start_at,
    salary_management_type,
    payment_interval,
  ]);

  const handleInterceptPaymnetIntervalChange = (e) => {
    if (
      e.target.name === "calculate_salary_by" &&
      e.target.value !== "payment_interval"
    ) {
      dispatch(setSalaryCalculationToFixed());
    }
  };

  const defaultCurrency =
    userRegulationTypesOptions
      ?.find(
        (regulation) => regulation?.id == regulation_type_id
      )
      ?.taxInfos?.find(
        (taxInfo) => taxInfo?.id == tax_info_id
      )?.country?.currency_id ??
    userCurrenciesOptions?.find((currency) => currency?.defaultCurrencyOffice)
      ?.id ??
    "";

  useEffect(() => {
    if (!!defaultCurrency && defaultCurrency != "") {
      dispatch(updateValueAction(formName, "currency_id", defaultCurrency));
    }
  }, [defaultCurrency, salary_management_type]);

  useEffect(() => {
    if (payment_interval == "weekly") {
      console.log("changing regulation type");
      dispatch(
        onInputResetWithValueAction(
          formName,
          "regulation_type_id",
          RegulationTypes.MAWARED_CUSTOM
        )
      );
    }
  }, [payment_interval]);

  const [attemptGetRelevantTaxInfo, { data: relevantTaxInfoData }] =
    useLazyQuery(GET_RELEVANT_TAX_INFO, {
      onCompleted: (data) => {
        dispatch(
          updateValueAction(formName, "tax_info_id", data?.relevantTaxInfo?.id)
        );
      },
      onError: (err) => {
        dispatch(updateValueAction(formName, "tax_info_id", null));
        dispatch(showErrorToast(err?.[0]?.message ?? "something went wrong"));
      },
    });

  const [isEditStartDate, setIsEditStartDate] = useState(false);

  const FETCH_OFFICE_START_DATE_QUERY = gql`
    query fetchOfficeStartDate($monthYear: String, $userId: ID) {
      payroll_v2_first_day(monthYear: $monthYear, userId: $userId)
    }
  `;
  const [
    fetchOfficeStartDateRelevantToMonth,
    {
      data: monthStartDate,
      loading: loadingMonthStartDate,
      error: errorMonthStartDate,
    },
  ] = useLazyQuery(FETCH_OFFICE_START_DATE_QUERY, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data?.payroll_v2_first_day) {
        dispatch(
          updateValueAction(formName, "start_at", data?.payroll_v2_first_day)
        );
      }
    },
    onError: (error) => {
      dispatch(
        showErrorToast(
          error?.graphQLErrors?.[0]?.extensions?.reason || error?.message
        )
      );
    },
  });
  const handleMonthyearChange = (value) => {
    if (value && moment(value).isValid()) {
      fetchOfficeStartDateRelevantToMonth({
        variables: {
          monthYear: moment(value).format("YYYY-MM"),
          userId: userId,
        },
      });
    }
  };

  const weekOptions = data?.weeks ?? [];


  const handleChangePreset = (preset) => {
    dispatch(
      onFormResetAction("salaryConfigForm", {
        ..._.cloneDeep({
          ...normalizeFetchedSalaryConfigInput({
            id: id,
            from: start_at,
            type: !!salary_management_type
              ? "External"
              : "Internal",
            payment: preset?.payment,
            details: preset?.details ?? {},
          }),
        }),
      })
    );
    dispatch(
      onFormResetAction("timeRangeProfileSetting", {
        ..._.cloneDeep({
          ...normalizeFetchedTimeRangeProfileInput({
            ...preset?.fixedSettings,
          }),
        }),
      })
    );
  };

  return (
    <div className={isSalaryConfigModal ? "" : "boxContainer"}>
      <>
        <div
          className={`name_container ${isSalaryConfigModal ? "d-flex" : ""}`}
        >
          <FontAwesomeIcon
            className=""
            icon={faMoneyBill}
            color="#23aaeb"
            size={isSalaryConfigModal ? "lg" : "2x"}
          />
          <h4
            className={`sub-title-style mb-0 ${isSalaryConfigModal ? "ml-2" : ""
              }`}
          >
            {t("salary configuration")}
          </h4>
        </div>
        <hr />
      </>
      {loadingPresetSalary ? (
        <div className="loader_wrapper_style">
          <Loader />
        </div>
      ) : null}

      <div className="mt-2 px-2">
        <div className="d-flex flex-column flex-md-row justify-content-between">
          {calculate_salary_by == "payment_interval" ? (
            <div className="add_salary_config_modal_wrapper_style d-flex justify-content-between mt-4 mt-lg-0">
              <div className="d-flex ">
                <div className="d-flex salary_amount_style justify-content-end pr-3 font-weight-normal mb-0">
                  {t("gross_salary")}
                  <p className="font-weight-bold mb-0">
                    <span>{totalGross()}</span>{" "}
                    {props.isFirst
                      ? t(
                        offices
                          ?.find(({ id }) => id == office_id)
                          ?.currencies.find(
                            ({ id }) => id == currency_id
                          )?.name
                      )
                      : t(
                        userCurrenciesOptions?.find(
                          ({ id }) => id == currency_id
                        )?.name
                      )}
                  </p>
                </div>

                <div className="d-flex salary_amount_style justify-content-end font-weight-normal mb-0">
                  {t("net_salary")}
                  <p className="font-weight-bold mb-0">
                    <span>{totalNet()}</span>{" "}
                    {props.isFirst
                      ? t(
                        offices
                          ?.find(({ id }) => id == office_id)
                          ?.currencies.find(
                            ({ id }) => id == currency_id
                          )?.name
                      )
                      : t(
                        userCurrenciesOptions?.find(
                          ({ id }) => id == currency_id
                        )?.name
                      )}
                  </p>
                  <br />
                  <span className="invalid-container-style mx-2">
                    {salaryConfigFormValidation.includes("totalNet") &&
                      props.formSubmitting && (
                        <span className="validity-msg-style">
                          {t("total net can't be negative value")}
                        </span>
                      )}
                  </span>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div className="add_salary_config_modal_wrapper_style mt-4">
          <div>
            <div className="row justify-content-between">
              <div
                className={`col-12 col-lg-8 ${props.activate ? "d-none" : ""}`}
              >
                {props.isFirst ? (
                  <>
                    <DateTimePickerForm
                      name="starts_at"
                      formName="employeeForm"
                      containerStyle="py-0 mb-2"
                      datePickerContainer="w-100"
                      placeholder={t("Choose Month")}
                      hasIcon
                      isClearable
                      disabled
                      {...FormProps}
                      validationName={`args.from`}
                    />
                  </>
                ) : (
                  <div className="row align-items-center">
                    <DateTimePickerForm
                      label="Payroll Month"
                      name="monthYear"
                      requestFormat="MM-YYYY"
                      rootStyle="col-md-6"
                      onIntercept={handleMonthyearChange}
                      disabled={
                        !canEditStartAt &&
                        id
                      }
                      {...FormProps}
                      containerStyle="py-0 mb-2"
                      datePickerContainer="w-100"
                      validateBy={props.activate ? null : "textRequired"}
                      placeholder={t("Choose Month")}
                      hasIcon
                      isClearable
                      validationName={`args.from`}
                      {...(!props.activate
                        ? {
                          mode: "month",
                          picker: "month",
                          format: "YYYY-MM",
                          requestFormat: "MM-YYYY",
                        }
                        : {})}
                    />
                    {payment_interval != "weekly" ? (
                      <>
                        {isEditStartDate ? (
                          loadingMonthStartDate ? (
                            <div className="align-items-start col-md-6 d-flex flex-column">
                              <label className="">{t("Payroll Start Date")}</label>
                              <Spinner size="sm" className="mx-5" />
                            </div>
                          ) : (
                            <DateTimePickerForm
                              label={t("Payroll Start Date")}
                              name="start_at"
                              requestFormat="YYYY-MM-DD"
                              rootStyle="col-md-6"
                              disabled={
                                !canEditStartAt &&
                                id
                              }
                              {...FormProps}
                              containerStyle="py-0 mb-2"
                              datePickerContainer="w-100"
                              validateBy="textRequired"
                              placeholder={t("Choose start Date")}
                              hasIcon
                              isClearable
                              validationName={`args.from`}
                            />
                          )
                        ) : (
                          <div className="align-items-start col-md-6 d-flex flex-column">
                            <label className="">{t("Payroll Start Date")}</label>
                            {loadingMonthStartDate ? (
                              <Spinner size={"sm"} className="mt-2 mx-5" />
                            ) : (
                              <>
                                <div className="row align-items-end mx-0">
                                  <h6 className="mr-2">
                                    {moment(
                                      start_at
                                    )?.isValid()
                                      ? moment(
                                        start_at
                                      ).format("DD-MM-YYYY")
                                      : t("start date")}
                                  </h6>
                                  {!canEditStartAt && id ? null : (
                                    <EditIconButton
                                      onClick={() => setIsEditStartDate(true)}
                                    />
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      </>
                    ) : null}
                  </div>
                )}
              </div>

              <div className="col-12 col-lg-4">
                <CheckboxBooleanForm
                  {...FormProps}
                  options={["Manage Employee Payroll Externally"]}
                  name="salary_management_type"
                  type="checkbox"
                  labelStyle="checkbox"
                  containerStyle="containerStyle"
                  optionsContainerStyle="d-flex flex-column"
                  optionItemStyle="optionItemStyle mb-2"
                  optionInputStyle="optionInputStyle containerStyle checkbox w-100"
                  optionLabelStyle="optionLabelStyle ml-1 mt-1"
                  optionLabelActiveStyle="optionLabelActiveStyle"
                  validationName={`args.type`}
                  onInterceptInputOnChange={handleSalaryManagementTypeChange}
                />
              </div>
            </div>
            <>
              {!Boolean(+salary_management_type) ? (
                <>
                  <div className="col-12 px-0">
                    <BSelect
                      {...FormProps}
                      name="company_salary_config_preset_id"
                      optionLabel="name"
                      optionValue="id"
                      options={companySalaryConfigPresets}
                      isClearable
                      placeholder={t("select Salary Preset")}
                      // validationName={`input.user_salary_config_input.salaryConfigPreset`}
                      icon="suitcase"
                      onInterceptInputOnChange={(_, preset) =>
                        handleChangePreset(preset)
                      }
                    />
                  </div>

                  <div className="row align-items-center">
                    <div className="col-12 col-lg-6">
                      <BSelect
                        {...FormProps}
                        name="regulation_type_id"
                        label="Regulation"
                        labelStyle="my-2 font-weight-bold"
                        optionLabel="name"
                        optionValue="id"
                        options={userRegulationTypesOptions ?? []}
                        keepDefaultStyle
                        containerStyle=""
                        isClearable
                        preSelectValue={
                          userRegulationTypesOptions?.find(
                            (reg) => reg?.isDefault
                          )?.id
                        }
                        placeholder={t("select Regulation Type")}
                        // validationName={`input.user_salary_config_input.salary_config.regulation_type_id`}
                        isDisabled={
                          calculate_salary_by !== "payment_interval" || payment_interval == "weekly"
                        }
                      />

                      {showState ? (
                        <BSelect
                          {...FormProps}
                          name="state_id"
                          label="state"
                          labelStyle="my-2 font-weight-bold"
                          optionLabel="name"
                          optionValue="id"
                          options={relevantStates ?? []}
                          keepDefaultStyle
                          containerStyle=""
                          isClearable
                          validateBy="textRequired"
                          placeholder={t("select state")}
                          isDisabled={false}
                        />
                      ) : null}
                    </div>

                    <div className="col-12 col-lg-6">
                      {!salary_management_type ? (
                        <BSelect
                          {...FormProps}
                          label="Currency"
                          labelStyle="my-2 font-weight-bold"
                          name="currency_id"
                          optionLabel="name"
                          optionValue="id"
                          options={
                            props.isFirst
                              ? offices?.find(({ id }) => id == office_id)?.currencies
                              : userCurrenciesOptions ?? []
                          }
                          keepDefaultStyle
                          containerStyle=""
                          placeholder={t("select currency")}
                          validateBy="textRequired"
                          // validationName={`input.user_salary_config_input.salary_detail.currency_id`}
                          icon="currency"
                        // preSelectValue={defaultCurrency}
                        // dependOn="salary_management_type"
                        // dependancyType="equal"
                        // dependancyValue={[0]}
                        />
                      ) : null}
                    </div>
                  </div>
                </>
              ) : null}

              {!!office && JSON.parse(office?.payment_intervals)?.length > 0 ? (
                <RadioboxForm
                  {...FormProps}
                  label={t("payment interval")}
                  containerStyle="flex-column justify-content-start"
                  labelStyle="my-2 font-weight-bold"
                  optionsContainerStyle="gap-60 mx-0 row my-1"
                  optionItemStyle="d-flex align-items-center"
                  optionLabelStyle="mb-0 mr-3"
                  optionInputStyle=" "
                  validateBy="textRequired"
                  options={[
                    ...(!!office &&
                      JSON.parse(office?.payment_intervals)?.includes("Month")
                      ? [
                        {
                          label: "Month",
                          value: "monthly",
                        },
                      ]
                      : []),
                    ...(!!office &&
                      JSON.parse(office?.payment_intervals)?.includes("Week")
                      ? [
                        {
                          label: "Week",
                          value: "weekly",
                        },
                      ]
                      : []),
                  ]}
                  name="payment_interval"
                  interceptChange={handleInterceptPaymnetIntervalChange}
                  validationName="args.payment"
                />
              ) : null}

              {props?.isFirst ||
                props?.activate ||
                (!canEditStartAt && id) ? null : (
                <BSelect
                  {...FormProps}
                  label="week start"
                  name="week_start"
                  optionLabel="from"
                  optionValue="from"
                  isOptionDisabled={(option) => option.status !== "Pending"}
                  options={weekOptions}
                  isLoading={loading}
                  isClearable
                  placeholder={t("select week start")}
                  // validationName={`input.user_salary_config_input.start_at`}
                  dependOn="payment_interval"
                  dependancyType="equal"
                  dependancyValue={["weekly"]}
                  validateBy="textRequired"
                  isDisabled={props?.isFirst || props?.activate}
                />
              )}

              {!Boolean(+salary_management_type) ? (
                <>
                  <RadioboxForm
                    {...FormProps}
                    label="Calculate Salary by"
                    containerStyle="flex-column justify-content-start"
                    labelStyle="my-2 font-weight-bold"
                    optionsContainerStyle="gap-60 mx-0 row my-1"
                    optionItemStyle="d-flex align-items-center"
                    optionLabelStyle="mb-0 mr-3"
                    optionInputStyle=" "
                    validateBy="textRequired"
                    options={[
                      {
                        label:
                          payment_interval?.toLowerCase() ==
                            "monthly"
                            ? "month"
                            : "week",
                        value: "payment_interval",
                      },
                      {
                        label: "day",
                        value: "daily",
                      },
                      {
                        label: "hour",
                        value: "hourly",
                      },
                    ]}
                    name="calculate_salary_by"
                    interceptChange={handleInterceptPaymnetIntervalChange}
                  // validationName="input.user_salary_config_input.salary_config.calculate_salary_by"
                  />

                  <div className="row my-2 align-items-center">
                    <div className="col-12 col-lg-8 mx-0">
                      <InputForm
                        name="base_salary"
                        type="number"
                        label={"Base Salary"}
                        onWheelCapture={(e) => {
                          e.target.blur();
                        }}
                        {...FormProps}
                        placeholder={t("base_salary")}
                        labelStyle="my-2 font-weight-bold"
                        containerStyle=""
                        inputContainerStyle="w-100"
                        validateBy="textRequired"
                        // validationName={`input.user_salary_config_input.salary_detail.base_salary`}
                        icon="money"
                        dependOn="calculate_salary_by"
                        dependancyType="equal"
                        dependancyValue={["payment_interval"]}
                      />
                      <InputForm
                        name="rate_per_day"
                        type="text"
                        label={`rate per day`}
                        onWheelCapture={(e) => {
                          e.target.blur();
                        }}
                        {...FormProps}
                        placeholder={t("rate per day")}
                        labelStyle="my-2 font-weight-bold"
                        containerStyle=""
                        inputContainerStyle="w-100"
                        validateBy="textRequired"
                        // validationName={`input.user_salary_config_input.salary_detail.ratePerDay`}
                        icon="money"
                        dependOn="calculate_salary_by"
                        dependancyType="equal"
                        dependancyValue={["daily"]}
                        isNumberInput
                      />
                    </div>
                    <div className="col-12 mx-0">
                      {calculate_salary_by == "hourly" ? (
                        <HourlySectionDetails FormProps={FormProps} />
                      ) : null}
                    </div>
                  </div>

                  {/* Start of Allowances section */}

                  <div className="d-flex align-items-baseline mt-3">
                    <div className="mr-3 mb-2">
                      <h4 className="blue-color font-weight-bold fa-1x">
                        {t("allowances")}
                      </h4>
                    </div>

                    <div>
                      <button
                        className="btn add_new_btn_style"
                        type="button"
                        onClick={handleAddAllowanceInput}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                    </div>
                  </div>

                  {salary_allowances?.map((allowance, i) => (
                    <AllowanceSection
                      key={`${i}`}
                      allowance={allowance}
                      index={i}
                      {...FormProps}
                    />
                  ))}

                  <div className="invalid-container-style mx-2">
                    {props.formSubmitting && (
                      <div className="validity-msg-style">
                        {salaryConfigFormServerValidation[
                          "input.user_salary_config_input.salary_allowances"
                        ] ?? ""}
                      </div>
                    )}
                  </div>

                  {/* End of Allowances section */}

                  {/* Start of deductibles section */}
                  <div className="mt-3">
                    <div className="row align-items-baseline mb-3">
                      <div className="mb-2 mx-3">
                        <h4 className="blue-color font-weight-bold fa-1x">
                          {t("deductibles")}
                        </h4>
                      </div>
                      <button
                        className="btn add_new_btn_style"
                        type="button"
                        onClick={handleAddDeductibleInput}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </button>
                    </div>
                  </div>

                  <div className="d-flex flex-wrap gap-10">
                    <SocialSection
                      {...FormProps}
                      type={regulation_type_id}
                      relevantTaxInfo={relevantTaxInfoData?.relevantTaxInfo}
                      totalGross={totalGross()}
                    />

                    <MedicalSection {...FormProps} />

                    <TaxSection
                      date={
                        props.isFirst
                          ? starts_at
                          : start_at
                      }
                      {...FormProps}
                      type={regulation_type_id}
                      taxInfoId={tax_info_id}
                      totalGross={totalGross()}
                    />

                    {salary_deductibles
                      ?.sort((a, b) => b?.id - a?.id)
                      ?.map((deductible, i) => (
                        <DeuductableSection
                          key={`${i}`}
                          {...FormProps}
                          deductible={deductible}
                          index={i}
                        />
                      ))}
                  </div>
                  <div className="invalid-container-style mx-2">
                    {props.formSubmitting && (
                      <div className="validity-msg-style">
                        {salaryConfigFormServerValidation[
                          "input.user_salary_config_input.salary_deductibles"
                        ] ?? ""}
                      </div>
                    )}
                  </div>

                  {/* End of deductibles section */}


                  {/* Start of calculations section */}
                  <div className="mt-3">
                    <div className="mb-2">
                      <h4 className="blue-color font-weight-bold fa-1x">
                        {t("calculations")}
                      </h4>
                    </div>
                  </div>

                  <div className="d-flex flex-column gap-10">
                    <PenaltyCalculation
                      totalNet={totalNet}
                      totalGross={totalGross}
                      FormProps={FormProps}
                    />

                    <BonusCalculation
                      totalNet={totalNet}
                      totalGross={totalGross}
                      FormProps={FormProps}
                    />

                    <UnpaidCalculation
                      totalNet={totalNet}
                      totalGross={totalGross}
                      FormProps={FormProps}
                    />

                    <OvertimeCalculation
                      totalNet={totalNet}
                      totalGross={totalGross}
                      FormProps={FormProps}
                    />

                    <div className="invalid-container-style mx-2">
                      {props.formSubmitting && (
                        <div className="validity-msg-style">
                          {salaryConfigFormServerValidation[
                            "input.user_salary_config_input.salary_calculations"
                          ] ?? null}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Start Over Time Range Profile Setting */}
                  <OvertTimeRangeSetting
                    formServerValidation={props.formServerValidation}
                  />
                  {/* End Over Time Range Profile Setting */}

                  <h3 className="sub-title-style mb-3">
                    {t("Salary Config Preset Options")}
                  </h3>

                  {company_salary_config_preset_id ? (
                    <div className="row">
                      <div className="px-3">
                        <CheckboxBooleanForm
                          {...FormProps}
                          options={[t("Update Salary Preset")]}
                          dependOn="create"
                          dependancyType="equal"
                          dependancyValue={[0]}
                          name="update"
                          type="checkbox"
                          labelStyle="checkbox"
                          containerStyle="containerStyle"
                          optionsContainerStyle="d-flex flex-column"
                          optionItemStyle="optionItemStyle mb-2"
                          optionInputStyle="optionInputStyle containerStyle checkbox w-100"
                          optionLabelStyle="optionLabelStyle ml-1 mt-1"
                          optionLabelActiveStyle="optionLabelActiveStyle"
                          validationName={`args.type`}
                        />
                      </div>
                    </div>
                  ) : null}

                  <div className="align-items-baseline col-12 col-lg-4 justify-content-between mt-2 mb-0 row">
                    <CheckboxBooleanForm
                      {...FormProps}
                      options={[t("Save as New Salary Preset")]}
                      dependOn="update"
                      dependancyType="equal"
                      dependancyValue={[0]}
                      name="create"
                      type="checkbox"
                      labelStyle="checkbox"
                      containerStyle="containerStyle"
                      optionsContainerStyle="d-flex flex-column"
                      optionItemStyle="optionItemStyle mb-0"
                      optionInputStyle="optionInputStyle containerStyle checkbox w-100"
                      optionLabelStyle="optionLabelStyle ml-1 mt-1"
                      optionLabelActiveStyle="optionLabelActiveStyle"
                      validationName={`args.type`}
                    />
                    <InputForm
                      name="name"
                      {...FormProps}
                      placeholder={t("name")}
                      labelStyle="mb-0"
                      containerStyle=""
                      inputContainerStyle="w-100"
                      validateBy="textRequired"
                      // validationName={`input.user_salary_config_input.name`}
                      dependOn="create"
                      dependancyType="equal"
                      dependancyValue={[1]}
                    />
                  </div>
                </>
              ) : (
                <OvertTimeRangeSetting
                  formServerValidation={props.formServerValidation}
                />
              )
              }
            </>

            {/* End of calculations section */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalaryConfigForm;
