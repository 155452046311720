import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { gql, useLazyQuery, useQuery } from "@apollo/client";

import {
	handleAdjustSignOut,
	fetchAllWorkTimingsOptionsAction,
	fetchAllOfficesOptionsAction,
	fetchAllDepartmentsByCompany,
	setAllPositionOptionsAction,
	updateEmployeesLogsFilter,
	fetchAttendanceLogsSuccess,
	showErrorToast,
	adjustSignInOutIds,
	resetRefetchFromSagaToHook,
	onFormResetAction,
	updateValueAction,
} from "../../Store/Actions";
import Swal from "sweetalert2";
import Const from "../../Constants";
import HelperFns, { getLateKeys, showToast } from "../../Helpers/HelperFns";
import Privilages from "../../Constants/Privilages";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import { exportLogsQuery, attendanceLogsQuery } from "../../Graphql/query";

import { Spinner } from "reactstrap";
import { Button, Drawer, Tooltip } from "@mui/material";
import HistoryList from "../HistoryList";
import Loader from "../../Components/Loader";
import Pagination from "../../Components/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditPenaltyModal from "../../Components/EditPenaltyModal";
import { DateTimePickerForm, BSelect, CheckboxBooleanForm } from "form-builder";
import { faFileExport, faFilter, faSlidersH, faUser, faUsers, faUserSlash } from "@fortawesome/free-solid-svg-icons";
import { components } from "react-select";
import IconButtonWithTooltip from "../../Components/IconButtonWithTooltip";
import "./LoglistStyle.scss";
import FiltersDrawer, { FilterCheckBox } from "../../Components/FiltersDrawer";
import LogoutIcon from "@mui/icons-material/Logout";
import ClearIcon from "@mui/icons-material/Clear";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import NewMultiSelect from "../../Components/FiltersDrawer/NewMultiSelect";
import FilterBadge from "../../Components/FilterBadge";
import moment from "moment";
import { attendanceLogFragment } from "../../Graphql/fragments";

const dataTableRef = `attendanceLogsList`;
const attendanceLogsOptions = gql`
	query attendanceLogsOptions {
		employees: users_by_role(
      first: 9999
      input: { page_flag: "attendance_history_list" }
    )  {
      data {
        id
        name
        active
      }
    }

    company_departments(first: 999) {
      data {
        id
        name
      }
    }

    company_offices(first: 999) {
      data {
        id
        name
      }
    }

    work_timings_menu(new_versions_only: true) {
      id
      name
    }

    company_positions(first: 999) {
      data {
        id
        name
      }
    }
	}
`
const attendanceLogs = gql`
  query attendanceLogs(
    $emp_id: [ID]
    $office_id: [ID]
    $department_id: [ID]
    $position_id: [ID]
    $work_timing_id: [ID]
    $rows: Int!
    $page: Int!
    $status: [String]
    $from: String
    $to: String
    $skipMultible: Boolean! = false
    $isEmpOnly: Boolean! = false
    $attendance_configuration_conflict: Boolean
    $direct_managed: Boolean
  ) {
    attendanceLogs: users_sign_in_outs_by_role(
      first: $rows
      page: $page
      input: {
        emp_id: $emp_id
        office_id: $office_id
        department_id: $department_id
        position_id: $position_id
        work_timing_id: $work_timing_id
        status: $status
        direct_managed: $direct_managed
        date_range: { from: $from, to: $to }
        attendance_configuration_conflict: $attendance_configuration_conflict
        page: "history_logs"
      }
    ) @skip(if: $isEmpOnly) {
      data {
        ...attendanceLog
        edited_by_action
        conflict_info
        attendance_configuration_conflict
        __typename
        multiple_sign_ins @skip(if: $skipMultible) {
          ...attendanceLog
          __typename
        }
      }
      paginatorInfo {
        lastItem
        perPage
        currentPage
        hasMorePages
        firstItem
      }
    }
  }
  ${attendanceLogFragment}
`;

const AttendanceLogsList = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	// Local State

	const signInAdjustIds = useSelector((state) => state?.super?.adjustSignInOutIds);
	const [isActiveEmp, setIsActiveEmp] = React.useState(true);

	// Reducer State
	const allOffices = useSelector((state) => state.super.allOffices);
	const allWorkTimings = useSelector((state) => state.super.allWorkTimings);
	const attendanceLogsList = useSelector((state) => state.super[dataTableRef]);
	const refetchFromSagaToHook = useSelector((state) => state.super.refetchFromSagaToHook);
	const adjustSignOutState = useSelector((state) => state.super.adjustSignOutState);
	const logsEmployeesFilter = useSelector((state) => state.super.logsEmployeesFilter);
	const allDepartmentsByCompany = useSelector((state) => state.super.allDepartmentsByCompany);
	const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);

	const allPositions = useSelector((state) => state.super.allPositions);

	let updatedStatus = (attendanceLogsList?.status ?? [])?.includes("Late")
		? [...(attendanceLogsList?.status ?? []), ...getLateKeys()]
		: [...(attendanceLogsList?.status ?? [])];

	const toggleDrawer = (open) => (event) => {
		if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
			return;
		}
		setIsFilterDrawerOpen(open);
	};

	// Server State

	const { loading: optionsLoading, data: options = {} } = useQuery(attendanceLogsOptions, {
    onError: (error) => {
      showToast("error", error?.graphQLErrors?.[0]?.message || error?.message);
    },
  });
	const [fetchAttLogs, { loading: fetchListLoading, data: listData = {}, refetch: refetchData, variables }] = useLazyQuery(
		attendanceLogs,
		{
			onCompleted: () => dispatch(resetRefetchFromSagaToHook()),
			onError: () => {
				dispatch(showErrorToast());
				dispatch(resetRefetchFromSagaToHook());
			},
			notifyOnNetworkStatusChange: true,
			variables: {
				rows: attendanceLogsList.pagination.perPage,
				page: 1,
			},
		}
	);

	const fetchData = { ...options, ...listData };

	useEffect(() => {
		refetchData();
	}, []);

	useEffect(() => {
		refetchFromSagaToHook && refetchData();
	}, [refetchFromSagaToHook]);

	const colors = {
		"Normal Shift": "#90979e",
		"An assignment treated as a normal shift": "#23aaeb",
		"Exceptional shift": "#FF6A6A",
	};

	const [exportLogs, { loading }] = useLazyQuery(exportLogsQuery, {
		fetchPolicy: "network-only",
		onCompleted: (response) => {
			if (response?.exportAttendanceHistory && response?.exportAttendanceHistory?.status === "success") {
				HelperFns.downloadFile(response?.exportAttendanceHistory?.file);
			} else {
				dispatch(showErrorToast(response?.exportAttendanceHistory?.message));
			}
		},
		onError: (error) => {
			dispatch(showErrorToast(error?.message));
		},
	});

	/* ↓ State Effects ↓ */

	useEffect(() => {
		dispatch(adjustSignInOutIds({ operation: "clear" }));
		return () => {
			dispatch(adjustSignInOutIds({ operation: "clear" }));
		};
	}, [
		attendanceLogsList.to,
		attendanceLogsList.from,
		attendanceLogsList.offices,
		attendanceLogsList.employees,
		attendanceLogsList.departments,
		attendanceLogsList.work_timings,
		attendanceLogsList.missing_sign_out,
		attendanceLogsList.direct_managed,
		attendanceLogsList.pagination.currentPage,
	]);

	useEffect(() => {
		if (fetchData?.attendanceLogs) {
			dispatch(fetchAttendanceLogsSuccess(dataTableRef, fetchData?.attendanceLogs));
		}
		if (fetchData?.employees) {
			dispatch(updateEmployeesLogsFilter(fetchData?.employees?.data));
		}

		if (fetchData?.company_departments) {
			dispatch(
				fetchAllDepartmentsByCompany({
					ref: "allDepartmentsByCompany",
					data: fetchData?.company_departments?.data,
				})
			);
		}

		if (fetchData?.company_offices) {
			dispatch(fetchAllOfficesOptionsAction(fetchData?.company_offices?.data));
		}

		if (fetchData?.company_positions) {
			dispatch(setAllPositionOptionsAction(fetchData?.company_positions?.data));
		}

		if (fetchData?.work_timings_menu) {
			dispatch(fetchAllWorkTimingsOptionsAction(fetchData?.work_timings_menu));
		}
	}, [JSON.stringify(fetchData)]);

	const onFilterSubmit = () => {
		let {
			attendance_configuration_conflict,
			department_id: departments,
			direct_managed,
			emp_id: employees,
			from,
			to,
			missing_sign_out,
			status,
			office_id: offices,
			position_id: positions,
			work_timing_id: work_timings,
		} = {
			...variables,
			missing_sign_out: variables?.status?.includes("Missing sign out") ? 1 : 0,
		};

		const prev = {
			from,
			to,
			status,
			missing_sign_out,
			attendance_configuration_conflict,
			direct_managed,
			employees,
			offices,
			departments,
			positions,
			work_timings,
		};

		let { isEmpLoading, empStatus, isEmpOnly, pagination, data, isLoading, ...cur } = {
			...attendanceLogsList,
		};

		let isDifferent = JSON.stringify(cur) !== JSON.stringify(prev);

		if (isDifferent) handleFilter();
		setIsFilterDrawerOpen(false);
	};

	const onFilterReset = () => {
		dispatch(
			onFormResetAction(dataTableRef, {
				...attendanceLogsList,
				from: "",
				to: "",
				status: [],
				missing_sign_out: 0,
				attendance_configuration_conflict: false,
				direct_managed: false,
				employees: [],
				offices: [],
				departments: [],
				positions: [],
				work_timings: [],
			})
		);
	};

	/* ↓ Helpers ↓ */

	const handleExportAttendanceLogs = () => {
		if (loading || !(attendanceLogsList?.from && attendanceLogsList?.to)) {
			return;
		}
		exportLogs({
			variables: {
				input: {
					filter_data: {
						emp_id: attendanceLogsList?.employees,
						office_id: attendanceLogsList?.offices,
						department_id: attendanceLogsList?.departments,
						position_id: attendanceLogsList?.positions,
						work_timing_id: attendanceLogsList?.work_timings,
						// status: attendanceLogsList?.status,
						status: attendanceLogsList?.missing_sign_out ? [...updatedStatus, "Missing sign out"] : updatedStatus,
						date_range: {
							from: attendanceLogsList?.from,
							to: attendanceLogsList?.to,
						},
						page: "history_logs",
					},
				},
			},
		});
	};

	const handleBulkAdjustSignout = async () => {
		const filterValues = attendanceLogsList;
		const totalRecords = filterValues?.pagination?.total;

		Swal.fire({
			icon: "warning",
			text: signInAdjustIds.length
				? t("defaut_warning_messsage")
				: t("bulk adjust sign out modal", {
						records: totalRecords,
				  }),
			showConfirmButton: true,
			confirmButtonText: t("Yes"),
			confirmButtonColor: "#7cd1f9",
			showDenyButton: true,
			denyButtonText: t("No"),
			showCancelButton: false,
			customClass: {
				popup: "swal-warning-style",
				icon: "swal-icon",
				denyButton: "order-1",
				confirmButton: "order-2",
			},
		}).then((values) => {
			if (values.isConfirmed) {
				signInAdjustIds.length
					? dispatch(handleAdjustSignOut(signInAdjustIds))
					: dispatch(handleAdjustSignOut(null, filterValues));
			}
		});
	};

	const handleFilter = () => {
		refetchData({
			empStatus: isActiveEmp ? "Active" : "Suspended",
			rows: attendanceLogsList.pagination.perPage,
			emp_id: attendanceLogsList.employees,
			office_id: attendanceLogsList.offices,
			department_id: attendanceLogsList.departments,
			position_id: attendanceLogsList.positions,
			work_timing_id: attendanceLogsList.work_timings,
			direct_managed: attendanceLogsList.direct_managed,
			status: attendanceLogsList.missing_sign_out ? [...updatedStatus, "Missing sign out"] : updatedStatus,
			skipMultible:
				!!attendanceLogsList?.status?.length ||
				!!attendanceLogsList?.work_timings?.length ||
				!!attendanceLogsList?.missing_sign_out,
			from: attendanceLogsList.from,
			to: attendanceLogsList.to,
			attendance_configuration_conflict: attendanceLogsList?.attendance_configuration_conflict,
			page: 1,
		});
	};

	const renderAppliedFilters = () => {
		const selectedOffices = allOffices?.filter((item) => variables?.office_id?.includes(item?.id));

		const selectedPositions = allPositions?.filter((item) => variables?.position_id?.includes(item?.id));
		const selectedDepartments = allDepartmentsByCompany?.filter((item) => variables?.department_id?.includes(item?.id));
		const selectedWorktimings = allWorkTimings?.filter((item) => variables?.work_timing_id?.includes(item?.id));
		const selectedEmployees = logsEmployeesFilter?.filter((item) => variables?.emp_id?.includes(item?.id));
		const selectedStatus = Const.signInStatusOptions
			?.filter((item) => variables?.status?.includes(item?.value))
			?.map((status) => ({ name: status?.value }));

		const onCancelFilter = (var_name, form_key, isBool = false, isMissingSignOut = false, isDate = false) => {
			if (isBool || isMissingSignOut) {
				dispatch(updateValueAction(dataTableRef, form_key, false));
			} else if (isDate) {
				dispatch(updateValueAction(dataTableRef, form_key, null));
			} else {
				dispatch(updateValueAction(dataTableRef, form_key, []));
			}
			refetchData({
				...variables,
				page: 1,
				...(isBool
					? {
							[var_name]: false,
					  }
					: isMissingSignOut
					? {
							[var_name]: updatedStatus?.filter((s) => s !== "Missing sign out"),
					  }
					: isDate
					? { [var_name]: null }
					: { [var_name]: [] }),
			});
		};

		return (
			<div className="d-flex gap-1 flex-wrap">
				<FilterBadge
					label={t("from")}
					selectedItems={
						variables?.from
							? [
									{
										name: moment(variables?.from, "YYYY/MM/DD").format("DD/MM/YYYY"),
									},
							  ]
							: []
					}
					onCancelFilter={() => onCancelFilter("from", "from", false, false, true)}
				/>
				<FilterBadge
					label={t("to")}
					selectedItems={
						variables?.to
							? [
									{
										name: moment(variables?.to, "YYYY/MM/DD").format("DD/MM/YYYY"),
									},
							  ]
							: []
					}
					onCancelFilter={() => onCancelFilter("to", "to", false, false, true)}
				/>
				<FilterBadge
					label={t("office")}
					selectedItems={selectedOffices}
					onCancelFilter={() => onCancelFilter("office_id", "offices")}
				/>
				<FilterBadge
					label={t("Positions")}
					selectedItems={selectedPositions}
					onCancelFilter={() => onCancelFilter("position_id", "positions")}
				/>
				<FilterBadge
					label={t("departments")}
					selectedItems={selectedDepartments}
					onCancelFilter={() => onCancelFilter("department_id", "departments")}
				/>
				<FilterBadge
					label={t("work timings")}
					selectedItems={selectedWorktimings}
					onCancelFilter={() => onCancelFilter("work_timing_id", "work_timings")}
				/>
				<FilterBadge
					label={t("employees")}
					selectedItems={selectedEmployees}
					onCancelFilter={() => onCancelFilter("emp_id", "employees")}
				/>
				<FilterBadge
					label={t("status")}
					selectedItems={selectedStatus}
					onCancelFilter={() => onCancelFilter("status", "status")}
				/>
				<FilterBadge
					label={t("Only show employees I manage directly")}
					selectedItems={[...(variables?.direct_managed ? [{ name: "true" }] : [])]}
					onCancelFilter={() => onCancelFilter("direct_managed", "direct_managed", true)}
				/>
				<FilterBadge
					label={t("Only show records with no sign-out time")}
					selectedItems={[...(variables?.status?.includes("Missing sign out") ? [{ name: "true" }] : [])]}
					onCancelFilter={() => onCancelFilter("status", "missing_sign_out", false, true)}
				/>
				<FilterBadge
					label={t("Attention Required!")}
					selectedItems={[...(variables?.attendance_configuration_conflict ? [{ name: "true" }] : [])]}
					onCancelFilter={() =>
						onCancelFilter("attendance_configuration_conflict", "attendance_configuration_conflict", true)
					}
				/>
			</div>
		);
	};

	const applied_FiltersList_length =
		(variables?.emp_id?.length > 0 ? 1 : 0) +
		(variables?.office_id?.length > 0 ? 1 : 0) +
		(variables?.status?.length > 0 ? 1 : 0) +
		(variables?.work_timing_id?.length > 0 ? 1 : 0) +
		(variables?.department_id?.length > 0 ? 1 : 0) +
		(variables?.attendance_configuration_conflict ? 1 : 0) +
		(variables?.direct_managed ? 1 : 0) +
		(variables?.position_id?.length > 0 ? 1 : 0) +
		(variables?.from ? 1 : 0) +
		(variables?.to ? 1 : 0);

	const handelClearFilters = () => {
		onFilterReset();
		refetchData({
			empStatus: isActiveEmp ? "Active" : "Suspended",
			rows: attendanceLogsList.pagination.perPage,
			emp_id: [],
			office_id: [],
			department_id: [],
			position_id: [],
			work_timing_id: [],
			direct_managed: false,
			status: [],
			skipMultible: false,
			from: "",
			to: "",
			attendance_configuration_conflict: false,
			page: 1,
		});
	};

	const handelActiveToggeler = () => {
		setIsActiveEmp(!isActiveEmp);
		dispatch(updateValueAction(dataTableRef, "employees", []));
	};

	return (
		<div className="content ml-0">
			<div className={`d-flex ${applied_FiltersList_length ? "flex-column" : "flex-row-reverse"}`}>
				<div className="d-flex w-100 align-items-center justify-content-between">
					<div>{renderAppliedFilters()}</div>

					<div className="d-flex flex-grow-1 justify-content-end">
						{applied_FiltersList_length ? (
							<div onClick={handelClearFilters} className="logs-btn clear-btn">
								<ClearIcon color="inherit" fontSize="small" />
								{t("clear")}
							</div>
						) : null}

						<div
							onClick={toggleDrawer(true)}
							className={`logs-btn ${applied_FiltersList_length ? "filter-btn-active" : "filter-btn-inactive"}`}
						>
							<FontAwesomeIcon icon={faSlidersH} />
							{t("filters")}
							{applied_FiltersList_length ? (
								<div className="filter-count-container">{t(applied_FiltersList_length)}</div>
							) : null}
						</div>

						{(!fetchListLoading &&
							(signInAdjustIds.length || attendanceLogsList?.missing_sign_out) &&
							attendanceLogsList?.data.length > 0) ||
						adjustSignOutState?.isLoading ? (
							<HasPrivileges
								reqireMain={[Privilages.EDIT_EMPLOYEE_ATTENDANCE, Privilages.MANAGE_EMPLOYEE_ATTENDANCE]}
								allowBP
								avalibleOnExpire={false}
							>
								<div className="logs-btn adj-sign-ou-btn" onClick={handleBulkAdjustSignout}>
									<LogoutIcon fontSize="small" />
									{adjustSignOutState?.isLoading ? (
										<Spinner
											style={{
												width: "1rem",
												height: "1rem",
												color: "#ff6a6a",
											}}
										/>
									) : (
										t("adjust sign out")
									)}
								</div>
							</HasPrivileges>
						) : null}

						<Tooltip
							arrow
							placement="bottom"
							classes={{
								popper: attendanceLogsList?.from && attendanceLogsList?.to ? "d-none" : "",
							}}
							title={t("Please select a date range to export")}
						>
							<div
								className={`logs-btn logs-export-btn text-nowrap
                       ${loading || !(attendanceLogsList?.from && attendanceLogsList?.to) ? "disabled" : ""}`}
								onClick={handleExportAttendanceLogs}
							>
								{loading ? (
									<Spinner style={{ width: "1rem", height: "1rem", color: "#2764AC" }} />
								) : (
									<>
										<FontAwesomeIcon className="mr-2" icon={faFileExport} />
										{t("export")}
									</>
								)}
							</div>
						</Tooltip>
					</div>
				</div>

				{/* legend */}
				<div
					style={{
						marginBlock: "7.5px",
						display: "grid",
						gridTemplateColumns:
							"repeat(auto-fill, minmax(400px, 1fr))" /* Repeat auto times with equal width (250 to ifr) */,
						rowGap: "7.5px",
						columnGap: "25px",
						alignItems: "center",
					}}
				>
					<div className="d-flex fit-width gap-20 px-3" style={{ height: 40, borderColor: "rgba(0, 0, 0, 0.12)" }}>
						{Object.keys(colors).map((key, i) => (
							<div className="d-flex align-items-center gap-5 text-center w-100 text-nowrap ">
								<span
									className="rounded-circle"
									style={{
										width: 10,
										height: 10,
										backgroundColor: colors[key],
									}}
								/>
								<div>{t(key)}</div>
							</div>
						))}
					</div>
				</div>
			</div>

			{/* (End) History Employee Header */}

			{fetchListLoading ? (
				<Loader />
			) : (
				<>
					<HistoryList
						reducer="super"
						listRef={dataTableRef}
						refetchList={() =>
							refetchData({
								page: attendanceLogsList?.pagination?.currentPage,
							})
						}
						isListLoading={fetchListLoading}
					/>
					<Pagination
						tableRef={dataTableRef}
						styleWraper=""
						reducer="super"
						onPaginate={(page = attendanceLogsList.pagination.currentPage) => {
							refetchData({ page });
						}}
					/>
				</>
			)}

			<EditPenaltyModal
				refetchList={() =>
					refetchData({ page: attendanceLogsList?.pagination?.currentPage })
				}
			/>

			<FiltersDrawer
				isFilterDrawerOpen={isFilterDrawerOpen}
				toggleDrawer={toggleDrawer}
				onFilterReset={onFilterReset}
				onFilterSubmit={onFilterSubmit}
				dataTableRef={dataTableRef}
				isActive={isActiveEmp}
				onEmpDataToggle={handelActiveToggeler}
				isLoading={attendanceLogsList.isEmpLoading}
				employeesOptions={logsEmployeesFilter}
				officesOptions={allOffices}
				positionsOptions={allPositions}
				departmentsOptions={allDepartmentsByCompany}
				formData={attendanceLogsList}
				activeEmployeeToggler
			>
				<NewMultiSelect
					options={Const.signInStatusOptions?.map((opt) => ({
						...opt,
						label: t(opt.value),
					}))}
					value={attendanceLogsList?.status}
					dataTableRef={dataTableRef}
					name="status"
					label={t("status")}
					icon="list"
					placeHolder={t("status")}
					optionLabel="label"
					optionValue="value"
				/>

				<NewMultiSelect
					options={allWorkTimings}
					isLoading={attendanceLogsList.isLoading}
					value={attendanceLogsList?.work_timings}
					dataTableRef={dataTableRef}
					name="work_timings"
					label={t("work timings")}
					icon="calendar"
					placeHolder={t("work timings")}
				/>

				<div className="check-box-container-in-filters mx-2">
					<FilterCheckBox
						label={t("Only show employees I manage directly")}
						formName={dataTableRef}
						name="direct_managed"
						value={attendanceLogsList?.direct_managed}
					/>

					<FilterCheckBox
						label={t("Only show records with no sign-out time")}
						formName={dataTableRef}
						name="missing_sign_out"
						value={attendanceLogsList?.missing_sign_out}
					/>

					<FilterCheckBox
						label={t("Attention Required!")}
						formName={dataTableRef}
						name="attendance_configuration_conflict"
						value={attendanceLogsList?.attendance_configuration_conflict}
					/>
				</div>
			</FiltersDrawer>
		</div>
	);
};

export default AttendanceLogsList;

export const ToggleActive = ({ isActive, onEmpDataToggle, isLoading }) => {
	const { t } = useTranslation();
	const handleClick = (e) => {
		if (!isLoading) {
			onEmpDataToggle();
			if (e) {
				e.preventDefault();
				e.stopPropagation();
			}
		}
	};

	return (
		<div onClick={handleClick} className="pointer">
			{isActive ? (
				<div className="d-flex align-items-center">
					<div className="toggle-active-in-logs-text">{t("active")}</div>
					<ToggleOnIcon sx={{ color: "#009EFB", fontSize: 27 }} />
				</div>
			) : (
				<div className="d-flex align-items-center">
					<div className="toggle-active-in-logs-text">{t("all")}</div>
					<ToggleOffIcon sx={{ color: "#009EFB", fontSize: 27 }} />
				</div>
			)}
		</div>
	);
};
