import Types from "../Actions/types";
import initStore from "../InitialState";
import { v4 as uuid } from "uuid";
import moment from "moment";
import * as REQUESTS from "../../Constants/Requests";
import PaymentFactors from "../../Constants/PaymentFactors";
import HelperFns, {
  normalizeFetchedSalaryConfigInput,
  normalizeFetchedTimeRangeProfileInput,
  safeJsonParse,
} from "../../Helpers/HelperFns";
import _ from "lodash";
import { RegulationTypes } from "../../Constants/RegulationTypes";
import { attendanceTypeConfig } from "../InitialState/sharedFormSections";

export const initState = {
  refetchFromSagaToHook: false,
  editEmployeeStatus: false,
  newEmployeeId: "",
  initEmployeeStatus: false,
  employeeForm: initStore.employeeForm,
  editEmployeeFormData: {},
  employeeFormStatus: {
    errorMsg: "",
    isLoading: false,
  },
  userRegulationTypesOptions: [],
  editEmployeeIsLoading: false,
  attendaceProfile: {
    id: null,
    profile_type: "Normal",
    name: "",
    week_start_day: "1",
    weekends: [],
    default_office_holiday_profile: 1,
    holidays: [],
    attendance_profile_work_timing_input: [
      {
        id: null,
        sign_in_req: 0,
        work_timing_id: null,
        first_half_work_timing_id: null,
        first_half_work_timing_name: null,
        second_half_work_timing_id: null,
        second_half_work_timing_name: null,
        start: null,
        end: null,
        allow_half_day: null,
        can_edit: null,
      },
    ],

    check_in_form_id: "",
    check_out_form_id: "",
    old_attendance_profile_work_timing_input: [],
    allow_multiple_check_ins: 0,
    allow_customize_check_ins: 0,
    permission_leaves_break_setting: "",
    allow_work_on_day_off: false,

    // day off exception inputs
    day_off_exception_id: null,
    apply_compensation: 0,
    weight: 1,
    compensation_type: null,
    compensation_quantity: null,
    treat_as_normal: false,
    allow_permission: false,
    allow_overtime: false,
    allow_half_days: 0,
    normal_work_timing_id: null,
    first_half_work_timing_id: null,
    second_half_work_timing_id: null,

    check_in_setting_id: null,
    check_in_settings_as_work_timing_sign_in_settings: 1,
    reuiqre_facial_recognition: 0,
    employee_can_check_in_from: "ANY_PLACE",
    check_in_specific_work_laces: [],
    apply_same_setting_when_checking_out: 1,
    employee_can_check_out_from: "ANY_PLACE",
    check_out_specific_work_laces: [],
    metres: "",

    // holiday config inputs
    allow_work_on_holiday: 0,
    same_config: 1,
    holiday_exception_id: null,
    holiday_apply_compensation: 0,
    holiday_weight: 1,
    holiday_compensation_type: null,
    holiday_compensation_quantity: null,
    holiday_treat_as_normal: false,
    holiday_allow_permission: false,
    holiday_allow_overtime: false,
    holiday_allow_half_days: 0,
    holiday_normal_work_timing_id: null,
    holiday_first_half_work_timing_id: null,
    holiday_second_half_work_timing_id: null,
  },
  attProfileNormalServerValidation: {},
  attProfileWorkdaysValidation: [],
  attProfileSignInValidation: [],
  attProfileHolidaysOptions: [],
  attendaceProfileValidation: [],

  attendaceProfileLeavesValidation: [],
  attendaceProfileOvertimeValidation: [],
  attendaceProfilePermissionValidation: [],
  attendaceProfileSignOutValidation: [],
  attendaceProfileTardinessValidation: [],
  creatAttProfileLoading: false,
  creatAttProfileSuccess: false,
  editAttProfileLoading: false,
  editAttProfileSuccess: false,

  employeeFormValidation: [],

  leavesBreaksForm: {
    id: null,
    name: "",
    holiday_hours_count: "",
    day_off_hours_count: "",
    start_week_day_number: "",
    deserved_against: [],
    allow_annual_leaves: false,
    allowed_annual_leaves: "",
    annual_leaves_id: null,
    annual_leave_cuttoff: "",
    allow_emergency: 0,
    allowed_emergency_leaves: "",
    include_weekends_and_holidays_in_annual: 1,
    leave_basis: "annual",
    leave_basis_type: "weightedDays",
    applicable_type: "fixed",
    add_leaves_at: "startOfMonth",
    total_weighted_days: "365",
    add_leaves_every: "year",
    annual_leaves_deserved_against: "calendar_week",
    probation_period_id: null,
    request_leaves: 0,
    settle_leaves_id: null,
    remaining_balance_option_id: "3",
    rollover_balance_automatic: 0,
    allow_max_number_of_leaves_to_be_rolled: 0,
    max_number_of_leaves_to_be_rolled: "",
    rollover_emergency_credit: 0,
    next_year: false,
    payable_at: "",
    sick_leaves_id: null,
    allow_sick_leaves: 0,
    allowed_sick_leaves: "",
    include_weekends_and_holidays_in_sick: 0,
    unpaid_leaves_id: null,
    allow_unpaid_leaves: 0,
    include_weekends_and_holidays_in_unpaid: 0,
    permissions_id: null,
    allow_permissions: 0,
    min_permission_unit: "",
    max_units_per_day: "",
    max_units_per_month: "",
    breaks_id: null,
    allow_break: 0,
    deduct_from_work_hours: false,
    number_of_allowed_break_sessions_per_day: "",
    maximum_break_session_duration: "",
    maximum_break_duration: "",
    deduct_leaves_against_unpaid_leaves: 0,
    deduct_leaves_against_paid_leaves: 0,
    deduct_leaves_against_absent_days: 0,

    // custom leaves input
    customLeaveSettingID: null,
    allow_custom_leave: false,
    bereavement_leave: false,
    maternal_paternal_leave: false,
    hajj_leave: false,
    sabbatical_leave: false,
    study_leave: false,
    marriage_leave: false,
    in_lieu_of_work_day_leave: false,
    military_call_leave: false,

    // request ability input
    requests_ability_id: null,
    annual_emergency_leave: "both",
    annual_emergency_half_day: "both",
    unpaid_leave: "both",
    unpaid_half_day: "both",
    sick_leave: "both",
    permission: "both",
    is_deductible: false,
    payment_factor_id: 2,
    deduction_type: "salary",
    deduction_amount: 0,
    negative_balance_action: "prevent",
    rollover_expires_after:null
  },
  leavesBreaksFormValidation: [],
  leavesBreaksFormServerValidation: {},
  isAnnualLeavesAllowedInLeaveBreakProfile: false,

  officeForm: {
    name: "",
    password: "",
    time_zone: "",
    head_quarter: 0,
    address: "",
    ips: [],
    holidays: [],
    locations: [],
    currency_id: "",
    country: null,
    month_start_day: "1",
    taxation: "1",
    tax_settlement_interval: "12",
    allow_sign_in_using_office_account: 0,
    ignore_location: 0,
    reset_password: 1,
    email_notification_locale: "",
    swal_answer: "null",
    isWorkPlaceRef: 0,
    // allow_monthly_interval: false,
    // allow_weekly_interval: false,
    canEditStartOfMonthOrWeek: true,
    Grace:false,
    Overtime:false,
    week_start_day:"1"
  },
  officeCurrenciesOptions: [],
  officeCountriesOptions: [],
  taxationsOptions: [],
  officeFormStatus: {
    error: "",
    isLoading: false,
  },
  officeFormValidation: [],
  holidayForm: {
    name: "",
    start_date: "",
    end_date: "",
  },
  holidayFormValidation: [],
  officeHolidayModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  officeHolidaysOptions: [],
  locationForm: {
    id: null,
    name: "",
    address: "",
    max_distance: "",
    lng: 0,
    lat: 0,
    is_office: 1,
  },
  locationFormValidation: [],
  locationFormServerValidation: {},
  officeLocationModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
    isViewOnly: false,
  },
  officeLocationsOptions: [],
  officeIpsOptions: [],
  attendanceProfilesList: {
    total: 0,
    filter: "",
    pagination: {
      lastItem: 20,
      total: 20,
      perPage: 20,
      currentPage: 1,
      hasMorePages: true,
      firstItem: 1,
      lastPage: 4,
    },
    sorting: {
      key: "name",
      dir: "asc",
    },
    data: [],
    isLoading: false,
    only_archive: false,
  },

  holidayOptions: [],
  workTimeOptions: [],
  leavesBreaksOptions: [],
  managerOptions: [],
  employeeOptions: [],
  locationOptions: [],
  allOffices: [],
  allWorkTimingsMenu: [],

  //handle selected employee Profile
  employeeProfileLoading: false,
  selectedEmployeeProfile: {},

  employeeRequestsList: {
    filter: "",
    from: moment().startOf("month").format("YYYY-MM-DD"),
    to: moment().endOf("month").format("YYYY-MM-DD"),
    statusFilter: [2],
    pagination: {
      lastItem: 10,
      total: 10,
      perPage: 10,
      currentPage: 1,
      hasMorePages: true,
      firstItem: 1,
      lastPage: 1,
    },
    sorting: {
      dir: "asc",
      key: "status",
    },
    data: [],
    isLoading: false,
  },
  officesList: {
    total: 0,
    filter: "",
    isWorkplace: null,
    pagination: {
      lastItem: 10,
      total: 10,
      perPage: 10,
      currentPage: 1,
      hasMorePages: true,
      firstItem: 1,
      lastPage: 1,
    },
    sorting: {
      key: "name",
      dir: "asc",
    },
    data: [],
    isLoading: false,
  },
  departmentsList: {
    filter: "",
    pagination: {
      lastItem: 30,
      total: 30,
      perPage: 30,
      currentPage: 1,
      hasMorePages: true,
      firstItem: 1,
      lastPage: 1,
    },
    sorting: {
      key: "name",
      dir: "asc",
    },
    data: [],
    isLoading: false,
  },
  isDeleteDepartmentLoading: false,
  positionsList: {
    filter: "",
    pagination: {
      lastItem: 30,
      total: 30,
      perPage: 30,
      currentPage: 1,
      hasMorePages: true,
      firstItem: 1,
      lastPage: 1,
    },
    sorting: {
      key: "name",
      dir: "asc",
    },
    data: [],
    isLoading: false,
  },
  isDeletePositionLoading: false,
  holidaysList: {
    filter: "",
    year: new Date().getFullYear(),
    auto_suggest_holidays: false,
    pagination: {
      lastItem: 10,
      total: 10,
      perPage: 10,
      currentPage: 1,
      hasMorePages: true,
      firstItem: 1,
      lastPage: 1,
    },
    sorting: {
      key: "name",
      dir: "asc",
    },
    data: [],
    isLoading: false,
  },

  autoSuggestHolidaysLoading: false,
  acceptSuggestedHolidayLoading: false,
  deleteSuggestedHolidayLoading: false,

  allEmployeesList: {
    total: false,
    filter: "",
    suspendFilter: ["Active"],
    statusFilter: [
      "Present",
      "Absent",
      "Late",
      "Leave",
      "Not signed in",
      "Day Off",
      "holiday",
    ],
    on_probation: false,
    showManagedEmployeesFilter: 0,
    directManaged: false,
    from_home: 0,
    on_break: 0,
    filterByDepartment: 0,
    filterByManager: null,
    filterByOffice: null,
    filterByPosition: null,
    selectedWorkTiming: null,
    listView: false,
    pagination: {
      lastItem: 50,
      total: 50,
      perPage: 50,
      currentPage: 1,
      hasMorePages: true,
      firstItem: 1,
      lastPage: 1,
    },
    data: [],
    isLoading: false,
    employeesDetails: [],
  },
  selectedEmployee: null,
  allManagers: [],
  allWorkTimings: [],
  allPositions: [],

  employeeListColumns: {
    phone: 1,
    email: 1,
    office: 1,
    department: 1,
    position: 1,
    attendance_profile: 1,
    manager: 1,
    direct_manager: 1,
    copied_manager: 1,
    joining_date: 1,
    leaves: 1,
    remaining_annual_leaves: 1,
    remaining_emergency_leaves: 1,
    remaining_sick_leaves: 1,
    remaining_permissions: 1,
  },

  exportEmployeeListModal: {
    isLoading: false,
    isVissible: false,
  },

  employeeListExportData: {
    name: 1, // by default
    phone: 1,
    email: 1,
    office: 1,
    department: 1,
    position: 1,
    attendance_profile: 1,
    managers: 1,
    direct_manager: 1,
    copied_managers: 1,
    joining_date: 1,
    leaves: 1,
    normal_leaves: 1,
    emergency_leaves: 1,
    sick_leaves: 1,
    permissions: 1,
    on_probation: 0,
    emp_code: 0,
    suspension_date: false,
    gender: 0,
  },

  commissionForm: {
    name: "",
    employees: [],
    date_range_start: "",
    date_range_end: "",
    sign_in_req: "",
    allow_awarding_overtime: "",
    awarding_overtime: "",
    allow_awarding_leaves: "",
    awarding_leaves: "",
    allow_exchange: "",
    exchange_days: [],
  },
  commissionFormValidation: [],
  inboundRequestsList: {
    from: moment().startOf("month").format("YYYY-MM-DD"),
    to: moment().endOf("month").format("YYYY-MM-DD"),
    payable_month: null,
    statusFilter: [2],
    directManaged: false,
    employees: null,
    offices: [],
    departments: [],
    positions: [],
    officesOptions: [],
    departmentsOptions: [],
    positionsOptions: [],
    type: [],
    pagination: {
      lastItem: 30,
      total: 30,
      perPage: 30,
      currentPage: 1,
      hasMorePages: true,
      firstItem: 1,
      lastPage: 30,
    },
    sorting: {
      dir: "asc",
      key: "status",
    },
    data: [],
    isLoading: false,
  },
  requestsEmployeesFilter: [],

  employeeBalanceList: {
    yearFilter: new Date().getFullYear(),
    type: REQUESTS.NORMAL_LEAVES,
    pagination: {
      lastItem: 10,
      total: 10,
      perPage: 10,
      currentPage: 1,
      hasMorePages: true,
      firstItem: 1,
      lastPage: 1,
    },
    sorting: {
      dir: "desc",
      key: "date_time",
    },
    data: [],
    isLoading: false,
  },
  attendanceLogsList: {
    from: "",
    to: "",
    status: [],
    isLoading: true,
    isEmpOnly: false,
    isEmpLoading: true,
    empStatus: "Active",
    missing_sign_out: 0,
    attendance_configuration_conflict: false,
    direct_managed: false,
    employees: [],
    offices: [],
    departments: [],
    positions: [],
    work_timings: [],
    pagination: {
      lastItem: 20,
      total: 20,
      perPage: 20,
      currentPage: 1,
      hasMorePages: false,
      firstItem: 1,
      lastPage: 1,
    },
    data: [],
  },

  employeeAttendanceLogs: {
    data: [],
    isLoading: false,
  },

  suspensionLogsList: {
    year: "",
    month: "",
    name: "",
    pagination: {
      lastItem: 20,
      total: 20,
      perPage: 20,
      currentPage: 1,
      hasMorePages: false,
      firstItem: 1,
      lastPage: 1,
    },
    sorting: {
      dir: "desc",
      key: "created_at",
    },
    data: [],
    isLoading: false,
  },
  overtimeLogsList: {
    year: new Date().getFullYear(),
    month: moment().format("MM"),
    name: "",
    pagination: {
      lastItem: 20,
      total: 20,
      perPage: 20,
      currentPage: 1,
      hasMorePages: false,
      firstItem: 1,
      lastPage: 1,
    },
    sorting: {
      dir: "desc",
      key: "created_at",
    },
    data: [],
    isLoading: false,
  },
  balanceLogsList: {
    year: null,
    month: null,
    name: null,
    type: null,
    operation: null,
    pagination: {
      lastItem: 20,
      total: 20,
      perPage: 20,
      currentPage: 1,
      hasMorePages: false,
      firstItem: 1,
      lastPage: 1,
    },
    sorting: {
      dir: "desc",
      key: "created_at",
    },
    data: [],
    isLoading: false,
  },
  logsEmployeesFilter: [],
  editAttHistoryModal: {
    id: "",
    emp_id: "",
    name: "",
    status: "",
    leave_type: "",
    treat_as_emergency: false,
    from_home: 0,
    sign_in_time: "",
    sign_out_time: "",
    sign_in_date: "",
    sign_out_date: "",
    day_type: "",
    day_sub_type: "",
    sign_in_lat: "",
    sign_in_lng: "",
    date: "",
    custom_leave_payment_type: "paid",
    half_day_payment_type: "Paid",
    signInSpace: "",
    signOutSpace: "",
  },
  editAttHistoryModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  editAttHistoryModalValidation: [],
  editAttHistoryServerValidation: {},

  editAttendanceWorkTimingOptions: [],
  fetchEditAttendnaceWorkTimingOptionsLoading: false,

  attendnaceOfficeLocationsOptions: [],
  fetchEditAttendnaceLocationsOptionsLoading: false,

  suspensionModal: {
    suspended_employee: "",
    suspend_from: "",
    suspend_to: "",
    handleLeaveBalanceAfterSuspensionOption: "3",
    replace_by: null,
    new_manager_for_replaced_by: null,
    affected_balance: "",
    is_blacklisted: false,
  },
  suspensionModalValidation: [],
  suspensionModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  managedEmployeesInSuspensionModal: [],
  isChangeLocaionsIdsLoading: false,
  activationModal: {
    id: "",
    end_date: "",
  },
  activationModalValidation: [],
  activationModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
    loadingModal: false,
    require_create_new_salary_configuration: true,
  },

  allowOvertimeInActivationModal: 0,

  balanceModal: {
    id: null,
    emp_id: null,
    reason: null,
    quantity: null,
    month: null,
    operation: null,
    balance_type_id: null,
    week: null,
    year: null,
    apply_on_emergency: 0,
    emergency_quantity: null,
  },
  balanceModalValidation: [],
  balanceModalServerValidation: {},
  balanceModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },

  departmentModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  departmentModal: {
    manager_id: null,
    name: "",
  },
  departmentModalValidation: [],

  positionModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  positionModal: {
    office_id: "",
    name: "",
  },
  positionModalValidation: [],

  holidaysModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  holidaysModal: {
    office_id: "",
    name: "",
    start_date: "",
    end_date: "",
    office_ids: [],
    att_profile_ids: [],
    all_offices: 1,
    all_att_profiles: 1,
  },
  holidaysModalValidation: [],
  holidaysModalServerValidation: {},

  holidayOffices: [],
  holidayAttProfile: [],

  loansList: {
    status: 4,
    user_id: "",
    pagination: {
      lastItem: 20,
      total: 20,
      perPage: 20,
      currentPage: 1,
      hasMorePages: false,
      firstItem: 1,
      lastPage: 1,
    },
    data: [],
  },

  loansModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  loansModal: {
    id: null,
    user_id: "",
    currency_id: "",
    name: "",
    amount: "",
    granted_on: "",
    notes: "",
    installment_number: "0",
    starting_from: "",
    week_start: "",
    include_payroll: false,
    installments: [],
  },
  loansModalValidation: [],
  loansModalServerValidation: {},

  upsertAttProfileLoading: false,

  attendanceChartEmployeeNamesModalActions: {
    isVissible: false,
  },
  attendanceChartEmployeeNamesModal: {
    date: "",
    office_id: null,
    status: "",
    label: "",
    value: "",
  },
  attendanceChartEmployeeNames: [],
  attendanceChartEmployeeNamesLoading: false,

  dashboardDepartments: [],

  dashboardWidgets: {
    isLoading: false,
    office_id: null,
    users: {
      all: {
        data: [],
        isOpen: false,
      },
      Present: {
        data: [],
        isOpen: false,
      },
      Absent: {
        data: [],
        isOpen: false,
      },
      Late: {
        data: [],
        isOpen: false,
      },
      Leave: {
        data: [],
        isOpen: false,
      },
      "Day Off": {
        data: [],
        isOpen: false,
      },
    },
    data: {
      all: 0,
      present: 0,
      absent: 0,
      late: 0,
      leave: 0,
      weekend: 0,
    },
  },

  dashboardViewLoading: false,
  allDepartmentsByCompany: [],

  claimsList: {
    from: moment().startOf("month").format("YYYY-MM-DD"),
    to: moment().endOf("month").format("YYYY-MM-DD"),
    status: [2],
    user_id: "",
    pagination: {
      lastItem: 10,
      total: 10,
      perPage: 10,
      currentPage: 1,
      hasMorePages: true,
      firstItem: 1,
      lastPage: 1,
    },
    sorting: {
      dir: "desc",
      key: "created_at",
    },
    data: [],
    isLoading: false,
  },

  employeeProfileClaimsList: {
    from: moment().startOf("month").format("YYYY-MM-DD"),
    to: moment().endOf("month").format("YYYY-MM-DD"),
    status: [2, 4, 3, 5, 1],
    pagination: {
      lastItem: 10,
      total: 10,
      perPage: 10,
      currentPage: 1,
      hasMorePages: true,
      firstItem: 1,
      lastPage: 1,
    },
    sorting: {
      dir: "desc",
      key: "created_at",
    },
    data: [],
    isLoading: false,
  },

  BonusList: {
    from: moment().startOf("month").format("YYYY-MM-DD"),
    to: moment().endOf("month").format("YYYY-MM-DD"),
    status: "un_paid",
    user_id: "",
    pagination: {
      lastItem: 30,
      total: 30,
      perPage: 30,
      currentPage: 1,
      hasMorePages: true,
      firstItem: 1,
      lastPage: 1,
    },
    sorting: {
      dir: "desc",
      key: "created_at",
    },
    data: [],
    isLoading: false,
  },

  DeductionsList: {
    from: moment().startOf("month").format("YYYY-MM-DD"),
    to: moment().endOf("month").format("YYYY-MM-DD"),
    status: "un_paid",
    user_id: "",
    pagination: {
      lastItem: 30,
      total: 30,
      perPage: 30,
      currentPage: 1,
      hasMorePages: true,
      firstItem: 1,
      lastPage: 1,
    },
    sorting: {
      dir: "desc",
      key: "created_at",
    },
    data: [],
    isLoading: false,
  },

  extraEmployeesFilter: [],

  AddNewBonusModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  AddNewBonusModal: {
    name: "",
    user_id: [],
    department_id: "",
    office_id: "",
    apply_on: "employee",
    payment_factor_id: "",
    value: "",
    payroll_at: "",
    description: "",
    id: null,
    sameMonth: 0,
    commission_at: null,
  },
  AddNewBonusModalValidation: [],
  BonusFormServerValidation: {},

  AddNewDeductionsModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  AddNewDeductionsModal: {
    name: "",
    user_id: [],
    department_id: "",
    office_id: "",
    apply_on: "employee",
    payment_factor_id: "",
    value: "",
    payroll_at: "",
    description: "",
    id: null,
    policy_id: null,
    deduction_type: "CUSTOM",
  },
  AddNewDeductionsModalValidation: [],
  DeductionFormServerValidation: {},

  employeeClaimRequestModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  employeeClaimRequestModal: {
    id: null,
    incurred_at: "",
    amount: "",
    reason: "",
    name: "",
  },
  employeeClaimRequestModalValidation: [],
  employeeClaimRequestFormServerValidation: {},
  requestType: [""],

  acceptClaimRequestModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  acceptClaimRequestModal: {
    reason: "",
    amount: "",
    id: "",
    name: "",
    payroll_at: "",
    currency: "",
    include_in_payroll: 0,
  },
  acceptClaimRequestModalFormServerValidation: {},
  acceptClaimRequestModalValidation: [],

  overtimeModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  overtimeModal: {
    id: "",
    interval_in_time: "",
    payable_interval_in_time: "",
    created_at: "",
    name: "",
    req_day: "",
    overtimeHours: "",
    overtimeMinutes: ""
  },
  overtimeModalFormServerValidation: {},
  overtimeModalValidation: [],

  editAcceptedOvertimeRequestLoading: false,

  managePrivilagesModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  managePrivilagesModal: {
    managed: [],
    all: [],
    main: [],
    assign_all: 1,
    system_privileges: [],
  },
  editPrivileges: false,
  managePrivilagesModalValidation: [],

  salaryConfigForm: {
    company_salary_config_preset_id: null,
    update: 0,
    create: 0,
    name: "",
    regulation_type_id: RegulationTypes.MAWARED_CUSTOM,
    tax_info_id: null,
    temporary_employee: 0,
    currency_id: "",
    payment_interval: "monthly",
    calculate_salary_by: "payment_interval",
    based_on: "full_time",
    base_salary: "",
    rate_per_day: "",
    hours_per_paid_leave: null,
    paid_leave_hourly_rate: null,
    calculate_all_work_hours: false,

    start_at: "",
    monthYear: "",
    week_start: null,
    salary_management_type: 1,

    salary_allowances: [],
    salary_deductibles: [],

    socialDeduction: null,
    medicalDeduction: null,
    taxDeduction: null,

    penaltyConfiguration: {
      paymentFactor: PaymentFactors.FIXED_AMOUNT,
      monthOption: null,
      amount: null,
      customDays: null,
    },

    bonusConfiguration: {
      paymentFactor: PaymentFactors.FIXED_AMOUNT,
      monthOption: null,
      amount: null,
      customDays: null,
    },

    unpaidConfiguration: {
      paymentFactor: PaymentFactors.FIXED_AMOUNT,
      monthOption: null,
      amount: null,
      customDays: null,
    },

    overtimeConfiguration: {
      paymentFactor: PaymentFactors.FIXED_AMOUNT,
      amount: null,
      factor: null,
      advancedOvertimeSettings: [],
    },

    Everyday: [
      {
        from: "00:00",
        to: "00:00",
        rate: "0",
        id: null,
      },
    ],
    apply_hourly_rate_on_every_day: 1,
    state_id: null,
  },
  paymentMethodForm: {
    has_payment_method: 0,
    deletePayment: 0,
    payments: [{}],
  },
  paymentMethodFormValidation: [],
  companySalaryConfigPresets: [],
  upsertSalaryConfigServerValidation: {},
  deductiblesOptions: [],
  deductibleLoadingOpts: [],
  monthOptions: [],
  paymentFactors: [],
  allowancesOptions: [],
  allowanceLoadingOpts: [],
  userCurrenciesOptions: [],
  upsertSalaryConfigModalActions: {
    modalMessage: "",
    isLoading: false,
    isVissible: false,
    prevSalaryType: 1,
  },
  loadingPresetSalary: false,
  salaryConfigFormValidation: [],

  deleteSalaryConfigLoading: false,

  selectedAttProfile: {},

  payrollList: {
    monthly_amount: false,
    show_external: true,
    regulation_id: "",
    basic_salary: 1,
    additions: 1,
    allowances: 1,
    bonuses: 1,
    overtime: 1,
    deductions: 1,
    penalties: 1,
    social_insurance: 1,
    medical_insurance: 1,
    taxes: 1,
    other_deductions: 1,
    others: 1,
    pagination: {
      lastItem: 50,
      total: 50,
      perPage: 50,
      currentPage: 1,
      hasMorePages: true,
      firstItem: 1,
      lastPage: 1,
    },
    sorting: {
      dir: "desc",
      key: "month_year_payroll",
    },
    data: [],
    isLoading: false,
  },
  payrollEmployeesFilter: [],
  payrollCurrenciesFilter: [],
  payrollOfficesFilter: [],
  regualtionTypesOptions: [],

  monthlyReport: {
    custom_report: false,
    name: "",
    department: "",
    position: "",
    month_year: "",
    holidays: "",
    weekends: "",
    attended_days: "",
    ontime_total: "",
    ontime_normal: "",
    ontime_half: "",
    late_total: "",
    late_normal: "",
    late_half: "",
    absent_total: "",
    absent_normal: "",
    absent_half: "",
    normal_leaves: "",
    emergency_leaves: "",
    unpaid_leaves: "",
    half_days: "",
    late_duration: "",
    permissions: "",
    permissions_time: "",
    overtime_days: "",
    accepted_overtime_hours: "",
    breaks: "",
    breaks_time: "",
    expected_hours_secs: "",
    total_hours_secs: "",
    total_early_sign_out_time: "",
    orderBy: {
      column: "name",
      order: "ASC",
    },

    pagination: {
      lastItem: 100,
      total: 100,
      perPage: 50,
      currentPage: 1,
      hasMorePages: true,
      firstItem: 1,
      lastPage: 1,
    },
    data: [],
    isLoading: false,
  },

  exportMonthlyReportModal: {
    isLoading: false,
  },

  monthlyReportExport: {
    name: 1, // by default
    total_over_time_hours: 1,
    overtimeColumn: 1, // Head - Hasn't Value
    permissionsColumn: 1, // Head - Hasn't Value
    breaksColumn: 1, // Head - Hasn't Value
    leavesColumn: 1, // Head - Hasn't Value

    normal_leaves: 1,
    emergency_leaves: 1,
    unpaid_leaves: 1,
    sick_leaves: 1,
    half_days: 1,

    office: 1,
    department: 1,
    position: 1,
    holidays: 1,
    weekends: 1,
    attended_days: 1, // work days

    ontime_total: 1, // total normal days attended

    ontime_normal: 1,
    late_normal: 1,
    normal_minor_late: 1,
    normal_major_late: 1,
    normal_major_late1: 1,
    normal_major_late2: 1,
    normal_major_late3: 1,
    normal_major_late4: 1,
    normal_major_late5: 1,
    normal_major_late6: 1,
    normal_major_late7: 1,
    normal_major_late8: 1,
    normal_major_late9: 1,
    normal_major_late10: 1,
    emp_code: 0,

    half_total: 1, // Total Half-Days Attended

    ontime_half: 1,
    late_half: 1,
    half_minor_late: 1,
    half_major_late: 1,
    half_major_late1: 1,
    half_major_late2: 1,
    half_major_late3: 1,
    half_major_late4: 1,
    half_major_late5: 1,
    half_major_late6: 1,
    half_major_late7: 1,
    half_major_late8: 1,
    half_major_late9: 1,
    half_major_late10: 1,

    from_home: 1,
    late_total: 1,
    late_duration: 1,
    absent_total: 1,

    absent_normal: 1,
    absent_normal_no_show: 1,
    absent_normal_early_sign_out: 1,
    absent_normal_late_sign_in: 1,

    absent_half: 1,
    absent_half_no_show: 1,
    absent_half_early_sign_out: 1,
    absent_half_late_sign_in: 1,

    total_checkIn_hours_secs: 0,

    overtime_days: 1,
    accepted_overtime_hours: 1,

    permissions: 1,
    permissions_time: 1,

    breaks: 1,
    breaks_time: 1,

    expected_hours_secs: 1,
    total_hours_secs: 1,
    total_early_sign_out_time: 1,
    total_penalties_as_days: 1,
    total_penalties_as_fixed_amount: 1,
    total_penalties_as_hours: 1,
    total_hours_with_out_over_time_sec: true,
  },

  yearlyReport: {
    year: ""+new Date().getFullYear(),
    offices: null,
    departments: null,

    employees: null,
    name: "",
    department: "",
    position: "",
    month_year: "",
    holidays: "",
    weekends: "",
    attended_days: "",
    ontime_total: "",
    ontime_normal: "",
    ontime_half: "",
    late_total: "",
    late_normal: "",
    late_half: "",
    absent_total: "",
    absent_normal: "",
    absent_half: "",
    normal_leaves: "",
    emergency_leaves: "",
    unpaid_leaves: "",
    half_days: "",
    late_duration: "",
    permissions: "",
    permissions_time: "",
    overtime_days: "",
    accepted_overtime_hours: "",
    breaks: "",
    breaks_time: "",
    expected_hours_secs: "",
    total_hours_secs: "",
    remaining_annual_leaves: "",
    remaining_emergency_credit: "",
    orderBy: {
      column: "name",
      order: "ASC",
    },

    pagination: {
      lastItem: 100,
      total: 100,
      perPage: 50,
      currentPage: 1,
      hasMorePages: true,
      firstItem: 1,
      lastPage: 1,
    },
    data: [],
    isLoading: false,
    total_penalties_as_hours: 1,
  },

  facialRecognitionReport: {
  },

  exportYearlyReportModal: {
    isLoading: false,
    isVissible: false,
  },

  yearlyReportExport: {
    name: 1, // by default
    total_over_time_hours: 1,
    overtimeColumn: 1,
    permissionsColumn: 1,
    breaksColumn: 1,
    leavesColumn: 1,

    normal_leaves: 1,
    emergency_leaves: 1,
    unpaid_leaves: 1,
    sick_leaves: 1,
    half_days: 1,

    remaningLeaves: 1,
    remaining_annual_leaves: 1,
    remaining_emergency_credit: 1,

    office: 1,
    department: 1,
    position: 1,
    holidays: 1,
    weekends: 1,
    attended_days: 1,

    ontime_total: 1,

    ontime_normal: 1,
    late_normal: 1,
    normal_minor_late: 1,
    normal_major_late: 1,
    normal_major_late: 1,
    normal_major_late1: 1,
    normal_major_late2: 1,
    normal_major_late3: 1,
    normal_major_late4: 1,
    normal_major_late5: 1,
    normal_major_late6: 1,
    normal_major_late7: 1,
    normal_major_late8: 1,
    normal_major_late9: 1,
    normal_major_late10: 1,
    emp_code: 0,

    half_total: 1,

    ontime_half: 1,
    late_half: 1,
    half_minor_late: 1,
    half_major_late: 1,
    half_major_late1: 1,
    half_major_late2: 1,
    half_major_late3: 1,
    half_major_late4: 1,
    half_major_late5: 1,
    half_major_late6: 1,
    half_major_late7: 1,
    half_major_late8: 1,
    half_major_late9: 1,
    half_major_late10: 1,

    from_home: 1,
    late_total: 1,
    late_duration: 1,
    absent_total: 1,

    absent_normal: 1,
    absent_normal_no_show: 1,
    absent_normal_early_sign_out: 1,
    absent_normal_late_sign_in: 1,

    absent_half: 1,
    absent_half_no_show: 1,
    absent_half_early_sign_out: 1,
    absent_half_late_sign_in: 1,

    total_checkIn_hours_secs: 0,

    overtime_days: 1,
    accepted_overtime_hours: 1,

    permissions: 1,
    permissions_time: 1,

    breaks: 1,
    breaks_time: 1,

    expected_hours_secs: 1,
    total_hours_secs: 1,
    total_early_sign_out_time: 1,
    total_penalties_as_days: 1,
    total_penalties_as_fixed_amount: 1,
    total_penalties_as_hours: 1,
  },

  editAmountModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  editAmountModal: {
    new_amount: "",
    title: "",
    amount: "",
    currency: "",
    deductible_id: "",
    user_id: "",
    month_year_payroll: "",
  },
  editAmountModalValidation: [],

  employeeServerValidation: {},
  requstsLoader: [],

  submitPayrollPayment: {
    isLoading: false,
  },
  reversePaymentLoading: [],
  payrollWidgets: {
    total_net_paid: 0,
    total_paid: 0,
    pending_payment: 0,
  },
  extraPayrollRow: {
    user: {
      name: null,
    },
    base_salary: 0,
    allowances_amount: 0,
    bonuses_amount: 0,
    overitme_amount: 0,
    claims_amount: 0,
    penalties_amount: 0,
    social_amount: 0,
    medical_amount: 0,
    taxes_amount: 0,
    all_other_deductibles_amount: 0,
    payable_amount: 0,
    currency: {
      id: 0,
      name: "",
    },
    isExtra: true,
  },
  closedPayrollMonths: [],

  userPayslipLoading: false,
  userPayslipModalData: {},
  exportPaySlipLoading: false,

  upsertDocumentsModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
    document_id: null,
  },
  fetchDocLoading: false,
  countryOpts: [],
  upsertDocumentsModal: {},
  docFields: [],
  upsertDocumentsModalValidation: [],

  selectedEmployeeDocs: {
    required: [],
    optional: [],
  },
  employeeDocumetsLoading: false,

  exportPayrollModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  exportPayrollForm: {
    base_salary: 1,
    payable_amount: 1,

    allow_additions: 1,
    allow_allowances: 0,
    allowances: [],
    bonuses_amount: 0,
    overtime_amount: 0,
    claims_amount: 0,

    allow_deductions: 1,
    allow_deductibles: 0,
    deductibles: [],
    otherDeductibles: [],
    other_deductions: 0,
    allow_penalty: 0,
    penalties: [],
    deductions_amount: 0,
    total_penalties: 0,
    total_deductions: 0,
    total_allowances: 0,
    office: 1,
    position: 1,
    department: 1,
    emp_code: 1,
  },

  loadingPayrollOptions: false,

  workTimingsList: {
    total: 0,
    filter: "",
    work_timing_profile_type: "normal",
    pagination: {
      lastItem: 30,
      total: 30,
      perPage: 30,
      currentPage: 1,
      hasMorePages: true,
      firstItem: 1,
      lastPage: 1,
    },
    data: [],
    isLoading: false,
    only_archive: false,
  },

  workTimings: _.cloneDeep(initStore.workTimings),
  tempWorkTimings: _.cloneDeep(initStore.workTimings),
  workTimingsValidation: [],
  workTimingsServerValidation: {},

  suggestedHalfWorkTimingTemplateLoading: false,

  isEditFetchWorkTime: false,

  workTimingsModal: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
    createdFrom: null,
    parentFormName: null,
    which_half: "",
    normal_work_timing_id: null,
  },
  exportMonthlyReportModal: {
    isLoading: false,
    isVissible: false,
  },

  loadingActivationAttendance: false,

  fetchedEmployee_attendance: null,
  activationEmployee_attendance: {
    employee_attendance_option: 1,
    data: [
      {
        date: null,
        sign_in_status: null,
        sign_in_time: null,
        sign_out_time: null,
      },
    ],
    annual_leaves: "0",
    emergency_leaves: "0",
    sick_leaves: null,
  },
  employeeAttendanceFormValidation: [],

  hasSickLeaveInActivationModal: 0,
  hasEmergencyLeaveInActivationModal: 0,

  emailNotificationsSettingsLoading: false,
  emailNotificationsSettings: [],
  emailSwitchLoader: [],
  mobileSwitchLoader: [],

  viewWorkTime: {},
  viewWorkTimeModal: {
    isVissible: false,
  },
  isViewWorkTimeLoading: false,

  isCloneWorkTimeLoading: false,
  isDeleteWorkTimeLoading: false,

  companiesList: {
    filter: "",
    status: "active",
    pagination: {
      lastItem: 50,
      total: 50,
      perPage: 50,
      currentPage: 1,
      hasMorePages: true,
      firstItem: 1,
      lastPage: 1,
    },
    sorting: {
      key: "name",
      dir: "ASC",
    },
    data: [],
    isLoading: false,
  },

  companiesModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  companiesModal: {
    primarySAAS: null,
    secondarySAAS: [],
    employees_limit: "",
    allow_am_access: true,
    week_start_day: "Saturday",
    report_status: "default",
    ratio: null,
    partners_input: [
      {
        id: null,
        company_id: null,
        name: "",
        phone: "",
        phone_country_code: "",
        email: "",
        force_save: false,
      },
    ],
  },
  companiesModalValidation: [],
  companiesModalServerValidation: {},
  fetchedBuissPartnersDataIsLoading: false,

  companyAccountManagers: [],
  companySubscriptionPlans: [],
  companyCurrency: [],

  companyProfileSubscriptionList: {
    pagination: {
      lastItem: 10,
      total: 10,
      perPage: 10,
      currentPage: 1,
      hasMorePages: true,
      firstItem: 1,
      lastPage: 1,
    },
    data: [],
    isLoading: false,
  },
  deleteCompanySubscriptionLoading: false,

  companyProfileLoading: false,
  selectedCompanyProfile: {},

  editCompanyNameModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  editCompanyNameModal: {},
  editCompanyNameModalValidation: [],
  editCompanyNameModalServerValidation: {},

  editAccountManagersModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  editAccountManagersModal: {},
  editAccountManagersModalValidation: [],
  editAccountManagersModalServerValidation: {},

  editCompanyBussPartnersModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  editCompanyBussPartnersModal: {
    partners_input: [
      {
        id: null,
        company_id: null,
        name: "",
        phone: "",
        phone_country_code: "",
        email: "",
        force_save: false,
      },
    ],
  },
  editCompanyBussPartnersModalValidation: [],
  editCompanyBussPartnersModalServerValidation: {},

  updateBussPartnerModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  updateBussPartnerModal: {
    name: "",
    phone: "",
    phone_country_code: "",
    email: "",
    force_save: false,
  },
  updateBussPartnerModalValidation: [],
  updateBussPartnerModalServerValidation: {},

  addSubscriptionModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  addSubscriptionModal: {
    employees_limit: "",
  },
  addSubscriptionModalValidation: [],
  addSubscriptionModalServerValidation: {},

  suspendCompanyLoading: false,
  deleteCompanyLoading: false,
  activiateCompanyLoading: false,

  adminUsersList: {
    filter: "",
    pagination: {
      lastItem: 10,
      total: 10,
      perPage: 10,
      currentPage: 1,
      hasMorePages: true,
      firstItem: 1,
      lastPage: 1,
    },
    sorting: {
      key: "name",
      dir: "asc",
    },
    data: [],
    isLoading: false,
  },
  adminUsersModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  adminUsersModal: {
    selectedCompanyFilter: "",
    user_id: "",
    role_id: "",
    company_ids: [],
  },
  adminUsersModalValidation: [],
  adminUsersModalServerValidation: {},

  adminUserCompaniesData: [],
  adminUserCompaniesLoading: false,

  adminUserEmployeesData: [],
  adminUserEmployeesLoading: false,

  adminUsersProfileModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  adminUsersProfileModal: {
    id: "",
    user_id: "",
    role_id: "",
    name: "",
    phone_country_code: "",
    phone: "",
    email: "",
    company_ids: [],
    company_names: [],
  },
  adminUsersProfileModalValidation: [],
  adminUsersProfileModalServerValidation: {},
  updateAdminUserProfileLoading: false,
  deleteAdminUserLoading: false,

  initSuspenseLoading: false,
  init_suspension: {
    deserved: {
      normal: 0,
      emergency: 0,
    },
    used: {
      normal: 0,
      emergency: 0,
    },
    remaining: {
      normal: 0,
      emergency: 0,
    },
  },
  resendCredentailsLoading: false,
  checkPasswordLoading: false,
  requestRulesList: {
    department_id: null,
    employee_id: null,
    request_type_id: null,
    status_id: null,
    pagination: {
      lastItem: 20,
      total: 20,
      perPage: 20,
      currentPage: 1,
      hasMorePages: true,
      firstItem: 1,
      lastPage: 1,
    },
    sorting: {
      dir: "desc",
      key: "action",
    },
    data: [],
    isLoading: false,
  },

  requestRuleModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },

  requestRule: {
    id: null,
    status_id: null,
    request_types_ids: [],
    department_id: null,
    employees_ids: [],
    start_date: "",
    all_department: 1,
    active: true,
  },
  requestRuleValidation: [],
  requestRuleServerValidation: {},
  requestRulesUsersOpts: [],
  requestRulesdepartmentsOpts: [],
  loadingRequestRulesUsers: false,
  loadingDeleteRequestRule: false,
  openPayrollMonths: {
    year: new Date().getFullYear(),
    office_id: null,
    office_flag: true,
  },
  openPayrollMonthsLoading: false,
  openPayrollMonthsData: undefined,
  CompanyCurrencies: [],
  isBulkImportEmployeesOpen: false,
  bulkImportEmployeesLoading: false,
  companyRegulations: {
    regulation_type: "",
  },
  uploadEmployeesCsvLoading: false,
  isEmployeeImportModalErrorsOpen: false,
  uploadEmployeesFailedErrors: [],

  isInitDeleteManagerLoading: false,
  isInitSuspendManagerLoading: false,
  replace_direct_manager_in_suspension_modal: false,

  confirmDeleteManagerModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  confirmDeleteManagerModal: {},
  confirmDeleteManagerModalValidation: [],
  confirmDeleteManagerModalServerValidation: {},

  isReplaceByManagersOptionsLoading: false,
  replaceByManagersOptions: [],

  planRoles: [],
  loadingRolePresetPrivileges: false,

  previewRolePrivilagesModalActions: {
    isVissible: false,
  },
  previewRolePrivilagesModal: {
    managed: [],
    all: [],
    main: [],
    system_privileges: [],
  },
  isRolePrivilagesModalLoading: false,

  OpenPayrollMonthsOfficesAccordingToScope: [],
  expensesChartSelectInfo: {
    office: null,
    currency_id: "",
    from: moment().startOf("month").format("YYYY-MM-DD"),
    to: moment().endOf("month").format("YYYY-MM-DD"),
  },
  dashboardCurrencies: [],
  fetchExpensesDataLoading: false,
  fetchTotalExpensesData: [],
  isTotalExpensesCurrentQuery: true,
  employeesTurnOverLoading: false,
  employeesTurnOverFilter: {
    office_id: null,
    department_id: null,
  },
  companyTurnoverOffices: [],
  companyDepartments: [],
  EmployeesTurnOverChartData: [],

  requestCommentModalActions: {
    isVissible: false,
    isLoading: false,
    isFirstRender: false,
    modalMessage: "",
  },
  requestCommentModal: {},
  requestCommentModalServerValidation: {},
  isRequestCommentsModalLoading: false,

  penaltiesList: {
    applied: "all",
    deducted_from: "all",
    employees: null,
    skip_zero_penalties: false,
    from: moment().startOf("month").format("YYYY-MM-DD"),
    to: moment().endOf("month").format("YYYY-MM-DD"),
    cause: null,
    pagination: {
      lastItem: 30,
      total: 30,
      perPage: 30,
      currentPage: 1,
      hasMorePages: true,
      firstItem: 1,
      lastPage: 1,
    },
    data: [],
    isLoading: false,
  },
  applyPenaltiesLoading: false,
  penaltiesEmployeesFilter: [],
  fetchingAccessLevelsLoading: false,
  accessLevels: {
    filter: "",
    data: [],
    isModalVissible: false,
  },
  accessLevelsFormValidation: [],
  accessLevelsServerValidation: {},
  editRoleToCompanyLoading: false,
  upsertRoleToCompanyLoading: false,
  deleteCompanyRoleLoading: false,
  upsertRoleToCompanyForm: {
    id: null,
    name: null,
    assign_all: 1,
  },
  plan_privileges: null,
  loadingPlanPrivileges: false,

  adjustSignOutState: {
    id: null,
    isLoading: false,
  },
  notificationDrawer: {
    isVisible: false,
  },
  watchedAndManagedEmployees: [],
  notificationHistoryFilters: {
    employee_id: null,
    from: null,
    to: null,
    page: 1,
  },
  notificationHistory: {
    data: [],
    isLoading: false,
    paginatorInfo: {
      currentPage: null,
      hasMorePages: false,
      lastPage: null,
    },
  },
  privilegeTemplateModalValidatoin: {},

  editPenaltyModal: {},
  editPenaltyModalActions: {
    isVissible: false,
    isLoading: false,
    modalMessage: "",
  },
  checkInOutFormServerValidations: {},

  requestApprovalFormValidations: [],

  activationFormStepOneServerValidation: {},
  attendanceTypeConfig: {
    ...attendanceTypeConfig,

    // GrantEmployee
    annual_leaves: "0",
    sick_leaves: null,
    emergency_leaves: "0",

    // EmployeeAttendance
    workplaceID: null,
    workTimingID: null,
    employee_attendance: "",
  },
  announcementForm: {
    id: undefined,
    title: "",
    body: "",
    offices: [],
    managers: [],
    positions: [],
    employees: [],
    companies: [],
    departments: [],
    justManagers: 0,
    sendToState: "ALL",
    is_all: 1,
    is_employees: 0,
    is_departments: 0,
    is_positions: 0,
    is_offices: 0,
  },
  announcementClientValidations: [],
  announcementServerValidations: {},

  signInBreaksModal: {
    isOpen: false,
    breakInOuts: [],
    break_setting: {},
    sign_in_id: null,
    name: null,
    date: null,
  },

  editAttRecord: {},
  attendanceProfileCheckInsWorkPlaces: [],
  employeeFormCheckInsWorkPlaces: [],

  logsModalData: {
    isOpen: false,
    data: [],
    name: null,
    date: null,
    fromWhere: "",
  },
  logsModalDataLoading: false,

  adjustSignInOutIds: [],

  batchEmployeesActions: {
    employees: [],
    offices: [],
    departments: [],
    positions: [],
    filterByManager: [],
    filterByWorkgroup: [],
    filterByAttType: null,
    showManagedEmployeesFilter: false,
    directManaged: false,
    on_probation: false,
  },

  acceptReqWithEdit: {
    isOpen: false,
    id: null,
    data: null,
    signInTime: null,
    signOutTime: null,
    signInDate: null,
    signOutDate: null,
    workTimingId: null,
  },

  addedWorkTime: null,

  AddNewDeductionPolicyModal: {
    name: "",
    id: null,
    violations: [
      {
        type: "",
        amount: "",
      },
    ],
  },
  AddNewDeductionPolicyModalValidation: [],
  AddNewDeductionPolicyModalServerValidation: {},
  setTemporaryPasswordModal: {
    isOpen: false,
    user: null,
  },

  timeRangeProfileSetting: {
    allow_overtime: false,
    overtime_cutoff: false,
    min_overtime_cutoff: "",
    overtime_limit: false,
    max_overtime_limit: "",
    min_work_hours: "",
    overtime_factor: "0",
    overtime_hour_rate:""
  },
  timeRangeProfileSettingValidation: [],
  timeRangeProfileSettingServerValidation: {},

  employeeGraceMinutesProfileSetting: {
    id: null,
    emp_id: null,
    start: null,
    allow_grace_minutes: false,
    sign_in_grace_minutes: "0",
  },
  employeeGraceMinutesProfileSettingServerValidation: {},

  bulkEditAttServerValidation: {},

  comapnyReminderForm: {
    id: null,
    type: null,
    contact_id: null,
    date: null,
    notes: "",
    attachments: [],
    savedAttachments: [],
  },
  comapnyReminderFormValidation: [],
  comapnyReminderFormServerValidation: [],
  comapnyActivityForm: {
    id: null,
    type: null,
    contact_id: null,
    date: null,
    notes: "",
    attachments: [],
    savedAttachments: [],
  },
  comapnyActivityFormValidation: [],
  comapnyActivityFormServerValidation: [],
  addContactForm: {
    id: null,
    name: "",
    company_id: "",
    email: null,
    position: "",
    phone: null,
    phone_country_code: "",
    description: "",
  },
  addContactFormValidation: [],
  addContactFormServerValidation: [],
  companyContactOpt: [],
  contactOptionsLoading: false,
};

export const superReducer = (state = initState, action) => {
  switch (action.type) {
    case Types.REFETCH_FROM_SAGE_TO_HOOK:
      return {
        ...state,
        refetchFromSagaToHook: true,
      };
    case Types.RESET_REFETCH_FROM_SAGE_TO_HOOK:
      return {
        ...state,
        refetchFromSagaToHook: false,
      };

    case "EDIT_ATTENDANCE_RECORD":
      return {
        ...state,
        editAttRecord: action.payload,
      };

    case "SHOW_ACCEPT_REQUEST_WITH_EDIT":
      return {
        ...state,
        acceptReqWithEdit: {
          ...state?.acceptReqWithEdit,
          isOpen: true,
          data: action.payload,
          workTimingId: action?.payload?.workTiming?.id,
        },
      };

    case "HIDE_ACCEPT_REQUEST_WITH_EDIT":
      return {
        ...state,
        acceptReqWithEdit: { ...initState?.acceptReqWithEdit },
      };
    // Init Employee Form
    case Types.INIT_ATTENDANCE_PROFILE_SUCCESS:
      return {
        ...state,
        attendaceProfile: {
          ...state.attendaceProfile,
          id: action.payload.attProfileId,
        },
      };

    case Types.ASSIGN_HLAF_ATTENDANCE_PROFILE:
      return {
        ...state,
        attendaceProfile: {
          ...state.attendaceProfile,
          [action.payload.half]: action.payload.id,
        },
      };
    case Types.INIT_EMPLOYEE_ATTEMPT:
    case Types.GET_OFFICE_LOCATIONS_ATTEMPT:
      return { ...state, initEmployeeStatus: true };

    case Types.EDIT_EMPLOYEE_SUCCESS:
      const {
        name,
        emp_code,
        email,
        phone,
        office,
        company,
        department,
        manager,
        attendance_profile,
        position,
        starts_at,
        joining_date,
        copied_managers,
        has_credentials,
        employee,
        workday_locations,
        face,
        phone_country_code,
        active,
        date_of_birth,
        gender,
        marital_status,
        national_id,
        insurance_number,
        display_roster,
        payments,
      } = action.payload.user;

      const locationsList = action.payload?.custom_locations_options;

      const fetchedEmployeeData = {
        id: action.payload.user?.id,
        name,
        emp_code,
        has_credentials,
        email,
        phone,
        phone_country_code,
        probation_period: action.payload.user?.employee?.probationPeriod
          ? 1
          : 0,
        type: action.payload.user?.employee?.probationPeriod?.type,
        value: action.payload.user?.employee?.probationPeriod?.value,
        office_id: office?.id,
        company_id: company?.id,
        department_id: department?.id,
        manager_id: manager?.id,
        copied_managers: copied_managers.map((mangerObj) => mangerObj?.id),
        att_profile_id: attendance_profile?.id,
        // role_id: role?.id,
        position_id: position?.id,
        can_work_home: employee?.relevantAttendanceTypeConfiguration
          ?.workRemotelySettings?.can_work_home
          ? 1
          : 0,
        max_homeDays_per_week:
          employee?.relevantAttendanceTypeConfiguration?.workRemotelySettings
            ?.max_homeDays_per_week,
        flexible_home: employee?.relevantAttendanceTypeConfiguration
          ?.workRemotelySettings?.flexible_home
          ? 1
          : 0,
        can_ex_days: employee?.relevantAttendanceTypeConfiguration
          ?.workRemotelySettings?.can_ex_days
          ? 1
          : 0,
        home_days: employee?.relevantAttendanceTypeConfiguration
          ?.workRemotelySettings?.home_days
          ? employee?.relevantAttendanceTypeConfiguration?.workRemotelySettings?.home_days?.split(
            ","
          )
          : [],
        starts_at,
        joining_date,
        face_path: face?.path ?? null,
        active,
        workGroupId:
          employee?.relevantAttendanceTypeConfiguration?.workGroup?.id,
        workTeamId: employee?.relevantAttendanceTypeConfiguration?.workTeam?.id,
        first_day_of_the_week:
          employee?.relevantAttendanceTypeConfiguration?.attendanceProfile
            ?.week_start_day,
        allowMultipleCheckIns:
          employee?.relevantAttendanceTypeConfiguration?.attendanceProfile
            ?.allow_multiple_check_ins,
        attendance_type_configuration_start_date:
          employee?.relevantAttendanceTypeConfiguration?.start,

        date_of_birth_year: moment(date_of_birth, "YYYY-MM-DD").format("YYYY"),
        date_of_birth_month: moment(date_of_birth, "YYYY-MM-DD").format("MM"),
        date_of_birth_day: moment(date_of_birth, "YYYY-MM-DD").format("DD"),
        gender,
        marital_status,
        national_id,
        insurance_number,
        display_roster: +Boolean(display_roster),
        payments,
      };

      const workdaysReducerFormat = workday_locations.map((locationObj) => ({
        [`${locationObj.workday}_${locationObj.type}_location`]:
          locationObj?.locations || [],
      }));
      Object.assign(fetchedEmployeeData, ...workdaysReducerFormat);

      const fetchedPaymentMethodForm = {
        has_payment_method: payments?.length > 0 ? 1 : 0,
        payments: payments?.length
          ? payments?.map((payment) => ({
            user_payment_id: payment?.id,
            payment_method: payment?.payment_method,
            ...JSON.parse(payment?.data ?? "{}"),
          }))
          : [{}],
      };
      return {
        ...state,
        editEmployeeIsLoading: false,
        editEmployeeStatus: false,
        editEmployeeFormData: {
          ...state.editEmployeeFormData,
          attendance_profiles:
            action.payload?.company_attendance_profiles?.data,
          departments: action.payload?.company_departments?.data,
          offices: action.payload?.company_offices?.data,
          managers: action.payload?.managers?.data,
          positions: action.payload?.company_positions?.data,
          locations: action.payload?.company_offices_workplaces?.data ?? [],
        },
        employeeForm: {
          ...state.employeeForm,
          ...fetchedEmployeeData,
        },
        paymentMethodForm: {
          ...state.paymentMethodForm,
          ...fetchedPaymentMethodForm,
        },
      };

    case Types.CALCULATE_NEW_NET_REMANING_LEAVE_BALANCE:
      return {
        ...state,
        suspensionModal: {
          ...state.suspensionModal,
          affected_balance: action.payload.newNetRemaningLeaveBalance,
        },
      };

    case Types.CHANGE_EMPLOYEE_DATA:
      return {
        ...state,
        allEmployeesList: {
          ...state.allEmployeesList,
          data: [
            ...state.allEmployeesList.data.map((emp, i) => {
              // eslint-disable-next-line
              if (emp.id == action.payload.data.user_id) {
                return {
                  ...emp,
                  sign_in_time: action.payload.data.sign_in_time,
                  sign_out_time: action.payload.data.sign_out_time,
                  from_home: action.payload.data.from_home,
                };
              } else {
                return emp;
              }
            }),
          ],
        },
      };
    case Types.PUSHER_EMPLOYEE_BREAK_IN_OUT:
      return {
        ...state,
        allEmployeesList: {
          ...state.allEmployeesList,
          data: [
            ...state?.allEmployeesList?.data?.map((emp, i) => {
              // eslint-disable-next-line
              if (emp?.id == action?.payload?.data?.user_id) {
                return {
                  ...emp,
                  on_break: action?.payload?.data?.on_break,
                };
              } else {
                return emp;
              }
            }),
          ],
        },
      };
    case Types.EDIT_EMPLOYEE_FAILED:
      return {
        ...state,
        editEmployeeStatus: false,
        editEmployeeIsLoading: false,
      };

    // Edit Employee Form
    case Types.UPDATE_EMPLOYEE_ATTEMPT:
      return { ...state, initEmployeeStatus: true };

    case Types.INIT_EMPLOYEE_SUCCESS:
      const {
        company_attendance_profiles,
        company_departments,
        managers,
        company_positions,
        company_offices,
        user,
        monthOptions,
        paymentFactors,
        company_offices_workplaces,
        company_leaves_breaks,
        work_groups_menu,
        workTeams,
      } = action.payload;
      return {
        ...state,
        initEmployeeStatus: false,
        editEmployeeFormData: {
          ...state.editEmployeeFormData,
          attendance_profiles: company_attendance_profiles.data,
          departments: company_departments.data,
          offices: company_offices.data,
          managers: managers.data,
          positions: company_positions.data,
          roles: user?.availableAccessLevels || [],
          workGroups: work_groups_menu,
          workTeams: workTeams?.data,
          leavesBreaks: company_leaves_breaks?.data,
          locations: company_offices_workplaces?.data ?? [],
        },
        allowancesOptions: user.company?.allowancesOptions,
        deductiblesOptions: user.company?.deductiblesOptions,
        monthOptions,
        paymentFactors,
        companySalaryConfigPresets: action?.payload?.presets ?? [],
        userRegulationTypesOptions: action.payload?.regulation_types,
        employeeFormCheckInsWorkPlaces: action.payload?.workPlaces ?? [],
      };

    case Types.GET_OFFICE_LOCATIONS_SUCCESS:
      const { locations } = action.payload;
      return {
        ...state,
        initEmployeeStatus: false,
        editEmployeeFormData: {
          ...state.editEmployeeFormData,
          locations: locations ? locations.data : [],
        },
      };

    case Types.INIT_EMPLOYEE_FAILED:
      return {
        ...state,
        initEmployeeStatus: false,
        editEmployeeFormData: {},
      };

    case Types.GET_OFFICE_LOCATIONS_FAILED:
      return {
        ...state,
        initEmployeeStatus: false,
        editEmployeeFormData: {
          ...state.editEmployeeFormData,
          locations: [],
        },
      };

    case Types.SET_SELECTED_EMPLOYEE_ATT_PROFILE:
      return {
        ...state,
        selectedAttProfile: action.payload?.selectedAttProfile,
      };

    case Types.UPDATE_EMPLOYEE_SUCCESS:
    case Types.CREATE_NEW_EMPLOYEE_SALARY_SUCCESS:
      return {
        ...state,
        employeeFormStatus: {
          errorMsg: "",
          isLoading: false,
        },
        salaryConfigForm: _.cloneDeep(initState.salaryConfigForm),
        timeRangeProfileSetting: initState.timeRangeProfileSetting,
      };

    case Types.UPDATE_EMPLOYEE_FAILED:
    case Types.CREATE_NEW_EMPLOYEE_SALARY_FAILED:
      return {
        ...state,
        initEmployeeStatus: false,
        employeeFormStatus: {
          isLoading: false,
        },
        employeeServerValidation: action.payload ?? {},
      };

    //handle Input Change

    case Types.ON_INPUT_CHANGE:
      if (state[action.payload.formName]) {
        return {
          ...state,
          [action.payload.formName]: {
            ...state[action.payload.formName],
            [action.payload.event.target.name]:
              action.payload.event.target.value,
          },
        };
      }
      return state;

    case Types.ON_APPEND_INPUT_CHANGE:
      if (state[action.payload.formName]) {
        return {
          ...state,
          [action.payload.formName]: {
            ...state[action.payload.formName],
            [action.payload.event.target.name]: state[action.payload.formName][
              action.payload.event.target.name
            ].map((el, i) =>
              // eslint-disable-next-line
              i == action.payload.value ? action.payload.event.target.value : el
            ),
          },
        };
      }
      return state;
    case Types.ON_SELECT_INPUT_CHANGE:
      if (state[action.payload.formName]) {
        return {
          ...state,
          [action.payload.formName]: {
            ...state[action.payload.formName],
            [action.payload.event.name]: action.payload.value,
          },
        };
      }
      return state;

    case Types.ON_INPUT_INCREMENT:
      if (state[action.payload.formName]) {
        return {
          ...state,
          [action.payload.formName]: {
            ...state[action.payload.formName],
            [action.payload.name]: (
              Number(state[action.payload.formName][action.payload.name]) + 1
            )?.toString(),
          },
        };
      }
      return state;
    case Types.ON_INPUT_DECREMENT:
      if (state[action.payload.formName]) {
        return {
          ...state,
          [action.payload.formName]: {
            ...state[action.payload.formName],
            [action.payload.name]:
              Number(state[action.payload.formName][action.payload.name]) - 1 >
                0
                ? (
                  Number(
                    state[action.payload.formName][action.payload.name]
                  ) - 1
                ).toString()
                : "0",
          },
        };
      }
      return state;
    case Types.ON_RADIO_INPUT_CHANGE:
      if (state[action.payload.formName]) {
        return {
          ...state,
          [action.payload.formName]: {
            ...state[action.payload.formName],
            [action.payload.event.target.name]: action.payload.value,
          },
        };
      }
      return state;
    case Types.ON_CHECKBOX_INPUT_CHANGE:
      if (state[action.payload.formName]) {
        return {
          ...state,
          [action.payload.formName]: {
            ...state[action.payload.formName],
            [action.payload.event.target.name]: action.payload.event.target
              .checked
              ? [
                ...state[action.payload.formName][
                action.payload.event.target.name
                ],
                action.payload.value,
              ]
              : state[action.payload.formName][
                action.payload.event.target.name
              ].filter((val) => val !== action.payload.value),
          },
        };
      }
      return state;

    case Types.ON_CHECKBOX_BOOLEAN_INPUT_CHANGE:
      if (state[action.payload.formName]) {
        return {
          ...state,
          [action.payload.formName]: {
            ...state[action.payload.formName],
            [action.payload.event.target.name]: action.payload.event.target
              .checked
              ? 1
              : 0,
          },
        };
      }
      return state;

    case Types.ON_INPUT_RESET:
      if (state[action.payload.formName]) {
        return {
          ...state,
          [action.payload.formName]: {
            ...state[action.payload.formName],
            [action.payload.name]:
              initState[action.payload.formName][action.payload.name],
          },
        };
      }
      return state;

    case Types.ON_INPUT_RESET_WITH_VALUE:
      if ([action.payload.formName] in state) {
        return {
          ...state,
          [action.payload.formName]: {
            ...state[action.payload.formName],
            [action.payload.name]: action.payload?.resetValue,
          },
        };
      } else {
        return state;
      }

    case Types.SET_INPUT_VALID:
      if (state[action?.payload?.formNameValidation]) {
        return {
          ...state,
          [action?.payload?.formNameValidation]: state[
            action?.payload?.formNameValidation
          ]?.filter((input) => input !== action?.payload?.name),
        };
      }
      return state;

    case Types.SET_INPUT_INVALID:
      if (state[action?.payload?.formNameValidation]) {
        if (
          !state[action?.payload?.formNameValidation]?.includes(
            action?.payload?.name
          )
        ) {
          return {
            ...state,
            [action?.payload?.formNameValidation]: [
              ...state[action?.payload?.formNameValidation],
              action?.payload?.name,
            ],
          };
        }
        return state;
      }
      return state;

    // handle Data table #sorting #filter #Paginate
    case Types.SET_DATATABLE_SORTING:
      if (state[action.payload.ref]) {
        return {
          ...state,
          [action.payload.ref]: {
            ...state[action.payload.ref],
            sorting: {
              key: action.payload.key,
              dir: action.payload.dir,
            },
          },
        };
      }
      return state;

    case Types.SET_DATATABLE_FILTER:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          filter: action.payload.filter,
        },
      };

    case Types.SET_DATATABLE_PAGINATE:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          pagination: {
            ...state[action.payload.ref].pagination,
            ...action.payload.pagination,
          },
        },
      };

    // HANDLE fetch att Profiles DATA TABLE ,
    case Types.FETCH_ATTENDANCE_PROFILES_LIST_ATTEMPT:
    case Types.FETCH_SUSPENSION_LOGS_ATTEMPT:
    case Types.FETCH_BALANCE_LOGS_ATTEMPT:
    case Types.FETCH_OVERTIME_LOGS_ATTEMPT:
    case Types.FETCH_ALL_DEPARTMENTS_ATTEMPT:
    case Types.FETCH_ALL_OFFICES_ATTEMPT:
    case Types.FETCH_ALL_POSITIONS_ATTEMPT:
    case Types.FETCH_ALL_HOLIDAYS_ATTEMPT:
    case Types.FETCH_INBOUND_REQUESTS_ATTEMPT:
    case Types.EDIT_ATT_LOG_ATTEMPT:
    case Types.FETCH_USERS_CLAIMS_ATTEMPT:
    case Types.FETCH_USERS_BONUS_ATTEMPT:
    case Types.FETCH_USERS_DEDUCTIONS_ATTEMPT:
    case Types.SUBMIT_PAYROLL_PAYMENT_ATTEMPT:
    case Types.FETCH_MONTHLY_REPORT_LIST_ATTEMPT:
    case Types.FETCH_YEARLY_REPORT_LIST_ATTEMPT:
    case Types.FETCH_ALL_COMPANIES_ATTEMPT:
    case Types.FETCH_ADMIN_USERS_ATTEMPT:
    case Types.FETCH_WORK_TIMINGS_LIST_ATTEMPT:
    case Types.EXPORT_MONTHLY_REPORT_ATTEMPT:
    case Types.EXPORT_YEARLY_REPORT_ATTEMPT:
    case Types.EXPORT_EMPLOYEE_LIST_ATTEMPT:
    case Types.FETCH_REQUEST_RULES_LIST_ATTEMPT:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: true,
        },
      };
    case Types.FETCH_ATTENDANCE_LOGS_ATTEMPT:
      const isEmpOnly = action.payload.attendanceLogsList.isEmpOnly;
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: !isEmpOnly,
          isEmpLoading: true,
        },
      };
    case Types.FETCH_PAYROLL_REPORT_LIST_ATTEMPT:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          ...action.payload[action.payload.ref],
          isLoading: true,
        },
        payrollEmployeesFilter: [],
      };

    case Types.FETCH_ATTENDANCE_PROFILES_LIST_SUCCESS:
    case Types.FETCH_ATTENDANCE_LOGS_SUCCESS:
    case Types.FETCH_SUSPENSION_LOGS_SUCCESS:
    case Types.FETCH_BALANCE_LOGS_SUCCESS:
    case Types.FETCH_OVERTIME_LOGS_SUCCESS:
    case Types.FETCH_ALL_OFFICES_SUCCESS:
    case Types.FETCH_ALL_DEPARTMENTS_SUCCESS:
    case Types.FETCH_ALL_POSITIONS_SUCCESS:
    case Types.FETCH_INBOUND_REQUESTS_SUCCESS:
    case Types.FETCH_USERS_CLAIMS_SUCCESS:
    case Types.FETCH_USERS_BONUS_SUCCESS:
    case Types.FETCH_USERS_DEDUCTIONS_SUCCESS:
    case Types.FETCH_PAYROLL_REPORT_LIST_SUCCESS:
    case Types.FETCH_ALL_COMPANIES_SUCCESS:
    case Types.FETCH_WORK_TIMINGS_LIST_SUCCESS:
    case Types.FETCH_REQUEST_RULES_LIST_SUCCESS:
    case Types.FETCH_PENALTIES_LIST_SUCCESS:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: false,
          data: action?.payload?.list?.data,
          pagination: action?.payload?.list?.paginatorInfo,
        },
      };

    case Types.FETCH_MONTHLY_REPORT_LIST_SUCCESS:
    case Types.FETCH_YEARLY_REPORT_LIST_SUCCESS:
      return {
        ...state,
        [action.payload.data.ref]: {
          ...action?.payload?.data[action.payload.data.ref],
          isLoading: false,
          data: action?.payload?.list?.data,
          pagination: action?.payload?.list?.paginatorInfo,
        },
      };

    case Types.FETCH_ALL_HOLIDAYS_SUCCESS:
      return {
        ...state,
        holidaysList: {
          ...state.holidaysList,
          isLoading: false,
          data: action?.payload?.list?.data,
          pagination: action?.payload?.list?.paginatorInfo,
          auto_suggest_holidays: action?.payload?.auto_suggest_holidays,
        },
      };

    case Types.UPDATE_TOTAL_lIST_COUNT:
      return {
        ...state,
        [action.payload.refList]: {
          ...state[action.payload.refList],
          total: action?.payload?.total,
        },
      };

    case Types.FETCH_ADMIN_USERS_SUCCESS:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: false,
          data: action?.payload?.list?.data,
          pagination: action?.payload?.list?.paginatorInfo,
        },
      };

    case Types.FETCH_ATTENDANCE_PROFILES_LIST_FAILED:
    case Types.FETCH_ATTENDANCE_LOGS_FAILED:
    case Types.FETCH_SUSPENSION_LOGS_FAILED:
    case Types.FETCH_BALANCE_LOGS_FAILED:
    case Types.FETCH_OVERTIME_LOGS_FAILED:
    case Types.FETCH_ALL_OFFICES_FAILED:
    case Types.FETCH_ALL_DEPARTMENTS_FAILED:
    case Types.FETCH_ALL_POSITIONS_FAILED:
    case Types.FETCH_ALL_HOLIDAYS_FAILED:
    case Types.FETCH_INBOUND_REQUESTS_FAILED:
    case Types.FETCH_USERS_CLAIMS_FAILED:
    case Types.FETCH_USERS_BONUS_FAILED:
    case Types.FETCH_USERS_DEDUCTIONS_FAILED:
    case Types.FETCH_PAYROLL_REPORT_LIST_FAILED:
    case Types.FETCH_MONTHLY_REPORT_LIST_FAILED:
    case Types.FETCH_YEARLY_REPORT_LIST_FAILED:
    case Types.FETCH_ALL_COMPANIES_FAILED:
    case Types.FETCH_ADMIN_USERS_FAILED:
    case Types.FETCH_WORK_TIMINGS_LIST_FAILED:
    case Types.FETCH_REQUEST_RULES_LIST_FAILED:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: false,
          data: [],
        },
      };

    case Types.ADJUST_SIGNOUT_ATTEMPT:
      return {
        ...state,
        adjustSignOutState: {
          id: action?.payload?.employee_id,
          isLoading: true,
        },
      };

    case Types.ADJUST_SIGNOUT_SUCCESS:
      return {
        ...state,
        adjustSignOutState: {
          id: null,
          isLoading: false,
        },
      };

    case Types.ADJUST_SIGNOUT_FAILED:
      return {
        ...state,
        adjustSignOutState: {
          id: null,
          isLoading: false,
        },
      };

    case Types.ACCEPT_INBOUND_REQUEST_ATTEMPT:
    case Types.REJECT_INBOUND_REQUEST_ATTEMPT:
    case Types.ACCEPT_DAY_OFF_REQUEST_ATTEMPT:
    case Types.ACCEPT_LOAN_REQUEST_ATTEMPT:
      return {
        ...state,
        requstsLoader: [...state.requstsLoader, action.payload.id],
      };

    case Types.ACCEPT_CLAIM_REQUEST_ATTEMPT:
      return {
        ...state,
        acceptClaimRequestModalFormServerValidation: {},
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: true,
          modalMessage: "",
        },
      };

    case Types.ACCEPT_INBOUND_REQUEST_SUCCESS:
    case Types.REJECT_INBOUND_REQUEST_SUCCESS:
      if (action.payload?.updateNotificationsRequestUi) {
        return {
          ...state,
          requstsLoader: state.requstsLoader.filter(
            (id) => id !== action.payload.id
          ),
          notificationHistory: {
            ...state.notificationHistory,
            data: state.notificationHistory?.data?.map((notification) => {
              if (notification?.notifiable?.id === action.payload?.id) {
                return {
                  ...notification,
                  seen: 1,
                  notifiable: {
                    ...notification.notifiable,
                    status: "accepted",
                  },
                };
              } else {
                return notification;
              }
            }),
          },
        };
      } else {
        return {
          ...state,
          requstsLoader: state.requstsLoader.filter(
            (id) => id !== action.payload.id
          ),
        };
      }

    case Types.ACCEPT_INBOUND_REQUEST_FAILED:
    case Types.REJECT_INBOUND_REQUEST_FAILED:
    case Types.ACCEPT_DAY_OFF_REQUEST_COMPLETED:
    case Types.ACCEPT_LOAN_REQUEST_COMPLETED:
      return {
        ...state,
        requstsLoader: state.requstsLoader.filter(
          (id) => id !== action.payload.id
        ),
      };

    case Types.CANCEL_REQUEST_ATTEMPT:
      return {
        ...state,
        requstsLoader: [...state.requstsLoader, action.payload.id],
        editLeavesModalActions: {
          ...state.editLeavesModalActions,
          isLoading: true,
        },
      };

    case Types.CANCEL_REQUEST_SUCCESS:
    case Types.CANCEL_REQUEST_FAILED:
      return {
        ...state,
        requstsLoader: state.requstsLoader.filter(
          (id) => id !== action.payload.id
        ),
        editLeavesModalActions: {
          ...state.editLeavesModalActions,
          isLoading: false,
        },
      };
    case Types.UPDATE_OFFICE_ATTEMPT:
      return {
        ...state,
        officeFormStatus: {
          error: "",
          isLoading: true,
        },
      };

    case Types.ACCEPT_OVERTIME_REQUEST_ATTEMPT:
      return {
        ...state,
        overtimeModalActions: {
          ...state.overtimeModalActions,
          isLoading: true,
          modalMessage: "",
        },
      };

    case Types.ACCEPT_OVERTIME_REQUEST_SUCCESS:
      return {
        ...state,
        overtimeModalActions: initState.overtimeModalActions,
        overtimeModal: initState.overtimeModal,
      };

    case Types.ACCEPT_OVERTIME_REQUEST_FAILED:
      return {
        ...state,
        overtimeModalActions: {
          ...state.overtimeModalActions,
          isLoading: false,
          modalMessage:
            action.payload?.error[0]?.extensions?.reason ??
            "Something Went Wrong",
        },
      };

    case Types.UPSERT_EMPLOYEE_ATTEMPT:
    case Types.CREATE_NEW_EMPLOYEE_SALARY_ATTEMPT:
      return {
        ...state,
        employeeFormStatus: {
          errorMsg: "",
          isLoading: true,
        },
      };
    case Types.UPDATE_OFFICE_SUCCESS:
      return {
        ...state,
        officeFormStatus: {
          error: "",
          isLoading: false,
        },
      };
    case Types.UPDATE_OFFICE_FAILED:
      return {
        ...state,
        officeFormStatus: {
          error: action.payload.message,
          isLoading: false,
        },
      };

    // handle create & edit Att Profile
    case Types.CREATE_ATTENDANCE_PROFILE_ATTEMPT:
      return {
        ...state,
        creatAttProfileLoading: true,
      };
    case Types.CREATE_ATTENDANCE_PROFILE_SUCCESS:
      return {
        ...state,
        creatAttProfileLoading: false,
        creatAttProfileSuccess: true,
        attendaceProfile: action.payload.normal,
      };
    case Types.FETCH_ATTENDANCE_PROFILE_ATTEMPT:
      return {
        ...state,
        editAttProfileLoading: true,
      };
    case Types.FETCH_ATTENDANCE_PROFILE_SUCCESS:
      if (action.payload.normal) {
        return {
          ...state,
          editAttProfileLoading: false,
          editAttProfileSuccess: true,
          attendaceProfile: action.payload.normal,
          attendanceProfileCheckInsWorkPlaces: action.payload?.workPlaces,
        };
      } else {
        return {
          ...state,
          editAttProfileLoading: false,
          editAttProfileSuccess: true,
          attendanceProfileCheckInsWorkPlaces: action.payload?.workPlaces,
        };
      }
    case Types.UPDATE_ATTENDANCE_PROFILE_HOLIDAYS:
      return {
        ...state,
        holidayOptions: action.payload,
      };
    case Types.CREATE_ATTENDANCE_PROFILE_FAILED:
    case Types.FETCH_ATTENDANCE_PROFILE_FAILED:
      return {
        ...state,
        creatAttProfileLoading: false,
        creatAttProfileSuccess: false,
        editAttProfileLoading: false,
        editAttProfileSuccess: false,
      };
    case Types.RESET_ATTENDANCE_PROFILE_FORM:
      return {
        ...state,
        creatAttProfileLoading: false,
        creatAttProfileSuccess: false,
        attendaceProfile: {
          ...initState.attendaceProfile,
          attendance_profile_work_timing_input: [
            {
              id: null,
              sign_in_req: 0,
              work_timing_id: null,
              first_half_work_timing_id: null,
              first_half_work_timing_name: null,
              second_half_work_timing_id: null,
              second_half_work_timing_name: null,
              start: null,
              end: null,
              allow_half_day: null,
              can_edit: null,
            },
          ],
        },
        attProfileNormalServerValidation: {},
      };

    case Types.SHOW_LEAVES_BREAKS_MODAL:
      return {
        ...state,
        leavesBreaksForm: {
          ...action.payload.data,
        },
        isAnnualLeavesAllowedInLeaveBreakProfile:
          !!action.payload?.data?.allow_annual_leaves,
      };

    case Types.SET_LEAVES_BREAKS_SERVER_VALIDATION:
      return {
        ...state,
        leavesBreaksFormServerValidation: action?.payload?.data,
      };

    case Types.RESET_LEAVES_BREAKS_FORM:
      return {
        ...state,
        leavesBreaksForm: {
          ...initState.leavesBreaksForm,
        },
        leavesBreaksFormValidation: [],
        leavesBreaksFormServerValidation: {},
      };

    // Handle Fetch All Employees ,
    case Types.FETCH_ALL_EMPLOYEES_ATTEMPT:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: true,
        },
      };
    case Types.FETCH_ALL_EMPLOYEES_SUCCESS:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: false,
          data: action.payload.list.data,
          employeesDetails: action.payload.employeesDetails,
          pagination: action.payload.list.paginatorInfo,
        },
      };
    case Types.FETCH_ALL_EMPLOYEES_FAILED:
    case Types.STOP_REPORT_LIST_LOADER:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: false,
        },
      };

    case Types.FETCH_ALL_DEPARTMENTS_BY_COMPANY:
      return {
        ...state,
        [action.payload.ref]: action.payload.data,
      };

    // Handle Fetch Employee attendance logs ,
    case Types.FETCH_EMPLOYEE_ATTENDANCE_LOGS_SUCCESS:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: false,
          data: action?.payload?.list?.data,
        },
      };

    // handle fetch selected employee
    case Types.RESET_EMPLOYEE_PRFOILE_VIEW:
      return {
        ...state,
        // selectedEmployeeProfile: {},
        employeeRequestsList: initState.employeeRequestsList,
      };
    // handle fetch selected employee Profile
    case Types.FETCH_EMPLOYEE_PROFILE_SUCCESS:
      return {
        ...state,
        selectedEmployeeProfile: action.payload.user,
        employeeProfileLoading: false,
      };

    // Fetching selected employee Requests
    case Types.FETCH_COMPANY_PROFILE_SUPSCRIPTIONS_ATTEMPT:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: true,
        },
      };

    case Types.FETCH_COMPANY_PROFILE_SUPSCRIPTIONS_SUCCESS:
    case Types.FETCH_LOANS_LIST_SUCCESS:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: false,
          data: action.payload.list.data,
          pagination: action.payload.list.paginatorInfo,
        },
      };

    case Types.FETCH_COMPANY_PROFILE_SUPSCRIPTIONS_FAILED:
    case Types.FETCH_EMPLOYEE_ATTENDANCE_LOGS_FAILED:
    case Types.SUBMIT_PAYROLL_PAYMENT_SUCCESS:
    case Types.SUBMIT_PAYROLL_PAYMENT_FAILED:
    case Types.EXPORT_MONTHLY_REPORT_SUCCESS:
    case Types.EXPORT_YEARLY_REPORT_SUCCESS:
    case Types.EXPORT_MONTHLY_REPORT_FAILED:
    case Types.EXPORT_YEARLY_REPORT_FAILED:
    case Types.EXPORT_EMPLOYEE_LIST_SUCCESS:
    case Types.EXPORT_EMPLOYEE_LIST_FAILED:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: false,
        },
      };

    case Types.LOG_OUT:
    case "SWITCH_ACCOUNT":
      return { ...initState };

    case Types.SET_EDIT_EMPLOYEE_OPEN:
      return {
        ...state,
        editEmployeeForm: true,
      };

    case Types.SET_EDIT_EMPLOYEE_CLOSED:
      return {
        ...state,
        editEmployeeForm: false,
      };

    case Types.RESET_EMPLOYEE_FORM:
      return {
        ...state,
        employeeFormValidation: [],
        employeeServerValidation: {},
        employeeForm: initState.employeeForm,
        paymentMethodForm: initState.paymentMethodForm,
      };
    case Types.SET_COMMISSION_DATE_RANGE:
      return {
        ...state,
        commissionForm: {
          ...state.commissionForm,
          exchange_days: action.payload,
        },
      };

    case Types.PAGINATE_DATA_TABLE:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          pagination: {
            ...state[action.payload.ref].pagination,
            currentPage: action.payload.page,
          },
        },
      };

    case Types.UPDATE_LNG_LAT_LOCATION:
      return {
        ...state,
        [action.payload.formName]: {
          ...state[action.payload.formName],
          lng: action.payload.lng,
          lat: action.payload.lat,
        },
      };

    // handle Inbound Requests
    // Fetching Requests

    case Types.UPDTAE_EMPLOYEES_FILTER_LOGS:
      return {
        ...state,
        logsEmployeesFilter: action.payload,
        attendanceLogsList: {
          ...state?.attendanceLogsList,
          isEmpLoading: false,
        },
      };

    case Types.TOGGLE_SUSPENSION_MODAL:
      return {
        ...state,
        suspensionModal: {
          ...initState.suspensionModal,
        },
        init_suspension: {
          ...initState.init_suspension,
        },
        suspensionModalActions: {
          isVissible: true,
          isLoading: false,
          modalMessage: "",
        },
        replace_direct_manager_in_suspension_modal: action.payload,
      };

    case Types.HIDE_SUSPENSION_MODAL:
      return {
        ...state,
        suspensionModalActions: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        replace_direct_manager_in_suspension_modal: false,
      };

    case Types.MANAGED_EMPLOYEES_IN_SUSPENSION_MODAL:
      return {
        ...state,
        managedEmployeesInSuspensionModal: action.payload,
      };

    case Types.ADD_EMPLOYEE_ID_TO_SUSPENSION:
      return {
        ...state,
        suspensionModal: {
          ...state.suspensionModal,
          suspended_employee: action.payload,
        },
      };

    case Types.UPDATE_SUSPENSION_ATTEMPT:
      return {
        ...state,
        suspensionModalActions: {
          ...state.suspensionModalActions,
          isLoading: true,
          modalMessage: "",
          suspensionModal: { ...initState.suspensionModal },
        },
      };
    case Types.UPDATE_SUSPENSION_SUCCESS:
      return {
        ...state,
        suspensionModalActions: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        suspensionModal: {
          ...initState.suspensionModal,
        },
        init_suspension: {
          ...initState.init_suspension,
        },
      };
    case Types.UPDATE_SUSPENSION_FAILED:
      return {
        ...state,
        suspensionModalActions: {
          ...state.suspensionModalActions,
          isLoading: false,
          modalMessage: action.payload,
        },
      };

    case Types.ADD_EMPLOYEE_ID_TO_ACTIVATION:
      return {
        ...state,
        activationModal: {
          ...state.activationModal,
          id: action.payload.user_id,
          countryId: action?.payload?.countryId,
          office: action?.payload?.office,
          office_id: action?.payload?.office?.id,
        },
        salaryConfigForm: { ..._.cloneDeep(state.salaryConfigForm) },
        activationModalActions: {
          ...state.activationModalActions,
          loadingModal: true,
        },
      };

    case Types.ACTIVATE_USER_ATTEMPT:
      return {
        ...state,
        activationModalActions: {
          ...state.activationModalActions,
          isLoading: true,
          modalMessage: "",
        },
      };
    case Types.ACTIVATE_USER_SUCCESS:
      return {
        ...state,
        activationModalActions: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
          require_create_new_salary_configuration: true,
        },
        salaryConfigForm: { ..._.cloneDeep(initState.salaryConfigForm) },
        fetchedEmployee_attendance: initState.fetchedEmployee_attendance,
        activationEmployee_attendance: {
          employee_attendance_option: 1,
          data: [
            {
              date: null,
              sign_in_status: null,
              sign_in_time: null,
              sign_out_time: null,
            },
          ],
          annual_leaves: "0",
          emergency_leaves: "0",
          sick_leaves: null,
        },
        loadingActivationAttendance: false,
        employeeAttendanceFormValidation: [],
      };
    case Types.ACTIVATE_USER_FAILED:
      return {
        ...state,
        activationModalActions: {
          ...state.activationModalActions,
          isLoading: false,
        },
      };

    case Types.TOGGLE_ACTIVATION_MODAL:
      return {
        ...state,
        activationModal: {
          id: "",
          // end_date: "",

          loadingModal: false,
          countryId: action?.payload?.countryId,
        },
        activationModalActions: {
          isVissible: !state.activationModalActions.isVissible,
          isLoading: false,
          modalMessage: "",
          require_create_new_salary_configuration: true,
        },
        upsertSalaryConfigServerValidation: {},
        fetchedEmployee_attendance: initState.fetchedEmployee_attendance,
        activationEmployee_attendance: {
          employee_attendance_option: 1,
          data: [
            {
              date: null,
              sign_in_status: null,
              sign_in_time: null,
              sign_out_time: null,
            },
          ],
          annual_leaves: "0",
          emergency_leaves: "0",
          sick_leaves: null,
        },
        loadingActivationAttendance: false,
        employeeAttendanceFormValidation: [],
        hasSickLeaveInActivationModal: action.payload.hasSickLeave,
        hasEmergencyLeaveInActivationModal: action.payload.hasEmergencyLeave,
        allowOvertimeInActivationModal: action.payload.allowOvertime,
        salaryConfigForm: { ..._.cloneDeep(initState.salaryConfigForm) },
      };
    case Types.INIT_ACTIVATE_SALARY_CONFIG_SUCCESS:
      return {
        ...state,
        activationModalActions: {
          isVissible: true,
          isLoading: false,
          modalMessage: "",
          loadingModal: false,
          require_create_new_salary_configuration: true,
        },
        allowancesOptions: action.payload?.user?.company?.allowancesOptions,
        deductiblesOptions: action.payload?.user?.company?.deductiblesOptions,
        monthOptions: action.payload?.monthOptions,
        paymentFactors: action.payload?.paymentFactors,
        userCurrenciesOptions: action.payload?.currencies,
        activationModal: {
          ...state.activationModal,
          ...action?.payload?.allowedUserLeaves,
        },
        userRegulationTypesOptions:
          action.payload?.relevantRegulationTypes?.map((regulation) => ({
            ...regulation,
            isDefault:
              regulation?.id === action.payload?.office?.defaultRegulation?.id,
          })),
        relevantStates: action?.payload?.relevantStates,
        companySalaryConfigPresets: action.payload?.presets ?? [],
      };

    case Types.INIT_ACTIVATE_SALARY_CONFIG_FAILED:
      return {
        ...state,
        activationModalActions: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
          loadingModal: false,
          require_create_new_salary_configuration: true,
        },
        allowancesOptions: initState.allowancesOptions,
        deductiblesOptions: initState.deductiblesOptions,
        monthOptions: initState.monthOptions,
        paymentFactors: initState.paymentFactors,
        userCurrenciesOptions: initState.currencies,
      };
    case Types.SHOW_DEPARTMENT_MODAL:
    case Types.SHOW_POSITION_MODAL:
    case Types.SHOW_ADMIN_USER_MODAL:
    case Types.SHOW_OFFICE_HOLIDAY_MODAL:
    case Types.SHOW_EDIT_AMOUNT_MODAL:
    case Types.SHOW_BALANCE_MODAL:
    case Types.FETCH_DEDUCTION_AMOUNT_SUCCESS:
    case Types.SHOW_COMPANY_MODAL:
    case Types.SHOW_EDIT_COMPANY_NAME_MODAL:
    case Types.SHOW_EDIT_ACCOUNT_MANAGERS_MODAL:
    case Types.SHOW_EDIT_BUSSINESS_PARTNERS_MODAL:
    case Types.SHOW_COMPANY_SUBSCRIPTION_MODAL:
    case Types.SHOW_CONFIRM_DELETE_MANAGER_MODAL:
    case Types.SHOW_UPDATE_BUSSINESS_PARTNER_MODAL:
    case Types.SHOW_PENALTY_MODAL:
      return {
        ...state,
        [action.payload.ref]: {
          isVissible: true,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload.formName]: {
          ...state[action.payload.formName],
          ...action.payload.data,
        },
      };

    case Types.SHOW_OFFICE_LOCATION_MODAL:
      return {
        ...state,
        officeLocationModalActions: {
          isVissible: true,
          isLoading: false,
          modalMessage: "",
          isViewOnly: action.payload?.isViewOnly || false,
        },
        locationForm: {
          ...state.locationForm,
          ...(action.payload?.data ? action.payload?.data : {}),
        },
      };

    case Types.SHOW_HOLIDAYS_MODAL:
      return {
        ...state,
        holidaysModalServerValidation: {},
        holidaysModalActions: {
          isVissible: true,
          isLoading: false,
          modalMessage: "",
        },
        holidaysModal: {
          ...state.holidaysModal,
          ...action.payload.data,
        },
      };

    case Types.UPSERT_WORK_TIMINGS_ATTEMPT:
      return {
        ...state,
        workTimingsModal: {
          ...state.workTimingsModal,
          isLoading: true,
          modalMessage: "",
        },
        workTimingsServerValidation: {},
        addedWorkTime: null,
      };
    case Types.UPSERT_WORK_TIMINGS_FAILED:
    case Types.UPSERT_SETUP_WIZARD_WORK_TIMING_FAILED:
      return {
        ...state,
        workTimingsModal: {
          ...state.workTimingsModal,
          isLoading: false,
          modalMessage: "",
        },
        workTimingsServerValidation: action.payload?.errors,
      };
    case Types.SHOW_WORK_TIMINGS_MODAL:
      return {
        ...state,
        workTimingsModal: {
          isVissible: true,
          isLoading: false,
          modalMessage: "",
          createdFrom: action?.payload?.createdFrom ?? null,
          parentFormName: action?.payload?.formName,
          workTimeIndex: action?.payload?.workTimeIndex,
          which_half: action?.payload?.which_half,
          normal_work_timing_id: action?.payload?.normal_work_timing_id,
        },
        workTimings: {
          ...state.workTimings,
          work_timing_profile_type: action?.payload?.createdFrom ?? "normal",
        },
        workTimingsServerValidation: {},
        addedWorkTime: null,
      };
    case Types.DISMISS_WORK_TIMINGS_MODAL:
      return {
        ...state,
        workTimingsModal: initState.workTimingsModal,
        workTimingsValidation: [],
        workTimingsServerValidation: {},
        workTimings: _.cloneDeep(initStore.workTimings),
        addedWorkTime: null,
      };

    case Types.UPSERT_WORK_TIMINGS_SUCCESS:
      let addedWorkTime = {
        which_half: state?.workTimingsModal?.which_half,
        worktime: action?.payload?.res,
      };
      return {
        ...state,
        addedWorkTime,
        workTimingsModal: { ...initState.workTimingsModal },
        workTimings: _.cloneDeep(initStore.workTimings),
        workTimingsValidation: [],
        workTimingsServerValidation: {},
      };

    case Types.SHOW_ADMIN_USER_PROFILE_MODAL:
      return {
        ...state,
        adminUsersProfileModalActions: {
          ...state.adminUsersProfileModalActions,
          isVissible: true,
        },
        adminUsersProfileModal: action.payload.adminUserDetails,
      };

    case Types.SHOW_EMPLOYEE_LOCATION_MODAL:
      return {
        ...state,
        [action.payload.ref]: {
          isVissible: true,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload.formName]: {
          ...state[action.payload.formName],
          ...(action.payload.data ? action.payload.data : {}),
        },
        employeeForm: {
          ...state.employeeForm,
          optionsName: action.payload.optionsName,
        },
      };

    case Types.HIDE_DEPARTMENT_MODAL:
    case Types.HIDE_POSITION_MODAL:
    case Types.HIDE_ADMIN_USER_PROFILE_MODAL:
    case Types.HIDE_ADMIN_USER_MODAL:
    case Types.HIDE_HOLIDAYS_MODAL:
    case Types.HIDE_OFFICE_LOCATION_MODAL:
    case Types.HIDE_OFFICE_HOLIDAY_MODAL:
    case Types.HIDE_EDIT_AMOUNT_MODAL:
    case Types.HIDE_EDIT_COMPANY_NAME_MODAL:
    case Types.HIDE_EDIT_ACCOUNT_MANAGERS_MODAL:
    case Types.HIDE_EDIT_BUSSINESS_PARTNERS_MODAL:
    case Types.HIDE_CONFIRM_DELETE_MANAGER_MODAL:
    case Types.HIDE_UPDATE_BUSSINESS_PARTNER_MODAL:
    case Types.HIDE_LOAN_MODAL:
    case Types.HIDE_PENALTY_MODAL:
      return {
        ...state,
        [action.payload.ref]: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload.formName]: initState[action.payload.formName],
      };
    case Types.HIDE_BALANCE_MODAL:
      return {
        ...state,
        balanceModalServerValidation: {},
        [action.payload.ref]: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload.formName]: initState[action.payload.formName],
      };

    case Types.HIDE_COMPANY_SUBSCRIPTION_MODAL:
      return {
        ...state,
        addSubscriptionModalServerValidation: {},
        addSubscriptionModalActions: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        addSubscriptionModal: initState.addSubscriptionModal,
      };

    case Types.HIDE_COMPANY_MODAL:
      return {
        ...state,
        companiesModalServerValidation: {},
        [action.payload.ref]: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload.formName]: initState[action.payload.formName],
        companiesModal: {
          week_start_day: "Saturday",
          partners_input: [
            {
              id: null,
              company_id: null,
              name: "",
              phone: "",
              phone_country_code: "",
              email: "",
              force_save: false,
            },
          ],
        },
      };

    case Types.UPDATE_DEPARTMENT_ATTEMPT:
    case Types.CREATE_DEPARTMENT_ATTEMPT:
    case Types.UPDATE_POSITION_ATTEMPT:
    case Types.CREATE_POSITION_ATTEMPT:
    case Types.EDIT_PAYROLL_DEDUCTIONS_ATTEMPT:
    case Types.FETCH_DEDUCTION_AMOUNT_ATTEMPT:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: true,
          modalMessage: "",
        },
      };

    case Types.CREATE_ADMIN_USER_ATTEMPT:
      return {
        ...state,
        adminUsersModalServerValidation: {},
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: true,
          modalMessage: "",
        },
      };

    case Types.CREATE_ADMIN_USER_SUCCESS:
      return {
        ...state,
        adminUsersModalServerValidation: {},
        [action.payload?.ref]: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload?.formName]: initState[action.payload?.formName],
      };

    case Types.UPDATE_DEPARTMENT_SUCCESS:
    case Types.CREATE_DEPARTMENT_SUCCESS:
    case Types.UPDATE_POSITION_SUCCESS:
    case Types.CREATE_POSITION_SUCCESS:
    case Types.EDIT_PAYROLL_DEDUCTIONS_SUCCESS:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: false,
          modalMessage: "",
        },
      };

    case Types.UPDATE_ADMIN_USER_PROFILE_ATTEMPT:
      return {
        ...state,
        updateAdminUserProfileLoading: true,
        adminUsersProfileModalServerValidation: {},
      };

    case Types.UPDATE_ADMIN_USER_PROFILE_SUCCESS:
      return {
        ...state,
        updateAdminUserProfileLoading: false,
        adminUsersProfileModalServerValidation: {},
        adminUsersProfileModal: {
          id: action?.payload?.id,
          user_id: action?.payload?.user_id,
          role_id: action?.payload?.role_id,
          name: action?.payload?.user?.name,
          phone_country_code: action?.payload?.user?.phone_country_code,
          phone: action?.payload?.user?.phone,
          email: action?.payload?.user?.email,
          company_ids: action?.payload?.companies?.map(
            (companiesDetails) => companiesDetails?.id
          ),
          company_names: action?.payload?.companies?.map(
            (companiesDetails) => companiesDetails?.name
          ),
        },
      };

    case Types.DELETE_COMPANIES_ATTEMPT:
      return {
        ...state,
        deleteCompanyLoading: true,
      };

    case Types.SUSPEND_COMPANIES_ATTEMPT:
      return {
        ...state,
        suspendCompanyLoading: true,
      };

    case Types.ACTIVATE_COMPANIES_ATTEMPT:
      return {
        ...state,
        activiateCompanyLoading: true,
      };

    case Types.DELETE_COMPANIES_SUCCESS:
    case Types.DELETE_COMPANIES_FAILED:
      return {
        ...state,
        deleteCompanyLoading: false,
      };

    case Types.SUSPEND_COMPANIES_SUCCESS:
    case Types.SUSPEND_COMPANIES_FAILED:
      return {
        ...state,
        suspendCompanyLoading: false,
      };

    case Types.ACTIVATE_COMPANIES_SUCCESS:
    case Types.ACTIVATE_COMPANIES_FAILED:
      return {
        ...state,
        activiateCompanyLoading: false,
      };

    case Types.DELETE_ADMIN_USER_ATTEMPT:
      return {
        ...state,
        deleteAdminUserLoading: true,
      };

    case Types.DELETE_ADMIN_USER_SUCCESS:
    case Types.DELETE_ADMIN_USER_FAILED:
      return {
        ...state,
        deleteAdminUserLoading: false,
      };

    case Types.BALANCE_MODAL_ATTEMPT:
      return {
        ...state,
        balanceModalServerValidation: {},
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: true,
          modalMessage: "",
        },
      };
    case Types.BALANCE_MODAL_SUCCESS:
      return {
        ...state,
        balanceModalServerValidation: {},
        [action.payload?.ref]: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload?.formName]: initState[action.payload?.formName],
      };

    case Types.UPDATE_DEPARTMENT_FAILED:
    case Types.CREATE_DEPARTMENT_FAILED:
    case Types.EDIT_ATT_LOG_FAILED:
    case Types.UPDATE_POSITION_FAILED:
    case Types.CREATE_POSITION_FAILED:
    case Types.EDIT_PAYROLL_DEDUCTIONS_FAILED:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: false,
          modalMessage: action.payload.message,
        },
      };

    case Types.UPDATE_OFFICE_LOCATION:
      return {
        ...state,
        [action.payload.formName]: {
          ...state[action.payload.formName],

          locations: !state[action.payload.formName]?.locations?.length
            ? [...state[action.payload.formName].locations, action.payload.data]
            : [
              ...state[action.payload.formName].locations?.filter(
                (loc) => loc.id !== action.payload.data.id
              ),

              action.payload.data,
            ],
        },

        officeLocationsOptions: [
          ...state.officeLocationsOptions?.filter(
            (loc) => loc?.id !== action.payload.data.id
          ),
          action.payload.data,
        ],

        officeLocationModalActions: initState.officeLocationModalActions,
        locationForm: initState.locationForm,
      };

    case Types.UPDATE_EMPLOYEE_LOCATION:
      return {
        ...state,
        [action.payload.formName]: {
          ...state[action.payload.formName],

          locations: !state[action.payload.formName]?.locations?.length
            ? [
              ...state[action?.payload?.formName]?.locations,
              action.payload?.data,
            ]
            : [
              ...state[action?.payload?.formName].locations?.filter(
                (loc) => loc.id !== action.payload.data.id
              ),

              {
                name: action.payload.data.name,
                value: [action.payload.data.id],
              },
            ],
        },

        employeeForm: {
          ...state.employeeForm,
          [state.employeeForm.optionsName]: [
            ...state.employeeForm[state.employeeForm.optionsName]?.filter(
              (loc) => loc?.id !== action.payload.data.id
            ),
            {
              name: action.payload.data.name,
              value: [action.payload.data.id],
            },
          ],
        },

        officeLocationModalActions: initState.officeLocationModalActions,
        locationForm: initState.locationForm,
      };

    case Types.SET_LOCATIONS_SERVER_VALIDATION:
      return {
        ...state,
        locationFormServerValidation: action.payload?.data,
      };

    case Types.UPDATE_OFFICE_HOLIDAY:
      return {
        ...state,
        officeForm: {
          ...state.officeForm,

          holidays: !state.officeForm?.holidays?.length
            ? [...state.officeForm.holidays, action.payload]
            : [
              ...state.officeForm.holidays.filter(
                (loc) => loc.id !== action.payload.id
              ),
              action.payload,
            ],
        },
        officeHolidaysOptions: [
          ...state.officeHolidaysOptions?.filter(
            (loc) => loc?.id !== action.payload.id
          ),
          action.payload,
        ],
        officeHolidayModalActions: initState.officeHolidayModalActions,
        holidayForm: initState.holidayForm,
      };
    case Types.UPDATE_ATTENDANCE_PROFILE_HOLIDAY:
      return {
        ...state,
        attendaceProfile: {
          ...state.attendaceProfile,

          holidays: !state.attendaceProfile?.holidays?.length
            ? [...state?.attendaceProfile?.holidays, action.payload]
            : [
              ...state.attendaceProfile.holidays.filter(
                (loc) => loc.id !== action.payload.id
              ),
              action.payload,
            ],
        },
        attProfileHolidaysOptions: [
          ...state.attProfileHolidaysOptions?.filter(
            (loc) => loc?.id !== action.payload.id
          ),
          action.payload,
        ],
        officeHolidayModalActions: initState.officeHolidayModalActions,
        holidayForm: initState.holidayForm,
      };

    case Types.UPDATE_OBJECT_MULTI_SELECT:
      return {
        ...state,
        [action.payload.formName]: {
          ...state[action.payload.formName],
          [action.payload.name]: action.payload.data,
        },
      };

    case Types.RESET_OFFICE_LOCATION:
      return {
        ...state,
        locationForm: initState.locationForm,
      };

    case Types.RESET_OFFICE_HOLIDAY:
      return {
        ...state,
        holidayForm: initState.holidayForm,
      };

    case Types.RESET_OFFICE_FORM:
      return {
        ...state,
        officeForm: { ...initState.officeForm },
        officeLocationsOptions: initState.officeLocationsOptions,
        officeHolidaysOptions: initState.officeHolidaysOptions,
        officeIpsOptions: initState.officeIpsOptions,
        attProfileHolidaysOptions: initState.attProfileHolidaysOptions,
        officeCurrenciesOptions: initState.officeCurrenciesOptions,
        officeCountriesOptions: initState.officeCountriesOptions,
        taxationsOptions: initState.taxationsOptions,
      };

    case Types.FETCH_OFFICE_ATTEMPT:
      return {
        ...state,
        fetchingOfficeForm: true,
      };
    case Types.FETCH_OFFICE_FAILED:
      return {
        ...state,
        fetchingOfficeForm: false,
      };
    case Types.FETCH_OFFICE_SUCCESS:
      let officeData = {};
      // Case Edit Form Only
      if (action.payload?.office) {
        const officeCurrency = action.payload?.currencies?.find(
          (currency) => currency?.defaultCurrencyOffice
        );
        officeData = {
          officeForm: {
            ...action.payload?.office,
            canEditStartOfMonthOrWeek:
              action.payload?.office?.canEditMonthStartDay,
            isWorkPlaceRef: action.payload?.office?.is_workplace,
            holidays: action.payload?.office?.holiday_profile?.holidays ?? [],
            ips: action.payload?.office.ips
              ? action.payload?.office.ips.map((ip) => ip.value)
              : [],
            taxation: action.payload?.office?.taxation?.id,
            country: action.payload?.office?.country,
            currency_id: officeCurrency?.id || "",
            month_start_day:
              action.payload?.office?.month_start_day != 0
                ? action.payload?.office?.month_start_day
                : "",
            reset_password: +action.payload?.office?.user_id ? 0 : 1,
            week_start_day: action.payload?.office?.week_start_day?.toString(),
            payment_intervals:
              JSON.parse(action.payload?.office?.payment_intervals ?? "[]") ??
              [],
            // allow_monthly_interval: !!JSON.parse(
            //   action.payload?.office?.payment_intervals
            // )?.includes("Month"),
            // allow_weekly_interval: !!JSON.parse(
            //   action.payload?.office?.payment_intervals
            // )?.includes("Week"),
          },
          officeHolidaysOptions:
            action.payload?.office?.holiday_profile?.holidays ?? [],
          officeIpsOptions: action.payload?.office?.ips ?? [],
        };
      } else {
        officeData = {
          officeForm: {
            ...state.officeForm,
            holidays:
              action.payload?.profile.company
                ?.holidays_applied_on_all_offices ?? [],
          },
        };
      }

      return {
        ...state,
        ...officeData,
        fetchingOfficeForm: false,
        attProfileHolidaysOptions: action.payload?.holidays?.data,
        officeCurrenciesOptions: action.payload?.currencies,
        officeLocationsOptions: action.payload?.office?.locations ?? [],
        officeCountriesOptions: action.payload?.countries,
        taxationsOptions: action.payload?.taxation,
      };

    case Types.INIT_OFFICE_SUCCESS:
      return {
        ...state,
        officeForm: {
          ...state.officeForm,
          id: action.payload?.id,
          canEditStartOfMonthOrWeek: action.payload?.canEditMonthStartDay,
        },
      };

    case Types.RESET_OFFICE_PASSWORD:
      return {
        ...state,
        officeForm: {
          ...state.officeForm,
          password: "",
        },
      };

    case Types.SET_SELECTED_CONTURY_CURRENIESES:
      return {
        ...state,
        officeForm: {
          ...state.officeForm,
          currencies: [...state.officeForm.currencies, action.payload],
        },
      };

    case Types.SHOW_EDIT_HISTORY_MODAL:
      return {
        ...state,
        editAttRecord: action.payload,
        editAttHistoryModalActions: {
          isVissible: true,
          isLoading: false,
          modalMessage: "",
        },
      };

    case Types.HIDE_EDIT_HISTORY_MODAL:
    case Types.EDIT_ATT_LOG_SUCCESS:
      return {
        ...state,
        editAttHistoryModalActions: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        editAttHistoryModal: {
          id: "",
          emp_id: "",
          name: "",
          status: "",
          from_home: 0,
          sign_in_time: "",
          sign_out_time: "",
          sign_in_date: "",
          sign_out_date: "",
          day_type: "",
          day_sub_type: "",
          sign_in_lat: "",
          sign_in_lng: "",
          date: "",
          signInSpace: null,
          signOutSpace: null,
        },
        editAttHistoryModalValidation: [],
        editAttHistoryServerValidation: {},
        editAttRecord: initState?.editAttRecord,
      };

    case Types.SET_BALANCE_WEEK_RANG:
      return {
        ...state,
        balanceModal: {
          ...state.balanceModal,
          week: action.payload,
        },
      };

    case Types.UPSERT_ATTENDANCE_PROFILE_ATTEMPT:
      return {
        ...state,
        upsertAttProfileLoading: true,
        attProfileNormalServerValidation: {},
      };
    case Types.UPSERT_ATTENDANCE_PROFILE_SUCCESS:
      return {
        ...state,
        upsertAttProfileLoading: false,
        attProfileNormalServerValidation: {},
        attendaceProfile: {
          ...initState.attendaceProfile,
          attendance_profile_work_timing_input: [
            {
              id: null,
              sign_in_req: 0,
              work_timing_id: null,
              first_half_work_timing_id: null,
              first_half_work_timing_name: null,
              second_half_work_timing_id: null,
              second_half_work_timing_name: null,
              start: null,
              end: null,
              allow_half_day: null,
              can_edit: null,
            },
          ],
        },
        attendaceProfileValidation: [],
        attProfileWorkdaysValidation: [],
        attProfileSignInValidation: [],
      };
    case Types.UPSERT_ATTENDANCE_PROFILE_FAILED:
      return {
        ...state,
        upsertAttProfileLoading: false,
        attProfileNormalServerValidation: action.payload,
      };

    case Types.FETCH_ALL_OFFICE_HOLIDAYS_ATTEMPT:
      return {
        ...state,
        loadingOfficeHolidays: true,
      };
    case Types.FETCH_ALL_OFFICE_HOLIDAYS_SUCCESS:
      return {
        ...state,
        attProfileHolidaysOptions: action.payload,
        loadingOfficeHolidays: false,
      };
    case Types.FETCH_ALL_OFFICE_HOLIDAYS_FAILED:
      return {
        ...state,
        attProfileHolidaysOptions: [],
        loadingOfficeHolidays: false,
      };

    case Types.EDIT_EMPLOYEE_ATTEMPT:
      return {
        ...state,
        employeeServerValidation: {},
        editEmployeeIsLoading: true,
      };

    case Types.FETCH_EMPLOYEE_NAME_ON_CLICK_ATTEMPT:
      return {
        ...state,
        attendanceChartEmployeeNamesLoading: true,
      };
    case Types.FETCH_EMPLOYEE_NAME_ON_CLICK_SUCCESS:
      return {
        ...state,
        attendanceChartEmployeeNames: action.payload,
        attendanceChartEmployeeNamesLoading: false,
      };
    case Types.FETCH_EMPLOYEE_NAME_ON_CLICK_FAILED:
      return {
        ...state,
        attendanceChartEmployeeNames: [],
        attendanceChartEmployeeNamesLoading: false,
      };
    case Types.SHOW_ATT_CHART_EMPLOYEES_NAME_MODAL:
      return {
        ...state,
        [action.payload.ref]: {
          isVissible: true,
        },
        [action.payload.formName]: {
          ...state[action.payload.formName],
          ...action.payload.data,
        },
      };
    case Types.HIDE_ATT_CHART_EMPLOYEES_NAME_MODAL:
      return {
        ...state,
        [action.payload.ref]: {
          isVissible: false,
        },
        [action.payload.formName]: initState[action.payload.formName],
      };

    case Types.FETCH_DASHBOARD_DEPARTMNETS:
      return {
        ...state,
        dashboardDepartments: action?.payload,
      };

    case Types.UPDATE_OVERTIME_INPUT:
      return {
        ...state,
        inboundRequestsList: {
          ...state.inboundRequestsList,
          data: action?.payload,
        },
      };

    case Types.SHOW_BONUS_MODAL:
    case Types.SHOW_DEDUCTIONS_MODAL:
    case Types.SHOW_CLAIM_REQUEST_MODAL:
    case Types.SHOW_MANAGE_PRIVILAGES_MODAL:
      return {
        ...state,
        [action.payload.ref]: {
          isVissible: true,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload.formName]: {
          ...state[action.payload.formName],
          ...action.payload.data,
        },
        managePrivilagesModal: {
          ...state.managePrivilagesModal,
          ...action.payload?.data,
        },
        editPrivileges: false,
      };

    case Types.SHOW_ACCEPT_CLAIM_REQUEST_MODAL:
      return {
        ...state,
        acceptClaimRequestModalActions: {
          ...state.acceptClaimRequestModalActions,
          isVissible: true,
        },
        acceptClaimRequestModal: action.payload.acceptClaimData,
      };

    case Types.SHOW_OVERTIME_MODAL:
      return {
        ...state,
        overtimeModalActions: {
          ...state.overtimeModalActions,
          isVissible: true,
        },
        overtimeModal: {
          ...action.payload.acceptOvertimeData,
          overtimeHours: action.payload.acceptOvertimeData?.payable_interval_in_time?.split(":")?.[0] ?? "",
          overtimeMinutes: action.payload.acceptOvertimeData?.payable_interval_in_time?.split(":")?.[1] ?? "",
        },
      };

    case Types.FETCH_EDIT_ACCEPTED_OVERTIME_REQUEST_DATA_ATTEMPT:
      return {
        ...state,
        editAcceptedOvertimeRequestLoading: true,
      };

    case Types.FETCH_EDIT_ACCEPTED_OVERTIME_REQUEST_DATA_SUCCESS:
      console.log('====================================');
      console.log({ sss: action?.payload });
      console.log('====================================');
      return {
        ...state,
        editAcceptedOvertimeRequestLoading: false,
        overtimeModal: {
          ...state.overtimeModal,
          // interval_in_time: action?.payload?.interval_in_time,
          // payable_interval_in_time: action?.payload?.payable_interval_in_time,
          created_at: action?.payload?.payable_at,
        },
      };

    case Types.FETCH_EDIT_ACCEPTED_OVERTIME_REQUEST_DATA_FAILED:
      return {
        ...state,
        editAcceptedOvertimeRequestLoading: false,
      };

    case Types.EDIT_BONUS_ATTEMPT:
      return {
        ...state,
        AddNewBonusModalActions: {
          ...state.AddNewBonusModalActions,
          isVissible: true,
        },
        AddNewBonusModal: action.payload.bonusRecord,
      };

    case Types.EDIT_DEDUCTIONS_ATTEMPT:
      return {
        ...state,
        AddNewDeductionsModalActions: {
          ...state.AddNewDeductionsModalActions,
          isVissible: true,
        },
        AddNewDeductionsModal: action.payload.deductionRecord,
      };

    case Types.EDIT_EMPLOYEE_PRIVILEGES_ATTEMPT:
    case Types.EDIT_ADMIN_USER_PRIVILEGES_ATTEMPT:
      return {
        ...state,
        editPrivileges: true,
      };

    case Types.EDIT_EMPLOYEE_PRIVILEGES_FAILED:
    case Types.EDIT_ADMIN_USER_PRIVILEGES_FAILED:
      return {
        ...state,
        managePrivilagesModal: initState.managePrivilagesModal,
        editPrivileges: false,
      };

    case Types.UPSERT_EMPLOYEE_PRIVILEGES_ATTEMPT:
    case Types.UPSERT_ADMIN_USER_PRIVILEGES_ATTEMPT:
      return {
        ...state,
        managePrivilagesModalActions: {
          ...state.managePrivilagesModalActions,
          isLoading: true,
        },
        privilegeTemplateModalValidatoin: {},
      };

    case Types.UPSERT_EMPLOYEE_PRIVILEGES_FAILED:
    case Types.UPSERT_ADMIN_USER_PRIVILEGES_FAILED:
      return {
        ...state,
        managePrivilagesModalActions: {
          ...state.managePrivilagesModalActions,
          isLoading: false,
        },
        privilegeTemplateModalValidatoin: action.payload?.validation,
      };
    case Types.HIDE_CLAIM_REQUEST_MODAL:
    case Types.HIDE_ACCEPT_CLAIM_REQUEST_MODAL:
    case Types.UPSERT_EMPLOYEE_CLAIM_REQUEST_SUCCESS:
      return {
        ...state,
        employeeClaimRequestFormServerValidation: {},
        acceptClaimRequestModalFormServerValidation: {},
        [action.payload?.ref]: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload?.formName]: initState[action.payload?.formName],
      };

    case Types.HIDE_BONUS_MODAL:
    case Types.HIDE_DEDUCTIONS_MODAL:
    case Types.UPSERT_BONUS_SUCCESS:
    case Types.UPSERT_DEDUCTION_SUCCESS:
      return {
        ...state,
        BonusFormServerValidation: {},
        DeductionFormServerValidation: {},
        [action.payload?.ref]: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload?.formName]: initState[action.payload?.formName],
      };

    case Types.CREATE_HOLIDAYS_SUCCESS:
      return {
        ...state,
        holidaysModalServerValidation: {},
        [action.payload?.ref]: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload?.formName]: initState[action.payload?.formName],
      };

    case Types.HIDE_OVERTIME_MODAL:
      return {
        ...state,
        [action.payload?.ref]: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload?.formName]: initState[action.payload?.formName],
      };

    case Types.HIDE_MANAGE_PRIVILAGES_MODAL:
      return {
        ...state,
        [action.payload.ref]: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload.formName]: initState[action.payload.formName],
        managePrivilagesModal: initState.managePrivilagesModal,
        privilegeTemplateModalValidatoin: {},
      };

    case Types.UPDATE_PRIVILEGES_RADIO:
      if (state[action.payload.formName]) {
        switch (action.payload?.name) {
          case "all":
            return {
              ...state,
              [action.payload.formName]: {
                ...state[action.payload.formName],
                managed: state[action.payload.formName]?.managed?.filter(
                  (val) => val != action.payload?.value
                ),
                all: [
                  ...state[action.payload.formName]?.all,
                  action.payload?.value,
                ],
              },
            };
          case "managed":
            return {
              ...state,
              [action.payload.formName]: {
                ...state[action.payload.formName],
                all: state[action.payload.formName]?.all?.filter(
                  (val) => val != action.payload?.value
                ),
                managed: [
                  ...state[action.payload.formName]?.managed,
                  action.payload?.value,
                ],
              },
            };
          default:
            return {
              ...state,
              [action.payload.formName]: {
                ...state[action.payload.formName],
                all: state[action.payload.formName]?.all?.filter(
                  (item) => !action.payload?.value.includes(item)
                ),
                managed: state[action.payload.formName]?.managed?.filter(
                  (item) => !action.payload?.value.includes(item)
                ),
              },
            };
        }
      }
      return state;
    case Types.UPDTAE_REQUESTS_EMPLOYEES_FILTER:
      return {
        ...state,
        requestsEmployeesFilter: action.payload.list.data,
      };

    case Types.FETCH_EXTRA_USERS_FILTER:
      return {
        ...state,
        extraEmployeesFilter: action?.payload?.data,
      };

    case Types.FETCH_PAYROLL_REPORT_EMPLOYEES_FILTER:
      return {
        ...state,
        payrollEmployeesFilter: action?.payload?.data,
      };

    case Types.FETCH_PAYROLL_REPORT_CURRENCIES_FILTER:
      return {
        ...state,
        payrollCurrenciesFilter: action?.payload,
      };

    case Types.FETCH_PAYROLL_REGULATION_OPTIONS_FILTER:
      return {
        ...state,
        regualtionTypesOptions: action?.payload,
      };

    case Types.UPSERT_BONUS_ATTEMPT:
      return {
        ...state,
        BonusFormServerValidation: {},
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: true,
          modalMessage: "",
        },
      };

    case Types.UPSERT_DEDUCTION_ATTEMPT:
      return {
        ...state,
        DeductionFormServerValidation: {},
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: true,
          modalMessage: "",
        },
      };

    case Types.CREATE_HOLIDAYS_ATTEMPT:
      return {
        ...state,
        holidaysModalServerValidation: {},
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: true,
          modalMessage: "",
        },
      };

    case Types.UPSERT_EMPLOYEE_CLAIM_REQUEST_ATTEMPT:
      return {
        ...state,
        employeeClaimRequestFormServerValidation: {},
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: true,
          modalMessage: "",
        },
      };

    case Types.EDIT_EMPLOYEE_CLAIM_REQUEST_ATTEMPT:
      return {
        ...state,
        employeeClaimRequestModalActions: {
          ...state.employeeClaimRequestModalActions,
          isVissible: true,
        },
        employeeClaimRequestModal: action.payload.claimRequestRecord,
      };

    case Types.UPSERT_BONUS_FAILED:
    case Types.CREATE_ADMIN_USER_FAILED:
    case Types.UPSERT_EMPLOYEE_CLAIM_REQUEST_FAILED:
    case Types.CLAIM_ACTION_REQUEST_FAILED:
    case Types.UPSERT_COMPANY_FAILED:
    case Types.UPDATE_COMPANY_FAILED:
    case Types.UPDATE_COMPANY_NAME_MODAL_FAILED:
    case Types.UPDATE_ACCOUNT_MANAGERS_MODAL_FAILED:
    case Types.UPDATE_BUSSINESS_PARTNERS_MODAL_FAILED:
    case Types.UPSERT_COMPANY_SUBSCRIPTION_FAILED:
    case Types.UPSERT_DEDUCTION_FAILED:
    case Types.CREATE_HOLIDAYS_FAILED:
    case Types.BALANCE_MODAL_FAILED:
    case Types.EDIT_BUSSINESS_PARTNER_MODAL_FAILED:
      return {
        ...state,
        [action.payload?.ref]: {
          ...state[action.payload.ref],
          isLoading: false,
          modalMessage: action.payload?.modalMessage ?? "",
        },
        [action.payload?.serverRef]: action.payload?.errors ?? {},
      };

    case Types.UPDATE_ADMIN_USER_PROFILE_FAILED:
      return {
        ...state,
        updateAdminUserProfileLoading: false,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: false,
          modalMessage: action.payload?.modalMessage ?? "",
        },
        [action.payload.serverRef]: action.payload?.errors ?? {},
      };

    case Types.FETCH_PAYROLL_EXTRA_FIELDS_SUCCESS:
      return {
        ...state,
        payrollWidgets: {
          total_net_paid:
            action?.payload?.total_net_paid ??
            action?.payload?.monthly_total_net_paid,
          total_paid:
            action?.payload?.total_paid ?? action?.payload?.monthly_total_paid,
          pending_payment:
            action?.payload?.pending_payment ??
            action?.payload?.monthly_pending_payment,
          currency_name: action?.payload?.currency?.name,
        },
        extraPayrollRow: {
          user: {
            name: action?.payload?.users_count
              ? `${action?.payload?.users_count}`
              : null,
          },
          ...action?.payload,
          isExtra: true,
        },
      };

    case Types.FETCH_PAYROLL_CLOSED_MONTHS_SUCCESS:
      return {
        ...state,
        [action?.payload?.ref]: action?.payload?.data,
      };

    case Types.FETCH_PAYROLL_EXTRA_FIELDS_FAILED:
      return {
        ...state,
        payrollWidgets: initState.payrollWidgets,
        extraPayrollRow: initState.extraPayrollRow,
      };

    case Types.REVERSE_PAYROLL_PAYMENT_ATTEMPT:
      return {
        ...state,
        [action.payload.ref]: [
          ...state[action.payload.ref],
          action.payload.ids[0],
        ],
      };

    case Types.REVERSE_PAYROLL_PAYMENT_SUCCESS:
    case Types.REVERSE_PAYROLL_PAYMENT_FAILED:
      return {
        ...state,
        [action.payload.ref]: state[action.payload.ref].filter(
          (monthlyId) => monthlyId != action?.payload?.id
        ),
      };

    // salary config form
    case Types.CREATE_ALLOWANCE_ATTEMPT:
    case Types.CREATE_DEDUCTIBLE_ATTEMPT:
      return {
        ...state,
        [action.payload.loadingKey]: [
          ...state[action.payload.loadingKey],
          action.payload.index,
        ],
      };

    case Types.CREATE_ALLOWANCE_FAILED:
    case Types.CREATE_DEDUCTIBLE_FAILED:
      return {
        ...state,
        [action.payload.loadingKey]: state[action.payload.loadingKey].filter(
          (i) => i !== action.payload.index
        ),
      };

    case Types.CREATE_ALLOWANCE_SUCCESS:
      let salary_allowancesValues = [
        ..._.cloneDeep(state.salaryConfigForm.salary_allowances),
      ];
      salary_allowancesValues[action.payload.index].allowance_id =
        action.payload.option.value;
      return {
        ...state,
        allowanceLoadingOpts: state.allowanceLoadingOpts.filter(
          (i) => i !== action.payload.index
        ),
        allowancesOptions: [...state.allowancesOptions, action.payload.option],
        salaryConfigForm: {
          ..._.cloneDeep(state.salaryConfigForm),
          salary_allowances: salary_allowancesValues,
        },
      };

    case Types.CREATE_DEDUCTIBLE_SUCCESS:
      let salary_DeductiblesValues = [
        ..._.cloneDeep(state.salaryConfigForm.salary_deductibles),
      ];
      salary_DeductiblesValues[action.payload.index].deductible_id =
        action.payload.option.value;
      return {
        ...state,
        deductibleLoadingOpts: state.deductibleLoadingOpts.filter(
          (i) => i !== action.payload.index
        ),
        deductiblesOptions: [
          ...state.deductiblesOptions,
          action.payload.option,
        ],
        salaryConfigForm: {
          ..._.cloneDeep(state.salaryConfigForm),
          salary_deductibles: salary_DeductiblesValues,
        },
      };

    case Types.ADD_ALLOWANCE_INPUT:
      return {
        ...state,
        salaryConfigForm: {
          ..._.cloneDeep(state.salaryConfigForm),
          salary_allowances: [
            ..._.cloneDeep(state?.salaryConfigForm?.salary_allowances),
            {
              allowance_id: "",
              payment_factor_id: PaymentFactors.FIXED_AMOUNT,
              value: "",
              not_payable_on_paid_days: false,
              not_payable_on_un_paid_days: false,
              not_payable_on_absent_days: false,
            },
          ],
        },
      };

    case Types.REMOVE_ALLOWANCE_INPUT:
      return {
        ...state,
        salaryConfigForm: {
          ..._.cloneDeep(state?.salaryConfigForm),
          salary_allowances: state?.salaryConfigForm?.salary_allowances?.filter(
            (_, i) => i != action.payload?.index
          ),
        },
      };

    case Types.ADD_OVERTIME_SETTING:
      return {
        ...state,
        salaryConfigForm: {
          ..._.cloneDeep(state.salaryConfigForm),
          overtimeSettings: [
            ..._.cloneDeep(state?.salaryConfigForm?.overtimeSettings),
            {
              from: "00:00",
              to: "00:00",
              overtime_payment_factor: "",
            },
          ],
        },
      };
    case "ADD_HOURLY_INTERVAL":
      return {
        ...state,
        salaryConfigForm: {
          ..._?.cloneDeep(state.salaryConfigForm),
          [action?.payload?.day]: [
            ..._?.cloneDeep(
              state?.salaryConfigForm?.[action?.payload?.day] ?? []
            ),
            {
              from: "00:00",
              to: "00:00",
              rate: "0",
              day: action?.payload?.day,
            },
          ],
        },
      };
    case "APPLY_EVERY_DAY":
      return {
        ...state,
        salaryConfigForm: {
          ..._.cloneDeep(state.salaryConfigForm),
          Everyday: [
            {
              from: "00:00",
              to: "00:00",
              rate: "0",
              day: "Everyday",
            },
          ],
        },
      };
    case "APPLY_SEPERATE_DAYS":
      return {
        ...state,
        salaryConfigForm: {
          ..._.cloneDeep(state.salaryConfigForm),
          ...action.payload?.seperateWorkDays,
        },
      };

    case "REMOVE_HOURLY_INTERVAL":
      return {
        ...state,
        salaryConfigForm: {
          ..._.cloneDeep(state.salaryConfigForm),
          [action?.payload?.day]: state?.salaryConfigForm?.[
            action?.payload?.day
          ]?.filter((_, i) => i != action.payload?.index),
        },
      };

    case Types.REMOVE_OVERTIME_SETTING:
      return {
        ...state,
        salaryConfigForm: {
          ..._.cloneDeep(state?.salaryConfigForm),
          overtimeSettings: state?.salaryConfigForm?.overtimeSettings?.filter(
            (_, i) => i != action.payload?.index
          ),
        },
      };

    case Types.RESET_OVERTIME_SETTINGS:
      return {
        ...state,
        salaryConfigForm: {
          ..._.cloneDeep(state?.salaryConfigForm),
          overtimeSettings: [
            {
              from: "00:00",
              to: "00:00",
              overtime_payment_factor:
                state?.salaryConfigForm?.overtimeSettings?.[0]
                  ?.overtime_payment_factor,
              // amount:""
            },
          ],
        },
      };

    case Types.ADD_DEDUCTIBLE_INPUT:
      return {
        ...state,
        salaryConfigForm: {
          ..._.cloneDeep(state?.salaryConfigForm),
          salary_deductibles: [
            ..._.cloneDeep(state?.salaryConfigForm?.salary_deductibles),
            {
              deductible_id: "",
              payment_factor_id: PaymentFactors.FIXED_AMOUNT,
              value: "",
            },
          ],
        },
      };

    case Types.REMOVE_DEDUCTIBLE_INPUT:
      return {
        ...state,
        salaryConfigForm: {
          ..._.cloneDeep(state.salaryConfigForm),
          salary_deductibles:
            state.salaryConfigForm?.salary_deductibles?.filter(
              (_, i) => i != action.payload?.index
            ),
        },
      };

    // handle children input
    case Types.ON_CHILD_INPUT_CHANGE:
      let newValues = [
        ...state[action.payload.formName]?.[action.payload.childName],
      ];
      newValues[action.payload.index][action.payload.name] =
        action.payload.value;
      return {
        ...state,
        [action.payload.formName]: {
          ...state[action.payload.formName],
          [action.payload.childName]: newValues,
        },
      };

    // handle children checkbox
    case Types.ON_CHILD_CHECKBOX_CHANGE:
      let newCheckBoxValues = [
        ...state[action.payload.formName][action.payload.childName],
      ];
      newCheckBoxValues[action.payload.index][
        action.payload.event.target.name
      ] = action.payload.event.target.checked;
      return {
        ...state,
        [action.payload.formName]: {
          ...state[action.payload.formName],
          [action.payload.childName]: newCheckBoxValues,
        },
      };

    // handle Multiple Inputs By Index
    case Types.ON_MULTI_INPUT_INDEX_CHANGE:
      let valuesArr = state[action.payload.formName][action.payload.childName];
      valuesArr[action.payload.index] = action.payload.value?.map(
        (val) => val.id
      );

      return {
        ...state,
        [action.payload.formName]: {
          ...state[action.payload.formName],
          [action.payload.childName]: valuesArr,
        },
      };

    case Types.INIT_UPSERT_SALARY_CONFIG_FORM:
      return {
        ...state,
        upsertSalaryConfigModalActions: {
          ...state.upsertSalaryConfigModalActions,
          loadingStatus: true,
        },
      };

    case Types.SHOW_NEW_UPSERT_SALARY_CONFIG_FORM_SUCCESS:
      return {
        ...state,
        salaryConfigForm: {
          ..._.cloneDeep(state.salaryConfigForm),
        },
        allowancesOptions: action.payload?.user?.company?.allowancesOptions,
        deductiblesOptions: action.payload?.user?.company?.deductiblesOptions,
        companySalaryConfigPresets: action.payload?.presets ?? [],
        monthOptions: action.payload?.monthOptions,
        paymentFactors: action.payload?.paymentFactors,
        userCurrenciesOptions: action.payload?.currencies,
        userRegulationTypesOptions:
          action.payload?.relevantRegulationTypes?.map((regulation) => ({
            ...regulation,
            isDefault:
              regulation?.id === action.payload?.office?.defaultRegulation?.id,
          })),
        relevantStates: action?.payload?.relevantStates,
        upsertSalaryConfigModalActions: {
          ...state.upsertSalaryConfigModalActions,
          loadingStatus: false,
          isVissible: true,
          modalMessage: "new salary configuration",
        },
      };
    case Types.SHOW_EDIT_UPSERT_SALARY_CONFIG_FORM_SUCCESS:    
      return {
        ...state,
        salaryConfigForm: {
          ...normalizeFetchedSalaryConfigInput(action?.payload?.salaryConfig),
        },
        timeRangeProfileSetting:{
          ...normalizeFetchedTimeRangeProfileInput(action?.payload?.timeRangeProfileSetting)
        },
        allowancesOptions: action.payload?.user?.company?.allowancesOptions,
        deductiblesOptions: action.payload?.user?.company?.deductiblesOptions,
        companySalaryConfigPresets: action?.payload?.presets ?? [],
        monthOptions: action.payload?.monthOptions,
        paymentFactors: action.payload?.paymentFactors,
        userCurrenciesOptions: action.payload?.currencies,
        userRegulationTypesOptions:
          action.payload?.relevantRegulationTypes?.map((regulation) => ({
            ...regulation,
            isDefault:
              regulation?.id === action.payload?.office?.defaultRegulation?.id,
          })),
        relevantStates: action?.payload?.relevantStates,
        upsertSalaryConfigModalActions: {
          ...state.upsertSalaryConfigModalActions,
          loadingStatus: false,
          isVissible: true,
          modalMessage: "Edit salary configuration",
          prevSalaryType: +(
            action?.payload?.salaryConfig?.payment?.toLowerCase() == "external"
          ),
        },
      };

    case Types.INIT_UPSERT_SALARY_CONFIG_FORM_FAILED:
      return {
        ...state,
        upsertSalaryConfigModalActions:
          initState.upsertSalaryConfigModalActions,
      };

    case Types.DISMISS_UPSERT_SALARY_CONFIG_FORM:
      return {
        ...state,
        upsertSalaryConfigModalActions:
          initState.upsertSalaryConfigModalActions,
        upsertSalaryConfigServerValidation: {},
        salaryConfigForm: _.cloneDeep(initState.salaryConfigForm),
        timeRangeProfileSetting: initState.timeRangeProfileSetting,
      };

    case Types.RESET_SALARY_CONFIG_FORM:
      return {
        ...state,
        salaryConfigForm: {
          ..._.cloneDeep(initState.salaryConfigForm),
          id: state.salaryConfigForm?.id,
          start_at: state.salaryConfigForm?.start_at,
          monthYear: state.salaryConfigForm?.monthYear,
          payment_interval: state.salaryConfigForm?.payment_interval,
          salary_management_type: action.payload?.salary_management_type,
          canEditStartAt: state.salaryConfigForm?.canEditStartAt,
          preserved_start_at: state.salaryConfigForm.preserved_start_at,
          week_start: state.salaryConfigForm.week_start,
          canDelete: state.salaryConfigForm.canDelete,
          originalFrom: state.salaryConfigForm?.originalFrom,
        },
      };

    // handle fetch Payslip data in payslip modal
    case Types.FETCH_USER_PAYSLIP_ATTEMPT:
      return {
        ...state,
        userPayslipLoading: true,
      };
    case Types.FETCH_USER_PAYSLIP_SUCCESS:
      return {
        ...state,
        userPayslipModalData: action?.payload,
        userPayslipLoading: false,
      };
    case Types.FETCH_USER_PAYSLIP_FAILED:
      return {
        ...state,
        userPayslipModalData: {},
        userPayslipLoading: false,
      };

    case Types.EXPORT_PAYSLIP_ACTION:
      return {
        ...state,
        exportPaySlipLoading: true,
      };

    case Types.EXPORT_PAYSLIP_SUCCESS:
      return {
        ...state,
        exportPaySlipLoading: false,
      };

    case Types.EXPORT_PAYSLIP_FAILED:
      return {
        ...state,
        exportPaySlipLoading: false,
      };

    case Types.SHOW_DOCUMENT_MODAL:
      return {
        ...state,
        [action.payload.ref]: {
          isVissible: true,
          isLoading: false,
          modalMessage: "",
          document_id: action.payload?.document_id,
          document_name: action.payload?.document_name,
          country: action.payload?.country,
          userId: action.payload?.userId,
        },
        [action.payload.formName]: {
          ...state[action.payload.formName],
          ...action.payload.inputs,
        },
        docFields: action.payload.fields,
      };
    case Types.HIDE_DOCUMENT_MODAL:
      return {
        ...state,
        [action.payload.ref]: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
          document_id: null,
        },
        [action.payload.formName]: {},
        docFields: [],
      };

    case Types.SHOW_EDIT_DOCUMENT_MODAL:
      // const { id_num, expiration_date, issuing_date, attachment_file } =
      //   action.payload;
      return {
        ...state,
        upsertDocumentsModal: {
          ...action.payload?.inputs,
          files: action.payload?.files,
        },
        docFields: action.payload.fields,
        upsertDocumentsModalActions: {
          isVissible: true,
          isLoading: false,
          modalMessage: "",
          document_id: action.payload.document_id,
          user_document_id: action.payload.user_document_id,
          document_name: action.payload.document_name,
          country: action.payload.country,
          userId: action.payload.userId,
        },
      };

    case Types.HIDE_EDIT_DOCUMENT_MODAL:
      return {
        ...state,
        upsertDocumentsModalActions: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
          document_id: null,
        },
        upsertDocumentsModal: {},
        upsertDocumentsModalValidation: [],
      };
    case Types.FETCH_HOLIDAY_OFFICES:
      return {
        ...state,
        holidayOffices: action?.payload?.data,
      };

    case Types.FETCH_HOLIDAY_ATT_PROFILE:
      return {
        ...state,
        holidayAttProfile: action?.payload?.data,
      };

    case Types.FETCH_EXPORT_PAYROLL_OPTIONS_ATTEMPT:
      return {
        ...state,
        loadingPayrollOptions: true,
      };

    case Types.FETCH_EXPORT_PAYROLL_OPTIONS_FAILED:
      return {
        ...state,
        loadingPayrollOptions: false,
      };

    case Types.FETCH_EXPORT_PAYROLL_OPTIONS_SUCCESS:
      return {
        ...state,
        loadingPayrollOptions: false,
        exportPayrollModalActions: {
          ...state.exportPayrollModalActions,
          isVissible: true,
        },
        allowancesOptions: action.payload?.allowancesOptions,
        deductiblesOptions: action.payload?.deductiblesOptions,
      };

    case Types.SUBMIT_EXPORT_PAYROLL_ATTEMPT:
      return {
        ...state,
        exportPayrollModalActions: {
          ...state.exportPayrollModalActions,
          isLoading: true,
        },
      };

    case Types.SUBMIT_EXPORT_PAYROLL_FAILED:
      return {
        ...state,
        exportPayrollModalActions: {
          ...state.exportPayrollModalActions,
          isLoading: false,
        },
      };

    case Types.DISMISS_EXPORT_PAYROLL_MODAL_ACTION:
    case Types.SUBMIT_EXPORT_PAYROLL_SUCCESS:
      return {
        ...state,
        exportPayrollModalActions: initState.exportPayrollModalActions,
        exportPayrollForm: initState.exportPayrollForm,
        allowancesOptions: [],
        deductiblesOptions: [],
      };

    case Types.ADD_VIOLATION_INPUT:
      return {
        ...state,
        [action.payload.formName]: {
          ...state[action.payload.formName],
          [action.payload.childName]: [
            ...state[action.payload.formName][action.payload.childName],
            {
              id: null,
              value: "",
              penalty_type: action.payload?.penalty_type,
            },
          ],
        },
      };

    case Types.REMOVE_VIOLATION_INPUT:
      return {
        ...state,
        [action.payload.formName]: {
          ...state[action.payload?.formName],
          [action.payload.childName]: state[action.payload?.formName][
            action.payload?.childName
          ]?.filter((_, i) => i != action.payload?.index),
        },
      };
    case Types.FETCH_ACTIVATION_ATTENDANCE_ATTEMPT:
      return {
        ...state,
        loadingActivationAttendance: true,
        fetchedEmployee_attendance: initState.fetchedEmployee_attendance,
        activationEmployee_attendance: {
          employee_attendance_option: 1,
          data: [
            {
              date: null,
              sign_in_status: null,
              sign_in_time: null,
              sign_out_time: null,
            },
          ],
          annual_leaves: "0",
          emergency_leaves: "0",
          sick_leaves: null,
        },
        employeeAttendanceFormValidation: [],
      };

    case Types.FETCH_ACTIVATION_ATTENDANCE_FAILED:
      return {
        ...state,
        loadingActivationAttendance: false,
      };

    case Types.FETCH_ACTIVATION_ATTENDANCE_SUCCESS:
      return {
        ...state,
        loadingActivationAttendance: false,
        employeeAttendanceFormValidation: [],
        fetchedEmployee_attendance: action.payload.employee_attendance,
        activationEmployee_attendance: {
          employee_attendance_option: 1,
          data: [
            {
              date: null,
              sign_in_status: null,
              sign_in_time: null,
              sign_out_time: null,
            },
          ],
          annual_leaves: "0",
          emergency_leaves: "0",
          sick_leaves: null,
        },
        activationModalActions: {
          ...state.activationModalActions,
          require_create_new_salary_configuration:
            action.payload.require_create_new_salary_configuration,
        },
      };

    case Types.RESET_ACTICATION_ATTENDANCE_FORM:
      return {
        ...state,
        employeeAttendanceFormValidation: [],
        activationEmployee_attendance: {
          ...state.activationEmployee_attendance,
          data: action.payload.auto
            ? [
              {
                date: null,
                sign_in_status: null,
                sign_in_time: null,
                sign_out_time: null,
              },
            ]
            : state.fetchedEmployee_attendance?.manually_adjust_attendance?.map(
              ({ date }) => ({
                date,
                sign_in_status: null,
                sign_in_time: null,
                sign_out_time: null,
              })
            ),
        },
      };

    case Types.FETCH_COMPANY_ACCOUNT_MANAGERS:
      return {
        ...state,
        companyAccountManagers: action?.payload?.data,
      };

    case Types.FETCH_SUBSCRIPTION_PLANS:
      return {
        ...state,
        companySubscriptionPlans: action?.payload,
      };

    case Types.FETCH_COMPANY_CURRENCY:
      return {
        ...state,
        companyCurrency: action?.payload,
      };

    case Types.UPSERT_COMPANY_ATTEMPT:
    case Types.UPDATE_COMPANY_ATTEMPT:
      return {
        ...state,
        companiesModalServerValidation: {},
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: true,
          modalMessage: "",
        },
      };

    case Types.UPSERT_COMPANY_SUCCESS:
    case Types.UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        companiesModalServerValidation: {},
        [action.payload?.ref]: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload?.formName]: initState[action.payload?.formName],
      };

    case Types.ADD_BUSSINESS_PARTNER_INPUT:
      return {
        ...state,
        companiesModal: {
          ...state.companiesModal,
          partners_input: [
            ...state?.companiesModal?.partners_input,
            {
              id: null,
              company_id: action?.payload?.company_id ?? null,
              name: "",
              phone: "",
              phone_country_code: "",
              email: "",
              force_save: false,
            },
          ],
        },
      };

    case Types.REMOVE_BUSSINESS_PARTNER_INPUT:
      return {
        ...state,
        companiesModal: {
          ...state?.companiesModal,
          partners_input: state?.companiesModal?.partners_input?.filter(
            (_, i) => i != action.payload?.index
          ),
        },
      };

    case Types.RESET_COMPANY_PRFOILE_VIEW:
      return {
        ...state,
        companyProfileSubscriptionList:
          initState.companyProfileSubscriptionList,
      };
    case Types.FETCH_COMPANY_PROFILE_ATTEMPT:
      return {
        ...state,
        companyProfileLoading: true,
      };
    case Types.FETCH_COMPANY_PROFILE_SUCCESS:
      return {
        ...state,
        selectedCompanyProfile: action.payload.company,
        companyProfileLoading: false,
      };
    case Types.FETCH_COMPANY_PROFILE_FAILED:
      return {
        ...state,
        selectedCompanyProfile: {},
        companyProfileLoading: false,
      };

    case Types.UPDATE_COMPANY_NAME_MODAL_ATTEMPT:
      return {
        ...state,
        editCompanyNameModalServerValidation: {},
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: true,
          modalMessage: "",
        },
      };

    case Types.UPDATE_COMPANY_NAME_MODAL_SUCCESS:
      return {
        ...state,
        editCompanyNameModalServerValidation: {},
        [action.payload?.ref]: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload?.formName]: initState[action.payload?.formName],
      };

    case Types.UPDATE_ACCOUNT_MANAGERS_MODAL_ATTEMPT:
      return {
        ...state,
        editAccountManagersModalServerValidation: {},
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: true,
          modalMessage: "",
        },
      };

    case Types.UPDATE_ACCOUNT_MANAGERS_MODAL_SUCCESS:
      return {
        ...state,
        editAccountManagersModalServerValidation: {},
        [action.payload?.ref]: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload?.formName]: initState[action.payload?.formName],
      };

    case Types.ADD_BUSSINESS_PARTNER_INPUT_IN_PROFILE:
      return {
        ...state,
        editCompanyBussPartnersModal: {
          ...state.editCompanyBussPartnersModal,
          partners_input: [
            ...state?.editCompanyBussPartnersModal?.partners_input,
            {
              id: null,
              company_id: action.payload?.company_id,
              name: "",
              phone: "",
              email: "",
            },
          ],
        },
      };

    case Types.REMOVE_BUSSINESS_PARTNER_INPUT_IN_PROFILE:
      return {
        ...state,
        editCompanyBussPartnersModal: {
          ...state?.editCompanyBussPartnersModal,
          partners_input:
            state?.editCompanyBussPartnersModal?.partners_input?.filter(
              (_, i) => i != action.payload?.index
            ),
        },
      };

    case Types.UPDATE_BUSSINESS_PARTNERS_MODAL_ATTEMPT:
      return {
        ...state,
        editCompanyBussPartnersModalServerValidation: {},
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: true,
          modalMessage: "",
        },
      };

    case Types.UPDATE_BUSSINESS_PARTNERS_MODAL_SUCCESS:
      return {
        ...state,
        editCompanyBussPartnersModalServerValidation: {},
        [action.payload?.ref]: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload?.formName]: initState[action.payload?.formName],
      };

    case Types.EDIT_BUSSINESS_PARTNER_MODAL_ATTEMPT:
      return {
        ...state,
        updateBussPartnerModalServerValidation: {},
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: true,
          modalMessage: "",
        },
      };

    case Types.EDIT_BUSSINESS_PARTNER_MODAL_SUCCESS:
      return {
        ...state,
        updateBussPartnerModalServerValidation: {},
        [action.payload?.ref]: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload?.formName]: initState[action.payload?.formName],
      };

    case Types.UPSERT_COMPANY_SUBSCRIPTION_ATTEMPT:
      return {
        ...state,
        addSubscriptionModalServerValidation: {},
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isLoading: true,
          modalMessage: "",
        },
      };
    case Types.UPDATE_EMPLOYEE_LIMIT_VALUE:
      return {
        ...state,
        [action.ref]: {
          ...state[action.ref],
          employees_limit: action?.payload ?? "",
        },
      };

    case Types.UPSERT_COMPANY_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        addSubscriptionModalServerValidation: {},
        [action.payload?.ref]: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        [action.payload?.formName]: initState[action.payload?.formName],
      };

    case Types.DELETE_COMPANY_SUBSCRIPTION_ATTEMPT:
      return {
        ...state,
        deleteCompanySubscriptionLoading: true,
      };
    case Types.DELETE_COMPANY_SUBSCRIPTION_SUCCESS:
    case Types.DELETE_COMPANY_SUBSCRIPTION_FAILED:
      return {
        ...state,
        deleteCompanySubscriptionLoading: false,
      };

    // handle fetch admin user companies data
    case Types.FETCH_ADMIN_USER_COMPANIES_ATTEMPT:
      return {
        ...state,
        adminUserCompaniesLoading: true,
      };

    case Types.FETCH_ADMIN_USER_COMPANIES_SUCCESS:
      return {
        ...state,
        adminUserCompaniesData: action?.payload?.data,
        adminUserCompaniesLoading: false,
      };
    case Types.FETCH_ADMIN_USER_COMPANIES_FAILED:
      return {
        ...state,
        adminUserCompaniesData: [],
        adminUserCompaniesLoading: false,
      };

    // handle fetch admin user employees data
    case Types.FETCH_ADMIN_USER_EMPLOYEES_ATTEMPT:
      return {
        ...state,
        adminUserEmployeesLoading: true,
      };
    case Types.FETCH_ADMIN_USER_EMPLOYEES_SUCCESS:
      return {
        ...state,
        adminUserEmployeesData: action?.payload?.activeUsers,
        adminUserEmployeesLoading: false,
      };
    case Types.FETCH_ADMIN_USER_EMPLOYEES_FAILED:
      return {
        ...state,
        adminUserEmployeesData: [],
        adminUserEmployeesLoading: false,
      };

    case Types.DELETE_COMPANY_BUSSINESS_PARTNERS_ATTEMPT:
      return {
        ...state,
        fetchedBuissPartnersDataIsLoading: true,
      };

    case Types.DELETE_COMPANY_BUSSINESS_PARTNERS_SUCCESS:
      return {
        ...state,
        fetchedBuissPartnersDataIsLoading: false,
        companiesModal: {
          ...state.companiesModal,
          partners_input: state?.companiesModal?.partners_input?.filter(
            (bp) => bp?.id !== action?.payload?.id
          ),
        },
      };

    case Types.DELETE_COMPANY_BUSSINESS_PARTNERS_FAILED:
      return {
        ...state,
        fetchedBuissPartnersDataIsLoading: false,
      };

    case Types.SET_SALARY_CONFIG_CALCULATIONS_FIXED:
      return {
        ...state,
        loadingPresetSalary: false,
        salaryConfigForm: {
          ..._.cloneDeep(state.salaryConfigForm),
          regulation_type_id: RegulationTypes.MAWARED_CUSTOM,

          hours_per_paid_leave: !!state.salaryConfigForm?.hours_per_paid_leave
            ? state.salaryConfigForm?.hours_per_paid_leave
            : null,

          paid_leave_hourly_rate: !!state.salaryConfigForm
            ?.paid_leave_hourly_rate
            ? state.salaryConfigForm?.paid_leave_hourly_rate
            : null,

          penaltyConfiguration: {
            ...state?.salaryConfigForm?.penaltyConfiguration,
            paymentFactor: PaymentFactors.FIXED_AMOUNT,
          },
          bonusConfiguration: {
            ...state?.salaryConfigForm?.bonusConfiguration,
            paymentFactor: PaymentFactors.FIXED_AMOUNT,
          },
          unpaidConfiguration: {
            ...state?.salaryConfigForm?.unpaidConfiguration,
            paymentFactor: PaymentFactors.FIXED_AMOUNT,
          },
          overtimeConfiguration: {
            ...state?.salaryConfigForm?.overtimeConfiguration,
            paymentFactor: PaymentFactors.FIXED_AMOUNT,
          },

          salary_deductibles: _.cloneDeep(
            state.salaryConfigForm?.salary_deductibles
          )?.map((deduct) =>
            deduct?.id != "3" && deduct?.id != "1"
              ? {
                ...deduct,
                payment_factor_id: PaymentFactors.FIXED_AMOUNT,
              }
              : deduct
          ),
          salary_allowances: _.cloneDeep(
            state.salaryConfigForm?.salary_allowances
          )?.map((allowance) => ({
            ...allowance,
            payment_factor_id: PaymentFactors.FIXED_AMOUNT,
          })),
        },
      };

    case Types.TOGGLE_MONTHLY_REPORT_CHECKBOX:
      return {
        ...state,
        monthlyReportExport: {
          ...state.monthlyReportExport,
          [action.payload?.ref]: action.payload?.value,
        },
      };

    case Types.TOGGLE_YEARLY_REPORT_CHECKBOX:
      return {
        ...state,
        yearlyReportExport: {
          ...state.yearlyReportExport,
          [action.payload?.ref]: action.payload?.value,
        },
      };

    case Types.SHOW_EXPORT_MONTHLY_REPORT_MODAL:
    case Types.SHOW_EXPORT_YEARLY_REPORT_MODAL:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isVissible: true,
        },
      };

    case Types.HIDE_EXPORT_MONTHLY_REPORT_MODAL:
    case Types.HIDE_EXPORT_YEARLY_REPORT_MODAL:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isVissible: false,
        },
      };
    case Types.EDIT_FETCH_WORK_TIMINGS_ATTEMPT:
      return {
        ...state,
        isEditFetchWorkTime: true,
      };
    case Types.EDIT_FETCH_WORK_TIMINGS_SUCCESS:
      return {
        ...state,
        workTimings: {
          ...action.payload?.workTime,
          allow_min_work_hours: action?.payload?.workTime?.min_work_hours !== null
            ? 1
            : 0,
          allow_work_timing_assigment:
            JSON.parse(JSON.parse(action?.payload?.workTime?.limit_access))
              ?.offices?.length > 0 ||
            JSON.parse(JSON.parse(action?.payload?.workTime?.limit_access))
              ?.departments?.length > 0 ||
            JSON.parse(JSON.parse(action?.payload?.workTime?.limit_access))
              ?.positions?.length > 0 ||
            JSON.parse(JSON.parse(action?.payload?.workTime?.limit_access))
              ?.employees?.length > 0
              ? 0
              : 1,
          offices:
            JSON.parse(JSON.parse(action?.payload?.workTime?.limit_access))
              ?.offices?.length > 0
              ? _.cloneDeep(
                  JSON.parse(
                    JSON.parse(action?.payload?.workTime?.limit_access)
                  )?.offices
                )
              : _.cloneDeep(initStore?.workTimings?.offices),
          departments:
            JSON.parse(JSON.parse(action?.payload?.workTime?.limit_access))
              ?.departments?.length > 0
              ? _.cloneDeep(
                  JSON.parse(
                    JSON.parse(action?.payload?.workTime?.limit_access)
                  )?.departments
                )
              : _.cloneDeep(initStore?.workTimings?.departments),
          positions:
            JSON.parse(JSON.parse(action?.payload?.workTime?.limit_access))
              ?.positions?.length > 0
              ? _.cloneDeep(
                  JSON.parse(
                    JSON.parse(action?.payload?.workTime?.limit_access)
                  )?.positions
                )
              : _.cloneDeep(initStore?.workTimings?.positions),
          employees:
            JSON.parse(JSON.parse(action?.payload?.workTime?.limit_access))
              ?.employees?.length > 0
              ? _.cloneDeep(
                  JSON.parse(
                    JSON.parse(action?.payload?.workTime?.limit_access)
                  )?.employees
                )
              : _.cloneDeep(initStore?.workTimings?.employees),

          record_actual: action?.payload?.workTime?.overtime_type === "default",
          minor_violations:
            action?.payload?.workTime?.minor_violations?.length > 0
              ? _.cloneDeep(action?.payload?.workTime?.minor_violations)
              : _.cloneDeep(initStore?.workTimings?.minor_violations),

          major_violations:
            action?.payload?.workTime?.major_violations?.length > 0
              ? _.cloneDeep(action?.payload?.workTime?.major_violations)
              : _.cloneDeep(initStore?.workTimings?.major_violations),
          major_violations1:
            action?.payload?.workTime?.major_violations1?.length > 0
              ? _.cloneDeep(action?.payload?.workTime?.major_violations1)
              : _.cloneDeep(initStore?.workTimings?.major_violations1),
          major_violations2:
            action?.payload?.workTime?.major_violations2?.length > 0
              ? _.cloneDeep(action?.payload?.workTime?.major_violations2)
              : _.cloneDeep(initStore?.workTimings?.major_violations2),
          major_violations3:
            action?.payload?.workTime?.major_violations3?.length > 0
              ? _.cloneDeep(action?.payload?.workTime?.major_violations3)
              : _.cloneDeep(initStore?.workTimings?.major_violations3),
          major_violations4:
            action?.payload?.workTime?.major_violations4?.length > 0
              ? _.cloneDeep(action?.payload?.workTime?.major_violations4)
              : _.cloneDeep(initStore?.workTimings?.major_violations4),
          major_violations5:
            action?.payload?.workTime?.major_violations5?.length > 0
              ? _.cloneDeep(action?.payload?.workTime?.major_violations5)
              : _.cloneDeep(initStore?.workTimings?.major_violations5),
          major_violations6:
            action?.payload?.workTime?.major_violations6?.length > 0
              ? _.cloneDeep(action?.payload?.workTime?.major_violations6)
              : _.cloneDeep(initStore?.workTimings?.major_violations6),
          major_violations7:
            action?.payload?.workTime?.major_violations7?.length > 0
              ? _.cloneDeep(action?.payload?.workTime?.major_violations7)
              : _.cloneDeep(initStore?.workTimings?.major_violations7),
          major_violations8:
            action?.payload?.workTime?.major_violations8?.length > 0
              ? _.cloneDeep(action?.payload?.workTime?.major_violations8)
              : _.cloneDeep(initStore?.workTimings?.major_violations8),
          major_violations9:
            action?.payload?.workTime?.major_violations9?.length > 0
              ? _.cloneDeep(action?.payload?.workTime?.major_violations9)
              : _.cloneDeep(initStore?.workTimings?.major_violations9),
          major_violations10:
            action?.payload?.workTime?.major_violations10?.length > 0
              ? _.cloneDeep(action?.payload?.workTime?.major_violations10)
              : _.cloneDeep(initStore?.workTimings?.major_violations10),

          absent_violations:
            action?.payload?.workTime?.absent_violations?.length > 0
              ? _.cloneDeep(action?.payload?.workTime?.absent_violations)
              : _.cloneDeep(initStore?.workTimings?.absent_violations),

          early_sign_out_violations:
            action?.payload?.workTime?.early_sign_out_violations?.length > 0
              ? _.cloneDeep(
                action?.payload?.workTime?.early_sign_out_violations
              )
              : _.cloneDeep(initStore?.workTimings?.early_sign_out_violations),
          early_sign_out_violations1:
            action?.payload?.workTime?.early_sign_out_violations1?.length > 0
              ? _.cloneDeep(
                action?.payload?.workTime?.early_sign_out_violations1
              )
              : _.cloneDeep(initStore?.workTimings?.early_sign_out_violations1),
          early_sign_out_violations2:
            action?.payload?.workTime?.early_sign_out_violations2?.length > 0
              ? _.cloneDeep(
                action?.payload?.workTime?.early_sign_out_violations2
              )
              : _.cloneDeep(initStore?.workTimings?.early_sign_out_violations2),
          early_sign_out_violations3:
            action?.payload?.workTime?.early_sign_out_violations3?.length > 0
              ? _.cloneDeep(
                action?.payload?.workTime?.early_sign_out_violations3
              )
              : _.cloneDeep(initStore?.workTimings?.early_sign_out_violations3),
          early_sign_out_violations4:
            action?.payload?.workTime?.early_sign_out_violations4?.length > 0
              ? _.cloneDeep(
                action?.payload?.workTime?.early_sign_out_violations4
              )
              : _.cloneDeep(initStore?.workTimings?.early_sign_out_violations4),
          early_sign_out_violations5:
            action?.payload?.workTime?.early_sign_out_violations5?.length > 0
              ? _.cloneDeep(
                action?.payload?.workTime?.early_sign_out_violations5
              )
              : _.cloneDeep(initStore?.workTimings?.early_sign_out_violations5),
          early_sign_out_violations6:
            action?.payload?.workTime?.early_sign_out_violations6?.length > 0
              ? _.cloneDeep(
                action?.payload?.workTime?.early_sign_out_violations6
              )
              : _.cloneDeep(initStore?.workTimings?.early_sign_out_violations6),
          early_sign_out_violations7:
            action?.payload?.workTime?.early_sign_out_violations7?.length > 0
              ? _.cloneDeep(
                action?.payload?.workTime?.early_sign_out_violations7
              )
              : _.cloneDeep(initStore?.workTimings?.early_sign_out_violations7),
          early_sign_out_violations8:
            action?.payload?.workTime?.early_sign_out_violations8?.length > 0
              ? _.cloneDeep(
                action?.payload?.workTime?.early_sign_out_violations8
              )
              : _.cloneDeep(initStore?.workTimings?.early_sign_out_violations8),
          early_sign_out_violations9:
            action?.payload?.workTime?.early_sign_out_violations9?.length > 0
              ? _.cloneDeep(
                action?.payload?.workTime?.early_sign_out_violations9
              )
              : _.cloneDeep(initStore?.workTimings?.early_sign_out_violations9),
          early_sign_out_violations10:
            action?.payload?.workTime?.early_sign_out_violations10?.length > 0
              ? _.cloneDeep(
                action?.payload?.workTime?.early_sign_out_violations10
              )
              : _.cloneDeep(
                initStore?.workTimings?.early_sign_out_violations10
              ),

          missing_sign_out_violations:
            action?.payload?.workTime?.missing_sign_out_violations?.length > 0
              ? _.cloneDeep(
                action?.payload?.workTime?.missing_sign_out_violations
              )
              : _.cloneDeep(
                initStore?.workTimings?.missing_sign_out_violations
              ),
        },

        // repeating the key to compare it with the default one to show swal in case of change
        tempWorkTimings: {
          ...action.payload?.workTime,
          allow_min_work_hours: action?.payload?.workTime?.min_work_hours !== null
          ? 1
          : 0,
          allow_work_timing_assigment:
            JSON.parse(JSON.parse(action?.payload?.workTime?.limit_access))
              ?.offices?.length > 0 ||
            JSON.parse(JSON.parse(action?.payload?.workTime?.limit_access))
              ?.departments?.length > 0 ||
            JSON.parse(JSON.parse(action?.payload?.workTime?.limit_access))
              ?.positions?.length > 0 ||
            JSON.parse(JSON.parse(action?.payload?.workTime?.limit_access))
              ?.employees?.length > 0
              ? 0
              : 1,
          offices:
            JSON.parse(JSON.parse(action?.payload?.workTime?.limit_access))
              ?.offices?.length > 0
              ? _.cloneDeep(
                  JSON.parse(
                    JSON.parse(action?.payload?.workTime?.limit_access)
                  )?.offices
                )
              : _.cloneDeep(initStore?.workTimings?.offices),
          departments:
            JSON.parse(JSON.parse(action?.payload?.workTime?.limit_access))
              ?.departments?.length > 0
              ? _.cloneDeep(
                  JSON.parse(
                    JSON.parse(action?.payload?.workTime?.limit_access)
                  )?.departments
                )
              : _.cloneDeep(initStore?.workTimings?.departments),
          positions:
            JSON.parse(JSON.parse(action?.payload?.workTime?.limit_access))
              ?.positions?.length > 0
              ? _.cloneDeep(
                  JSON.parse(
                    JSON.parse(action?.payload?.workTime?.limit_access)
                  )?.positions
                )
              : _.cloneDeep(initStore?.workTimings?.positions),
          employees:
            JSON.parse(JSON.parse(action?.payload?.workTime?.limit_access))
              ?.employees?.length > 0
              ? _.cloneDeep(
                  JSON.parse(
                    JSON.parse(action?.payload?.workTime?.limit_access)
                  )?.employees
                )
              : _.cloneDeep(initStore?.workTimings?.employees),

          record_actual: action?.payload?.workTime?.overtime_type === "default",
          minor_violations:
            action?.payload?.workTime?.minor_violations?.length > 0
              ? _.cloneDeep(action?.payload?.workTime?.minor_violations)
              : _.cloneDeep(initStore?.workTimings?.minor_violations),

          major_violations:
            action?.payload?.workTime?.major_violations?.length > 0
              ? _.cloneDeep(action?.payload?.workTime?.major_violations)
              : _.cloneDeep(initStore?.workTimings?.major_violations),
          major_violations1:
            action?.payload?.workTime?.major_violations1?.length > 0
              ? _.cloneDeep(action?.payload?.workTime?.major_violations1)
              : _.cloneDeep(initStore?.workTimings?.major_violations1),
          major_violations2:
            action?.payload?.workTime?.major_violations2?.length > 0
              ? _.cloneDeep(action?.payload?.workTime?.major_violations2)
              : _.cloneDeep(initStore?.workTimings?.major_violations2),
          major_violations3:
            action?.payload?.workTime?.major_violations3?.length > 0
              ? _.cloneDeep(action?.payload?.workTime?.major_violations3)
              : _.cloneDeep(initStore?.workTimings?.major_violations3),
          major_violations4:
            action?.payload?.workTime?.major_violations4?.length > 0
              ? _.cloneDeep(action?.payload?.workTime?.major_violations4)
              : _.cloneDeep(initStore?.workTimings?.major_violations4),
          major_violations5:
            action?.payload?.workTime?.major_violations5?.length > 0
              ? _.cloneDeep(action?.payload?.workTime?.major_violations5)
              : _.cloneDeep(initStore?.workTimings?.major_violations5),
          major_violations6:
            action?.payload?.workTime?.major_violations6?.length > 0
              ? _.cloneDeep(action?.payload?.workTime?.major_violations6)
              : _.cloneDeep(initStore?.workTimings?.major_violations6),
          major_violations7:
            action?.payload?.workTime?.major_violations7?.length > 0
              ? _.cloneDeep(action?.payload?.workTime?.major_violations7)
              : _.cloneDeep(initStore?.workTimings?.major_violations7),
          major_violations8:
            action?.payload?.workTime?.major_violations8?.length > 0
              ? _.cloneDeep(action?.payload?.workTime?.major_violations8)
              : _.cloneDeep(initStore?.workTimings?.major_violations8),
          major_violations9:
            action?.payload?.workTime?.major_violations9?.length > 0
              ? _.cloneDeep(action?.payload?.workTime?.major_violations9)
              : _.cloneDeep(initStore?.workTimings?.major_violations9),
          major_violations10:
            action?.payload?.workTime?.major_violations10?.length > 0
              ? _.cloneDeep(action?.payload?.workTime?.major_violations10)
              : _.cloneDeep(initStore?.workTimings?.major_violations10),

          absent_violations:
            action?.payload?.workTime?.absent_violations?.length > 0
              ? _.cloneDeep(action?.payload?.workTime?.absent_violations)
              : _.cloneDeep(initStore?.workTimings?.absent_violations),

          early_sign_out_violations:
            action?.payload?.workTime?.early_sign_out_violations?.length > 0
              ? _.cloneDeep(
                action?.payload?.workTime?.early_sign_out_violations
              )
              : _.cloneDeep(initStore?.workTimings?.early_sign_out_violations),
          early_sign_out_violations1:
            action?.payload?.workTime?.early_sign_out_violations1?.length > 0
              ? _.cloneDeep(
                action?.payload?.workTime?.early_sign_out_violations1
              )
              : _.cloneDeep(initStore?.workTimings?.early_sign_out_violations1),
          early_sign_out_violations2:
            action?.payload?.workTime?.early_sign_out_violations2?.length > 0
              ? _.cloneDeep(
                  action?.payload?.workTime?.early_sign_out_violations2
                )
              : _.cloneDeep(initStore?.workTimings?.early_sign_out_violations2),
          early_sign_out_violations3:
            action?.payload?.workTime?.early_sign_out_violations3?.length > 0
              ? _.cloneDeep(
                  action?.payload?.workTime?.early_sign_out_violations3
                )
              : _.cloneDeep(initStore?.workTimings?.early_sign_out_violations3),
          early_sign_out_violations4:
            action?.payload?.workTime?.early_sign_out_violations4?.length > 0
              ? _.cloneDeep(
                  action?.payload?.workTime?.early_sign_out_violations4
                )
              : _.cloneDeep(initStore?.workTimings?.early_sign_out_violations4),
          early_sign_out_violations5:
            action?.payload?.workTime?.early_sign_out_violations5?.length > 0
              ? _.cloneDeep(
                  action?.payload?.workTime?.early_sign_out_violations5
                )
              : _.cloneDeep(initStore?.workTimings?.early_sign_out_violations5),
          early_sign_out_violations6:
            action?.payload?.workTime?.early_sign_out_violations6?.length > 0
              ? _.cloneDeep(
                  action?.payload?.workTime?.early_sign_out_violations6
                )
              : _.cloneDeep(initStore?.workTimings?.early_sign_out_violations6),
          early_sign_out_violations7:
            action?.payload?.workTime?.early_sign_out_violations7?.length > 0
              ? _.cloneDeep(
                  action?.payload?.workTime?.early_sign_out_violations7
                )
              : _.cloneDeep(initStore?.workTimings?.early_sign_out_violations7),
          early_sign_out_violations8:
            action?.payload?.workTime?.early_sign_out_violations8?.length > 0
              ? _.cloneDeep(
                  action?.payload?.workTime?.early_sign_out_violations8
                )
              : _.cloneDeep(initStore?.workTimings?.early_sign_out_violations8),
          early_sign_out_violations9:
            action?.payload?.workTime?.early_sign_out_violations9?.length > 0
              ? _.cloneDeep(
                  action?.payload?.workTime?.early_sign_out_violations9
                )
              : _.cloneDeep(initStore?.workTimings?.early_sign_out_violations9),
          early_sign_out_violations10:
            action?.payload?.workTime?.early_sign_out_violations10?.length > 0
              ? _.cloneDeep(
                  action?.payload?.workTime?.early_sign_out_violations10
                )
              : _.cloneDeep(
                  initStore?.workTimings?.early_sign_out_violations10
                ),

          missing_sign_out_violations:
            action?.payload?.workTime?.missing_sign_out_violations?.length > 0
              ? _.cloneDeep(
                action?.payload?.workTime?.missing_sign_out_violations
              )
              : _.cloneDeep(
                initStore?.workTimings?.missing_sign_out_violations
              ),
        },

        workTimingsModal: {
          ...initState.workTimingsModal,
          isVissible: true,
        },
        isEditFetchWorkTime: false,
      };

    case Types.VIEW_WORK_TIME_ATTEMPT:
      return {
        ...state,
        isViewWorkTimeLoading: true,
      };
    case Types.VIEW_WORK_TIME_SUCCESS:
      return {
        ...state,
        viewWorkTime: { ...action.payload?.workTime },
        viewWorkTimeModal: {
          ...initState.viewWorkTimeModal,
          isVissible: true,
        },
        isViewWorkTimeLoading: false,
      };
    case Types.VIEW_WORK_TIME_FAILED:
    case Types.DISMISS_VIEW_WORK_TIME:
      return {
        ...state,
        viewWorkTime: initState.viewWorkTime,
        viewWorkTimeModal: initState.viewWorkTimeModal,
        isViewWorkTimeLoading: false,
      };

    case Types.EDIT_FETCH_WORK_TIMINGS_FAILED:
      return {
        ...state,
        isEditFetchWorkTime: false,
      };

    case Types.FETCH_ALL_WORK_TIME_OPTIONS:
      return {
        ...state,
        workTimeOptions: action?.payload,
      };

    case Types.SET_ALL_POSITION_OPTIONS:
      return {
        ...state,
        allPositions: action?.payload,
      };

    case Types.ADD_NEW_WORK_TIME_CONFIG:
      return {
        ...state,
        [action.payload.formName]: {
          ...state?.[action.payload.formName],
          attendance_profile_work_timing_input: [
            {
              id: null,
              sign_in_req: 0,
              work_timing_id: null,
              first_half_work_timing_id: null,
              first_half_work_timing_name: null,
              second_half_work_timing_id: null,
              second_half_work_timing_name: null,
              start: null,
              end: null,
              allow_half_day: null,
              can_edit: null,
            },
            ...state?.[action.payload.formName]
              ?.attendance_profile_work_timing_input,
          ],
        },
      };

    case Types.CLONE_WORK_TIME_ATTEMPT:
    case Types.DELETE_WORK_TIME_ATTEMPT:
      return {
        ...state,
        workTimingsList: {
          ...state.workTimingsList,
          isLoading: true,
        },
      };

    case Types.CLONE_WORK_TIME_SUCCESS:
    case Types.CLONE_WORK_TIME_FAILED:
    case Types.DELETE_WORK_TIME_SUCCESS:
    case Types.DELETE_WORK_TIME_FAILED:
      return {
        ...state,
        workTimingsList: {
          ...state.workTimingsList,
          isLoading: false,
        },
      };

    case Types.FETCH_EMAIL_NOTFIFCATIONS_ATTEMPT:
      return {
        ...state,
        emailNotificationsSettingsLoading: action.payload.refetch,
      };
    case Types.FETCH_EMAIL_NOTFIFCATIONS_SUCCESS:
      return {
        ...state,
        emailNotificationsSettings: action.payload,
        emailNotificationsSettingsLoading: false,
      };
    case Types.FETCH_EMAIL_NOTFIFCATIONS_FAILED:
      return {
        ...state,
        emailNotificationsSettings: [],
        emailNotificationsSettingsLoading: false,
      };

    case Types.UPDATE_EMAIL_NOTIFICATIONS_ATTEMPT:
      return {
        ...state,
        emailSwitchLoader:
          action.payload.type == "email"
            ? [...state.emailSwitchLoader, action.payload.id]
            : state.emailSwitchLoader,
        mobileSwitchLoader:
          action.payload.type == "phone"
            ? [...state.mobileSwitchLoader, action.payload.id]
            : state.mobileSwitchLoader,
      };
    case Types.UPDATE_EMAIL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        emailSwitchLoader:
          action.payload.type == "email"
            ? state.emailSwitchLoader?.filter((id) => id !== action.payload.id)
            : state.emailSwitchLoader,
        mobileSwitchLoader:
          action.payload.type == "phone"
            ? state.mobileSwitchLoader?.filter((id) => id !== action.payload.id)
            : state.mobileSwitchLoader,
        emailNotificationsSettings: state.emailNotificationsSettings.map(
          (notification) =>
            action.payload.id == notification.id
              ? {
                ...notification,
                [`${action.payload.type}_status`]: Boolean(
                  action.payload.status
                ),
              }
              : notification
        ),
      };
    case Types.UPDATE_EMAIL_NOTIFICATIONS_FAILED:
      return {
        ...state,
        emailSwitchLoader:
          action.payload.type == "email"
            ? state.emailSwitchLoader?.filter((id) => id !== action.payload.id)
            : state.emailSwitchLoader,
        mobileSwitchLoader:
          action.payload.type == "phone"
            ? state.mobileSwitchLoader?.filter((id) => id !== action.payload.id)
            : state.mobileSwitchLoader,
      };

    case Types.RESET_OLD_SIGN_IN_CONFIG_ACTION:
      let restoredSignInConfig = state[
        action.payload.formName
      ]?.old_attendance_profile_work_timing_input?.filter(
        (oldSinInConfig) => oldSinInConfig?.id == action.payload?.id
      )[0];
      return {
        ...state,
        [action.payload.formName]: {
          ...state[action.payload.formName],
          attendance_profile_work_timing_input: [
            ...state[
              action.payload.formName
            ]?.attendance_profile_work_timing_input?.map((sign_in_config) =>
              sign_in_config.id == action.payload.id
                ? { ...restoredSignInConfig }
                : { ...sign_in_config }
            ),
          ],
        },
      };

    case Types.REMOVE_SIGN_IN_CONFIG_ACTION:
      return {
        ...state,
        [action.payload.formName]: {
          ...state[action.payload.formName],
          attendance_profile_work_timing_input: [
            ...state[
              action.payload.formName
            ]?.attendance_profile_work_timing_input?.filter(
              (_, i) => i !== action.payload.index
            ),
          ],
        },
      };

    case Types.INIT_SUSPENSION_USER_ATTEMPT:
      return {
        ...state,
        initSuspenseLoading: true,
        suspensionModalActions: {
          ...state.suspensionModalActions,
          modalMessage: null,
        },
      };
    case Types.INIT_SUSPENSION_USER_SUCCESS:
      return {
        ...state,
        initSuspenseLoading: false,
        init_suspension: action.payload,
        suspensionModal: {
          ...state.suspensionModal,
          affected_balance: (+action.payload.remaining?.annual)?.toFixed(2),
        },
      };
    case Types.INIT_SUSPENSION_USER_FAILED:
      return {
        ...state,
        initSuspenseLoading: false,
        suspensionModalActions: {
          ...state.suspensionModalActions,
          modalMessage: action?.payload,
        },
      };
    case Types.RESEND_CREDENTAILS_ATTEMPT:
      return {
        ...state,
        resendCredentailsLoading: true,
      };

    case Types.RESEND_CREDENTAILS_SUCCESS:
    case Types.RESEND_CREDENTAILS_FAILED:
      return {
        ...state,
        resendCredentailsLoading: false,
      };
    case Types.CHECK_PASSWORD_ATTEMPT:
      return {
        ...state,
        checkPasswordLoading: true,
      };

    case Types.CHECK_PASSWORD_SUCCESS:
    case Types.CHECK_PASSWORD_FAILED:
      return {
        ...state,
        checkPasswordLoading: false,
      };
    case Types.FETCH_ALL_MANAGERS_ACTION:
      return {
        ...state,
        managerOptions: action?.payload,
      };

    case Types.FETCH_EMPLOYEE_DOCUMENTS_ATTEMPT:
      return {
        ...state,
        employeeDocumetsLoading: true,
      };
    case Types.FETCH_EMPLOYEE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        selectedEmployeeDocs: {
          required: action.payload?.required_documents,
          optional: action.payload?.optional_documents,
        },
        employeeDocumetsLoading: false,
      };
    case Types.FETCH_EMPLOYEE_DOCUMENTS_FAILED:
      return {
        ...state,
        employeeDocumetsLoading: false,
      };

    case Types.FETCH_UPSERT_DOC_MODAL_ATTEMPT:
      return {
        ...state,
        fetchDocLoading: true,
      };
    case Types.FETCH_UPSERT_DOC_MODAL_SUCCESS:
      return {
        ...state,
        countryOpts: action.payload?.countries || [],
        fetchDocLoading: false,
      };
    case Types.FETCH_UPSERT_DOC_MODAL_FAILED:
      return {
        ...state,
        fetchDocLoading: false,
      };

    case Types.ADD_USER_DOCUMENT_ATTEMPT:
      return {
        ...state,
        upsertDocumentsModalActions: {
          ...state.upsertDocumentsModalActions,
          isLoading: true,
          modalMessage: "",
        },
        upsertDocumentsModalActions: {
          ...state.upsertDocumentsModalActions,
          isLoading: true,
          modalMessage: "",
        },
      };

    case Types.ADD_USER_DOCUMENT_SUCCESS:
      return {
        ...state,
        upsertDocumentsModalActions: {
          ...initState.upsertDocumentsModalActions,
        },
        upsertDocumentsModal: {
          ...initState.upsertDocumentsModal,
        },
        upsertDocumentsModal: {
          ...initState.upsertDocumentsModal,
        },
        upsertDocumentsModalActions: {
          ...initState.upsertDocumentsModalActions,
        },
      };

    case Types.ADD_USER_DOCUMENT_FAILED:
      return {
        ...state,
        upsertDocumentsModalActions: {
          ...state.upsertDocumentsModalActions,
          isLoading: false,
          modalMessage: action?.payload,
        },
        upsertDocumentsModalActions: {
          ...state.upsertDocumentsModalActions,
          isLoading: false,
          modalMessage: action?.payload,
        },
      };

    case Types.DELETE_USER_DOCUMENT_ATTACHMENT_ATTEMPT:
      return {
        ...state,
        fetchDocLoading: true,
      };

    case Types.DELETE_USER_DOCUMENT_ATTACHMENT_SUCCESS:
      return {
        ...state,
        fetchDocLoading: false,
        upsertDocumentsModal: {
          ...state.upsertDocumentsModal,
          files: state?.upsertDocumentsModal?.files?.filter(
            (file) => file.id !== action.payload.file_id
          ),
        },
      };
    case Types.DELETE_USER_DOCUMENT_ATTACHMENT_FAILED:
      return {
        ...state,
        fetchDocLoading: false,
      };
    case Types.CREATE_WIZARD_OFFICE_ATTEMPT:
      return {
        ...state,
        officeFormStatus: {
          error: "",
          isLoading: true,
        },
      };
    // Leaves Breaks
    case Types.UPDATE_LEAVES_BREAKS_OPTIONS:
      return {
        ...state,
        leavesBreaksOptions: action?.payload,
      };

    case Types.CREATE_WIZARD_OFFICE_SUCCESS:
      return {
        ...state,
        officeFormStatus: {
          error: "",
          isLoading: false,
        },
      };
    case Types.CREATE_WIZARD_OFFICE_FAILED:
      return {
        ...state,
        officeFormStatus: {
          error: action.payload.message,
          isLoading: false,
        },
      };
    case Types.UPSERT_SETUP_WIZARD_WORK_TIMING_SUCCESS:
      return {
        ...state,
        workTimings: { ...initStore.workTimings },
      };
    case Types.UPSERT_SETUP_WIZARD_ATTENDANCE_PROFILE_ATTEMPT:
      return {
        ...state,
        upsertAttProfileLoading: true,
      };
    case Types.UPSERT_SETUP_WIZARD_ATTENDANCE_PROFILE_SUCCESS:
    case Types.UPSERT_SETUP_WIZARD_ATTENDANCE_PROFILE_FAILED: {
      return {
        ...state,
        upsertAttProfileLoading: false,
      };
    }

    case Types.SHOW_EXPORT_EMPLOYEE_LIST_MODAL:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isVissible: true,
        },
      };
    case Types.HIDE_EXPORT_EMPLOYEE_LIST_MODAL:
      return {
        ...state,
        [action.payload.ref]: {
          ...state[action.payload.ref],
          isVissible: false,
        },
      };

    case Types.CHANGE_TO_LIST_DATA_VIEW:
      return {
        ...state,
        [action.payload.dataTableRef]: {
          ...state[action.payload.dataTableRef],
          listView: true,
        },
      };
    case Types.CHANGE_TO_GRID_DATA_VIEW:
      return {
        ...state,
        [action.payload.dataTableRef]: {
          ...state[action.payload.dataTableRef],
          listView: false,
        },
      };

    case Types.SHOW_REQUEST_RULE_MODAL:
      return {
        ...state,
        requestRule: action.payload
          ? action.payload
          : { ...initState.requestRule },
        requestRuleModalActions: {
          ...state.requestRuleModalActions,
          isVissible: true,
        },
      };

    case Types.DISMISS_REQUEST_RULE_MODAL:
      return {
        ...state,
        requestRuleModalActions: {
          ...initState.requestRuleModalActions,
        },
        requestRule: { ...initState.requestRule },
        requestRuleServerValidation: {},
      };

    case Types.FETCH_REQUEST_RULES_OPTS_ATTEMPT:
      return {
        ...state,
        loadingRequestRulesUsers: true,
        requestRulesUsersOpts: [],
      };

    case Types.FETCH_REQUEST_RULES_USERS_OPTS_SUCCESS:
      return {
        ...state,
        loadingRequestRulesUsers: false,
        requestRulesUsersOpts: action?.payload?.usersOptions,
      };

    case Types.FETCH_DEPARTMENTS_OPTS_SUCCESS:
      return {
        ...state,
        loadingRequestRulesUsers: false,
        departmentsOptions: action?.payload?.departmentsOptions,
      };

    case Types.FETCH_REQUEST_RULES_USERS_OPTS_FAILED:
      return {
        ...state,
        loadingRequestRulesUsers: false,
        requestRulesUsersOpts: [],
      };

    case Types.FETCH_DEPARTMENTS_OPTS_FAILED:
      return {
        ...state,
        loadingRequestRulesUsers: false,
        departmentsOptions: [],
      };

    case Types.UPSERT_REQUEST_RULES_ATTEMPT:
      return {
        ...state,
        requestRuleModalActions: {
          ...state.requestRuleModalActions,
          isLoading: true,
        },
        requestRuleServerValidation: {},
      };
    case Types.UPSERT_REQUEST_RULES_SUCCESS:
      return {
        ...state,
        requestRuleModalActions: {
          ...initState.requestRuleModalActions,
        },
        requestRule: {
          ...initState.requestRule,
        },
        requestRuleServerValidation: {},
      };
    case Types.UPSERT_REQUEST_RULES_FAILED:
      return {
        ...state,
        requestRuleModalActions: {
          ...state.requestRuleModalActions,
          isLoading: false,
        },
        requestRuleServerValidation: action?.payload,
      };

    case Types.DELETE_REQUEST_RULES_ATTEMPT:
      return {
        ...state,
        loadingDeleteRequestRule: true,
      };
    case Types.DELETE_REQUEST_RULES_SUCCESS:
    case Types.DELETE_REQUEST_RULES_FAILED:
      return {
        ...state,
        loadingDeleteRequestRule: false,
      };
    case Types.SET_IS_EMPLOYEE_IMPORT_MODAL_OPEN:
      return {
        ...state,
        isBulkImportEmployeesOpen: action.payload,
      };
    case Types.DOWNLOAD_EMPLOYEE_TEMPLATE_ATTEMPT:
      return {
        ...state,
        bulkImportEmployeesLoading: true,
      };
    case Types.DOWNLOAD_EMPLOYEE_TEMPLATE_SUCCESS:
    case Types.DOWNLOAD_EMPLOYEE_TEMPLATE_FAILED:
      return {
        ...state,
        bulkImportEmployeesLoading: false,
      };
    case Types.SET_IS_EMPLOYEE_IMPORT_MODAL_ERRORS_OPEN:
      return {
        ...state,
        isEmployeeImportModalErrorsOpen: action.payload,
      };
    case Types.UPLOAD_BULK_EMPLOYEES_CSV_ATTEMPT:
      return {
        ...state,
        uploadEmployeesCsvLoading: true,
      };
    case Types.UPLOAD_BULK_EMPLOYEES_CSV_SUCCESS:
      return {
        ...state,
        uploadEmployeesCsvLoading: false,
        isBulkImportEmployeesOpen: false,
      };
    case Types.UPLOAD_BULK_EMPLOYEES_CSV_FAILED:
      return {
        ...state,
        uploadEmployeesCsvLoading: false,
        isBulkImportEmployeesOpen: false,
        isEmployeeImportModalErrorsOpen: true,
        uploadEmployeesFailedErrors: action.payload,
      };
    case Types.UPLOAD_BULK_EMPLOYEES_CSV_REQUEST_FAILED:
      return {
        ...state,
        uploadEmployeesCsvLoading: false,
        isEmployeeImportModalErrorsOpen: false,
        isBulkImportEmployeesOpen: false,
      };
    case Types.FETCH_OPEN_PAYROLL_MONTHS_ATTEMPT:
      return {
        ...state,
        openPayrollMonthsLoading: true,
      };
    case Types.FETCH_OPEN_PAYROLL_MONTHS_SUCCESS:
      return {
        ...state,
        openPayrollMonthsData: action.payload,
        openPayrollMonthsLoading: false,
      };
    case Types.FETCH_OPEN_PAYROLL_MONTHS_FAILED:
      return {
        ...state,
        openPayrollMonthsLoading: false,
      };

    case Types.FETCH_INIT_DELETE_MANAGER_ATTEMPT:
      return {
        ...state,
        isInitDeleteManagerLoading: true,
      };
    case Types.FETCH_INIT_DELETE_MANAGER_SUCCESS:
    case Types.FETCH_INIT_DELETE_MANAGER_FAILED:
      return {
        ...state,
        isInitDeleteManagerLoading: false,
      };

    case Types.FETCH_INIT_SUSPEND_MANAGER_ATTEMPT:
      return {
        ...state,
        isInitSuspendManagerLoading: true,
      };
    case Types.FETCH_INIT_SUSPEND_MANAGER_SUCCESS:
    case Types.FETCH_INIT_SUSPEND_MANAGER_FAILED:
      return {
        ...state,
        isInitSuspendManagerLoading: false,
      };
    case Types.FETCH_REPLACE_BY_MANAGERS_OPTIONS_ATTEMPT:
      return {
        ...state,
        isReplaceByManagersOptionsLoading: true,
      };
    case Types.FETCH_REPLACE_BY_MANAGERS_OPTIONS_SUCCESS:
      return {
        ...state,
        isReplaceByManagersOptionsLoading: false,
        replaceByManagersOptions: action?.payload?.data,
      };
    case Types.FETCH_REPLACE_BY_MANAGERS_OPTIONS_FAILED:
      return {
        ...state,
        isReplaceByManagersOptionsLoading: false,
        replaceByManagersOptions: [],
      };
    case Types.ADD_COMPANY_CURRENCIES:
      return {
        ...state,
        companyCurrencies: action.payload,
      };
    case Types.EDIT_OPEN_PAYROLL_MONTHS_OFFICES_ACCORDING_TO_SCOPE:
      return {
        ...state,
        OpenPayrollMonthsOfficesAccordingToScope: action.payload,
      };
    case Types.FETCH_DASHBOARD_CURRENCIES_SUCCESS:
      return {
        ...state,
        dashboardCurrencies: action.payload,
      };
    case Types.FETCH_TOTAL_EXPENSES_ATTEMPT:
    case Types.FETCH_SUB_CATEGORY_EXPENSES_ATTEMPT:
      return {
        ...state,
        fetchExpensesDataLoading: true,
      };
    case Types.FETCH_TOTAL_EXPENSES_SUCCESS:
      return {
        ...state,
        fetchExpensesDataLoading: false,
        fetchTotalExpensesData: action.payload,
      };
    case Types.FETCH_SUB_CATEGORY_EXPENSES_SUCCESS: {
      return {
        ...state,
        fetchExpensesDataLoading: false,
        fetchTotalExpensesData: action.payload,
        isTotalExpensesCurrentQuery: false,
      };
    }
    case Types.FETCH_TOTAL_EXPENSES_FAILED:
    case Types.FETCH_SUB_CATEGORY_EXPENSES_FAILED:
      return {
        ...state,
        fetchExpensesDataLoading: false,
        isTotalExpensesCurrentQuery: true,
        // fetchTotalExpensesData: [],
      };
    case Types.FETCH_PROFILE_SUCCESS:
    case Types.SET_OFFICE_CURRENCY:
      return {
        ...state,
        expensesChartSelectInfo: {
          ...state.expensesChartSelectInfo,
          currency_id: !!state.expensesChartSelectInfo?.currency_id
            ? state.expensesChartSelectInfo?.currency_id
            : action?.payload?.user?.offices[0]
              ?.currenciesWithDefaultCurrency[0]?.id,
        },
      };
    case Types.SET_IS_THIS_QUERY_TOTAL_EXPENSES:
      return {
        ...state,
        isTotalExpensesCurrentQuery: action.payload,
      };
    case Types.FETCH_EMPLOYEES_TURNOVER_CHART_ATTEMPT:
      return {
        ...state,
        employeesTurnOverLoading: true,
      };
    case Types.FETCH_EMPLOYEES_TURNOVER_CHART_SUCCESS:
      return {
        ...state,
        employeesTurnOverLoading: false,
        companyDepartments: action.payload?.departments?.data,
        EmployeesTurnOverChartData: action.payload?.employees_turnover_ratio,
        companyTurnoverOffices: action.payload?.company_offices?.data,
      };
    case Types.FETCH_EMPLOYEES_TURNOVER_CHART_FAILED:
      return {
        ...state,
        employeesTurnOverLoading: false,
      };

    case Types.FETCH_PLAN_ROLES_OPTS:
      return {
        ...state,
        planRoles: action?.payload,
      };
    case Types.FETCH_ROLE_PRESET_PRIVILEGS_ATTEMPT:
      return {
        ...state,
        loadingRolePresetPrivileges: true,
      };
    case Types.FETCH_ROLE_PRESET_PRIVILEGS_SUCCESS:
      let { all, managed, main } = action.payload;
      let limit = safeJsonParse(safeJsonParse(action?.payload?.limit_access));
      return {
        ...state,
        loadingRolePresetPrivileges: false,
        managePrivilagesModal: {
          ...state.managePrivilagesModal,
          managed,
          all,
          main,
          assign_all: +!Boolean(limit),
          employees: limit?.employees || [],
          positions: limit?.positions || [],
        },
      };
    case Types.FETCH_ROLE_PRESET_PRIVILEGS_FAILED:
      return {
        ...state,
        loadingRolePresetPrivileges: false,
      };

    case Types.SHOW_PREVIEW_ROLE_PRIVILEGES_MODAL:
      return {
        ...state,
        previewRolePrivilagesModalActions: {
          isVissible: true,
        },
        previewRolePrivilagesModal: {
          ...state.previewRolePrivilagesModal,
          ...action.payload.data,
        },
      };
    case Types.HIDE_PREVIEW_ROLE_PRIVILEGES_MODAL:
      return {
        ...state,
        previewRolePrivilagesModalActions: {
          isVissible: false,
        },
        previewRolePrivilagesModal: initState.previewRolePrivilagesModal,
      };
    case Types.FETCH_PREVIEW_ROLE_PRIVILEGES_ATTEMPT:
      return {
        ...state,
        isRolePrivilagesModalLoading: true,
      };
    case Types.FETCH_PREVIEW_ROLE_PRIVILEGES_SUCCESS:
      return {
        ...state,
        previewRolePrivilagesModal: {
          ...action.payload?.data,
        },
        isRolePrivilagesModalLoading: false,
      };
    case Types.FETCH_PREVIEW_ROLE_PRIVILEGES_FAILED:
      return {
        ...state,
        previewRolePrivilagesModal: initState.previewRolePrivilagesModal,
        isRolePrivilagesModalLoading: false,
      };

    case Types.FETCH_REQUEST_COMMENTS_ATTEMPT:
      return {
        ...state,
        isRequestCommentsModalLoading: true,
      };
    case Types.FETCH_REQUEST_COMMENTS_SUCCESS:
      return {
        ...state,
        requestCommentModal: {
          ...state.requestCommentModal,
          ...action.payload,
        },
        isRequestCommentsModalLoading: false,
      };
    case Types.FETCH_REQUEST_COMMENTS_FAILED:
      return {
        ...state,
        requestCommentModal: initState.requestCommentModal,
        isRequestCommentsModalLoading: false,
      };
    case Types.SHOW_REQUEST_COMMENTS_MODAL:
      return {
        ...state,
        requestCommentModalActions: {
          isVissible: true,
          isLoading: false,
          isFirstRender: true,
          modalMessage: "",
        },
        requestCommentModal: {
          ...state.requestCommentModal,
          ...action.payload.data,
        },
      };
    case Types.HIDE_REQUEST_COMMENTS_MODAL:
      return {
        ...state,
        requestCommentModalActions: {
          isVissible: false,
          isLoading: false,
          isFirstRender: false,
          modalMessage: "",
        },

        requestCommentModalServerValidation: {},
      };
    case Types.UPSERT_REQUEST_COMMENT_ATTEMPT:
      return {
        ...state,
        requestCommentModalServerValidation: {},
        requestCommentModalActions: {
          ...state.requestCommentModalActions,
          isLoading: true,
          isFirstRender: false,
          modalMessage: "",
        },
      };
    case Types.UPSERT_REQUEST_COMMENT_SUCCESS:
      return {
        ...state,
        requestCommentModalServerValidation: {},
        requestCommentModalActions: {
          isVissible: true,
          isLoading: false,
          isFirstRender: false,
          modalMessage: "",
        },
      };
    case Types.UPSERT_REQUEST_COMMENT_FAILED:
      return {
        ...state,
        requestCommentModalActions: {
          ...state.requestCommentModalActions,
          isLoading: false,
          isFirstRender: false,
          modalMessage: action.payload?.modalMessage ?? "",
        },
        requestCommentModalServerValidation: action.payload?.errors ?? {},
      };

    case Types.FETCH_PENALTIES_EMPLOYEES_FILTER:
      return {
        ...state,
        penaltiesEmployeesFilter: action.payload.list?.data,
      };

    case Types.FETCH_HALF_WORK_TIMING_TEMPLATE_DATA_ATTEMPT:
      return {
        ...state,
        suggestedHalfWorkTimingTemplateLoading: true,
      };
    case Types.FETCH_HALF_WORK_TIMING_TEMPLATE_DATA_SUCCESS:
      return {
        ...state,
        suggestedHalfWorkTimingTemplateLoading: false,
        workTimings: {
          ...state.workTimings,
          ...action?.payload,
          minor_violations:
            action?.payload?.minor_violations?.length > 0
              ? action?.payload?.minor_violations
              : _.cloneDeep(initStore?.workTimings?.minor_violations),

          major_violations:
            action?.payload?.major_violations?.length > 0
              ? action?.payload?.major_violations
              : _.cloneDeep(initStore?.workTimings?.major_violations),
          major_violations1:
            action?.payload?.major_violations1?.length > 0
              ? action?.payload?.major_violations1
              : _.cloneDeep(initStore?.workTimings?.major_violations1),
          major_violations2:
            action?.payload?.major_violations2?.length > 0
              ? action?.payload?.major_violations2
              : _.cloneDeep(initStore?.workTimings?.major_violations2),
          major_violations3:
            action?.payload?.major_violations3?.length > 0
              ? action?.payload?.major_violations3
              : _.cloneDeep(initStore?.workTimings?.major_violations3),
          major_violations4:
            action?.payload?.major_violations4?.length > 0
              ? action?.payload?.major_violations4
              : _.cloneDeep(initStore?.workTimings?.major_violations4),
          major_violations5:
            action?.payload?.major_violations5?.length > 0
              ? action?.payload?.major_violations5
              : _.cloneDeep(initStore?.workTimings?.major_violations5),
          major_violations6:
            action?.payload?.major_violations6?.length > 0
              ? action?.payload?.major_violations6
              : _.cloneDeep(initStore?.workTimings?.major_violations6),
          major_violations7:
            action?.payload?.major_violations7?.length > 0
              ? action?.payload?.major_violations7
              : _.cloneDeep(initStore?.workTimings?.major_violations7),
          major_violations8:
            action?.payload?.major_violations8?.length > 0
              ? action?.payload?.major_violations8
              : _.cloneDeep(initStore?.workTimings?.major_violations8),
          major_violations9:
            action?.payload?.major_violations9?.length > 0
              ? action?.payload?.major_violations9
              : _.cloneDeep(initStore?.workTimings?.major_violations9),
          major_violations10:
            action?.payload?.major_violations10?.length > 0
              ? action?.payload?.major_violations10
              : _.cloneDeep(initStore?.workTimings?.major_violations10),

          absent_violations:
            action?.payload?.absent_violations?.length > 0
              ? action?.payload?.absent_violations
              : _.cloneDeep(initStore?.workTimings?.absent_violations),

          early_sign_out_violations:
            action?.payload?.early_sign_out_violations?.length > 0
              ? action?.payload?.early_sign_out_violations
              : _.cloneDeep(initStore?.workTimings?.early_sign_out_violations),

          missing_sign_out_violations:
            action?.payload?.missing_sign_out_violations?.length > 0
              ? action?.payload?.missing_sign_out_violations
              : _.cloneDeep(
                initStore?.workTimings?.missing_sign_out_violations
              ),
        },
      };
    case Types.FETCH_HALF_WORK_TIMING_TEMPLATE_DATA_FAILED:
      return {
        ...state,
        suggestedHalfWorkTimingTemplateLoading: false,
        workTimings: { ...initStore.workTimings },
      };

    case Types.AUTO_SUGGEST_HOLIDAYS_ATTEMPT:
      return {
        ...state,
        autoSuggestHolidaysLoading: true,
      };

    case Types.AUTO_SUGGEST_HOLIDAYS_SUCCESS:
    case Types.AUTO_SUGGEST_HOLIDAYS_FAILED:
      return {
        ...state,
        autoSuggestHolidaysLoading: false,
      };

    case Types.ACCEPT_SUGGESTED_HOLIDAY_ATTEMPT:
      return {
        ...state,
        acceptSuggestedHolidayLoading: true,
      };
    case Types.ACCEPT_SUGGESTED_HOLIDAY_SUCCESS:
    case Types.ACCEPT_SUGGESTED_HOLIDAY_FAILED:
      return {
        ...state,
        acceptSuggestedHolidayLoading: false,
      };

    case Types.DELETE_SUGGESTED_HOLIDAYS_ATTEMPT:
      return {
        ...state,
        deleteSuggestedHolidayLoading: true,
      };
    case Types.DELETE_SUGGESTED_HOLIDAYS_SUCCESS:
    case Types.DELETE_SUGGESTED_HOLIDAYS_FAILED:
      return {
        ...state,
        deleteSuggestedHolidayLoading: false,
      };

    case Types.DELETE_SALARY_CONFIG_ATTEMPT:
      return {
        ...state,
        deleteSalaryConfigLoading: true,
      };
    case Types.DELETE_SALARY_CONFIG_SUCCESS:
    case Types.DELETE_SALARY_CONFIG_FAILED:
      return {
        ...state,
        deleteSalaryConfigLoading: false,
      };

    case Types.FETCH_ALL_OFFICE_OPTIONS:
      return {
        ...state,
        allOffices: action?.payload,
      };
    case Types.FETCH_ALL_WORK_TIMINGS_MENU:
      return {
        ...state,
        allWorkTimingsMenu: action?.payload,
      };

    case Types.FETCH_ALL_WORK_TIMINGS_OPTIONS:
      return {
        ...state,
        allWorkTimings: action?.payload?.data,
      };

    case Types.FETCH_ALL_EMPLOYEES_OPTIONS:
      return {
        ...state,
        employeeOptions: action?.payload?.data,
      };

    case Types.FETCH_ALL_LOCATION_OPTIONS:
      return {
        ...state,
        locationOptions: action?.payload?.data,
      };

    case Types.FETCH_EDIT_ATTENDNACE_WORK_TIMINGS_OPTIONS:
      return {
        ...state,
        fetchEditAttendnaceWorkTimingOptionsLoading: true,
      };
    case Types.FETCH_EDIT_ATTENDNACE_WORK_TIMINGS_OPTIONS_SUCCESS:
      let data = action?.payload
        ?.map((worktime) => [worktime, ...(worktime?.oldVersions ?? [])])
        .flat(Infinity);
      return {
        ...state,
        fetchEditAttendnaceWorkTimingOptionsLoading: false,
        editAttendanceWorkTimingOptions: action?.payload,
        editAttHistoryModal: {
          ...state.editAttHistoryModal,
          work_timing_id: data?.find(
            (timing) => timing.id === action?.oldWorkTimingId
          )
            ? action?.oldWorkTimingId
            : null,
        },
      };
    case Types.FETCH_EDIT_ATTENDNACE_WORK_TIMINGS_OPTIONS_FAILED:
      return {
        ...state,
        fetchEditAttendnaceWorkTimingOptionsLoading: false,
      };

    case Types.FETCH_EDIT_ATTENDNACE_LOCATIONS_OPTIONS:
      return {
        ...state,
        fetchEditAttendnaceLocationsOptionsLoading: true,
      };
    case Types.FETCH_EDIT_ATTENDNACE_LOCATIONS_OPTIONS_SUCCESS:
      return {
        ...state,
        fetchEditAttendnaceLocationsOptionsLoading: false,
        attendnaceOfficeLocationsOptions: action?.payload,
      };
    case Types.FETCH_EDIT_ATTENDNACE_LOCATIONS_OPTIONS_FAILED:
      return {
        ...state,
        fetchEditAttendnaceLocationsOptionsLoading: false,
      };

    case Types.UPDATE_ATTENDNACE_LOG_LOCATIONS_IDS:
      return {
        ...state,
        isChangeLocaionsIdsLoading: true,
      };
    case Types.UPDATE_ATTENDNACE_LOG_LOCATIONS_IDS_SUCCESS:
      return {
        ...state,
        isChangeLocaionsIdsLoading: false,
        editAttHistoryModal: {
          ...state.editAttHistoryModal,
          new_available_locations_ids:
            action?.payload?.available_locations?.map((obj) => obj?.id),
        },
      };
    case Types.UPDATE_ATTENDNACE_LOG_LOCATIONS_IDS_FAILED:
      return {
        ...state,
        isChangeLocaionsIdsLoading: false,
      };
    case Types.HIDE_NOTIFICATION_DRAWER:
      return {
        ...state,
        notificationDrawer: { isVisible: false },
      };
    case Types.SHOW_NOTIFICATION_DRAWER:
      return {
        ...state,
        notificationDrawer: { isVisible: true },
      };
    case Types.GET_NOTIFICATION_HISTORY_ATTEMPT:
      return {
        ...state,
        notificationHistory: {
          ...state.notificationHistory,
          isLoading: true,
          data: !action.payload?.resetData
            ? state.notificationHistory.data
            : [],
        },
      };
    case Types.GET_NOTIFICATION_HISTORY_SUCCESS:
      let { data: notificationsHistory, paginatorInfo } =
        action.payload?.user_notifications_history;
      let {
        managedAndWatchedEmployees: managedAndWatchedEmployeesOptions = null,
      } = action.payload;
      let totalNotifications = [
        ...state.notificationHistory?.data,
        ...notificationsHistory,
      ];
      return {
        ...state,
        notificationHistory: {
          ...state.notificationHistory,
          isLoading: false,
          data: totalNotifications,
          paginatorInfo,
        },
        watchedAndManagedEmployees:
          managedAndWatchedEmployeesOptions ?? state.watchedAndManagedEmployees,
      };
    case Types.GET_NOTIFICATION_HISTORY_FAILED:
      return {
        ...state,
        notificationHistory: {
          ...state.notificationHistory,
          isLoading: false,
        },
      };
    case Types.NOTIFICATION_HISTORY_RESET:
      return {
        ...state,
        notificationHistory: {
          ...state.notificationHistory,
          data: [],
        },
      };

    case Types.SET_NOTIFICATION_PAGE_ACTION:
      return {
        ...state,
        notificationHistoryFilters: {
          ...state.notificationHistoryFilters,
          page: action.payload,
        },
      };

    case Types.FETCH_COMPANY_ACCESS_LEVELS_ATTEMPT:
      return {
        ...state,
        fetchingAccessLevelsLoading: true,
      };

    case Types.FETCH_COMPANY_ACCESS_LEVELS_SUCCESS:
      return {
        ...state,
        fetchingAccessLevelsLoading: false,
        accessLevels: { ...state.accessLevels, data: action.payload },
      };

    case Types.FETCH_COMPANY_ACCESS_LEVELS_FAILED:
      return {
        ...state,
        fetchingAccessLevelsLoading: false,
      };

    case Types.FETCH_PLAN_PRIVILEGES_SUCCESS:
      let mainPrivileges = HelperFns.getAllBasicPrivileges(
        JSON.parse(action.payload)
      );
      return {
        ...state,
        accessLevels: { ...state.accessLevels, isModalVissible: true },
        managePrivilagesModal: {
          ...state.managePrivilagesModal,
          main: [...mainPrivileges],
        },
        loadingPlanPrivileges: false,
        plan_privileges: action.payload,
      };

    case Types.DISMISS_ADD_NEW_ACCESS_LEVEL_MODAL_ACTION:
      return {
        ...state,
        accessLevels: { ...state.accessLevels, isModalVissible: false },
      };

    case Types.UPSERT_ROLE_TO_COMPANY_ATTEMPT:
      return {
        ...state,
        upsertRoleToCompanyLoading: true,
      };

    case Types.UPSERT_ROLE_TO_COMPANY_SUCCESS:
      return {
        ...state,
        upsertRoleToCompanyLoading: false,
        accessLevels: {
          ...state.accessLevels,
          isModalVissible: false,
        },
      };

    case Types.UPSERT_ROLE_TO_COMPANY_FAILED:
      return {
        ...state,
        [action.payload?.serverRef]: action.payload?.errors ?? {},
        upsertRoleToCompanyLoading: false,
      };

    case Types.EDIT_ROLE_PRIVILEGES_ATTEMPT:
      return {
        ...state,
        editRoleToCompanyLoading: true,
      };

    case Types.EDIT_ROLE_PRIVILEGES_SUCCESS:
      let limit_access = safeJsonParse(safeJsonParse(action?.payload?.role_privileges?.limit_access));
      return {
        ...state,
        upsertRoleToCompanyForm: {
          ...state.upsertRoleToCompanyForm,
          id: action?.payload?.role_privileges?.id,
          name: action?.payload?.role_privileges?.name,
          assign_all: +!Boolean(limit_access),
          employees: limit_access?.employees || [],
          positions: limit_access?.positions || [],
        },
        managePrivilagesModal: {
          ...state.managePrivilagesModal,
          managed: action?.payload?.role_privileges?.managed,
          all: action?.payload?.role_privileges?.all,
          main: action?.payload?.role_privileges?.main,
        },
        plan_privileges: action?.payload?.plan_privileges,
        accessLevels: { ...state.accessLevels, isModalVissible: true },
        editRoleToCompanyLoading: false,
      };

    case Types.FETCH_PLAN_PRIVILEGES_ATTEMPT:
      return {
        ...state,
        loadingPlanPrivileges: true,
      };

    case Types.EDIT_ROLE_PRIVILEGES_FAILED:
      return {
        ...state,
        editRoleToCompanyLoading: false,
      };

    case Types.DELETE_COMPANY_ROLE_ATTEMPT:
      return {
        ...state,
        deleteCompanyRoleLoading: true,
      };

    case Types.DELETE_COMPANY_ROLE_SUCCESS:
    case Types.DELETE_COMPANY_ROLE_FAILED:
      return {
        ...state,
        deleteCompanyRoleLoading: false,
      };

    case Types.SHOW_LOAN_MODAL:
      return {
        ...state,
        loansModalServerValidation: {},
        loansModalActions: {
          isVissible: true,
          isLoading: false,
          modalMessage: "",
        },
        loansModal: {
          ...state.loansModal,
          ...action.payload.data,
        },
      };

    case Types.ADD_INSTALLMENTS_INPUT:
      return {
        ...state,
        loansModal: {
          ...state.loansModal,
          installments: [
            ...state?.loansModal?.installments,
            {
              id: null,
              amount: "",
              payment_date: "",
            },
          ],
        },
      };

    case Types.GENERATE_INSTALLMENT_INPUT:
      let installmentsArray = new Array(action?.payload?.input_index);

      return {
        ...state,
        loansModal: {
          ...state?.loansModal,
          installments: installmentsArray
            ?.fill({ id: null, amount: "", payment_date: "" })
            ?.map((_, i) => {
              return {
                ..._,
                amount:
                  i + 1 != installmentsArray?.length
                    ? parseInt(
                      action?.payload?.loanAmount /
                      action?.payload?.input_index
                    )
                    : (
                      action?.payload?.loanAmount -
                      (installmentsArray?.length - 1) *
                      parseInt(
                        action?.payload?.loanAmount /
                        action?.payload?.input_index
                      )
                    ).toFixed(2),
                payment_date:
                  action.payload?.payment_interval?.toLowerCase() == "monthly"
                    ? moment(
                      moment(
                        action?.payload?.payment_date,
                        "YYYY-MM-DD"
                      ).format("YYYY-MM-DD"),
                      "YYYY-MM-DD"
                    ).add(i, "M")
                    : moment(action?.payload?.payment_date).add(i * 7, "days"),
              };
            }),
        },
      };

    case Types.SET_lOAN_CURRENCY_DUE_TO_SALARY_CONFIG:
      return {
        ...state,
        loansModal: {
          ...state?.loansModal,
          currency_id: action?.payload,
        },
      };

    case Types.REMOVE_INSTALLMENTS_INPUT:
      return {
        ...state,
        loansModal: {
          ...state?.loansModal,
          installments: state?.loansModal?.installments?.filter(
            (_, i) => i != action.payload?.index
          ),
        },
      };

    case Types.UPSERT_LOAN_ATTEMPT:
      return {
        ...state,
        loansModalServerValidation: {},
        loansModalActions: {
          ...state.loansModalActions,
          isLoading: true,
          modalMessage: "",
        },
      };
    case Types.UPSERT_LOAN_SUCCESS:
      return {
        ...state,
        loansModalServerValidation: {},
        loansModalActions: {
          isVissible: false,
          isLoading: false,
          modalMessage: "",
        },
        loansModal: initState.loansModal,
      };
    case Types.UPSERT_LOAN_FAILED:
      return {
        ...state,
        loansModalActions: {
          ...state.loansModalActions,
          isLoading: false,
          modalMessage: action.payload?.modalMessage ?? "",
        },
        loansModalServerValidation: action.payload?.errors ?? {},
      };

    case Types.DELETE_DEPARTMENT_ATTEMPT: {
      return {
        ...state,
        isDeleteDepartmentLoading: true,
      };
    }
    case Types.DELETE_DEPARTMENT_SUCCESS:
    case Types.DELETE_DEPARTMENT_FAILED: {
      return {
        ...state,
        isDeleteDepartmentLoading: false,
      };
    }

    case Types.DELETE_POSITION_ATTEMPT: {
      return {
        ...state,
        isDeletePositionLoading: true,
      };
    }
    case Types.DELETE_POSITION_SUCCESS:
    case Types.DELETE_POSITION_FAILED: {
      return {
        ...state,
        isDeletePositionLoading: false,
      };
    }

    case Types.SET_SERVER_VALIDATION: {
      return {
        ...state,
        checkInOutFormServerValidations: action.payload,
      };
    }

    case Types.TOGGLE_SIGN_IN_BREAKS_MODAL: {
      return {
        ...state,
        signInBreaksModal: {
          isOpen: action.payload?.isOpen,
          sign_in_id: action.payload?.sign_in_id,
          breakInOuts: action.payload?.breakInOuts ?? [],
          break_setting: action.payload?.break_setting ?? {},
          name: action.payload?.name,
          date: action.payload?.date,
        },
      };
    }

    case Types.TOGGLE_LOGS_MODAL: {
      return {
        ...state,
        logsModalData: {
          isOpen: action.payload?.isOpen,
          data: action.payload?.data ?? [],
          name: action.payload?.name,
          date: action.payload?.date,
          fromWhere: action.payload?.fromWhere ?? "",
        },
      };
    }

    case Types.ADJUST_SIGN_IN_OUT_IDS: {
      return {
        ...state,
        adjustSignInOutIds:
          action.payload?.operation == "add"
            ? [...state?.adjustSignInOutIds, action.payload?.id]
            : action.payload?.operation == "remove"
              ? [
                ...state?.adjustSignInOutIds?.filter(
                  (id) => id != action.payload?.id
                ),
              ]
              : [],
      };
    }

    case Types.TOGGLE_SET_TEMPORARY_PASSWORD_MODAL: {
      return {
        ...state,
        setTemporaryPasswordModal: {
          isOpen: action.payload?.isOpen ?? false,
          user: action.payload?.user ?? null,
        },
      };
    }
    case "ADD_BREAK_INTERVAL":
      return {
        ...state,
        workTimings: {
          ...state.workTimings,
          break_interval: Array.isArray(state.workTimings?.break_interval)
            ? [...state.workTimings?.break_interval, { start: "", end: "" }]
            : [{ start: "", end: "" }],
        },
      };
    case "RESET_BREAK_INTERVAL":
      return {
        ...state,
        workTimings: {
          ...state.workTimings,
          break_interval: [],
        },
      };
    case "DELETE_BREAK_INTERVAL":
      return {
        ...state,
        workTimings: {
          ...state.workTimings,
          break_interval: state.workTimings?.break_interval?.filter(
            (_, index) => index !== action.payload?.index
          ),
        },
      };
    case "UPDATE_BREAK_INTERVAL":
      return {
        ...state,
        workTimings: {
          ...state.workTimings,
          break_interval: state.workTimings?.break_interval?.map(
            (interval, index) =>
              index == action.payload?.index
                ? { ...interval, [action.payload.name]: action.payload.value }
                : interval
          ),
        },
      };

    case "UPDATE_FORM_LIST":
      return {
        ...state,
        [action.payload.formName]: {
          ...state[action.payload.formName],
          [action.payload.listName]: state[action.payload.formName][
            action.payload.listName
          ].map((item, index) => {
            if (index === action.payload.index)
              return {
                ...item,
                [action.payload.key]: action.payload.value,
              };
            return item;
          }),
        },
      };

    case "UPDATE_ALL_FORM_DATA":
      return {
        ...state,
        [action.payload.formName]: action.payload.formData,
      };

    case Types.FETCH_COMPANY_CONTACTS_ATTEMPT:
      return {
        ...state,
        contactOptionsLoading: true,
      };
    case Types.FETCH_COMPANY_CONTACTS_SUCCESS:
      return {
        ...state,
        contactOptionsLoading: false,
        companyContactOpt: action.payload.data,
      };
    case Types.FETCH_COMPANY_CONTACTS_FAILED:
      return {
        ...state,
        contactOptionsLoading: false,
      };

    case "START_LOGS_MODAL_DATA_LOADER":
      return {
        ...state,
        logsModalDataLoading: true,
      };

    case "STOP_LOGS_MODAL_DATA_LOADER":
      return {
        ...state,
        logsModalDataLoading: false,
      };

    default:
      return state;
  }
};
