import React from "react";
import { makeStyles } from "tss-react/mui";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useTranslation } from "react-i18next";
import HelperFns from "../../Helpers/HelperFns";
import Privilages from "../../Constants/Privilages";
import Constants from "../../Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEllipsisH,
	faMoneyBill,
	faFileAlt,
	faHandHoldingUsd,
	faMoneyCheckAlt,
	faCalendarDay,
	faClock,
	faAddressBook,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";

const horizontalNavbarStyles = makeStyles()(() => Constants.horizontalNavbarStyles);

const possibleValues = [1, 2, 3, 4, 5, 6, 7, 8, 9];

const EmployeeProfileTabs = (props) => {
	const { classes } = horizontalNavbarStyles();
	const { t } = useTranslation();

	return (
		<div className={classes.root}>
			<Tabs
				variant="scrollable"
				value={possibleValues.includes(props?.activeValue) ? props?.activeValue : false}
				onChange={props.handleChangeTab}
				scrollButtons="auto"
				classes={{
					root: classes.root,
					indicator: classes.indicator,
					flexContainer: classes.flexContainer,
					scrollButtons: classes.scrollButtons,
				}}
			>
				{props.shouldRenderTab([
					Privilages.VIEW_EMPLOYEE_REQUESTS,
					Privilages.VIEW_EMPLOYEE_BALANCE_ADJUSTMENT,
					Privilages.VIEW_EMPLOYEE_CLAIMS,
				]) ? (
					<Tab
						classes={{
							root: classes.tabRoot,
							wrapper: "top-bar-label",
							selected: classes.selected,
						}}
						value={1}
						label={
							<div className="mx-2 routeBar-text">
								<FontAwesomeIcon icon={faEllipsisH} className="mx-2 routeBar-icon" />
								{t("general")}
							</div>
						}
					/>
				) : null}

				{props.shouldRenderTab([Privilages.VIEW_ATTENDANCE_HISTORY_LOGS, Privilages.MANAGE_EMPLOYEE_ATTENDANCE]) && (
					<Tab
						classes={{
							root: classes.tabRoot,
							wrapper: "profile-top-bar-label",
							selected: classes.selected,
						}}
						value={8}
						label={
							<div className="mx-2 routeBar-text">
								<FontAwesomeIcon icon={faAddressBook} className="mx-2 routeBar-icon" />
								{t("history")}
							</div>
						}
					/>
				)}

				{props?.shouldRenderTab([Privilages.VIEW_EMPLOYEE_ATTENDANCE_TYPE_CONFIGURATIONS]) ? (
					<Tab
						classes={{
							root: classes.tabRoot,
							wrapper: "top-bar-label",
							selected: classes.selected,
						}}
						value={6}
						label={
							<div className="mx-2 routeBar-text">
								<FontAwesomeIcon icon={faClock} className="mx-2 routeBar-icon" />
								{t("attendance type")}
							</div>
						}
					/>
				) : null}

				{props?.shouldRenderTab([
					Privilages.VIEW_EMPLOYEE_GRACE_MINUTES,
				]) ? (
					<Tab
						classes={{
							root: classes.tabRoot,
							wrapper: "top-bar-label",
							selected: classes.selected,
						}}
						value={9}
						label={
							<div className="mx-2 routeBar-text">
								<FontAwesomeIcon icon={faClock} className="mx-2 routeBar-icon" />
								{t("Grace Minutes")}
							</div>
						}
					/>
				) : null}

				{props?.shouldRenderTab([Privilages.VIEW_EMPLOYEE_SCHEDULES]) ? (
					<Tab
						classes={{
							root: classes.tabRoot,
							wrapper: "top-bar-label",
							selected: classes.selected,
						}}
						value={7}
						label={
							<div className="mx-2 routeBar-text">
								<FontAwesomeIcon icon={faCalendarDay} className="mx-2 routeBar-icon" />
								{t("work calendar")}
							</div>
						}
					/>
				) : null}

				{props.shouldRenderTab([Privilages.VIEW_EMPLOYEE_SALARY_CONFIGURATION]) ? (
					<Tab
						classes={{
							root: classes.tabRoot,
							wrapper: "top-bar-label",
							selected: classes.selected,
						}}
						value={2}
						label={
							<div className="mx-2 routeBar-text">
								<FontAwesomeIcon icon={faMoneyBill} className="mx-2 routeBar-icon" />
								{t("salary configurations")}
							</div>
						}
					/>
				) : null}

				{props?.loans?.length > 0 && props.shouldRenderTab([Privilages.VIEW_LOANS_LIST]) ? (
					<Tab
						classes={{
							root: classes.tabRoot,
							wrapper: "top-bar-label",
							selected: classes.selected,
						}}
						value={5}
						label={
							<div className="mx-2 routeBar-text">
								<FontAwesomeIcon icon={faMoneyCheckAlt} className="mx-2 routeBar-icon" />
								{t("loans")}
							</div>
						}
					/>
				) : null}

				{props.shouldRenderTab([Privilages.VIEW_EMPLOYEE_DOCUMENTS]) &&
				(props?.employee?.profileDocuments?.optional_documents?.length !== 0 ||
					props.employee?.profileDocuments?.required_documents?.length !== 0) ? ( // check if the employee has any documents
					<Tab
						classes={{
							root: classes.tabRoot,
							wrapper: "top-bar-label",
							selected: classes.selected,
						}}
						value={3}
						label={
							<div className="mx-2 routeBar-text">
								<FontAwesomeIcon icon={faFileAlt} className="mx-2 routeBar-icon" />
								{t("documents")}
							</div>
						}
					/>
				) : null}

				{props?.userPettyCash?.length > 0 &&
				props.shouldRenderTab([Privilages.VIEW_EMPLOYEE_PETTY_CASH_BALANCE_IN_EMPLOYEE_PROFILE]) ? (
					<Tab
						classes={{
							root: classes.tabRoot,
							wrapper: "top-bar-label",
							selected: classes.selected,
						}}
						value={4}
						label={
							<div className="mx-2 routeBar-text">
								<FontAwesomeIcon icon={faHandHoldingUsd} className="mx-2 routeBar-icon" />
								{t("Petty Cash")}
							</div>
						}
					/>
				) : (
					""
				)}
			</Tabs>
		</div>
	);
};

export default EmployeeProfileTabs;
