import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "react-dropzone-uploader/dist/styles.css";
import {
	closeEditEmployeeAction,
	upsertEmployeeAction,
	resetEmployeeFormAction,
	setSelectedAttProfileAction,
	createNewEmployeeSalaryAction,
	onInputResetWithValueAction,
	onFormResetAction,
} from "../../Store/Actions";
import useDidUpdateEffect from "../../Helpers/Hooks/useDidUpdate";
import { Spinner } from "reactstrap";
import { useTranslation } from "react-i18next";
import SalaryConfigForm from "../../Components/SalaryConfigForm";
import moment from "moment";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import Privilages from "../../Constants/Privilages";
import {
	OfficeInfoSection,
	PersonalInfoSection,
	WorkRemotelySection,
	WorkScheduleSection,
	CustomLocationsSection,
} from "./EmployeeFormSections";
import Constants, { EgyptId } from "../../Constants";
import HelperFns, {
	serializeUpsertDayOffException,
	serializeUpsertHolidayException,
	normalizeSalaryConfigInput,
} from "../../Helpers/HelperFns";
import CountryFieldsSection from "./EmployeeFormSections/CountryFieldsSection";
import UpdateOrCreateUserPayment from "../../Components/UpdateOrCreateUserPayment";
import Store from "../../Store";

const formNameValidation = `employeeFormValidation`;
const formServerValidation = "employeeServerValidation";
const reducer = "super";

const EmployeeForm = (props) => {
	const { t } = useTranslation();
	const [formSubmitting, setFormSubmitting] = useState(false);
	const [selectedAttProfileState, setSelectedAttProfileState] = useState({});
	const formName = props.formName;
	const FormProps = {
		formName,
		formNameValidation,
		formSubmitting,
		formServerValidation,
		reducer,
	};

	const dispatch = useDispatch();
	const attendance_profiles = useSelector((state) => state.super.editEmployeeFormData?.attendance_profiles);
	const departments = useSelector((state) => state.super.editEmployeeFormData?.departments);
	const leavesBreaks = useSelector((state) => state.super.editEmployeeFormData?.leavesBreaks);
	const offices = useSelector((state) => state.super.editEmployeeFormData?.offices);

	const isOpen = useSelector((state) => state.super.editEmployeeForm);
	const att_profile_id = useSelector((state) => state.super?.[formName]?.att_profile_id);
	const department_id = useSelector((state) => state.super?.[formName]?.department_id);
	const manager_id = useSelector((state) => state.super?.[formName]?.manager_id);
	const office_id = useSelector((state) => state.super?.[formName]?.office_id);
	const permissionLeavesBreakSettingId = useSelector(
		(state) => state.super?.[formName]?.permissionLeavesBreakSettingId
	);
	const id = useSelector((state) => state.super?.[formName]?.id);

	const employeeFormValidation = useSelector((state) => state.super?.[formNameValidation]);
	const empServerValidation = useSelector((state) => state.super?.[formServerValidation]);
	const salaryConfigForm = useSelector((state) => state.super?.salaryConfigForm);
	const salaryConfigFormValidation = useSelector((state) => state.super?.salaryConfigFormValidation);

	const paymentMethodForm = useSelector((state) => state.super?.paymentMethodForm);

	const paymentMethodFormValidation = useSelector((state) => state.super?.paymentMethodFormValidation);

	const userCompanyID = useSelector((state) => state?.auth?.userProfile?.company?.id);
	const requestStatus = useSelector((state) => state.super.employeeFormStatus);

  const employeeFormSelectedProfile = attendance_profiles?.find((attProfile) => attProfile.id == att_profile_id);

	const selectedOffice = offices?.find((office) => office?.id == office_id);
	const timeRangeProfileSetting = useSelector((state) => state?.super?.timeRangeProfileSetting);

	useEffect(() => {
		if (attendance_profiles) {
			const selectedAttProfile = attendance_profiles?.filter((attProfile) => attProfile.id == att_profile_id);

			if (selectedAttProfile) {
				setSelectedAttProfileState(...selectedAttProfile);
				// To Use It At Create New Employee
				dispatch(setSelectedAttProfileAction(...selectedAttProfile));
			}
		}
	}, [att_profile_id, attendance_profiles]);

	// Form Clean Up
	useEffect(() => {
		return () => {
			setFormSubmitting(false);
			dispatch(resetEmployeeFormAction());
		};
	}, []);

	// Set manager depend on the department
	useEffect(() => {
		if (department_id && !manager_id) {
			const department = departments?.find((department) => department.id === department_id);
			department?.manager && dispatch(onInputResetWithValueAction(formName, "manager_id", department?.manager.id));
		}
	}, [department_id]);

	// Start of handling each attendnace profile if it has annual leaves and sick leaves and emergency leaves or not
	const [attProfileHasAnnualLeaves, setAttProfileHasAnnualLeaves] = useState(0);
	const [attProfileHasSickLeave, setAttProfileSickLeaves] = useState(0);
	const [attProfileHasEmergencyLeave, setAttProfileEmergencyLeaves] = useState(0);

	const handleAttProfileSelectionUpdate = () => {
		const selectedSettings = attendance_profiles?.find(
			(attProfile) => attProfile.id == att_profile_id
		)?.permission_leaves_break_setting;

		let selectedAttProfileAnnualLeaves = selectedSettings?.annualLeaveSetting?.allow_annual_leaves;

		let selectedAttProfileSickLeaves = selectedSettings?.sickLeaveSetting?.sick_leaves;

		let selectedAttProfileEmergencyLeaves = selectedSettings?.annualLeaveSetting?.allowed_emergency_leaves;

		setAttProfileHasAnnualLeaves(selectedAttProfileAnnualLeaves);
		setAttProfileSickLeaves(selectedAttProfileSickLeaves);
		setAttProfileEmergencyLeaves(selectedAttProfileEmergencyLeaves);
	};

	const useDidAttProfileSickLeaveUpdate = useDidUpdateEffect(handleAttProfileSelectionUpdate, [att_profile_id]);
	// End of handling each attendnace profile if it has sick leaves or not

	// getting the selected leave and break profile in case of shift based
	const selectedLeaveAndBreakProfile = leavesBreaks?.find((profile) => profile?.id == permissionLeavesBreakSettingId);
	// check if selected leaves and breaks profile allow annual leaves
	const leaveAndBreakProfileHasAnnualLeaves = selectedLeaveAndBreakProfile?.annualLeaveSetting?.allow_annual_leaves;
	// check if selected leaves and breaks profile allow emergency leaves
	const leaveAndBreakProfileHasEmergencyLeaves =
		selectedLeaveAndBreakProfile?.annualLeaveSetting?.allowed_emergency_leaves;
	// check if selected leaves and breaks profile allow sick leaves
	const leaveAndBreakProfileHasSickLeaves = selectedLeaveAndBreakProfile?.sickLeaveSetting?.allowed_sick_leaves;

	// Form Clean Up
	useEffect(() => {
		return () => {
			setFormSubmitting(false);
			dispatch(resetEmployeeFormAction());
		};
	}, []);

	const handleSubmitForm = () => {
		setFormSubmitting(true);

		if (
			!employeeFormValidation.length &&
			!salaryConfigFormValidation.length &&
			(!paymentMethodFormValidation.length || !paymentMethodForm?.has_payment_method)
		) {
			let employeeForm = Store.getState().super.employeeForm;
			let workday_locations = [];

			Constants.Days?.forEach((day) => {
				if (employeeForm?.[`${day}_sign_in_location`]?.length) {
					workday_locations.push({
						workday: day,
						locations_id: employeeForm?.[`${day}_sign_in_location`]?.map((location) => location?.value),
						type: "sign_in",
					});
				}

				if (employeeForm?.[`${day}_sign_out_location`]?.length) {
					workday_locations.push({
						workday: day,
						locations_id: employeeForm?.[`${day}_sign_out_location`]?.map((location) => location?.value),
						type: "sign_out",
					});
				}
			});

			const {
				Friday_sign_in_location,
				Friday_sign_out_location,
				Saturday_sign_in_location,
				Saturday_sign_out_location,
				Sunday_sign_in_location,
				Sunday_sign_out_location,
				Monday_sign_in_location,
				Monday_sign_out_location,
				Tuesday_sign_in_location,
				Tuesday_sign_out_location,
				Wednesday_sign_in_location,
				Wednesday_sign_out_location,
				Thursday_sign_in_location,
				Thursday_sign_out_location,
				annual_leaves,
				sick_leaves,
				emergency_leaves,
				value,
				type,
				optionsName,
				face_path,
				workGroupId,
				workTeamId,
				att_profile_id,
				permissionLeavesBreakSettingId,
				workTeamStartDate,
				starts_at,
				has_credentials,
				email,
				phone,
				user_image,
				attendanceType,
				// work remotely keys start
				can_work_home,
				employee_can_request,
				max_homeDays_per_week,
				flexible_home,
				home_days,
				can_ex_days,
				first_day_of_the_week,
				// work remotely keys end
				attendance_type_configuration_start_date = null,

				allow_half_days,

				// day off exception
				allow_work_on_day_off,
				day_off_exception_id,
				normal_work_timing_id,
				first_half_work_timing_id,
				second_half_work_timing_id,
				apply_compensation,
				weight,
				treat_as_normal,
				allow_permission,
				allow_overtime,
				offices_ids,
				compensation_type,
				payment_factor_id,
				compensation_quantity,
				workplace_setting,
				additional_work_places,
				workplace_setting_signout,
				additional_work_places_signout,
				workplace_setting_identical,

				check_in_form_id,
				check_out_form_id,
				allow_customize_check_ins,
				date_of_birth_year,
				date_of_birth_month,
				date_of_birth_day,
				metres,
				check_in_settings_as_work_timing_sign_in_settings,
				allowMultipleCheckIns,
				check_in_setting_id,
				reuiqre_facial_recognition,
				employee_can_check_in_from,
				check_in_specific_work_laces,
				apply_same_setting_when_checking_out,
				employee_can_check_out_from,
				check_out_specific_work_laces,
				total_income_amount,
				total_tax_amount,

				// holiday exception
				allow_work_on_holiday,
				same_config,
				holiday_exception_id,
				holiday_normal_work_timing_id,
				holiday_first_half_work_timing_id,
				holiday_second_half_work_timing_id,
				holiday_weight,
				holiday_treat_as_normal,
				holiday_workplace_setting,
				holiday_workplace_setting_identical,
				holiday_additional_work_places,
				holiday_workplace_setting_signout,
				holiday_additional_work_places_signout,
				holiday_allow_overtime,
				holiday_allow_permission,
				holiday_payment_factor_id,
				holiday_compensation_type,
				holiday_compensation_quantity,
				holiday_apply_compensation,
				holiday_allow_half_days,

				...restEmployeeForm
			} = employeeForm;

			let dateOfBirth =
				date_of_birth_year || date_of_birth_month || date_of_birth_day
					? `${date_of_birth_year}-${date_of_birth_month}-${date_of_birth_day}`
					: null;

			let salaryConfigDate = moment(employeeForm?.starts_at).format("YYYY-MM-DD");

			const { min_overtime_cutoff, max_overtime_limit, min_work_hours, ...rest } = timeRangeProfileSetting;

			let timeRangeProfileSettingInput = {
				...rest,
				min_overtime_cutoff: HelperFns.hoursToTime(min_overtime_cutoff),
				max_overtime_limit: HelperFns.hoursToTime(max_overtime_limit),
				min_work_hours: HelperFns.hoursToTime(min_work_hours),
			};

			if (props.isFirst) {
				let user_salary_config_input = {
					...normalizeSalaryConfigInput(null, salaryConfigForm, salaryConfigDate, timeRangeProfileSettingInput),
				};

				let userInput = {
					...restEmployeeForm,
					workday_locations,
					country_short_name: restEmployeeForm?.phone_country_code,
					starts_at: moment(starts_at).format("YYYY-MM-DD"),
					has_credentials,
					company_id: userCompanyID,
					email: has_credentials ? email : null,
					phone: has_credentials ? phone : null,
					// max_homeDays_per_week: +max_homeDays_per_week, // work remotely
					probation_period: value === "" || type === "" ? null : { value, type },
					beginning_balance: {
						annual_leaves:
							(attendanceType === Constants.attendanceTypes.OFFICE_BASED && !!attProfileHasAnnualLeaves) ||
							(attendanceType === "shifts" && !!leaveAndBreakProfileHasAnnualLeaves)
								? annual_leaves
								: null,
						emergency_leaves:
							(attendanceType === Constants.attendanceTypes.OFFICE_BASED && !!attProfileHasEmergencyLeave) ||
							(attendanceType === "shifts" && !!leaveAndBreakProfileHasEmergencyLeaves)
								? emergency_leaves
								: "0",
						sick_leaves:
							(attendanceType === "office based" && !!attProfileHasSickLeave) ||
							(attendanceType === "shifts" && !!leaveAndBreakProfileHasSickLeaves)
								? sick_leaves
								: null,
					},
					user_image: face_path ? undefined : user_image,
					attendanceType: {
						type: attendanceType,
						start: moment(starts_at)?.format("YYYY-MM-DD"),
						workRemotelySettings: {
							canWorkRemote: can_work_home ? true : false,
							employee_can_request,
							maxRemoteDaysPerWeek: +max_homeDays_per_week,
							flexibleRemote:
								attendanceType === Constants.attendanceTypes.OFFICE_BASED ? !!flexible_home : !!can_work_home,
							canExchangeDays: attendanceType === Constants.attendanceTypes.OFFICE_BASED ? !!can_ex_days : false,
							remoteDays: attendanceType === Constants.attendanceTypes.OFFICE_BASED ? home_days : [],
						},
						...(attendanceType === Constants.attendanceTypes.SHIFT_BASED
							? {
									weekStartDay: +first_day_of_the_week,
									permissionLeavesBreakSettingId,
									workGroupId,
									workTeamId,
									joiningFrom: workTeamStartDate ? moment(workTeamStartDate).format("YYYY-MM-DD") : null,

									// day off exception
									...serializeUpsertDayOffException({
										allow_work_on_day_off,
										day_off_exception_id,
										normal_work_timing_id,
										first_half_work_timing_id,
										second_half_work_timing_id,
										apply_compensation,
										weight,
										treat_as_normal,
										allow_permission,
										allow_overtime,
										offices_ids,
										compensation_type,
										payment_factor_id,
										compensation_quantity,
										workplace_setting,
										additional_work_places,
										workplace_setting_signout,
										additional_work_places_signout,
										workplace_setting_identical,
									}),

									//check in out settings
									allowMultipleCheckIns: !!allowMultipleCheckIns,
									checkInFormId: check_in_form_id,
									checkOutFormId: check_out_form_id,
									check_in_distance_setting: !!metres ? parseFloat(metres) : 0,
									checkin_setting:
										check_in_settings_as_work_timing_sign_in_settings || !allowMultipleCheckIns
											? null
											: {
													id: check_in_setting_id,
													face_recognition_check_in: !!reuiqre_facial_recognition,
													face_recognition_check_out: !!reuiqre_facial_recognition,

													workplace_setting: employee_can_check_in_from,

													additional_work_places:
														employee_can_check_in_from == "DEFAULT" ? check_in_specific_work_laces : [],

													workplace_setting_identical: apply_same_setting_when_checking_out,

													workplace_setting_signout: apply_same_setting_when_checking_out
														? employee_can_check_in_from
														: employee_can_check_out_from,

													additional_work_places_signout: apply_same_setting_when_checking_out
														? employee_can_check_in_from == "DEFAULT"
															? check_in_specific_work_laces
															: []
														: employee_can_check_out_from == "DEFAULT"
														? check_out_specific_work_laces
														: [],
											  },
									attendanceProfileId: null,

									// holiday exception
									...serializeUpsertHolidayException({
										allow_work_on_day_off,
										allow_work_on_holiday,
										same_config,
										holiday_exception_id,
										holiday_normal_work_timing_id,
										holiday_first_half_work_timing_id,
										holiday_second_half_work_timing_id,
										holiday_weight,
										holiday_treat_as_normal,
										holiday_workplace_setting,
										holiday_workplace_setting_identical,
										holiday_additional_work_places,
										holiday_workplace_setting_signout,
										holiday_additional_work_places_signout,
										holiday_allow_overtime,
										holiday_allow_permission,
										holiday_payment_factor_id,
										holiday_compensation_type,
										holiday_compensation_quantity,
										holiday_apply_compensation,
										holiday_allow_half_days,
									}),
							  }
							: {
									attendanceProfileId: att_profile_id,
									allowMultipleCheckIns: false,
							  }),
					},

					date_of_birth: dateOfBirth,

					previousIncome: {
						total_income_amount: !!total_income_amount ? parseFloat(total_income_amount) : null,
						total_tax_amount: !!total_tax_amount ? parseFloat(total_tax_amount) : null,
					},
				};
				dispatch(
					createNewEmployeeSalaryAction(
						user_salary_config_input,
						userInput,
						Boolean(paymentMethodForm?.payments?.[0]?.payment_method)
							? HelperFns.serializePaymentMethodInput({
									...paymentMethodForm?.payments[0],
									user_id: employeeForm?.id,
									has_payment_method: Boolean(paymentMethodForm?.payments?.[0]?.payment_method),
							  })
							: undefined
					)
				);
			} else {
				dispatch(
					upsertEmployeeAction({
						...restEmployeeForm,
						starts_at: moment(starts_at)?.format("YYYY-MM-DD"),
						has_credentials,
						user_image,
						face_path,
						att_profile_id,
						value,
						type,
						company_id: userCompanyID,
						workday_locations,
						email: has_credentials ? email : null,
						phone: has_credentials ? phone : null,
						force_update_att_profile: true,
						attendanceType: {
							attendanceProfileId: undefined,
							type: "",
							workGroupId: undefined,
							workTeamId: undefined,
							permissionLeavesBreakSettingId: undefined,
							allowMultipleCheckIns: undefined,
							start: undefined,
							weekStartDay: undefined,
							workRemotelySettings: undefined,
						},

						date_of_birth: dateOfBirth,
						userPaymentInput: HelperFns.serializePaymentMethodInput({
							...paymentMethodForm?.payments[0],
							user_id: employeeForm?.id,
							has_payment_method: Boolean(paymentMethodForm?.payments?.[0]?.payment_method),
						}),
						includePaymentMethod: Boolean(paymentMethodForm?.payments?.[0]?.payment_method),
						userPaymentId: paymentMethodForm?.payments?.[0]?.user_payment_id,
						deletePayment: Boolean(
							!Boolean(paymentMethodForm?.payments?.[0]?.payment_method) &&
								paymentMethodForm?.payments?.[0]?.user_payment_id
						),
					})
				);
			}
		}
	};



	return (
		<div className="content employee-form">
			<div className="page-title">{t(id ? "edit employee" : "new employee")}</div>

			{empServerValidation?.["input.user_input.id"] ? (
				<div className="alert alert-danger fit-width mx-5 py-2 my-0 " role="alert">
					{empServerValidation?.["input.user_input.id"] ?? ""}
				</div>
			) : null}

			<PersonalInfoSection FormProps={FormProps} formSubmitting={formSubmitting} />

			<OfficeInfoSection FormProps={FormProps} />

			{!id ? (
				<>
					<WorkScheduleSection
						isEditable={!id}
						FormProps={FormProps}
						attProfileHasAnnualLeaves={attProfileHasAnnualLeaves}
						attProfileHasSickLeave={attProfileHasSickLeave}
						attProfileHasEmergencyLeave={attProfileHasEmergencyLeave}
						leaveAndBreakProfileHasAnnualLeaves={leaveAndBreakProfileHasAnnualLeaves}
						leaveAndBreakProfileHasEmergencyLeaves={leaveAndBreakProfileHasEmergencyLeaves}
						leaveAndBreakProfileHasSickLeaves={leaveAndBreakProfileHasSickLeaves}
					/>
					<WorkRemotelySection
						FormProps={FormProps}
						isEmployeeForm={true}
						selectedAttProfile={employeeFormSelectedProfile}
					/>
				</>
			) : null}

			{Boolean(
				selectedAttProfileState?.activeWorkTiming?.allow_locations_custom_override ||
					(Boolean(+selectedAttProfileState?.activeWorkTiming?.sign_out_locations_custom_override) &&
						!Boolean(+selectedAttProfileState?.activeWorkTiming?.same_policies))
			) ? (
				<div className="boxContainer">
					<h4 className="sub-title-style mb-1">{t("custom offices/work places")}</h4>
					<hr />

					<div className="mt-4 mb-2 row custom-locations-style">
						{selectedAttProfileState?.workdays &&
						Boolean(selectedAttProfileState?.activeWorkTiming?.allow_locations_custom_override) ? (
							<div className="col-12">
								<h5 className="text-primary h5">{t("sign in")}</h5>
								<CustomLocationsSection type="sign_in" formName={formName} />
							</div>
						) : null}

						{selectedAttProfileState?.workdays &&
						Boolean(+selectedAttProfileState?.activeWorkTiming?.sign_out_locations_custom_override) &&
						!Boolean(+selectedAttProfileState?.activeWorkTiming?.same_policies) ? (
							<div className="col-12">
								<h5 className="text-primary h5 mt-4">{t("sign out")}</h5>
								<CustomLocationsSection type="sign_out" formName={formName} />
							</div>
						) : null}
					</div>
				</div>
			) : null}

			{selectedOffice?.country?.id == EgyptId ? <CountryFieldsSection formProps={FormProps} /> : null}

			{/* (Start) Error Message */}
			{requestStatus.errorMsg && formSubmitting && (
				<div className="warnig-msg-style boxContainer">{requestStatus.errorMsg}</div>
			)}
			{/* (End) Error Message */}
			<HasPrivileges reqireMain={[Privilages.ADD_EMPLOYEE_SALARY_CONFIGURATION]} allowBP>
				{props.isFirst && (
					<SalaryConfigForm
						formName={"salaryConfigForm"}
						isFirst={true}
						formSubmitting={formSubmitting}
						formServerValidation={formServerValidation}
						countryId={selectedOffice?.country?.id}
						userId={office_id}
						office={selectedOffice}
					/>
				)}
			</HasPrivileges>

			<HasPrivileges reqireMain={[Privilages.ADD_EMPLOYEE_SALARY_CONFIGURATION]} allowBP>
				<UpdateOrCreateUserPayment
					formName={"paymentMethodForm"}
					isFirst={props.isFirst}
					formSubmitting={formSubmitting}
					formServerValidation={formServerValidation}
					countryId={selectedOffice?.country?.id}
					userId={office_id}
					office={selectedOffice}
				/>
			</HasPrivileges>

			<div className="mt-1 mb-1 text-right">
				{isOpen && (
					<button
						color="danger"
						onClick={() => dispatch(closeEditEmployeeAction())}
						className="btn btn-danger submit_btn_style mr-3"
					>
						{t("Cancel")}
					</button>
				)}
				<button
					disabled={requestStatus.isLoading}
					type="submit"
					onClick={handleSubmitForm}
					className="btn btn-primary submit_btn_style"
				>
					{requestStatus.isLoading ? <Spinner style={{ width: "1rem", height: "1rem", color: "#fff" }} /> : t("save")}
				</button>
			</div>
		</div>
	);
};

export default EmployeeForm;
