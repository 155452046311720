import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import DataTable from "react-data-table-component";
import {
  setDataTableSortingAction,
  showErrorToast
} from "../../../../Store/Actions";
import Pagination from "../../../../Components/Pagination";
import { gql, useMutation, useQuery } from "@apollo/client";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import HasPrivileges from "../../../../Helpers/HOC/HasPrivileges";
import Privilages from "../../../../Constants/Privilages";
import { CopyIconButton, RemoveIconButton, RefreshIconButton, ExportIconButton } from "../../../../Components/IconButtonWithTooltip";
import HelperFns from "../../../../Helpers/HelperFns";

const FoodicsLeadsListQuery = gql`
  query foodicsLeadsQuery($page: Int) {
    foodicsLeads(first: 10, page: $page) {
      data {
        id
        company_name
        contact_name
        email
        phone
        country
        time_zone
        created_at
        hasCrmLead
      }
      paginatorInfo {
        total
        perPage
        lastPage
        lastItem
        firstItem
        currentPage
        hasMorePages
      }
    }
  }
`;

const deleteFoodicsLeadMutation = gql`
mutation deleteFoodicsLead($foodics_lead_id: ID!) {
  deleteFoodicsLead(foodics_lead_id: $foodics_lead_id) {
    status
    message
  }
}
`;

const refreshLeadUsersMutation = gql`
  mutation refreshLeadUsers($foodics_lead_id: ID!) {
    refreshLeadUsers(foodics_lead_id: $foodics_lead_id) {
      status
      message
    }
  }
`;

const convertToCrmLeadMutation = gql`
  mutation convertToCrmLead($foodics_lead_id: ID!) {
    convertToCrmLead(foodics_lead_id: $foodics_lead_id) {
      id
      name
    }
  }
`;

const exportLeadUsersMutation = gql`
  mutation exportFoodicsLeadUsers($foodics_lead_id: ID!) {
    exportFoodicsLeadUsers(foodics_lead_id: $foodics_lead_id) {
      status
      message
      file
    }
  }
`;

const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};


const FoodicsLeads = (props) => {
  const [paginationData, setPaginationData] = useState(paginationInitState);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { loading, error, data, refetch } = useQuery(FoodicsLeadsListQuery, {
    notifyOnNetworkStatusChange: true,
    variables: {
      page: paginationData.currentPage,
    },
    onCompleted: (data) => {
      if (data) {
        setPaginationData(data?.foodicsLeads?.paginatorInfo);
      }
    },
  });

  const columns = [
    {
      name: "Company Name",
      wrap: true,
      selector: "company_name",
      grow: 1,
    },
    {
      name: "contact Name",
      wrap: true,
      selector: "contact_name",
      sortable: true,
      grow: 1,
    },
    {
      name: "email",
      wrap: true,
      selector: "email",
  
      grow: 1,
    },
    {
      name: "phone",
      wrap: true,
      selector: "phone",
      grow: 1,
    },
    {
      name: "country",
      wrap: true,
      selector: "country",
      grow: 1,
    },
    {
      name: "timeZone",
      wrap: true,
      selector: "time_zone",
      grow: 1,
    },
    {
      name: "createdAt",
      wrap: true,
      selector: "created_at",
      grow: 1,
    },
    {
      name: "actions",
      wrap: true,
      selector: "action",
      grow: 0.6,
      cell: (row) => (
        <HasPrivileges
        reqireMain={[Privilages.VIEW_FOODICS_LEADS]}
        allowBP
        avalibleOnExpire={false}
        altExpireChildren={
          <div className="cards_table_actions">
            <RemoveIconButton />
            <CopyIconButton />
            <RefreshIconButton />
            <ExportIconButton />
          </div>
        }
      >
        <div className="cards_table_actions">
          <CopyIconButton label="convert to crm lead"  iconColor={row.hasCrmLead ? "#8997a4" : "#009ce7"} onClick={() => handleConvertToCrmLead(row)}  />
          <RefreshIconButton label="refresh lead users" onClick={() => handleRefreshLeadUsers(row)}/>
          <ExportIconButton label="export lead users" onClick={() => handleExportLeadUsers(row)} />
          <RemoveIconButton onClick={() => handleDeleteFoodicsLead(row)} />
        </div>
      </HasPrivileges>
      ),
      ignoreRowClick: true,
    },
  ];

  const handleRefreshLeadUsers = (row) => {
    refreshLeadUsers({
      variables: {
        foodics_lead_id: row?.id
      }
    });
  };

  const [ refreshLeadUsers, {} ] = useMutation(refreshLeadUsersMutation,{
      onCompleted(res){
        if (res?.refreshLeadUsers?.status === "success") {
          refetch();
        }
      },
      onError(error){
        dispatch(showErrorToast(error?.message));
      }
  })

  const handleConvertToCrmLead = (row) => {
    convertToCrmLead({
      variables: {
        foodics_lead_id: row?.id
      }
    });
  };

  const [ convertToCrmLead, {} ] = useMutation(convertToCrmLeadMutation,{
   
      onCompleted(res){
        console.log(res);
        if (res?.convertToCrmLead) {
          refetch();
        }
      },
      onError(error){
        dispatch(showErrorToast(error?.message));
      }
  })

  const handleExportLeadUsers = (row) => {
    exportLeadUsers({
      variables: {
        foodics_lead_id: row?.id
      }
    });
  };

  const [ exportLeadUsers, {} ] = useMutation(exportLeadUsersMutation,{
      onCompleted(response){
        if (
          response?.exportFoodicsLeadUsers &&
          response?.exportFoodicsLeadUsers?.status === "success"
        ) {
          HelperFns.downloadFile(response?.exportFoodicsLeadUsers?.file);
        } else {
          dispatch(showErrorToast(response?.exportFoodicsLeadUsers?.message));
        }
      },
      onError(error){
        dispatch(showErrorToast(error?.message));
      }
  })

  const handleDeleteFoodicsLead = (row) => {
    swal({
      title: t("are you sure"),
      text: t("defaut_warning_messsage"),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: true,
      className: "swal-warning-style",
      buttons: [t("Cancel"), t("OK")],
    }).then((willDelete) => {
      if (willDelete) {
        deleteLead({
          variables: {
            foodics_lead_id: row?.id
          }
        })
  
      }
    });
  };

  const [ deleteLead, { data: deleteData, loading: deleteLoading, error: deleteError }] = useMutation(deleteFoodicsLeadMutation,{
    onCompleted(res){
      if (res?.deleteFoodicsLead?.status === "success") {
        refetch();
      }
    },
    onError(error){
      dispatch(showErrorToast(error?.message));
    }
  }); 

  const handleSorting = (field, dir) => {
    // console.log("@sorting", field, dir);
  };

  const handleFilter = () => {
    console.log("Filter");
  };

  const handlePaginate = (page = paginationData.currentPage) => {
    setPaginationData((prev) => ({ ...prev, currentPage: page }));
  };

  return (
    <div className="mb-3 mt-2">
      <DataTable
        className="cards_table"
        columns={columns}
        data={data?.foodicsLeads?.data || []}
        noHeader
        persistTableHead
        sortServer
        onSort={handleSorting}
        paginationComponent={() => (
          <Pagination
            customPaginator={paginationData}
            // tableRef={dataTableRef}
            styleWraper=""
            onPaginate={handlePaginate}
            // reducer="super"
          />
        )}
        pagination={true}
        paginationServer={true}
      />
    </div>
  );
};

export default connect(null, {
  setDataTableSortingAction,
})(FoodicsLeads);
