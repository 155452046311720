import gql from "graphql-tag";

/*
  Offices
*/

/*
  Departments
*/

/*
  Positions
*/

/*
  Work Timings
*/
/*
  time ranges profile settings for overtime 
*/
export const UPSERT_TIME_RANGE_PRESET = gql`
	mutation UpsertTimeRangeProfile($input: TimeRangeProfilePresetInput) {
		upsertTimeRangeProfilePreset(input: $input) {
			id
		}
	}
`;

export const DELETE_OVERTIME_GRACE_MINUTES_PRESET = gql`
	mutation deleteOvertimeGraceMinutePreset($id: ID) {
		deleteTimeRangeProfilePreset(id: $id) {
			id
		}
	}
`;

export const DELETE_EMPLOYEE_GRACE_MINUTES_PROFILE = gql`
	mutation deleteEmployeeGraceMinuteProfile($id: ID) {
		deleteGraceMinute(id: $id) {
			id
		}
	}
`;

export const UPSERT_EMPLOYEE_GRACE_MINUTES_PROFILE = gql`
	mutation UpsertEmployeeGraceMinutesProfile($input: EmployeeGraceMinuteInput) {
		upsertGraceMinute(input: $input) {
			id
		}
	}
`;

/*
  Leave & Breaks
*/

/*
  Attendance Profiles
*/

/*
  Holidays
*/

/*
  Documents
*/

/*
  Expense Categories
*/

/*
  Access levels
*/

/*
  Announcements
*/

export const storeAnnouncementMutation = gql`
	mutation storeAnnouncement($type: String, $body: String, $publish: Int, $title: String, $send_to: JSON) {
		store_announcement(body: $body, type: $type, title: $title, publish: $publish, send_to: $send_to) {
			__typename
		}
	}
`;
export const editAnnouncementMutation = gql`
	mutation editAnnouncement($id: ID, $type: String, $body: String, $publish: Int, $title: String, $send_to: JSON) {
		change_announcement(id: $id, body: $body, type: $type, title: $title, publish: $publish, send_to: $send_to) {
			__typename
		}
	}
`;
export const publishAnnouncementMutation = gql`
	mutation publishAnnouncement($id: ID!) {
		publish_announcement(id: $id)
	}
`;
export const removeAnnouncementMutation = gql`
	mutation publishAnnouncement($id: ID!) {
		remove_announcement(id: $id)
	}
`;

// handle manual phone verification mutation
export const manualPhoneVerificationMutation = gql`
  mutation manualPhoneVerification($id: ID!,$password: String) {
    admin_verify_phone_number(id: $id, password: $password) {
      status
      message
    }
  }
`;
