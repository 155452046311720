import { ApolloClient, InMemoryCache, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";

import Store from "../Store";
import {
	attendanceProfileQuery,
	attendanceProfilesQuery,
	allEmployeesQuery,
	editEmployeeFormQuery,
	initEmployeeFormQuery,
	officesQuery,
	departmentsQuery,
	positionsQuery,
	inboundRequestsQuery,
	fetchHistoryListQuery,
	refreshSignInOutQuery,
	attendanceLogsQuery,
	suspensionLogsQuery,
	balanceLogsQuery,
	overtimeLogsQuery,
	attendanceWorkDaysQuery,
	officeHolidaysQuery,
	fetchOfficeQuery,
	attendanceChartEmployeesNameQuery,
	usersClaimsQuery,
	usersBonusQuery,
	usersDeductionsQuery,
	editEmployeePrivilegesQuery,
	payrollReportQuery,
	usersSalaryConfigQuery,
	getSignInInfoQuery,
	getSignOutInfoQuery,
	fetchDeductCalculationQuery,
	myPayrollQuery,
	initSalaryConfigDataQuery,
	userPayslipQuery,
	holidaysQuery,
	monthlyReportQuery,
	yearlyReportQuery,
	fetchExportPayrollOptsQuery,
	allCompaniesQuery,
	companyProfileQuery,
	companyProfileSubscriptionQuery,
	adminUsersQuery,
	adminUsersCompaniesQuery,
	adminUsersEmployeesQuery,
	editAdminUserPrivilegesQuery,
	viewAttRequestQuery,
	workTimingsListQuery,
	exportMonthlyReportQuery,
	exportYearlyReportQuery,
	fetchPresetSalaryQuery,
	emailNotificationsSettingsQuery,
	editFetchWorkTimeQuery,
	pettyCashesQuery,
	employeeCurrentBalanceQuery,
	companySettingsQuery,
	subCategoryQuery,
	expenseTotalBalanceQuery,
	avaliablePettyCashesEmployeesQuery,
	currenciesOfficeQuery,
	pettyCashCurenciesQuery,
	initSuspensionQuery,
	fetchEmployeeDocumentsQuery,
	fetchUpsertDocModalQuery,
	fetchSetupWizardlData,
	exportEmployeeListQuery,
	fetchRequestRulesListQuery,
	fetchRequestRulesOptsQuery,
	fetchSystemPlansListQuery,
	fetchSystemPrivilegesQuery,
	fetchPlanPrivilegesQuery,
	fetchPlanRolesQuery,
	editSystemPlanQuery,
	fetchPlanPageQuery,
	fetchEditRoleQuery,
	fetchOpenPayrollMonths,
	exportExcelEmployeeTemplate,
	UploadEmployeeCsv,
	initDeleteManagerQuery,
	initSuspendManagerQuery,
	fetchReplaceByManagersOptsQuery,
	fetchEditAcceptedOvertimeRequestQuery,
	rolePrivilegesQuery,
	fetchPreviewRolePrivilegesDataQuery,
	fetchEmployeesTurnover,
	fetchTotalExpensesData,
	fetchSubCategoryCurrenciesData,
	requestCommentsQuery,
	recruitmentProcessesQuery,
	intakeFormsQuery,
	jobPostsQuery,
	applicationsQuery,
	initRecruitmentProcessFormQuery,
	initRecruitmentIntakeFormQuery,
	applicantsQuery,
	JobPostFormIntakeFormsAndProcessesQuery,
	applicantProfileQuery,
	jobPostIntakeFormQuery,
	externalJobPostIntakeFormQuery,
	adminHolidaysQuery,
	fetchSuggestedHalfWorkTimingTemplateQuery,
	editAttendnaceLogsWorkTimingsQuery,
	editAttendnaceLogsLocationsQuery,
	checkInOutLocationsQuery,
	fetchAccessLevelsQuery,
	editRolePrivilegesQuery,
	assignmentFormQuery,
	assignmentsListQuery,
	fetchNotificationsHistoryQuery,
	GET_COMPANY_CONTACTS,
} from "../Graphql/query";

import {
	createAttProfileMutation,
	acceptInboundRequestMutation,
	acceptOvertimeRequestMutation,
	rejectInboundRequestMutation,
	updateEmployeeMutation,
	NormalRequestMutation,
	UnpaidRequestMutation,
	PermissionRequestMutation,
	HalfdayRequestMutation,
	EmergencyRequestMutation,
	authUserMutation,
	HomeExchangeRequestMutation,
	HomeFlexRequestMutation,
	cancelRequestMutation,
	createSignInMutation,
	dismissHomeSignInMutation,
	createSignOutMutation,
	updateSuspensionMutation,
	upsertDepartmentMutation,
	deleteSuspensionMutation,
	deleteUserMutation,
	checkPasswordMutation,
	activateUserMutation,
	editHistoryModalMutation,
	adjustSignOutMutation,
	deleteDepartmentMutation,
	deletePositionMutation,
	initBalanceModalMutation,
	updateOfficeMutation,
	deleteOfficeMutation,
	upsertAttendanceProfileMutation,
	deleteAttProfileMutation,
	cloneAttProfileMutation,
	forgotPasswordMutation,
	resetPasswordMutation,
	upsertPositionMutation,
	upsertBonusMutation,
	deleteBonusMutation,
	upsertDeductionMutation,
	deleteDeductionMutation,
	upsertEmployeePrivilegesMutation,
	upsertUserLocaleMutation,
	upsertClaimsRequestMutation,
	upsertEmployeeClaimsRequestMutation,
	claimRequestActionMutation,
	cancelClaimRqeuestMutation,
	deductionsMutation,
	updatePaymentMutation,
	createNewAllowanceMutation,
	createNewDeductibleMutation,
	createNewEmployeeSalaryMutation,
	upsertHolidayMutation,
	deleteHolidayMutation,
	initOfficeMutation,
	exportPayrollMutation,
	deleteCompaniesMutation,
	activiateCompaniesMutation,
	suspendCompaniesMutation,
	upsertCompanyMutation,
	updateBussinessPartnersCompanyMutation,
	deleteBussinessPartnersCompanyMutation,
	upsertCompanySubscriptionMutation,
	deleteCompanySubscriptionMutation,
	updateCompanyAccountManagersMutation,
	upsertAdminUserMutation,
	deleteAdminUserMutation,
	upsertAdminUserPrivilegesMutation,
	editAttRequestMutation,
	upsertWorkTimingsMutation,
	upsertSickLeavesRequestMutation,
	verifyUserPhoneMutation,
	verifyUserEmailMutation,
	SendUserPhoneVerificationMutation,
	fetchActivationAttendanceMutatuon,
	updateEmailNotifcationsSettingsMutation,
	cloneWorkTimeMutation,
	deleteWorkTimeMutation,
	upsertPettyCashBalanceMutation,
	upsertCompanySettingsMutation,
	deleteCompanySettingsMutation,
	exportCompanyExpensesMutation,
	deleteCompanyExpenseMutation,
	deletePettyCashAttachmentFileMutation,
	sendUserEmailVerificationMutation,
	resendCredentailsMutation,
	upsertEmployeeDocumentMutation,
	deleteUserDocumentAttachmentMutation,
	addDepartments,
	addPositions,
	editBussinessPartnerCompanyMutation,
	upsertRequestRulesMutation,
	deleteRequestRulesMutation,
	upsertCustomLeavesRequestMutation,
	upsertSystemPlanMutation,
	upsertRoleMutation,
	upsertRoleTemplateToPlanMutation,
	updatePlanStatusMutation,
	deletePlanMutation,
	deleteRoleMutation,
	deleteRoleTemplateMutation,
	requestCommentMutation,
	deleteRecruitmentProcessMutation,
	updateRecruitmentProcessStatusMutation,
	deleteIntakeFormMutation,
	updateIntakeFormStatusMutation,
	deleteJobPostMutation,
	updateJobPostStatusMutation,
	upsertRecruitmentProcessMutation,
	upsertRecruitmentIntakeMutation,
	updateApplicationStageStatusMutation,
	deleteApplicantMutation,
	upsertJobPostMutation,
	upsertApplicationMutation,
	updateApplicantEmailMutation,
	updateApplicantPhoneMutation,
	deleteApplicantPhoneNumberMutation,
	fillStageFormMutation,
	upsertAdminHolidayMutation,
	deleteAdminHolidayMutation,
	autoSuggestHolidaysMutation,
	acceptSuggsetedHolidayMutation,
	deleteSuggestedHolidayMutation,
	generateDemoMutation,
	deleteSalaryConfigMutation,
	updateLocationsIdsMutation,
	updateCheckInMutation,
	upsertRoleToCompanyMutation,
	deleteCompanyRoleMutation,
	exportEmployeePayslip,
	cloneRecruitmentProcessMutation,
	cloneIntakeFormMutation,
	externalUpsertApplicationMutation,
	AuthSendUserPhoneVerificationMutation,
	AuthSendUserEmailVerificationMutation,
	upsertAssignmentMutation,
	deleteAssignmentMutation,
	checkAllNotificationToReadMutation,
} from "../Graphql/mutation";

import { Config } from "../Config";
import HelperFns, { getLateKeys } from "../Helpers/HelperFns";
import Privilages from "../Constants/Privilages";
import { logoutAction } from "../Store/Actions";
import moment from "moment";

const abortController = new AbortController();

// Instantiate required constructor fields
const cache = new InMemoryCache({ addTypename: false });

export const directionalLink = () => {
	if (!Store.getState().auth?.token && Store.getState().auth?.authForm?.username?.split("@")[1] === "mawared-hr.com") {
		return `${Config.DEMO_URL}/graphql`;
	}
	if (Store.getState().auth?.token && Store.getState().auth?.userProfile?.email?.split("@")[1] === "mawared-hr.com") {
		return `${Config.DEMO_URL}/graphql`;
	}
	return `${Config.API_URL}/graphql`;
};

const mainLink = createUploadLink({
	uri: directionalLink,
	fetchOptions: {
		signal: abortController.signal, // overwrite the default abort signal
	},
	// credentials: "include",
});

const authMiddleware = setContext((_, { headers }) => {
	// get the authentication token from local storage if it exists
	// return the headers to the context so httpLink can read them
	const { token, userProfileRef } = Store.getState().auth;
	return {
		headers: {
			...headers,
			authorization: `Bearer ${token}`,
			version: "2.8",
			...(userProfileRef?.id && { adminUserId: userProfileRef?.id }),
		},
	};
});

const errorLink = new onError((error) => {
	if (error?.networkError?.message == "Network request failed") {
		error.networkError.message = HelperFns.localizeFns(
			"Unable to connect to the internet, please make sure that your internet connection is working properly and try again."
		);
	}
	if (
		error?.response?.errors?.[0]?.extensions?.category === "internal" &&
		error?.graphQLErrors?.[0]?.message === "Internal server error"
	) {
		error.graphQLErrors[0].message = HelperFns.localizeFns(
			"Something went wrong. We are working on it, Please try again later."
		);
	}
	if (error.networkError && error.networkError?.statusCode === "503") {
		setTimeout(() => {
			if (window.location?.href.includes("down")) {
				return;
			}
			window.location.replace("/down");
		}, 0);
		return;
	}
	if (error.networkError && error.networkError?.statusCode === "401") {
		// HelperFns.showToast("error", error.networkError?.result?.errors?.message);
		return;
	}
	if (
		error?.response?.errors[0]?.extensions?.category === "authentication" &&
		// added this condition to skip logging out if i already have another accounts saved
		error?.response?.errors?.[0]?.message == "Unauthenticated."
	) {
		Store.dispatch(logoutAction());
		return;
	}
	if (error?.response?.errors[0]?.extensions?.category === "authorization") {
		// setTimeout(() => {
		//   window.location.replace("/unauthorized");
		// }, 0);
		return;
	}
	if (
		error?.response?.errors[0]?.extensions?.category?.toLowerCase() ===
		"expirationexception"
	) {
		HelperFns.showToast("error", error?.response?.errors[0]?.message);
	}
});

export const client = new ApolloClient({
	// Provide required constructor fields
	cache: cache,
	// link: authLink.concat(link),
	link: from([authMiddleware, errorLink, mainLink]),

	// Provide some optional constructor fields
	name: "attendance-web-client",
	connectToDevTools: false,
	version: "1.0",
	queryDeduplication: true,
	defaultOptions: {
		watchQuery: {
			fetchPolicy: "no-cache",
		},
		query: {
			fetchPolicy: "no-cache",
			errorPolicy: "all",
		},
		mutate: {
			fetchPolicy: "no-cache",
			errorPolicy: "all",
		},
	},
});

export const authUser = ({ user }) => {
	return client.mutate({
		mutation: authUserMutation,
		variables: {
			...user,
		},
	});
};

export const fetchAttProfiles = ({ attendanceProfilesList }) => {
	return client.query({
		query: attendanceProfilesQuery,
		variables: {
			rows: attendanceProfilesList.pagination.perPage,
			page: attendanceProfilesList.pagination.currentPage,
			field: attendanceProfilesList.sorting.key,
			order: attendanceProfilesList.sorting.dir.toUpperCase(),
			filter: attendanceProfilesList.filter,
			only_archive: attendanceProfilesList.only_archive,
		},
	});
};

export const createAttProfile = () => {
	return client.mutate({
		mutation: createAttProfileMutation,
	});
};

export const fetchAttProfile = ({ id }) => {
	return client.query({
		query: attendanceProfileQuery,
		variables: {
			id,
			skipAttProfile: id ? false : true,
		},
	});
};

export const fetchAllEmployees = ({ allEmployeesList }) => {
	let input = {
		name: allEmployeesList.filter,
		status: allEmployeesList.suspendFilter,
		working_status: allEmployeesList.statusFilter,
		from_home: allEmployeesList.from_home,
		on_break: allEmployeesList.on_break,
		department_id: +allEmployeesList.filterByDepartment,
		manager_id: allEmployeesList.filterByManager,
		office_id: allEmployeesList.filterByOffice,
		position_id: allEmployeesList.filterByPosition,
		work_timing_id: allEmployeesList.work_timing_id,
		managed: allEmployeesList.showManagedEmployeesFilter ? true : false,
		directManaged: allEmployeesList?.directManaged,
		on_probation: allEmployeesList.on_probation,
		working_status: allEmployeesList?.statusFilter?.includes("Day Off")
			? [...allEmployeesList?.statusFilter, "Day Off"]
			: allEmployeesList?.statusFilter,
	};
	return client.query({
		query: allEmployeesQuery,
		variables: {
			rows: allEmployeesList.pagination.perPage,
			page: allEmployeesList.pagination.currentPage,
			input,
			listView: allEmployeesList.listView,
		},
	});
};

export const initEmployee = () => {
	return client.query({
		query: initEmployeeFormQuery,
		variables: {
			attendanceProfileDate: moment().format("YYYY-MM-DD"),
		},
	});
};

export const editEmployee = ({ user_id }) => {
	return client.query({
		query: editEmployeeFormQuery,
		variables: { user_id },
	});
};

export const updateEmployee = ({
	name,
	emp_code,
	id,
	active,
	has_credentials,
	email,
	phone,
	starts_at,
	joining_date,
	department_id,
	role_id,
	position_id,
	att_profile_id,
	manager_id,
	company_id,
	copied_managers,
	workday_locations,
	office_id,
	user_image,
	face_path,
	phone_country_code,
	value,
	type,
	force_save,
	force_email,
	force_update_att_profile,
	probation_period,
	attendanceType,
	date_of_birth,
	gender,
	marital_status,
	national_id,
	insurance_number,
	display_roster,
	userPaymentInput,
	includePaymentMethod = false,
	userPaymentId = "",
	deletePayment = false,
}) => {
	let input = {
		name,
		emp_code,
		id,
		active,
		has_credentials,
		email,
		phone,
		starts_at,
		joining_date,
		department_id,
		role_id,
		position_id,
		att_profile_id,
		manager_id,
		company_id,
		copied_managers,
		workday_locations,
		office_id,
		...(!face_path && { user_image }),
		phone_country_code,
		country_short_name: phone_country_code,
		probation_period: probation_period === 0 ? null : { value, type },
		force_save,
		force_email,
		force_update_att_profile,
		attendanceType,
		date_of_birth,
		gender,
		marital_status,
		national_id,
		insurance_number,
		display_roster,
	};

	return client.mutate({
		mutation: updateEmployeeMutation,
		variables: {
			input,
			userPaymentInput,
			includePaymentMethod,
			userPaymentId,
			deletePayment,
		},
	});
};

export const cancelRqeuest = ({ id, end_date, refetchQueries }) => {
	return client.mutate({
		mutation: cancelRequestMutation,
		variables: {
			id,
			end_date,
		},
		refetchQueries,
		awaitRefetchQueries: true,
	});
};

export const fetchInboundRqeuests = ({ inboundRequestsList }) => {
	return client.query({
		query: inboundRequestsQuery,
		variables: {
			rows: inboundRequestsList.pagination.perPage,
			page: inboundRequestsList.pagination.currentPage,
			field: inboundRequestsList.sorting.key,
			directManaged: inboundRequestsList.directManaged,
			order: inboundRequestsList.sorting.dir.toUpperCase(),
			status: !!inboundRequestsList.statusFilter ? inboundRequestsList.statusFilter : null,
			month: !inboundRequestsList.month ? null : inboundRequestsList.month,
			payable_month: !inboundRequestsList.payable_month ? null : inboundRequestsList.payable_month,
			year: !!inboundRequestsList.year ? +inboundRequestsList.year : null,
			emp_id: inboundRequestsList.employees?.length ? inboundRequestsList.employees : null,
			request_type_id: !!inboundRequestsList.type ? inboundRequestsList.type : null,
			route: inboundRequestsList.page,
			office_id: inboundRequestsList.office_id,
		},
	});
};

// fetch employee name for attendance chart
export const fetchAttendanceChartEmployeesName = ({ date, status, office_id = null }) => {
	return client.query({
		query: attendanceChartEmployeesNameQuery,
		variables: {
			input: {
				date,
				status,
				office_id,
			},
		},
	});
};

export const acceptInboundRqeuest = ({ id, refetchQueries }) => {
	return client.mutate({
		mutation: acceptInboundRequestMutation,
		variables: {
			id,
		},
		fetchPolicy: "network-only",
		refetchQueries,
		awaitRefetchQueries: true,
	});
};

export const acceptOvertimeRqeuest = ({ id, interval, created_at, force_confirm, refetchQueries }) => {
	return client.mutate({
		mutation: acceptOvertimeRequestMutation,
		variables: {
			id,
			interval,
			created_at,
			force_confirm,
		},
		refetchQueries,
		awaitRefetchQueries: true,
	});
};

export const rejectInboundRqeuest = ({ id, refetchQueries }) => {
	return client.mutate({
		mutation: rejectInboundRequestMutation,
		variables: {
			id,
		},
		refetchQueries,
		awaitRefetchQueries: true,
	});
};

export const fetchAllOffices = ({ officesList }) => {
	return client.query({
		query: officesQuery,
		variables: {
			rows: officesList.pagination.perPage,
			page: officesList.pagination.currentPage,
			field: officesList.sorting.key,
			order: officesList.sorting.dir.toUpperCase(),
			filter: officesList.filter,
			isWorkplace: officesList.isWorkplace,
		},
	});
};

export const fetchAllDepartments = ({ departmentsList }) => {
	return client.query({
		query: departmentsQuery,
		variables: {
			rows: departmentsList.pagination.perPage,
			page: departmentsList.pagination.currentPage,
			field: departmentsList.sorting.key,
			order: departmentsList.sorting.dir.toUpperCase(),
			filter: departmentsList.filter,
		},
	});
};

export const fetchAllPositions = ({ positionsList }) => {
	return client.query({
		query: positionsQuery,
		variables: {
			rows: positionsList.pagination.perPage,
			page: positionsList.pagination.currentPage,
			field: positionsList.sorting.key,
			order: positionsList.sorting.dir.toUpperCase(),
			filter: positionsList.filter,
		},
	});
};

export const fetchAllHolidays = ({ holidaysList }) => {
	return client.query({
		query: holidaysQuery,
		variables: {
			rows: holidaysList.pagination.perPage,
			page: holidaysList.pagination.currentPage,
			field: holidaysList.sorting.key,
			order: holidaysList.sorting.dir.toUpperCase(),
			filter: holidaysList.filter,
			year: holidaysList?.year ? holidaysList?.year?.toString() : null,
		},
	});
};

export const postNormalRequest = ({
	from,
	to,
	deducted_from_emergency,
	employee_id,
	requestable,
	refetchQueries,
	attachments,
}) => {
	return client.mutate({
		mutation: NormalRequestMutation,
		variables: {
			from,
			to,
			deducted_from_emergency,
			employee_id,
			requestable,
			attachments,
		},
		refetchQueries,
		awaitRefetchQueries: true,
	});
};

export const postUnpaidRequest = ({ from, to, employee_id, requestable, refetchQueries, attachments }) => {
	return client.mutate({
		mutation: UnpaidRequestMutation,
		variables: {
			from,
			to,
			employee_id,
			requestable,
			attachments,
		},
		refetchQueries,
		awaitRefetchQueries: true,
	});
};

export const postEmergencyRequest = ({ from, to, employee_id }) => {
	return client.mutate({
		mutation: EmergencyRequestMutation,
		variables: {
			from,
			to,
			employee_id,
		},
	});
};

export const postHomeExchangeRequest = ({
	homeDay,
	instead,
	employee_id,
	requestableId,
	requestableType,
	refetchQueries,
	attachments,
}) => {
	return client.mutate({
		mutation: HomeExchangeRequestMutation,
		variables: {
			homeDay,
			instead,
			employee_id,
			requestableId,
			requestableType,
			attachments,
		},
		refetchQueries,
		awaitRefetchQueries: true,
	});
};

export const postPermissionRequest = ({
	from,
	to,
	employee_id,
	requestDay,
	requestable,
	refetchQueries,
	attachments,
	work_timing_id,
}) => {
	return client.mutate({
		mutation: PermissionRequestMutation,
		variables: {
			from,
			to,
			employee_id,
			requestDay,
			requestableId: requestable?.id,
			requestableType: requestable?.typename,
			attachments,
			workTimingId: work_timing_id,
		},
		refetchQueries,
		awaitRefetchQueries: true,
	});
};

export const postHalfdayRequest = ({
	deducted_from_emergency,
	date,
	half,
	employee_id,
	requestable,
	refetchQueries,
	attachments,
	work_timing_id,
}) => {
	return client.mutate({
		mutation: HalfdayRequestMutation,
		variables: {
			deducted_from_emergency,
			date,
			half: half?.toLowerCase(),
			employee_id,
			requestableId: requestable?.id,
			requestableType: requestable?.typename,
			workTimingId: work_timing_id,
			attachments,
		},
		refetchQueries,
		awaitRefetchQueries: true,
	});
};

export const postHomeFlexRequest = ({
	homeDay,
	employee_id,
	requestableId,
	requestableType,
	refetchQueries,
	attachments,
}) => {
	return client.mutate({
		mutation: HomeFlexRequestMutation,
		variables: {
			homeDay,
			employee_id,
			requestableId,
			requestableType,
			attachments,
		},
		refetchQueries,
		awaitRefetchQueries: true,
	});
};

export const fetchHistoryList = ({ from, to, status, rows = 20, page = 1 }) => {
	let updatedStatus = status?.includes("Late") ? [...status, ...getLateKeys()] : [...status];
	return client.query({
		query: fetchHistoryListQuery,
		variables: {
			from,
			to,
			status: updatedStatus,
			rows,
			page,
		},
	});
};

export const getSignInInfo = () => {
	return client.query({
		query: getSignInInfoQuery,
	});
};

export const getSignOutInfo = () => {
	return client.query({
		query: getSignOutInfoQuery,
	});
};

export const craeteSignIn = ({ from_home }) => {
	return client.mutate({
		mutation: createSignInMutation,
		variables: {
			from_home,
		},
	});
};

export const dismissHomeSignIn = () => {
	return client.mutate({
		mutation: dismissHomeSignInMutation,
	});
};

export const createSignOut = ({ force_confirm = 0 }) => {
	return client.mutate({
		mutation: createSignOutMutation,
		variables: {
			force_confirm,
		},
	});
};

export const refreshSignInOut = () => {
	return client.query({
		query: refreshSignInOutQuery,
		variables: {
			BussinessPartner: HelperFns.checkPrivileges({
				allowBP: true,
			}),
			viewRequests: HelperFns.checkPrivileges({
				privileges: [Privilages.VIEW_EMPLOYEE_REQUESTS],
				allowBP: true,
			}),
			viewClaims: HelperFns.checkPrivileges({
				privileges: [Privilages.VIEW_EMPLOYEE_CLAIMS],
				allowBP: true,
			}),
		},
	});
};

export const adjustSignOut = ({ employee_id, filter }) => {
	return client.mutate({
		mutation: adjustSignOutMutation,
		variables: {
			sign_in_out_ids: employee_id || null,
			auto_sign_out_all: employee_id ? 0 : 1,
			filtered_emp_id: filter?.employees,
			from: filter?.from,
			to: filter?.to,
			status: filter?.status,
		},
	});
};

export const fetchAttendanceLogs = ({ attendanceLogsList }) => {
	let updatedStatus = attendanceLogsList.status?.includes("Late")
		? [...attendanceLogsList.status, ...getLateKeys()]
		: [...attendanceLogsList.status];
	return client.query({
		query: attendanceLogsQuery,
		variables: {
			rows: attendanceLogsList.pagination.perPage,
			page: attendanceLogsList.pagination.currentPage,
			emp_id: attendanceLogsList.employees,
			office_id: attendanceLogsList.offices,
			department_id: attendanceLogsList.departments,
			work_timing_id: attendanceLogsList.work_timings,
			attendance_configuration_conflict:
				attendanceLogsList.attendance_configuration_conflict,
			status: attendanceLogsList.missing_sign_out
				? [...updatedStatus, "Missing sign out"]
				: attendanceLogsList?.status?.includes("Weekend / Day Off")
					? [
						...updatedStatus?.filter(
							(status) => !status?.includes("Weekend")
						),
						"Weekend",
						"Day Off",
					]
					: updatedStatus,
			from: attendanceLogsList.from,
			to: attendanceLogsList.to,
			empStatus: attendanceLogsList.empStatus,
			isEmpOnly: attendanceLogsList.isEmpOnly,
		},
	});
};

export const fetchSuspensionLogs = ({ suspensionLogsList }) => {
	return client.query({
		query: suspensionLogsQuery,
		variables: {
			rows: suspensionLogsList.pagination.perPage,
			page: suspensionLogsList.pagination.currentPage,
			name: suspensionLogsList.name,
			month: +suspensionLogsList.month,
			year: +suspensionLogsList.year,
		},
	});
};

export const fetchBalanceLogs = ({ balanceLogsList }) => {
	return client.query({
		query: balanceLogsQuery,
		variables: {
			rows: balanceLogsList.pagination.perPage,
			page: balanceLogsList.pagination.currentPage,
			name: !!balanceLogsList.name ? balanceLogsList.name : null,
			month: balanceLogsList.month ? balanceLogsList.month : null,
			year: balanceLogsList.year ? +balanceLogsList.year : null,
			type: !!balanceLogsList.type ? balanceLogsList.type : null,
			operation: balanceLogsList.operation,
			requestsField: balanceLogsList.sorting.key,
			requestsOrder: balanceLogsList.sorting.dir.toUpperCase(),
		},
	});
};

export const fetchOvertimeLogs = ({ overtimeLogsList }) => {
	return client.query({
		query: overtimeLogsQuery,
		variables: {
			rows: overtimeLogsList.pagination.perPage,
			page: overtimeLogsList.pagination.currentPage,
			name: overtimeLogsList.name,
			month: overtimeLogsList.month,
			year: overtimeLogsList.year,
		},
	});
};

export const fetchAllAdminUsers = ({ adminUsersList }) => {
	return client.query({
		query: adminUsersQuery,
		variables: {
			rows: adminUsersList.pagination.perPage,
			page: adminUsersList.pagination.currentPage,
			field: adminUsersList.sorting.key,
			order: adminUsersList.sorting.dir.toUpperCase(),
			filter: adminUsersList.filter,
		},
	});
};

export const fetchAdminUsersCompanies = () => {
	return client.query({
		query: adminUsersCompaniesQuery,
		variables: {
			isSuper: HelperFns.checkPrivileges({
				privileges: [Privilages.SUPER_PRIVILEGE],
				allowBP: false,
			}),
		},
	});
};

export const fetchAdminUsersEmployees = ({ adminUsersModal }) => {
	return client.query({
		query: adminUsersEmployeesQuery,
		variables: {
			selectedCompanyFilter: adminUsersModal.selectedCompanyFilter,
		},
	});
};

export const fetchOfficeHolidays = () => {
	return client.query({
		query: officeHolidaysQuery,
	});
};

export const updateSuspension = ({
	suspended_employee,
	suspend_from,
	suspend_to,
	handleLeaveBalanceAfterSuspensionOption,
	replace_by,
	new_manager_for_replaced_by,
	affected_balance,
	is_blacklisted,
}) => {
	return client.mutate({
		mutation: updateSuspensionMutation,
		variables: {
			suspended_employee,
			suspend_from,
			suspend_to,
			handleLeaveBalanceAfterSuspensionOption,
			replace_by,
			new_manager_for_replaced_by,
			affected_balance,
			is_blacklisted,
		},
	});
};

export const updateDepartment = ({ id, name, manager_id }) => {
	return client.mutate({
		mutation: upsertDepartmentMutation,
		variables: {
			id,
			name,
			manager_id,
		},
	});
};

export const createDepartment = ({ id, name, manager_id }) => {
	return client.mutate({
		mutation: upsertDepartmentMutation,
		variables: {
			id: null,
			name,
			manager_id,
		},
	});
};

export const deleteDepartment = (id) => {
	return client.mutate({
		mutation: deleteDepartmentMutation,
		variables: {
			id,
		},
	});
};

export const updatePosition = ({ id, name }) => {
	return client.mutate({
		mutation: upsertPositionMutation,
		variables: {
			id,
			name,
		},
	});
};

export const upsertHoliday = ({
	id,
	name,
	start_date,
	end_date,
	all_offices,
	office_ids,
	att_profile_ids,
	all_att_profiles,
	suggested_holiday_id,
}) => {
	return client.mutate({
		mutation: upsertHolidayMutation,
		variables: {
			id,
			name,
			start_date,
			end_date,
			all_offices,
			office_ids,
			att_profile_ids,
			all_att_profiles,
			suggested_holiday_id,
		},
	});
};

export const createPosition = ({ name }) => {
	return client.mutate({
		mutation: upsertPositionMutation,
		variables: {
			id: null,
			name,
		},
	});
};

export const fetchOffice = ({ id, includeOffice }) => {
	return client.query({
		query: fetchOfficeQuery,
		variables: {
			id: id ? id : null,
			includeOffice,
		},
	});
};
export const updateOffice = ({ created_at, holiday_profile, user_id, tax_settlement_interval, ...input }) => {
	return client.mutate({
		mutation: updateOfficeMutation,
		variables: {
			input: {
				...input,
				tax_settlement_interval: tax_settlement_interval ? +tax_settlement_interval : undefined,
				holidays: null,
			},
		},
	});
};

export const deletePosition = (id) => {
	return client.mutate({
		mutation: deletePositionMutation,
		variables: {
			id,
		},
	});
};

export const deleteHoliday = (id) => {
	return client.mutate({
		mutation: deleteHolidayMutation,
		variables: {
			id,
		},
	});
};

export const deleteOffice = (id) => {
	return client.mutate({
		mutation: deleteOfficeMutation,
		variables: {
			id,
		},
	});
};

export const deleteSuspension = ({ id, password }) => {
	return client.mutate({
		mutation: deleteSuspensionMutation,
		variables: {
			id,
			password,
		},
	});
};

export const deleteUser = ({ id, password, replace_by, new_manager_for_replaced_by }) => {
	return client.mutate({
		mutation: deleteUserMutation,
		variables: {
			id,
			password,
			replace_by,
			new_manager_for_replaced_by,
		},
	});
};

export const userCheckPassword = ({ password }) => {
	return client.mutate({
		mutation: checkPasswordMutation,
		variables: {
			password,
		},
	});
};

export const deleteAttProfile = (id) => {
	return client.mutate({
		mutation: deleteAttProfileMutation,
		variables: {
			id,
		},
	});
};

export const deleteWorkTime = (id) => {
	return client.mutate({
		mutation: deleteWorkTimeMutation,
		variables: {
			id,
		},
	});
};

export const cloneAttProfile = (id) => {
	return client.mutate({
		mutation: cloneAttProfileMutation,
		variables: {
			id,
		},
	});
};

export const cloneWorkTime = (id) => {
	return client.mutate({
		mutation: cloneWorkTimeMutation,
		variables: {
			id,
		},
	});
};

export const activateUser = ({
	user_id,
	activation_date,
	user_salary_config_input,
	employee_attendance,
	newBalanceData,
	attendanceTypeConfig,
	scheduleBaseDataForActivation,
	force_save,
	force_email,
}) => {
	return client.mutate({
		mutation: activateUserMutation,
		variables: {
			user_id,
			activation_date,
			user_salary_config_input,
			employee_attendance,
			newBalanceData,
			attendanceTypeConfig,
			scheduleBaseDataForActivation,
			force_save,
			force_email,
		},
	});
};

export const editHistoryModal = ({
	id,
	home,
	user_status,
	sign_in_time,
	sign_out_time,
	day_type,
	day_sub_type,
	work_timing_id,
	custom_leave_payment_type,
}) => {
	return client.mutate({
		mutation: editHistoryModalMutation,
		variables: {
			id,
			user_status,
			sign_in_time,
			sign_out_time,
			day_type,
			day_sub_type,
			home: !!home,
			work_timing_id: work_timing_id ? work_timing_id : null,
			custom_leave_payment_type: custom_leave_payment_type ? custom_leave_payment_type : null,
		},
	});
};

export const fetchattendanceWorkDays = (id) => {
	return client.query({
		query: attendanceWorkDaysQuery,
		variables: {
			id,
		},
	});
};

export const upsertAttendanceProfile = ({ normal }) => {
	return client.mutate({
		mutation: upsertAttendanceProfileMutation,
		variables: {
			input: {
				...normal,
			},
		},
	});
};

export const adjustBalanceModal = ({ refetchQueries, data }) => {
	const { type, ...balanceData } = data ?? {};
	return client.mutate({
		mutation: initBalanceModalMutation,
		variables: {
			input: {
				...balanceData,
				month: balanceData?.month?.toString(),
				year: balanceData?.year?.toString(),
			},
		},
		refetchQueries,
		awaitRefetchQueries: true,
	});
};
// (Start) Forgot Password
export const forgotPasswordRequest = ({ email, phone, dialCode }) =>
	client.mutate({
		mutation: forgotPasswordMutation,
		variables: {
			email,
			phone: dialCode === "+20" && phone?.startsWith("0") ? phone.slice(1) : phone,
			dialCode,
		},
	});

// (End) Forgot Password

// (Start) Reset Password
export const updateForgottenPassword = ({ email, token, password, password_confirmation }) =>
	client.mutate({
		mutation: resetPasswordMutation,
		variables: {
			email,
			token,
			password,
			password_confirmation,
		},
		errorPolicy: "none",
	});
// (End) Reset Password

// fetch users claims
export const fetchUsersClaims = ({ claimsList }) => {
	return client.query({
		query: usersClaimsQuery,
		variables: {
			input: {
				user_id: claimsList.user_id,
				status: claimsList.status,
				from: claimsList.from,
				to: claimsList.to,
			},
			rows: claimsList.pagination.perPage,
			page: claimsList.pagination.currentPage,
		},
	});
};

// fetch users Bonus
export const fetchUsersBonus = ({ BonusList }) => {
	return client.query({
		query: usersBonusQuery,
		variables: {
			input: {
				user_id: !!BonusList.user_id ? BonusList.user_id : null,
				status: !!BonusList.status ? BonusList.status : null,
				from: BonusList?.from,
				to: BonusList?.to,
			},
			rows: BonusList.pagination.perPage,
			page: BonusList.pagination.currentPage,
		},
	});
};

// Delete Bonus Mutation
export const deleteBonus = (id) => {
	return client.mutate({
		mutation: deleteBonusMutation,
		variables: {
			id,
		},
	});
};

// fetch users Deductions
export const fetchUsersDeductions = ({ DeductionsList }) => {
	return client.query({
		query: usersDeductionsQuery,
		variables: {
			input: {
				user_id: !!DeductionsList.user_id ? DeductionsList.user_id : null,
				status: !!DeductionsList.status ? DeductionsList.status : null,
				from: DeductionsList?.from,
				to: DeductionsList?.to,
			},
			rows: DeductionsList.pagination.perPage,
			page: DeductionsList.pagination.currentPage,
		},
	});
};

// fetch companies
export const fetchAllCompanies = ({ companiesList }) => {
	return client.query({
		query: allCompaniesQuery,
		variables: {
			name: companiesList.filter,
			company_status: companiesList.status,
			orderByRelation: {
				field: companiesList?.sorting?.key,
				direction: companiesList?.sorting?.dir?.toUpperCase(),
			},
			rows: companiesList.pagination.perPage,
			page: companiesList.pagination.currentPage,
			isSuper: HelperFns.checkPrivileges({
				privileges: [Privilages.SUPER_PRIVILEGE],
				allowBP: false,
			}),
			includePlans: HelperFns.checkPrivileges({
				privileges: [Privilages.SUPER_PRIVILEGE, Privilages.ADD_NEW_SUBSCIPTION, Privilages.EDIT_DELETE_SUBSCIPTION],
				allowBP: false,
			}),
		},
	});
};

// fetch company profile
export const fetchCompanyProfile = ({ id, companyProfileSubscriptionList }) => {
	return client.query({
		query: companyProfileQuery,
		variables: {
			id,
			companySubId: id,
			rows: companyProfileSubscriptionList.pagination.perPage,
			page: companyProfileSubscriptionList.pagination.currentPage,
		},
	});
};

// fetch company profile subscriptions
export const fetchCompanyProfileSubscription = ({ id, companyProfileSubscriptionList }) => {
	return client.query({
		query: companyProfileSubscriptionQuery,
		variables: {
			id,
			rows: companyProfileSubscriptionList.pagination.perPage,
			page: companyProfileSubscriptionList.pagination.currentPage,
		},
	});
};

// Delete companies Mutation
export const deleteCompanies = (id) => {
	return client.mutate({
		mutation: deleteCompaniesMutation,
		variables: {
			id,
		},
	});
};

// mutate activate companies action
export const activateCompanies = ({ company_id, company_status }) => {
	return client.mutate({
		mutation: activiateCompaniesMutation,
		variables: {
			input: {
				company_id,
				company_status,
			},
		},
	});
};

// mutate suspend companies action
export const suspendCompanies = ({ company_id, company_status }) => {
	return client.mutate({
		mutation: suspendCompaniesMutation,
		variables: {
			input: {
				company_id,
				company_status,
			},
		},
	});
};

// Delete Deduction Mutation
export const deleteDeduction = (id) => {
	return client.mutate({
		mutation: deleteDeductionMutation,
		variables: {
			id,
		},
	});
};

// upsert Bonus
export const upsertBonus = ({ data }) => {
	const { apply_on, ...bonusData } = data;
	return client.mutate({
		mutation: upsertBonusMutation,
		variables: {
			input: {
				...bonusData,
			},
		},
	});
};

// upsert Deduction
export const upsertDeduction = ({ data }) => {
	const { apply_on, ...deductionData } = data;

	return client.mutate({
		mutation: upsertDeductionMutation,
		variables: {
			input: {
				...deductionData,
			},
		},
	});
};

export const editEmployeePrivileges = ({ user_id, planId }) => {
	return client.query({
		query: editEmployeePrivilegesQuery,
		variables: {
			user_id,
			planId,
		},
	});
};

export const upsertEmployeePrivileges = ({ user_id, main, all, managed, presetAction }) => {
	return client.mutate({
		mutation: upsertEmployeePrivilegesMutation,
		variables: {
			user_id,
			main,
			all,
			managed,
			presetAction,
		},
	});
};

export const editPayrollDeductions = ({ monthlyId, deductible_id, amount, revert }) => {
	return client.mutate({
		mutation: deductionsMutation,
		variables: {
			monthlyId,
			deductible_id,
			amount,
			revert,
		},
	});
};

export const fetchDeductCalculation = ({ id }) => {
	return client.query({
		query: fetchDeductCalculationQuery,
		variables: {
			id,
		},
	});
};

// fetch payroll Report
export const fetchPayrollReport = ({ payrollList }) => {
	return client.query({
		query: payrollReportQuery,
		variables: {
			office_id: payrollList.office_id,
			input: {
				user_id: payrollList.user_id,
				status: payrollList.status,
				month: payrollList.month,
				year: payrollList.year,
				office_id: payrollList.office_id,
				department_id: payrollList.department_id || null,
				currency_id: payrollList.currency_id,
				config_type: payrollList.show_external ? null : "internal",
				regulation_id: payrollList.regulation_id,
			},
			rows: payrollList.pagination.perPage,
			page: payrollList.pagination.currentPage,
			fetchExtra: Boolean(payrollList.currency_id),
			year: `${payrollList.year}`,
			monthly_amount: payrollList.monthly_amount,
		},
	});
};

export const fetchMyPayroll = () => {
	return client.query({
		query: myPayrollQuery,
	});
};

// fetch Monthly Report
export const fetchMonthlyReport = ({ monthlyReport }) => {
	return client.query({
		query: monthlyReportQuery,
		variables: {
			user_id: monthlyReport.user_id,
			office_id: monthlyReport.office_id,
			department_id: monthlyReport.department_id,
			position_id: monthlyReport.position_id,
			from_month_year: monthlyReport?.from_month_year?.toString(),
			to_month_year: monthlyReport?.to_month_year?.toString(),
			from_date: monthlyReport?.from_date?.toString(),
			to_date: monthlyReport?.to_date?.toString(),
			custom_report: monthlyReport?.customReport,
			column:
				monthlyReport?.orderBy?.column == "total_over_time_hours"
					? "total_over_time_hours_sec"
					: monthlyReport?.orderBy?.column,
			order: monthlyReport?.orderBy?.order,
			rows: monthlyReport.pagination.perPage,
			page: monthlyReport.pagination.currentPage,
		},
	});
};

// fetch Yearly Report
export const fetchYearlyReport = ({ yearlyReport }) => {
  return client.query({
    query: yearlyReportQuery,
    variables: {
      user_id: yearlyReport?.employees,
      office_id: yearlyReport?.offices,
      department_id: yearlyReport?.departments,
      year: yearlyReport?.year,
      column:
        yearlyReport?.orderBy?.column == "total_over_time_hours"
          ? "total_over_time_hours_sec"
          : yearlyReport?.orderBy?.column,
      order: yearlyReport?.orderBy?.order,
      rows: yearlyReport?.pagination.perPage,
      page: yearlyReport?.pagination.currentPage,
    },
  });
};

// Export Yearly Report
export const exportYearlyReport = ({ exportColumns, filterOptions }) => {
	return client.query({
		query: exportYearlyReportQuery,
		variables: {
			input: {
				...exportColumns,
				overtimeColumn: undefined,
				permissionsColumn: undefined,
				breaksColumn: undefined,
				leavesColumn: undefined,
				remaningLeaves: undefined,
				YearlyReportFilterInput: {
					orderBy: [{ column: filterOptions?.column, order: filterOptions?.order }],
					user_id: filterOptions?.user_id,
					office_id: filterOptions?.office_id,
					department_id: filterOptions?.department_id,
					year: filterOptions?.year,
				},
				name: true,
				office: !!exportColumns?.office,
				department: !!exportColumns?.department,
				position: !!exportColumns?.position,
				holidays: !!exportColumns?.holidays,
				weekends: !!exportColumns?.weekends,
				attended_days: !!exportColumns?.attended_days,
				ontime_total: !!exportColumns?.ontime_total,
				ontime_normal: !!exportColumns?.ontime_normal,
				late_normal: !!exportColumns?.late_normal,
				normal_minor_late: !!exportColumns?.normal_minor_late,
				normal_major_late: !!exportColumns?.normal_major_late,
				normal_major_late1: !!exportColumns?.normal_major_late1,
				normal_major_late2: !!exportColumns?.normal_major_late2,
				normal_major_late3: !!exportColumns?.normal_major_late3,
				normal_major_late4: !!exportColumns?.normal_major_late4,
				normal_major_late5: !!exportColumns?.normal_major_late5,
				normal_major_late6: !!exportColumns?.normal_major_late6,
				normal_major_late7: !!exportColumns?.normal_major_late7,
				normal_major_late8: !!exportColumns?.normal_major_late8,
				normal_major_late9: !!exportColumns?.normal_major_late9,
				normal_major_late10: !!exportColumns?.normal_major_late10,
				half_total: !!exportColumns?.half_total,
				ontime_half: !!exportColumns?.ontime_half,
				late_half: !!exportColumns?.late_half,
				half_minor_late: !!exportColumns?.half_minor_late,
				half_major_late: !!exportColumns?.half_major_late,
				half_major_late1: !!exportColumns?.half_major_late1,
				half_major_late2: !!exportColumns?.half_major_late2,
				half_major_late3: !!exportColumns?.half_major_late3,
				half_major_late4: !!exportColumns?.half_major_late4,
				half_major_late5: !!exportColumns?.half_major_late5,
				half_major_late6: !!exportColumns?.half_major_late6,
				half_major_late7: !!exportColumns?.half_major_late7,
				half_major_late8: !!exportColumns?.half_major_late8,
				half_major_late9: !!exportColumns?.half_major_late9,
				half_major_late10: !!exportColumns?.half_major_late10,
				from_home: !!exportColumns?.from_home,
				late_total: !!exportColumns?.late_total,
				late_duration: !!exportColumns?.late_duration,
				absent_total: !!exportColumns?.absent_total,
				absent_normal: !!exportColumns?.absent_normal,
				absent_normal_no_show: !!exportColumns?.absent_normal_no_show,
				absent_normal_early_sign_out: !!exportColumns?.absent_normal_early_sign_out,
				absent_normal_late_sign_in: !!exportColumns?.absent_normal_late_sign_in,
				absent_half: !!exportColumns?.absent_half,
				absent_half_no_show: !!exportColumns?.absent_half_no_show,
				absent_half_early_sign_out: !!exportColumns?.absent_half_early_sign_out,
				absent_half_late_sign_in: !!exportColumns?.absent_half_late_sign_in,
				normal_leaves: !!exportColumns?.normal_leaves,
				emergency_leaves: !!exportColumns?.emergency_leaves,
				unpaid_leaves: !!exportColumns?.unpaid_leaves,
				half_days: !!exportColumns?.half_days,
				remaining_annual_leaves: !!exportColumns?.remaining_annual_leaves,
				remaining_emergency_credit: !!exportColumns?.remaining_emergency_credit,
				total_checkIn_hours_secs: !!exportColumns?.total_checkIn_hours_secs,
				overtime_days: !!exportColumns?.overtime_days,
				accepted_overtime_hours: !!exportColumns?.accepted_overtime_hours,
				permissions: !!exportColumns?.permissions,
				permissions_time: !!exportColumns?.permissions_time,
				breaks: !!exportColumns?.breaks,
				breaks_time: !!exportColumns?.breaks_time,
				expected_hours_secs: !!exportColumns?.expected_hours_secs,
				total_hours_secs: !!exportColumns?.total_hours_secs,
				sick_leaves: !!exportColumns?.sick_leaves,
				total_early_sign_out_time: !!exportColumns?.total_early_sign_out_time,
				total_penalties_as_days: !!exportColumns?.total_penalties_as_days,
				total_penalties_as_fixed_amount: !!exportColumns?.total_penalties_as_fixed_amount,
				total_penalties_as_hours: !!exportColumns?.total_penalties_as_hours,
				emp_code: !!exportColumns?.emp_code,
				total_over_time_hours: !!exportColumns?.total_over_time_hours,
			},
		},
	});
};

// fetch users Salary Config

// upsert Employee claim request
export const upsertEmployeeClaimRequest = ({ data }) => {
	return client.mutate({
		mutation: upsertEmployeeClaimsRequestMutation,
		variables: {
			input: {
				...data,
			},
		},
	});
};

export const updatePayrollPayment = ({ ids, status_id, confirm }) => {
	return client.mutate({
		mutation: updatePaymentMutation,
		variables: {
			ids,
			status_id,
			confirm,
		},
	});
};

// upsert user claim request
export const upsertClaimRequest = ({ allData, refetchQueries }) => {
	const { employee_id = null, amount, reason, id, attachments, requestFrom: incurred_at } = allData;
	return client.mutate({
		mutation: upsertClaimsRequestMutation,
		variables: {
			input: {
				id,
				incurred_at,
				amount,
				reason,
				attachments,
				employee_id,
			},
		},
		refetchQueries,
		awaitRefetchQueries: true,
	});
};

// upsert Sick Leaves request
export const upsertSickLeavesRequest = ({ allData, refetchQueries }) => {
	const { employee_id = null, time_from, time_to, attachments, requestable } = allData;
	return client.mutate({
		mutation: upsertSickLeavesRequestMutation,
		variables: {
			input: {
				timeFrom: time_from,
				timeTo: time_to,
				files: attachments,
				employeeId: employee_id,
				requestable,
			},
		},
		refetchQueries,
		awaitRefetchQueries: true,
	});
};

// upsert Custom Leaves request

export const upsertCustomLeavesRequest = ({ allData, refetchQueries }) => {
	const {
		employee_id = null,
		from,
		to,
		files,
		request_type_id,
		payment_flag,
		include_weekends_and_holidays_in_custom_requests,
		requestable,
	} = allData;
	return client.mutate({
		mutation: upsertCustomLeavesRequestMutation,
		variables: {
			input: {
				includeDayoffAndHoliday: !!include_weekends_and_holidays_in_custom_requests,
				timeFrom: from,
				timeTo: to,
				files,
				requestTypeId: request_type_id,
				paymentFlag: payment_flag,
				employeeId: employee_id,
				requestable,
			},
		},
		refetchQueries,
		awaitRefetchQueries: true,
	});
};

// salary config form
export const createNewAllowance = ({ value }) => {
	return client.mutate({
		mutation: createNewAllowanceMutation,
		variables: {
			value,
		},
	});
};

// mutate claim request action
export const mutateClaimRqeuestAction = ({ id, payroll_at, action_type, include_in_payroll }) => {
	return client.mutate({
		mutation: claimRequestActionMutation,
		variables: {
			input: {
				id,
				payroll_at,
				action_type,
				include_in_payroll: !!!include_in_payroll,
			},
		},
	});
};

export const createNewDeductible = ({ value }) => {
	return client.mutate({
		mutation: createNewDeductibleMutation,
		variables: {
			value,
		},
	});
};

// cancel claim request mutation
export const cancelClaimRqeuest = ({ id }) => {
	return client.mutate({
		mutation: cancelClaimRqeuestMutation,
		variables: {
			id,
		},
	});
};

export const createNewEmployeeSalary = ({ user_input, user_salary_config_input, userPaymentInput }) => {
	return client.mutate({
		mutation: createNewEmployeeSalaryMutation,
		variables: {
			user_salary_config_input: user_salary_config_input,
			user_input: user_input,
			user_payment: userPaymentInput,
		},
	});
};

export const initSalaryConfigInfo = (payload) => {
	return client.query({
		query: initSalaryConfigDataQuery,
		variables: {
			office_id: payload?.office_id,
			countryId: payload?.countryId,
		},
	});
};

// fetch payslip
export const fetchUserPayslip = ({ user_id, month_year_payroll }) => {
	return client.query({
		query: userPayslipQuery,
		variables: {
			input: {
				user_id: user_id,
				month_year: month_year_payroll,
			},
		},
	});
};

// export payslip
export const submitExportPayslip = ({ user_id, month_year }) => {
	return client.mutate({
		mutation: exportEmployeePayslip,
		variables: {
			user_id,
			month_year,
		},
	});
};

// Init Office
export const initOffice = () => {
	return client.mutate({
		mutation: initOfficeMutation,
	});
};

// fetch fetchExportPayrollOpts
export const fetchExportPayrollOpts = () => {
	return client.query({
		query: fetchExportPayrollOptsQuery,
	});
};

// submit payroll
export const submitExportPayroll = (input) => {
	return client.mutate({
		mutation: exportPayrollMutation,
		variables: {
			input,
		},
	});
};

// upsert user Locale
export const upsertUserLocale = ({ user_id, locale }) => {
	return client.mutate({
		mutation: upsertUserLocaleMutation,
		variables: {
			user_id,
			locale,
		},
	});
};

// upsert company
export const upsertCompany = ({
	id,
	company_name,
	primarySAAS,
	secondarySAAS,
	partners_input,
	plan_id,
	payment_amount,
	currency_id,
	start_date,
	end_date,
	employees_limit,
	week_start_day,
	allow_am_access,
	week_day,
	month_start_day,
	year_start_month,
	allow_multiple_offices_start,
	company_status,
	client_at,
	contract_duration,
	contract_amount,
	created_by_agent_id,
	report_status,
	ratio,
	force_save,
}) => {
	return client.mutate({
		mutation: upsertCompanyMutation,
		variables: {
			input: {
				company_input: {
					id,
					name: company_name,
					week_start_day,
					allow_am_access,
					week_day,
					month_start_day: +month_start_day,
					year_start_month: +year_start_month,
					allow_multiple_offices_start,
					report_status,
					ratio,
				},
				primaryId: primarySAAS,
				secondaryIds: secondarySAAS,
				partners_input:
					partners_input?.map((bp) => ({
						...bp,
						force_save,
						country_short_name: bp?.phone_country_code,
					})) ?? [],
				subscription_input: {
					id,
					plan_id,
					payment_amount,
					currency_id,
					start_date,
					end_date,
					employees_limit: parseInt(employees_limit),
					company_status,
					client_at,
					contract_duration,
					contract_amount,
					created_by_agent_id,
				},
			},
		},
	});
};

// update company
export const updateCompany = ({
	id,
	company_name,
	primarySAAS,
	secondarySAAS,
	partners_input,
	week_start_day,
	allow_am_access,
	week_day = undefined,
	month_start_day = undefined,
	year_start_month = undefined,
	allow_multiple_offices_start,
	new_start_year = undefined,
	new_start_of_month = undefined,
	new_start_of_week = undefined,
	change_action = undefined,
	prorate = undefined,
	report_status,
	ratio,
	force_save,
}) => {
	return client.mutate({
		mutation: upsertCompanyMutation,
		variables: {
			input: {
				company_input: {
					id,
					name: company_name,
					week_start_day,
					allow_am_access,
					week_day,
					month_start_day: +month_start_day,
					year_start_month: +year_start_month,
					allow_multiple_offices_start,
					new_start_year,
					new_start_of_month,
					new_start_of_week,
					change_action,
					prorate,
					report_status,
					ratio,
				},
				primaryId: primarySAAS,
				secondaryIds: secondarySAAS,
				partners_input:
					partners_input?.map((bp) => ({
						...bp,
						force_save,
						country_short_name: bp?.phone_country_code,
					})) ?? [],
			},
		},
	});
};

// update company name
export const updateCompanyName = ({ id, company_name }) => {
	return client.mutate({
		mutation: upsertCompanyMutation,
		variables: {
			input: {
				company_input: {
					id,
					name: company_name,
				},
			},
		},
	});
};

// update company account managers
export const updateCompanyAccountManagers = ({ id, primarySAAS, secondarySAAS }) => {
	return client.mutate({
		mutation: updateCompanyAccountManagersMutation,
		variables: {
			input: {
				company_id: id,
				primaryId: primarySAAS,
				secondaryIds: secondarySAAS,
			},
		},
	});
};

// update company bussiness partners
export const updateCompanyBussPartners = ({ partners_input }) => {
	return client.mutate({
		mutation: updateBussinessPartnersCompanyMutation,
		variables: {
			input: partners_input,
		},
	});
};

// edit company bussiness partner
export const editCompanyBussPartner = ({
	id,
	company_id,
	name,
	email,
	phone,
	phone_country_code,
	force_save,
}) => {
	return client.mutate({
		mutation: editBussinessPartnerCompanyMutation,
		variables: {
			input: {
				id,
				company_id,
				name,
				email,
				phone,
				phone_country_code,
				country_short_name: phone_country_code,
				force_save,
			},
		},
	});
};

// Delete company bussiness partners
export const deleteCompanyBussPartners = (user_id) => {
	return client.mutate({
		mutation: deleteBussinessPartnersCompanyMutation,
		variables: {
			input: {
				user_id,
			},
		},
	});
};

// upsert company subscription
export const upsertCompanySubscription = ({
	id,
	company_id,
	plan_id,
	payment_amount,
	currency_id,
	start_date,
	end_date,
	employees_limit,
	company_status,
	client_at,
	contract_duration,
	contract_amount,
	created_by_agent_id,
}) => {
	return client.mutate({
		mutation: upsertCompanySubscriptionMutation,
		variables: {
			input: {
				id,
				company_id,
				plan_id,
				payment_amount,
				currency_id,
				start_date,
				end_date,
				employees_limit: parseInt(employees_limit),
				company_status,
				client_at,
				contract_duration,
				contract_amount,
				created_by_agent_id,
			},
		},
	});
};

// to delete company subscription
export const deleteCompanySubscription = (id) => {
	return client.mutate({
		mutation: deleteCompanySubscriptionMutation,
		variables: {
			id,
		},
	});
};

// upsert admin user
export const createAdminUser = ({ user_id, role_id, company_ids }) => {
	return client.mutate({
		mutation: upsertAdminUserMutation,
		variables: {
			user_id,
			role_id,
			company_ids,
		},
	});
};

// delete admin user
export const deleteAdminUser = ({
	saas_id,
	assign_primary_to,
	assign_secondary_to,
}) => {
	return client.mutate({
		mutation: deleteAdminUserMutation,
		variables: {
			saas_id,
			assign_primary_to,
			assign_secondary_to,
		},
	});
};

// handle admin user privilages
export const editAdminUserPrivileges = ({ user_id }) => {
	return client.query({
		query: editAdminUserPrivilegesQuery,
		variables: {
			user_id,
		},
	});
};

// Edit Attendance Request Modal
export const editAttRequestModal = ({ id, emp_id, home, sign_in_time, sign_out_time, date }) => {
	return client.mutate({
		mutation: editAttRequestMutation,
		variables: {
			id,
			emp_id,
			home,
			sign_in_time,
			sign_out_time,
			date,
		},
	});
};
// Export Monthly Report
export const exportMonthlyReport = ({ exportColumns, filterOptions }) => {
	return client.query({
		query: exportMonthlyReportQuery,
		variables: {
			input: {
				...exportColumns,
				leavesColumn: undefined,
				breaksColumn: undefined,
				overtimeColumn: undefined,
				permissionsColumn: undefined,
				ReportFilterInput: {
					orderBy: [{ column: filterOptions?.column, order: filterOptions?.order }],
					user_id: filterOptions?.user_id,
					office_id: filterOptions?.office_id,
					department_id: filterOptions?.department_id,
					from_month_year: filterOptions?.from_month_year?.toString(),
					to_month_year: filterOptions?.to_month_year?.toString(),
					from_date: filterOptions?.from_date?.toString(),
					to_date: filterOptions?.to_date?.toString(),
					custom_report: filterOptions?.custom_report,
					position_id: filterOptions?.position_id,
				},
				name: true,
				office: !!exportColumns?.office,
				department: !!exportColumns?.department,
				position: !!exportColumns?.position,
				holidays: !!exportColumns?.holidays,
				weekends: !!exportColumns?.weekends,
				attended_days: !!exportColumns?.attended_days,
				ontime_total: !!exportColumns?.ontime_total,
				ontime_normal: !!exportColumns?.ontime_normal,
				late_normal: !!exportColumns?.late_normal,
				normal_minor_late: !!exportColumns?.normal_minor_late,
				normal_major_late: !!exportColumns?.normal_major_late,
				normal_major_late1: !!exportColumns?.normal_major_late1,
				normal_major_late2: !!exportColumns?.normal_major_late2,
				normal_major_late3: !!exportColumns?.normal_major_late3,
				normal_major_late4: !!exportColumns?.normal_major_late4,
				normal_major_late5: !!exportColumns?.normal_major_late5,
				normal_major_late6: !!exportColumns?.normal_major_late6,
				normal_major_late7: !!exportColumns?.normal_major_late7,
				normal_major_late8: !!exportColumns?.normal_major_late8,
				normal_major_late9: !!exportColumns?.normal_major_late9,
				normal_major_late10: !!exportColumns?.normal_major_late10,
				half_total: !!exportColumns?.half_total,
				ontime_half: !!exportColumns?.ontime_half,
				late_half: !!exportColumns?.late_half,
				half_minor_late: !!exportColumns?.half_minor_late,
				half_major_late: !!exportColumns?.half_major_late,
				half_major_late1: !!exportColumns?.half_major_late1,
				half_major_late2: !!exportColumns?.half_major_late2,
				half_major_late3: !!exportColumns?.half_major_late3,
				half_major_late4: !!exportColumns?.half_major_late4,
				half_major_late5: !!exportColumns?.half_major_late5,
				half_major_late6: !!exportColumns?.half_major_late6,
				half_major_late7: !!exportColumns?.half_major_late7,
				half_major_late8: !!exportColumns?.half_major_late8,
				half_major_late9: !!exportColumns?.half_major_late9,
				half_major_late10: !!exportColumns?.half_major_late10,
				from_home: !!exportColumns?.from_home,
				late_total: !!exportColumns?.late_total,
				late_duration: !!exportColumns?.late_duration,
				absent_total: !!exportColumns?.absent_total,
				absent_normal: !!exportColumns?.absent_normal,
				absent_normal_no_show: !!exportColumns?.absent_normal_no_show,
				absent_normal_early_sign_out: !!exportColumns?.absent_normal_early_sign_out,
				absent_normal_late_sign_in: !!exportColumns?.absent_normal_late_sign_in,
				absent_half: !!exportColumns?.absent_half,
				absent_half_no_show: !!exportColumns?.absent_half_no_show,
				absent_half_early_sign_out: !!exportColumns?.absent_half_early_sign_out,
				absent_half_late_sign_in: !!exportColumns?.absent_half_late_sign_in,
				normal_leaves: !!exportColumns?.normal_leaves,
				emergency_leaves: !!exportColumns?.emergency_leaves,
				unpaid_leaves: !!exportColumns?.unpaid_leaves,
				half_days: !!exportColumns?.half_days,
				total_checkIn_hours_secs: !!exportColumns?.total_checkIn_hours_secs,
				overtime_days: !!exportColumns?.overtime_days,
				accepted_overtime_hours: !!exportColumns?.accepted_overtime_hours,
				permissions: !!exportColumns?.permissions,
				permissions_time: !!exportColumns?.permissions_time,
				breaks: !!exportColumns?.breaks,
				breaks_time: !!exportColumns?.breaks_time,
				expected_hours_secs: !!exportColumns?.expected_hours_secs,
				total_hours_secs: !!exportColumns?.total_hours_secs,
				sick_leaves: !!exportColumns?.sick_leaves,
				total_early_sign_out_time: !!exportColumns?.total_early_sign_out_time,
				total_penalties_as_days: !!exportColumns?.total_penalties_as_days,
				total_penalties_as_fixed_amount: !!exportColumns?.total_penalties_as_fixed_amount,
				total_penalties_as_hours: !!exportColumns?.total_penalties_as_hours,
				emp_code: !!exportColumns?.emp_code,
				total_over_time_hours: !!exportColumns?.total_over_time_hours,
			},
		},
	});
};

export const fetchWorkTimings = ({ workTimingsList }) => {
	return client.query({
		query: workTimingsListQuery,
		variables: {
			rows: workTimingsList.pagination.perPage,
			page: workTimingsList.pagination.currentPage,
			filter: workTimingsList.filter,
			work_timing_profile_type: workTimingsList.work_timing_profile_type,
			only_archive: workTimingsList.only_archive,
		},
	});
};

export const editFetchWorkTime = ({ id }) => {
	return client.query({
		query: editFetchWorkTimeQuery,
		variables: {
			id,
		},
	});
};

export const upsertWorkTimings = ({ workTimingForm }) => {
	return client.mutate({
		mutation: upsertWorkTimingsMutation,
		variables: {
			workTimingForm,
		},
	});
};

// verify userphone

export const verifyUserPhone = ({ phone_token }) => {
	return client.mutate({
		mutation: verifyUserPhoneMutation,
		variables: {
			phone_token,
		},
	});
};

// verfiy email
export const verifyEmailAddress = ({ email_token }) => {
	return client.mutate({
		mutation: verifyUserEmailMutation,
		variables: {
			email_token,
		},
	});
};

// fetch Activation Attendance
export const fetchActivationAttendance = ({ user_id, activation_date, attendanceProfileId }) => {
	return client.mutate({
		mutation: fetchActivationAttendanceMutatuon,
		variables: {
			user_id: +user_id,
			activation_date,
			attendanceProfileId,
		},
	});
};

// fetch edit accpted overtiem request data
export const fetchEditAcceptedOvertimeRequest = (request_id) => {
	return client.query({
		query: fetchEditAcceptedOvertimeRequestQuery,
		variables: {
			request_id,
		},
	});
};

export const SendUserPhoneVerification = ({ user_id, phone, force_send, send_via }) => {
	const variables = { user_id, phone, force_send, send_via };

	if (user_id && !phone) {
		return client.mutate({
			mutation: AuthSendUserPhoneVerificationMutation,
			variables,
		});
	}

	if (!user_id && phone) {
		return client.mutate({
			mutation: SendUserPhoneVerificationMutation,
			variables,
		});
	}

	return;
};

export const sendUserEmailVerification = ({ user_id, force_send, email }) => {
	const variables = { user_id, email, force_send };

	if (user_id && !email) {
		return client.mutate({
			mutation: AuthSendUserEmailVerificationMutation,
			variables,
		});
	}

	if (!user_id && email) {
		return client.mutate({
			mutation: sendUserEmailVerificationMutation,
			variables,
		});
	}

	return;
};

export const upsertAdminUserPrivileges = ({
	user_id,
	main,
	all,
	managed,
	presetAction,
}) => {
	client.mutate({
		mutation: upsertAdminUserPrivilegesMutation,
		variables: {
			user_id,
			main,
			all,
			managed,
			presetAction,
		},
	});
};

// handle fetch email notification settings
export const fetchEmailNotificationsSettings = () => {
	return client.query({
		query: emailNotificationsSettingsQuery,
	});
};

// update Email notifications settings
export const updateEmailNotifcationsSettings = ({ id, type, status }) => {
	return client.mutate({
		mutation: updateEmailNotifcationsSettingsMutation,
		variables: {
			input: {
				id,
				type,
				status,
			},
		},
	});
};

// View Attendance Request Modal
export const viewAttRequestModal = ({ emp_id, date, type }) => {
	return client.query({
		query: viewAttRequestQuery,
		variables: {
			emp_id,
			date,
			type,
		},
	});
};

// fetch petty cash list
export const fetchPettyCashList = ({ pettyCashList }) => {
	return client.query({
		query: pettyCashesQuery,
		variables: {
			avaliablePettyCashFilterInputType: "petty_cash_list",
			input: {
				emp_id: pettyCashList.emp_id,
				currency_id: pettyCashList.currency_id,
				from: pettyCashList.from ?? "",
				to: pettyCashList.to ?? "",
				type: pettyCashList.type,
			},
			rows: pettyCashList.pagination.perPage,
			page: pettyCashList.pagination.currentPage,
			isUserProfile: false,
			isPettyCashListInFinancesTab: true,
			usersByRoleInput: { status: ["Active"], page_flag: "petty_cash_list" },
		},
	});
};

// fetch company expense list
export const fetchCompanyExpenseList = ({ companyExpensesList }) => {
	return client.query({
		query: pettyCashesQuery,
		variables: {
			avaliablePettyCashFilterInputType: "expense",
			input: {
				emp_id: companyExpensesList.emp_id,
				currency_id: companyExpensesList.currency_id,
				category_id: companyExpensesList.category_id,
				sub_category_id: companyExpensesList.category_id === "" ? "" : companyExpensesList.sub_category_id,
				office_id: companyExpensesList.office_id,
				from: companyExpensesList?.from,
				to: companyExpensesList?.to,
				type: companyExpensesList.type,
			},
			rows: companyExpensesList.pagination.perPage,
			page: companyExpensesList.pagination.currentPage,
			isUserProfile: false,
			isPettyCashListInFinancesTab: false,
		},
	});
};

// fetch user petty cash list
export const fetchUserPettyCashList = ({ officeId, id, userPettyCashList }) => {
	return client.query({
		query: pettyCashesQuery,
		variables: {
			officeId,
			input: {
				emp_id: id,
				currency_id: userPettyCashList.currency_id,
				from: userPettyCashList.from ?? "",
				to: userPettyCashList.to ?? "",
				type: userPettyCashList.type,
			},
			rows: userPettyCashList.pagination.perPage,
			page: userPettyCashList.pagination.currentPage,
			isUserProfile: true,
			isPettyCashListInFinancesTab: false,
		},
	});
};

export const fetchSubCategory = ({ companyExpensesList }) => {
	return client.query({
		query: subCategoryQuery,
		variables: {
			input: {
				category_id: companyExpensesList.category_id,
			},
		},
	});
};

export const fetchExpenseTotalBalance = ({ companyExpensesList }) => {
	return client.query({
		query: expenseTotalBalanceQuery,
		variables: {
			input: {
				emp_id: companyExpensesList.emp_id,
				currency_id: companyExpensesList.currency_id,
				category_id: companyExpensesList.category_id,
				sub_category_id: companyExpensesList.sub_category_id,
				office_id: companyExpensesList.office_id,
				from: companyExpensesList?.from,
				to: companyExpensesList?.to,
				type: companyExpensesList.type,
			},
		},
	});
};

// fetch company settings list
export const fetchCompanySettings = ({ companySettingsList }) => {
	return client.query({
		query: companySettingsQuery,
		variables: {
			rows: companySettingsList.pagination.perPage,
			page: companySettingsList.pagination.currentPage,
		},
	});
};

// To fetch employee current balance in modify petty cash modal form
export const fetchEmployeeCurrentBalance = ({ modifyPettyCashBalanceModal }) => {
	return client.query({
		query: employeeCurrentBalanceQuery,
		variables: {
			emp_id: modifyPettyCashBalanceModal.emp_id,
		},
	});
};

// To fetch user profile current balance
export const fetchUserProfileCurrentBalance = ({ emp_id }) => {
	return client.query({
		query: employeeCurrentBalanceQuery,
		variables: {
			emp_id: emp_id,
		},
	});
};

// To fetch user profile curencies
export const fetchUserProfilePettyCashCurrencies = ({ emp_id, type }) => {
	return client.query({
		query: pettyCashCurenciesQuery,
		variables: {
			emp_id: emp_id,
			type: type,
		},
	});
};

// upsert petty cash mutation
export const upsertPettyCashBalance = ({ allData }) => {
	const { id, emp_id, currency_id, type, amount, date, attachments } = allData;
	return client.mutate({
		mutation: upsertPettyCashBalanceMutation,
		variables: {
			input: {
				id,
				emp_id,
				currency_id,
				type,
				amount,
				date,
				files: attachments,
			},
		},
	});
};

// upsert company expenses mutation
export const upsertCompanyExpense = ({ allData }) => {
	const {
		id,
		emp_id,
		currency_id,
		amount,
		date,
		category_id,
		sub_category_id,
		details,
		attachments,
		office_id,
		on_behalf_of,
	} = allData;
	return client.mutate({
		mutation: upsertPettyCashBalanceMutation,
		variables: {
			input: {
				id,
				emp_id,
				currency_id,
				amount,
				type: on_behalf_of ? "expense" : "office_expense",
				date,
				category_id,
				sub_category_id,
				details: details ? details : "",
				files: attachments,
				office_id,
				on_behalf_of: !!on_behalf_of,
			},
		},
	});
};

// upsert company settings mutation
export const upsertCompanySettings = ({ id, name, subCategory }) => {
	return client.mutate({
		mutation: upsertCompanySettingsMutation,
		variables: {
			input: {
				id,
				name,
				subCategory,
			},
		},
	});
};

// delete company settings
export const deleteCompanySettings = (id) => {
	return client.mutate({
		mutation: deleteCompanySettingsMutation,
		variables: {
			id,
		},
	});
};

// export company expenses
export const exportCompanyExpenses = ({
	emp_id,
	currency_id,
	category_id,
	sub_category_id,
	office_id,
	from,
	to,
}) => {
	return client.mutate({
		mutation: exportCompanyExpensesMutation,
		variables: {
			input: {
				emp_id,
				currency_id,
				category_id,
				sub_category_id,
				office_id,	
				from,
				to,
				type: ["expense", "office_expense"],
			},
		},
	});
};

// fetch expense form sub catgories options
export const fetchExpenseFormSubCatgories = ({ companyExpensesModal }) => {
	return client.query({
		query: subCategoryQuery,
		variables: {
			input: {
				category_id: companyExpensesModal.category_id,
			},
		},
	});
};

// fetch expense form currency options
export const fetchExpenseFormCurrency = ({ companyExpensesModal }) => {
	return client.query({
		query: currenciesOfficeQuery,
		variables: {
			office_id: companyExpensesModal.office_id,
		},
	});
};

// fetch expense form employees options
export const fetchExpenseFormEmployees = ({ companyExpensesModal }) => {
	return client.query({
		query: avaliablePettyCashesEmployeesQuery,
		variables: {
			input: {
				currency_id: companyExpensesModal.currency_id,
				office_id: companyExpensesModal.office_id,
				type: "add_expense",
			},
		},
	});
};

// delete company expense
export const deleteCompanyExpense = (id) => {
	return client.mutate({
		mutation: deleteCompanyExpenseMutation,
		variables: {
			id,
		},
	});
};

// delete petty cash attachment file
export const deletePettyCashAttachmentFile = ({ file_id, pettyCashId }) => {
	return client.mutate({
		mutation: deletePettyCashAttachmentFileMutation,
		variables: {
			input: {
				file_id,
				pettyCashId,
			},
		},
	});
};

// fetch employee petty cash currency in modify balance
export const fetchEmployeesPettyCashCurenciess = ({ office_id }) => {
	return client.query({
		query: currenciesOfficeQuery,
		variables: {
			office_id: office_id,
		},
	});
};

// resend credentials for user
export const resendCredentails = ({ user_id }) => {
	return client.mutate({
		mutation: resendCredentailsMutation,
		variables: {
			user_id,
		},
	});
};

// employee documents
export const fetchEmployeeDocuments = ({ id }) => {
	return client.query({
		query: fetchEmployeeDocumentsQuery,
		variables: {
			id,
		},
	});
};

export const fetchUpsertDocModal = () => {
	return client.query({
		query: fetchUpsertDocModalQuery,
	});
};

export const addEmployeeDocument = ({ documnet }) => {
	return client.mutate({
		mutation: upsertEmployeeDocumentMutation,
		variables: {
			documnet,
		},
	});
};

export const deleteUserDocumentAttachment = ({ file_id, user_document_id }) => {
	return client.mutate({
		mutation: deleteUserDocumentAttachmentMutation,
		variables: {
			file_id,
			user_document_id,
		},
	});
};

// export employee list
export const exportEmployeeList = ({ exportColumns, filterOptions }) => {
	return client.query({
		query: exportEmployeeListQuery,
		variables: {
			input: {
				...exportColumns,
				leaves: undefined,
				UserFilterInput: {
					orderBy: [
						{ column: filterOptions?.column, order: filterOptions?.order },
					],
					name: filterOptions?.name,
					status: filterOptions?.status,
					working_status: filterOptions?.working_status,
					from_home: filterOptions?.from_home,
					on_break: filterOptions?.on_break,
					department_id: filterOptions?.department_id,
					manager_id: filterOptions?.manager_id,
					position_id: filterOptions?.position_id,
					office_id: filterOptions?.office_id,
					work_timing_id: filterOptions?.work_timing_id,
				},
				is_managed_filter: !!filterOptions?.is_managed_filter,
				name: true,
				phone: !!exportColumns?.phone,
				email: !!exportColumns?.email,
				office: !!exportColumns?.office,
				position: !!exportColumns?.position,
				department: !!exportColumns?.department,
				attendance_profile: !!exportColumns?.attendance_profile,
				managers: !!exportColumns?.managers,
				direct_manager: !!exportColumns?.direct_manager,
				copied_managers: !!exportColumns?.copied_managers,
				joining_date: !!exportColumns?.joining_date,
				normal_leaves: !!exportColumns?.normal_leaves,
				emergency_leaves: !!exportColumns?.emergency_leaves,
				sick_leaves: !!exportColumns?.sick_leaves,
				permissions: !!exportColumns?.permissions,
				on_probation: !!exportColumns?.on_probation,
				emp_code: !!exportColumns?.emp_code,
				gender: !!exportColumns?.gender,
			},
		},
	});
};

export const createDepartments = (DepartmentsInput) => {
	return client.mutate({
		mutation: addDepartments,
		variables: {
			DepartmentsInput,
		},
	});
};
export const createPositions = (PositionsInput) => {
	return client.mutate({
		mutation: addPositions,
		variables: {
			PositionsInput,
		},
	});
};

// init suspension
export const initSuspenseUser = ({ suspended_employee, suspend_from }) => {
	return client.query({
		query: initSuspensionQuery,
		variables: {
			suspended_employee,
			suspend_from,
		},
	});
};

export const fetchRequestRules = ({ requestRulesList }) => {
	return client.query({
		query: fetchRequestRulesListQuery,
		variables: {
			rows: requestRulesList.pagination?.perPage,
			page: requestRulesList.pagination?.currentPage,
			input: {
				department_id: requestRulesList?.department_id,
				employee_id: requestRulesList?.employee_id,
				request_type_id: requestRulesList?.request_type_id,
				status_id: requestRulesList?.status_id,
			},
		},
	});
};

export const fetchRequestRulesOpts = () => {
	return client.query({
		query: fetchRequestRulesOptsQuery,
	});
};

export const upsertRequestRules = (requestRule) => {
	return client.mutate({
		mutation: upsertRequestRulesMutation,
		variables: {
			input: {
				...requestRule,
			},
		},
	});
};

export const deleteRequestRules = ({ id }) => {
	return client.mutate({
		mutation: deleteRequestRulesMutation,
		variables: {
			id,
		},
	});
};
export const fetchOpenPayrollMonthsQuery = ({ year, office_id }) => {
	return client.query({
		query: fetchOpenPayrollMonths,
		variables: {
			year,
			office_id,
			viewManagedOnly: HelperFns.checkPrivileges({
				privileges: [Privilages.VIEW_OPEN_PAYROLL_MONTHS],
				scope: "managed",
			}),
		},
	});
};
export const ExportEmployeeImportTemplate = (company_id) => {
	return client.query({
		query: exportExcelEmployeeTemplate,
		variables: {
			company_id,
		},
	});
};
export const UploadEmployeeBulkCsvQuery = ({ file, company_id, send_verification_msg }) => {
	return client.query({
		query: UploadEmployeeCsv,
		variables: {
			file,
			company_id,
			send_verification_msg,
		},
	});
};

export const fetchSystemPlansList = ({ systemPlansList }) => {
	return client.query({
		query: fetchSystemPlansListQuery,
		variables: {
			rows: systemPlansList.pagination?.perPage,
			page: systemPlansList.pagination?.currentPage,
			input: {
				...(systemPlansList?.type && { type: systemPlansList?.type }),
				name: systemPlansList?.name,
				page_flag: "list",
			},
		},
	});
};

// fetch init delete manager data
export const initDeleteManager = (id) => {
	return client.query({
		query: initDeleteManagerQuery,
		variables: {
			id,
		},
	});
};

export const fetchSystemPrivileges = () => {
	return client.query({
		query: fetchSystemPrivilegesQuery,
	});
};

export const fetchPlanPrivileges = ({ planId }) => {
	return client.query({
		query: fetchPlanPrivilegesQuery,
		variables: { planId },
	});
};

export const fetchPlanRoles = ({ planId }) => {
	return client.query({
		query: fetchPlanRolesQuery,
		variables: { planId },
	});
};

export const editSystemPlan = ({ planId }) => {
	return client.query({
		query: editSystemPlanQuery,
		variables: { planId },
	});
};

export const upsertSystemPlan = ({ plan }) => {
	return client.mutate({
		mutation: upsertSystemPlanMutation,
		variables: {
			plan,
		},
	});
};

// fetch init suspend manager data
export const initSuspendManager = (id) => {
	return client.query({
		query: initSuspendManagerQuery,
		variables: {
			id,
		},
	});
};

export const upsertRole = ({ role }) => {
	const { isNewPlan, ...newRole } = role;
	return client.mutate({
		mutation: upsertRoleMutation,
		variables: {
			newRole,
		},
	});
};

export const upsertRoleTemplateToPlan = ({ role }) => {
	const { isNewPlan, ...newRole } = role;
	return client.mutate({
		mutation: upsertRoleTemplateToPlanMutation,
		variables: {
			newRole,
		},
	});
};

export const fetchPlanPage = ({ planId }) => {
	return client.query({
		query: fetchPlanPageQuery,
		variables: { planId },
	});
};

export const fetchEditRole = ({ planId, roleId }) => {
	return client.query({
		query: fetchEditRoleQuery,
		variables: { planId, roleId },
	});
};

export const updatePlanStatus = ({ planId, status }) => {
	return client.mutate({
		mutation: updatePlanStatusMutation,
		variables: {
			planId,
			status,
		},
	});
};

export const deletePlan = ({ planId }) => {
	return client.mutate({
		mutation: deletePlanMutation,
		variables: {
			planId,
		},
	});
};

export const deleteRole = ({ role_id }) => {
	return client.mutate({
		mutation: deleteRoleMutation,
		variables: {
			role_id,
		},
	});
};

export const deleteRoleTemplate = ({ role_id }) => {
	return client.mutate({
		mutation: deleteRoleTemplateMutation,
		variables: {
			role_id,
		},
	});
};

// fetch replace by managers options
export const fetchReplaceByManagersOpts = () => {
	return client.query({
		query: fetchReplaceByManagersOptsQuery,
	});
};

export const fetchSetupWizardEssentialData = () => {
	return client.query({
		query: fetchSetupWizardlData,
	});
};

export const fetchEmployeesTurnoverQuery = ({ office_id, department_id }) => {
	return client.query({
		query: fetchEmployeesTurnover,
		variables: {
			office_id,
			department_id,
		},
	});
};

export const fetchTotalExpenses = ({ office_id, currency_id, from, to }) => {
	return client.query({
		query: fetchTotalExpensesData,
		variables: {
			office_id,
			currency_id,
			from,
			to,
		},
	});
};

export const fetchSubExpenses = ({ office_id, currency_id, from, to, category_id }) => {
	return client.query({
		query: fetchSubCategoryCurrenciesData,
		variables: {
			office_id,
			currency_id,
			from,
			to,
			category_id,
		},
	});
};

// fetch rolePreset Privileges
export const fetchRolePresetPrivileges = ({ roleId }) => {
	return client.query({
		query: rolePrivilegesQuery,
		variables: {
			roleId,
		},
	});
};

// fetch role privilages preview modal data
export const fetchPreviewRolePrivilegesData = ({ planId, roleId }) => {
	return client.query({
		query: fetchPreviewRolePrivilegesDataQuery,
		variables: {
			planId,
			roleId,
		},
	});
};

// to fetch request comments
export const fetchRequestCommentsModalData = ({ requestId }) => {
	return client.query({
		query: requestCommentsQuery,
		variables: {
			requestId,
		},
	});
};

// upsert request comment mutation
export const upsertRequestComments = ({ request_id, comment, refetchQueries }) => {
	return client.mutate({
		mutation: requestCommentMutation,
		variables: {
			input: {
				id: null,
				request_id,
				comment,
			},
		},
		refetchQueries,
		awaitRefetchQueries: true,
	});
};

// to fetch recruitment process list
export const fetchRecruitmentProcessesList = ({ recruitmentProcessesList }) => {
	return client.query({
		query: recruitmentProcessesQuery,
		variables: {
			rows: recruitmentProcessesList?.pagination?.perPage,
			page: recruitmentProcessesList?.pagination?.currentPage,
			input: {
				name: recruitmentProcessesList?.name,
				status: recruitmentProcessesList?.status,
			},
		},
	});
};

// to delete recruitment process
export const deleteRecruitmentProcess = (id) => {
	return client.mutate({
		mutation: deleteRecruitmentProcessMutation,
		variables: {
			id,
		},
	});
};

// to clone recruitment process
export const cloneRecruitmentProcess = (id) => {
	return client.mutate({
		mutation: cloneRecruitmentProcessMutation,
		variables: {
			id,
		},
	});
};

// fetch half day work timing template data
export const fetchSuggestedHalfWorkTimingTemplate = ({ id, which_half }) => {
	return client.query({
		query: fetchSuggestedHalfWorkTimingTemplateQuery,
		variables: {
			id,
			which_half,
		},
	});
};

// to update recruitment process status
export const updateRecruitmentProcessStatus = (id) => {
	return client.mutate({
		mutation: updateRecruitmentProcessStatusMutation,
		variables: {
			id,
		},
	});
};

// to fetch intake forms list
export const fetchIntakeFormsList = ({ intakeFormsList }) => {
	return client.query({
		query: intakeFormsQuery,
		variables: {
			rows: intakeFormsList?.pagination?.perPage,
			page: intakeFormsList?.pagination?.currentPage,
			input: {
				name: intakeFormsList?.name,
				status: intakeFormsList?.status,
			},
		},
	});
};

// to delete intake forms
export const deleteIntakeForm = (id) => {
	return client.mutate({
		mutation: deleteIntakeFormMutation,
		variables: {
			id,
		},
	});
};

// to clone intake forms
export const cloneIntakeForm = (id) => {
	return client.mutate({
		mutation: cloneIntakeFormMutation,
		variables: {
			id,
		},
	});
};

// to update intake forms status
export const updateIntakeFormStatus = (id) => {
	return client.mutate({
		mutation: updateIntakeFormStatusMutation,
		variables: {
			id,
		},
	});
};

// to fetch job posts list
export const fetchJobPostsList = ({ jobPostsList }) => {
	return client.query({
		query: jobPostsQuery,
		variables: {
			rows: jobPostsList?.pagination?.perPage,
			page: jobPostsList?.pagination?.currentPage,
			input: {
				name: jobPostsList?.name,
				status: jobPostsList?.status,
			},
		},
	});
};

// to delete job posts
export const deleteJobPost = (id) => {
	return client.mutate({
		mutation: deleteJobPostMutation,
		variables: {
			id,
		},
	});
};

// to update job posts status
export const updateJobPostStatus = (id) => {
	return client.mutate({
		mutation: updateJobPostStatusMutation,
		variables: {
			id,
		},
	});
};

// fetch applications list
export const fetchApplicationsList = ({ job_post_id, applicationsList }) => {
	return client.query({
		query: applicationsQuery,
		variables: {
			job_post_id,
			rows: applicationsList.pagination.perPage,
			page: applicationsList.pagination.currentPage,
			input: {
				name: applicationsList?.name,
				status: applicationsList?.status,
				stage_id: applicationsList?.stage_id ? applicationsList?.stage_id : null,
			},
		},
	});
};

// fetch admin holidays list
export const fetchAdminHolidaysList = ({ adminHolidaysList }) => {
	return client.query({
		query: adminHolidaysQuery,
		variables: {
			rows: adminHolidaysList?.pagination.perPage,
			page: adminHolidaysList?.pagination.currentPage,
			input: {
				name: adminHolidaysList?.name,
				country_id: adminHolidaysList?.country_id ? adminHolidaysList?.country_id : null,
				year: adminHolidaysList?.year ? adminHolidaysList?.year?.toString() : null,
			},
		},
	});
};

// upsert upsertRecruitmentProcess mutation

export const upsertRecruitmentProcess = ({ data }) => {
	return client.mutate({
		mutation: upsertRecruitmentProcessMutation,
		variables: {
			input: {
				...data,
			},
		},
	});
};

// upsert admin holidays
export const upsertAdminHoliday = (data) => {
	return client.mutate({
		mutation: upsertAdminHolidayMutation,
		variables: {
			input: {
				...data,
			},
		},
	});
};

// to update application stage status
export const updateApplicationStageStatus = ({ stage_id, applicant_job_post_id }) => {
	return client.mutate({
		mutation: updateApplicationStageStatusMutation,
		variables: {
			stage_id,
			applicant_job_post_id,
		},
	});
};

// Delete admin hoildays Mutation
export const deleteAdminHolidays = (holiday_id) => {
	return client.mutate({
		mutation: deleteAdminHolidayMutation,
		variables: {
			holiday_id,
		},
	});
};

// to auto suggest holidays
export const autoSuggestHolidays = (id) => {
	return client.mutate({
		mutation: autoSuggestHolidaysMutation,
		variables: {
			id,
		},
	});
};

// to accept suggested holiday
export const acceptSuggsetedHoliday = (suggested_holiday_id) => {
	return client.mutate({
		mutation: acceptSuggsetedHolidayMutation,
		variables: {
			suggested_holiday_id,
		},
	});
};

// Delete suggested hoildays Mutation
export const deleteSuggesetedHolidays = (holiday_id) => {
	return client.mutate({
		mutation: deleteSuggestedHolidayMutation,
		variables: {
			holiday_id,
		},
	});
};

// generate demo data
export const generateDemo = (id) => {
	return client.mutate({
		mutation: generateDemoMutation,
	});
};

// to delete salary config
export const deleteSalaryConfig = (salary_config_id) => {
	return client.mutate({
		mutation: deleteSalaryConfigMutation,
		variables: {
			salary_config_id,
		},
	});
};

// to fetch work timing options in edit attendnace modal
export const fetchEditAttendnaceLogsWorkTimings = (work_timing_profile_type) => {
	return client.query({
		query: editAttendnaceLogsWorkTimingsQuery,
		variables: {
			work_timing_profile_type:
				work_timing_profile_type == "1st_half" || work_timing_profile_type == "2nd_half"
					? "half"
					: work_timing_profile_type?.toLowerCase(),
		},
	});
};

// to update check ins
export const updateCheckIn = ({
  check_in_id,
  check_in_date_time,
  check_in_lng,
  check_in_lat,
  check_in_space_id,
  check_in_description,
  validate_against_check_in_location,
  check_out_date_time,
  check_out_lng,
  check_out_lat,
  check_out_space_id,
  check_out_description,
  validate_against_check_out_location,
}) => {
  return client.mutate({
    mutation: updateCheckInMutation,
    variables: {
      input: {
        check_in_id,
        check_in_date_time,
        check_in_lng,
        check_in_lat,
        check_in_space_id,
        check_in_description,
        validate_against_check_in_location,
        check_out_date_time,
        check_out_lng,
        check_out_lat,
        check_out_space_id,
        check_out_description,
        validate_against_check_out_location,
      },
    },
  });
};

// fetch applicants list
export const fetchApplicantsList = ({ applicantsList }) => {
	return client.query({
		query: applicantsQuery,
		variables: {
			job_post_id: applicantsList?.job_post_id ? applicantsList?.job_post_id : null,
			rows: applicantsList.pagination.perPage,
			page: applicantsList.pagination.currentPage,
			input: {
				name: applicantsList?.name,
				status: applicantsList?.status,
				stage_id: applicantsList?.stage_id ? applicantsList?.stage_id : null,
			},
		},
	});
};

// to fetch locations options in edit attendnace modal
export const fetchEditAttendnaceLogsLocations = (user_id) => {
	return client.query({
		query: editAttendnaceLogsLocationsQuery,
		variables: {
			user_id,
		},
	});
};

export const upsertRecruitmentIntake = ({ data }) => {
	return client.mutate({
		mutation: upsertRecruitmentIntakeMutation,
		variables: {
			input: {
				...data,
			},
		},
	});
};

// to delete applicants
export const deleteApplicant = (id) => {
	return client.mutate({
		mutation: deleteApplicantMutation,
		variables: {
			id,
		},
	});
};

// upsert job post mutation
export const upsertJobPost = ({
	id,
	title,
	description,
	allow_multiple_apply,
	limit_of_recurring_applications,
	custom_intakeForm_and_process,
	process_id,
	intake_form_id,
	stages_map,
}) => {
	return client.mutate({
		mutation: upsertJobPostMutation,
		variables: {
			input: {
				id,
				title,
				description,
				allow_multiple_apply: !!allow_multiple_apply,
				limit_of_recurring_applications: +limit_of_recurring_applications,
				custom_intakeForm_and_process: !!custom_intakeForm_and_process,
				process_id,
				intake_form_id,
				stages_map,
			},
		},
	});
};
// update locations ids in edit attendnace log
export const updateLocationsIds = ({ sign_in_id, new_available_locations_ids }) => {
	return client.mutate({
		mutation: updateLocationsIdsMutation,
		variables: {
			sign_in_id,
			new_available_locations_ids,
		},
	});
};

// upsert application mutation
export const upsertApplication = ({ payload, external }) => {
	if (external) {
		return client.mutate({
			mutation: externalUpsertApplicationMutation,
			variables: {
				input: {
					...payload,
				},
			},
		});
	}
	return client.mutate({
		mutation: upsertApplicationMutation,
		variables: {
			input: {
				...payload,
			},
		},
	});
};

// fetch validation types
export const initRecruitmentProcessForm = (id) => {
	return client.query({
		query: initRecruitmentProcessFormQuery,
		variables: {
			id,
			newRecruitmentProcess: !!!id,
		},
	});
};

export const initRecruitmentIntakeForm = (id) => {
	return client.query({
		query: initRecruitmentIntakeFormQuery,
		variables: {
			id,
			newRecruitmentIntake: !!!id,
		},
	});
};

// to fetch job post process and intake forms options
export const fetchJobPostFormIntakeFormsAndProcesses = ({ jobPostId = null }) => {
	return client.query({
		query: JobPostFormIntakeFormsAndProcessesQuery,
		variables: { jobPostId },
	});
};

// to fetch applicant profile data
export const fetchApplicantProfile = ({ id }) => {
	return client.query({
		query: applicantProfileQuery,
		variables: {
			id,
		},
	});
};

// update applicant email mutation
export const updateApplicantEmail = ({ applicant_id, email }) => {
	return client.mutate({
		mutation: updateApplicantEmailMutation,
		variables: {
			input: {
				applicant_id,
				email,
			},
		},
	});
};

// update applicant phone mutation
export const updateApplicantPhone = ({ id, applicant_id, phone_number }) => {
	return client.mutate({
		mutation: updateApplicantPhoneMutation,
		variables: {
			input: {
				id,
				applicant_id,
				phone_number,
			},
		},
	});
};

// to delete applicant phone number
export const deleteApplicantPhoneNumber = ({ id, applicant_id, phone_number }) => {
	return client.mutate({
		mutation: deleteApplicantPhoneNumberMutation,
		variables: {
			id,
			applicant_id,
			phone_number,
		},
	});
};

// update

export const fetchJobPostIntakeForm = ({ jobPostId, external }) => {
	if (external) {
		return client.query({
			query: externalJobPostIntakeFormQuery,
			variables: {
				jobPostId,
			},
		});
	}
	return client.query({
		query: jobPostIntakeFormQuery,
		variables: {
			jobPostId,
		},
	});
};

export const fillStageForm = (data) => {
	console.log(data);
	return client.mutate({
		mutation: fillStageFormMutation,
		variables: {
			input: data,
		},
	});
};
// to fetch check in and out locations options
export const fetchCheckInOutLocations = () => {
	return client.query({
		query: checkInOutLocationsQuery,
	});
};

export const fetchAccessLevels = () => {
	return client.query({
		query: fetchAccessLevelsQuery,
	});
};

export const editRolePrivileges = ({ planId, roleId }) => {
	return client.query({
		query: editRolePrivilegesQuery,
		variables: { planId, roleId },
	});
};

export const upsertRoleToCompany = ({ id, name, privileges, PrivilegesWithScopeAll, PrivilegesWithScopeManaged, limit_access }) => {
	return client.mutate({
		mutation: upsertRoleToCompanyMutation,
		variables: {
			input: {
				id,
				name,
				privileges,
				PrivilegesWithScopeAll,
				PrivilegesWithScopeManaged,
				limit_access,
			},
		},
	});
};

export const deleteCompanyRole = (role_id) => {
	return client.mutate({
		mutation: deleteCompanyRoleMutation,
		variables: {
			role_id,
		},
	});
};

export const fetchAssignmentForm = ({ includeInputs, includeAssignment, assignmentId }) => {
	return client.query({
		query: assignmentFormQuery,
		variables: { includeInputs, includeAssignment, assignmentId },
	});
};

export const upsertAssignment = (input) => {
	return client.mutate({
		mutation: upsertAssignmentMutation,
		variables: {
			input,
		},
	});
};

export const deleteAssignment = ({ id, force_delete = false }) => {
	return client.mutate({
		mutation: deleteAssignmentMutation,
		variables: {
			id,
			force_delete,
		},
	});
};

export const fetchAssignments = (input) => {
	return client.query({
		query: assignmentsListQuery,
		variables: {
			input,
		},
	});
};

//fetch managed and watched employees
export const fetchNotificationHistory = (payload) => {
	return client.query({
		query: fetchNotificationsHistoryQuery,
		variables: {
			notify_for_id: payload?.employee_id,
			from: payload?.from?.replaceAll("/", "-") ?? null,
			to: payload?.to?.replaceAll("/", "-") ?? null,
			event: payload?.event,
			page: payload?.page ? payload.page : 1,
			fetchEmployeesFilterOptions: payload?.fetchEmployeesFilterOptions ?? false,
		},
	});
};
export const checkAllNotificationToRead = () => {
	return client.mutate({
		mutation: checkAllNotificationToReadMutation,
	});
};
// fetch payslip
export const fetchCompanyContacts = ({ id }) => {
	return client.query({
		query: GET_COMPANY_CONTACTS,
		variables: {
			id,
		},
	});
};
