import React, { useState } from "react";
import DataTable from "react-data-table-component";
import CanViewEmployeeProfile from "../../Helpers/HOC/CanViewEmployeeProfile";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Privilages from "../../Constants/Privilages";
import ShowMoreText from "react-show-more-text";
import {
  AcceptIconButton,
  TimesIconButton,
} from "../../Components/IconButtonWithTooltip";
import HasPrivileges from "../../Helpers/HOC/HasPrivileges";
import { Spinner } from "reactstrap";
import Loader from "../../Components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RadioboxForm } from "form-builder";
import Select from "react-select";
import AcceptLoanRequestModal from "./AcceptLoanRequestModal";
import { useMutation, useQuery } from "@apollo/client";
import { fetchLoanRequestsQuery } from "../../Graphql/query";
import { rejectLoanRequestMutation } from "../../Graphql/mutation";
import HelperFns, { showToast } from "../../Helpers/HelperFns";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import {
  startLoanRequestLoader,
  stopLoanRequestLoader,
} from "../../Store/Actions";
import Pagination from "../../Components/Pagination";

const paginationInitState = {
  total: 20,
  perPage: 20,
  lastPage: 1,
  lastItem: 20,
  firstItem: 1,
  currentPage: 1,
  hasMorePages: false,
};

const LoanRequests = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState(paginationInitState);
  const [filters, setFilters] = useState({
    status: 2,
    user_id: null,
  });
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);

  const requstsLoader = useSelector((state) => state.super.requstsLoader);
  const authUserId = useSelector((state) => state.auth.userProfile.id);

  const { data, loading, error, refetch } = useQuery(fetchLoanRequestsQuery, {
    variables: {
      filters,
      perPage: 20,
      page: 1,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (res) => {
      setPagination({...res?.loanRequests?.paginatorInfo})
    },
    onError: (err) => {
      showToast(
        "error",
        err?.graphQLErrors[0]?.extensions?.reason ||
          err?.graphQLErrors[0]?.message ||
          err?.message
      );
    },
  });

  const handleAcceptLoan = (row) => {
    setIsAcceptModalOpen({ isOpen: true, req: row });
  };

  const [rejectLoanRequest, { loading: rejectLoanRequestLoading }] =
    useMutation(rejectLoanRequestMutation, {
      onCompleted: (res, m) => {
        dispatch(stopLoanRequestLoader(m.variables.id));
        if (res?.reject_loan_request?.id) {
          showToast(res?.reject_loan_request?.status, t("done"));
          refetch({
            filters,
            perPage: 20,
            page: 1,
          });
          return;
        }
        showToast("error", res?.reject_loan_request?.message);
      },
      onError: (err, m) => {
        dispatch(stopLoanRequestLoader(m.variables.id));
        showToast(
          "error",
          err?.graphQLErrors[0]?.extensions?.reason || err.message
        );
      },
    });

  const handleRejectRequest = (row) => {
    swal({
      title: t("are you sure"),
      text: t(""),
      icon: "warning",
      className: "swal-warning-style",
      dangerMode: true,
      buttons: true,
      className: "swal-warning-style",
      buttons: [t("Cancel"), t("confirm")],
    }).then((confirm) => {
      if (confirm) {
        dispatch(startLoanRequestLoader(row.id));
        rejectLoanRequest({
          variables: {
            id: row?.id,
          },
        });
      }
    });
  };
  const handleSelect = (opt) => {
    setFilters((prev) => {
      return { ...prev, user_id: opt?.id };
    });
  };

  const handleRadioChange = (_, val) => {
    setFilters((prevState) => {
      return { ...prevState, status: val };
    });
  };

  const onPaginate = (page = pagination.currentPage) => {
    refetch({ 
      filters,
      perPage: 20,
      page: page, 
    });
  };

  const columns = [
    {
      name: t("employee"),
      wrap: true,
      selector: "name",
      grow: 0.5,
      cell: (row) => (
        <CanViewEmployeeProfile
          allowBP
          directManger={row?.employee?.user?.manager?.id}
          copiedManagers={row?.employee?.user?.copied_managers?.map(
            (cp) => cp?.id
          )}
          altChildren={<div className="col pl-0">{row?.employee?.name}</div>}
        >
          <div className="col pl-0">
            <Link
              className="employee-name"
              to={`/employees/employee-profile/${row?.employee?.id}`}
            >
              {row?.employee?.name}
            </Link>
          </div>
        </CanViewEmployeeProfile>
      ),
    },
    {
      name: t("loan name"),
      wrap: true,
      selector: "loan_name",
      grow: 0.5,
      cell: (row) => <>{row?.name}</>,
    },
    {
      name: t("amount"),
      selector: "amount",
      grow: 0.5,
      wrap: true,
      cell: (row) => <>{row?.amount}</>,
    },
    {
      name: t("notes"),
      wrap: true,
      grow: 1,
      selector: "layer_notes",
      ignoreRowClick: true,
      cell: (row) => (
        <ShowMoreText
          lines={1}
          more="Show more"
          less="Show less"
          expanded={false}
          width={250}
        >
          <div className="py-2">
            {(row?.layerApproval ?? [])?.map((layer) => (
              <p className="mb-1">
                {layer?.acceptor
                  ? layer?.isApproved
                    ? t("layer-level-approval", {
                        level: layer?.level,
                        suffix: HelperFns.getOrderSuffix(layer?.level),
                        acceptor: layer?.acceptor?.name,
                      })
                    : t("layer-level-rejection", {
                        level: layer?.level,
                        suffix: HelperFns.getOrderSuffix(layer?.level),
                        acceptor: layer?.acceptor?.name,
                      })
                  : t("layer-level-pending", {
                      level: layer?.level,
                      suffix: HelperFns.getOrderSuffix(layer?.level),
                    })}
              </p>
            ))}
          </div>
        </ShowMoreText>
      ),
    },
    {
      name: "",
      wrap: true,
      center: true,
      selector: "action",
      grow: 0.75,
      minWidth: "150px",
      cell: (row) => {
        if (row?.progress_indicator)
          return (
            <b className="d-flex gap-5">
              <FontAwesomeIcon icon="fa-solid fa-spinner" />{" "}
              {t("in progress...")}
            </b>
          );
        switch (row?.status?.toLowerCase()) {
          case "2": // pending
            // request has no layers or it has layers and auth is one of pending acceptors
            if (
              HelperFns.checkPrivileges({
                privileges: [],
                allowBP: true,
              }) ||
              (HelperFns.checkPrivileges({
                privileges: [Privilages.ACCEPT_REJECT_LOAN_REQUEST],
                allowBP: true,
              }) &&
                (row.pendingAcceptors?.length === 0 ||
                  row.pendingAcceptors?.find((a) => a == authUserId)))
            ) {
              return (
                <HasPrivileges
                  reqireMain={[Privilages.ACCEPT_REJECT_LOAN_REQUEST]}
                  allowBP
                  avalibleOnExpire={false}
                  altExpireChildren={
                    <div className="cards_table_actions">
                      <AcceptIconButton />
                      <TimesIconButton />
                    </div>
                  }
                >
                  <div className="cards_table_actions">
                    {requstsLoader?.includes(row?.id) ? (
                      <Spinner />
                    ) : (
                      <>
                        <AcceptIconButton
                          onClick={() => handleAcceptLoan(row)}
                        />
                        <TimesIconButton
                          label="reject"
                          onClick={() => handleRejectRequest(row)}
                        />
                      </>
                    )}
                  </div>
                </HasPrivileges>
              );
            }

          case "1": // accepted
            return (
              <HasPrivileges
                reqireMain={[Privilages.ACCEPT_REJECT_LOAN_REQUEST]}
                allowBP
                avalibleOnExpire={false}
                altExpireChildren={<div className="cards_table_actions"></div>}
              >
                <div className="cards_table_actions">
                  {requstsLoader?.includes(row?.id) ? <Spinner /> : null}
                </div>
              </HasPrivileges>
            );

          case "5": // rejected
            return (
              <HasPrivileges
                reqireMain={[Privilages.VIEW_LOAN_REQUEST]}
                allowBP
                avalibleOnExpire={false}
                altExpireChildren={<></>}
              ></HasPrivileges>
            );
          default:
            break;
        }
      },
      ignoreRowClick: true,
    },
  ];

  return (
    <div>
      <div className="d-flex text-nowrap gap-5 align-items-center w-100 p-3 pt-4 ">
        <RadioboxForm
          formName={""}
          containerStyle="mb-0"
          optionsContainerStyle=""
          name="status_id"
          optionInputStyle
          interceptChange={handleRadioChange}
          value={filters?.status}
          options={[
            {
              label: "pending",
              value: 2,
            },
            {
              label: "accepted",
              value: 1,
            },
            {
              label: "rejected",
              value: 5,
            },
          ]}
        />
        <Select
          className={"select-def-input-containe b-select-style flex-grow-1"}
          classNamePrefix={"b-select-style"}
          value={data?.employees?.data?.find(
            (opt) => opt.id === filters.user_id
          )}
          onChange={(v, e) => handleSelect(v, e)}
          isClearable={true}
          isRtl={document?.body?.dir?.toLowerCase() == "rtl"}
          isSearchable
          placeholder={t("employees")}
          options={data?.employees?.data}
          getOptionLabel={(opt) => opt?.name}
          getOptionValue={(opt) => opt?.id}
          isLoading={loading}
          name={" user_id"}
        />
      </div>
      {loading ? (
        <Loader />
      ) : (
        <DataTable
          noDataComponent={<div className="p-4"> {t("no_records")} </div>}
          className="cards_table"
          columns={columns}
          responsive={true}
          data={data?.loanRequests?.data ? data?.loanRequests?.data : []}
          noHeader
          persistTableHead
          pagination={false}
          paginationServer={false}
          progressPending={false}
          progressComponent={<Loader />}
          customStyles={{ tableWrapper: { style: { minWidth: 1100 } } }}
        />
      )}

      <Pagination
        onPaginate={onPaginate}
        customPaginator={pagination}
      />

      {isAcceptModalOpen && (
        <AcceptLoanRequestModal
          isOpen={isAcceptModalOpen?.isOpen}
          close={() => setIsAcceptModalOpen(false)}
          req={isAcceptModalOpen?.req}
          refetch={refetch}
          allowEdit={
            HelperFns.checkPrivileges({
              privileges: [],
              allowBP: true,
            }) ||
            isAcceptModalOpen?.req.pendingAcceptors?.length === 0 ||
            (isAcceptModalOpen?.req.pendingAcceptors.find(
              (a) => a == authUserId
            ) &&
              !Boolean(
                isAcceptModalOpen?.req.layerApproval.find((a) => a?.level === 1)
                  ?.isApproved
              ))
          }
        />
      )}
    </div>
  );
};

export default LoanRequests;
